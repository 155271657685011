import React from "react";
// import { Modal } from "react-bootstrap-v5";
// import { MdEmail, MdPhone, MdLocationOn } from "react-icons/md";
// import { Link } from "react-router-dom";

export default function StoreBooking() {
  // const [show, setShow] = useState(false);
  // const [Editshow, setEditshow] = useState(false);
  // const [DeleteShow, setDeleteShow] = useState(false)

  // const OpenDeleteHandle = () => setDeleteShow(true)
  // const CloseDeletehandle = () => setDeleteShow(false)

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  // const editHandle = () => setEditshow(true)
  // const CloseeditHandle = () => setEditshow(false)

  return (
    <>
      <section className="pb-5 pt-0">
        <div className="header_title">
          <div className="container-fluid">
            <div className="d-flex justify-content-between">
              <h1>Bookings</h1>
              {/* <button type="button" className="themebutton" >Add Stores</button> */}
            </div>
            <img src="assets/images/login_bg.jpg" className="bannerimg"  alt='Not found'  />
          </div>
        </div>
        <div className="container-fluid mt-5">
          <div className="white_box h-100 pb-1">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>S.NO.</th>
                    <th>Store Name</th>
                    <th>Today's Bookings</th>
                    <th>Total Bookings</th>
                    <th>Total Incentive</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Lorem Ipsum</td>
                    <td>45</td>
                    <td>758</td>
                    <td>AED 2056.00</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Lorem Ipsum</td>
                    <td>45</td>
                    <td>758</td>
                    <td>AED 2056.00</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Lorem Ipsum</td>
                    <td>45</td>
                    <td>758</td>
                    <td>AED 2056.00</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>Lorem Ipsum</td>
                    <td>45</td>
                    <td>758</td>
                    <td>AED 2056.00</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Lorem Ipsum</td>
                    <td>45</td>
                    <td>758</td>
                    <td>AED 2056.00</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>Lorem Ipsum</td>
                    <td>45</td>
                    <td>758</td>
                    <td>AED 2056.00</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>Lorem Ipsum</td>
                    <td>45</td>
                    <td>758</td>
                    <td>AED 2056.00</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>Lorem Ipsum</td>
                    <td>45</td>
                    <td>758</td>
                    <td>AED 2056.00</td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>Lorem Ipsum</td>
                    <td>45</td>
                    <td>758</td>
                    <td>AED 2056.00</td>
                  </tr>
                  <tr>
                    <td>10</td>
                    <td>Lorem Ipsum</td>
                    <td>45</td>
                    <td>758</td>
                    <td>AED 2056.00</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
