import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useParams } from 'react-router'
import { authServices } from '../../services';
import { Storage } from '../../Storage';
import { UserDetails } from '../../Redux/action';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import simpleReactValidator from 'simple-react-validator'

export default function Verification(props) {

    const [, setForceUpdate] = useState('')
    const validator = useRef(new simpleReactValidator({ autoForceUpdate: { forceUpdate: setForceUpdate } }))


    const { usertoken } = useParams();

    const dispatch = useDispatch();
    const history = useHistory();

    // const [ErrOTP, SetErrOTP] = useState();

    // const [visible, setVisible] = useState(true);
    // const onDismiss = () => setVisible(false);

    const [Otp, setOtp] = useState({
        user_otp: ""
    })

    const Handlechange = (event) => {
        setOtp({ ...Otp, [event.target.name]: event.target.value })
    }

    const HandleSubmit = async (event) => {
        event.preventDefault();
        // const formData = new FormData();

        let validate = validator.current.allValid()
        if (validate) {
            var Authtoken = usertoken;
            let headers = {
                "Authorization": Authtoken
            }
            await authServices.verification(Otp, headers).then((result) => {
                if (result.status === true) {
                    Storage.set(result.data.user_authtoken)
                    dispatch(UserDetails({ data: result.data }))
                    history.push({
                        pathname: '/',
                        msg: result.message
                    })
                } else {
                    toast.error(result.message);
                }
            });
        } else {
            validator.current.showMessages()
        }
    }

    const Resendotp = async () => {
        const formData = new FormData();

        var Authtoken = usertoken
        let headers = {
            "Authorization": Authtoken
        }

        await authServices.resendotp(formData, headers).then((result) => {
            if (result.status === true) {
                toast.success(result.message)
            }
        });
    }

    useEffect(() => {
        toast.success(props.successmsg);
    }, [])


    return (
        <>
            <ToastContainer draggableDirection="y" />
            <div className="loginpage">
                <img src="https://philconex.com/wp-content/uploads/2021/04/industrial-port-container-yard-2048x1359.jpg" className="loginbg" alt='Not found' />
                <div className="flex-grow-1 text-center loginchar">
                    <img src="/assets/images/shopping_in_UAE.svg" height="500" alt='Not found' />
                </div>
                <div className="loginbox verificationbox">

                    {/* {
                    props.successmsg ?
                        <Alert variant="info" isOpen={visible} toggle={onDismiss}>
                            {props.successmsg}
                        </Alert>
                        :
                        null
                } */}



                    <h2>Verification Code</h2>
                    <p>Please Enter OTP received on your mobile number</p>
                    <form className="w-100" onSubmit={(event) => HandleSubmit(event)}>
                        <div className="form-floating mb-3">
                            <input type="number" className="form-control" id="floatingInput" placeholder="" onChange={(event) => Handlechange(event)} name="user_otp" />
                            <label for="floatingInput">Enter OTP</label>
                            {validator.current.message('otp', Otp.user_otp, 'required|numeric|06', { className: 'text-danger' })}
                        </div>


                        <div className="mt-3">
                            <button className="themebutton w-100" type="submit" >Submit</button>
                        </div>

                        <div className="text-center mt-5">
                            <button className="resendotp" type="button" onClick={() => Resendotp()}>Resend OTP</button>
                        </div>

                    </form>
                </div>
            </div>
        </>
    )
}
