import React from "react";

export default function CancellationPolicy() {
  return (
    <section className="pb-5 pt-0">
      <div className="header_title">
        <div className="container-fluid">
          <h1>Cancellation Policy</h1>
          <img
            src="/assets/images/about-us-inner-banner.jpg"
            className="bannerimg"
            alt="Not found"
          />
        </div>
      </div>
      <div className="container-fluid mt-5">
        <div className="white_box h-100">
          <div className="row">
            <div className="col-md-7">
              <ul className="fancylist">
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                  Full Refund : If the shipment is cancelled within 12 hours from the time of booking, Kens World2Door Cargo LLC will refund the full amount deducting only the bank charges if any and refund will be made onto the Original Mode of Payments. Please allow for upto 45 days for the refund transfer to be completed.
                  </div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    Partial Refund: If the shipment is cancelled after 12 hours from the time of booking, Kens World2Door Cargo LLC will refund partial amount deducting the Pick up charges if any, the Storage Charges, the Handling In and Out charges, Kens World2Door Cargo LLC Fees and any bank charges if any and refund will be made onto the Original Mode of Payments. Please allow for upto 45 days for the refund transfer to be completed.
                  </div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    No Refund : If the shipment is cancelled after 24 hours from the time of booking and the shipment has been dispatched to the port for customs clearance, then Kens World2Door Cargo LLC will not provide any cancellation charges as the shipment has been executed. 
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-md-5">
              <img
                src="/assets/images/cancelation.svg"
                width="100%"
                alt="Not found"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
