import React, { useContext } from 'react'
import {BookingDetailContext} from '../BookingDetails'

const BookingNo = () => {
    const {state, disptach} = useContext(BookingDetailContext);
    if(!state) {
        return 'Loading...'
    } else {
        const info = state.bookingDetails?state.bookingDetails.job_token:''
        return (
           <h3 className="mb-3 d-flex align-items-center">
               <b>Booking ID: {info} </b>{" "}
          </h3>
        )
    }
    
}

export default BookingNo