import React, { useContext } from 'react'
import {BookingDetailContext} from '../BookingDetails'

const SourceDestinaton = () => {
    const {state, disptach} = useContext(BookingDetailContext);
    if(!state) {
        return 'Loading...'
    } else {
        const info = state.bookingDetails.shipment_details
        return (
            <div className="d-flex places col-lg-6 col-12 mx-auto align-items-center justify-content-between mb-4 mt-4">
           
              <div className="fromPlace">
                <span></span>
                <h5 className="mb-0">
                  <small style={{ fontSize: "12px" }}>From</small>
                  <br />
                  {info.origin}
                </h5>
              </div>
              <div className="path flex-grow-1 text-center">
                <span>{info.shipment_mode}</span>
              </div>
              <div className="toPlace">
                <span></span>
                <h5 className="mb-0">
                  <small style={{ fontSize: "12px" }}>To</small>
                  <br />
                  {info.destination}
                </h5>
              </div>
            </div>
        )
    }
    
}

export default SourceDestinaton
