import React, { useEffect, useContext, useState } from 'react'

export default function Pickup() {

    return (
        <React.Fragment>
            <div className='p-5'>
                Pickup
            </div>
        </React.Fragment>
    )

}