import React, { useContext } from 'react'
import { BookingDetailContext } from '../BookingDetails'
const REACT_APP_API_BASEURL = process.env.REACT_APP_API_BASEURL


export default function ShippingDoc() {
    const { state, disptach } = useContext(BookingDetailContext);
    if (!state) {
        return 'Loading...'
    } else {
        const info = state.bookingDetails.document.customer_document
        // console.log(info,'info')

        return (
            <>
                <div className='row'>
                    <h5 className="secHeading">Shipping Document</h5>
                    {info && Array.isArray(info) && info.length > 0 && info[0]?.document_file 
                    && Array.isArray(info[0].document_file) && info[0]?.document_file.length > 0 && info[0]?.document_file.map((item, i) => {
                        // console.log("qwertyt-->", item)
                        return <div className='mt-3 cursor-pointer' xs={6} md={3} onClick={() =>
                            window.open(`${REACT_APP_API_BASEURL}/uploads/document/${item}`)
                        }>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='d-flex'>
                                        <div>
                                            <div className='mr-3'>
                                                <img src="/assets/images/outline.svg" alt="" />
                                            </div>
                                        </div>
                                        <div className='ps-2'>
                                            <div>File Name: {item && typeof item === 'string' ? item : 'N/A'
                                            }</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    )}
                </div>
            </>
        )
    }
}
