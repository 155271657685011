import React from "react";
export default function RefundPolicy() {
  return (
    <section className="pb-5 pt-0">
      <div className="header_title">
        <div className="container-fluid">
          <h1>Refund Policy</h1>
          <img
            src="/assets/images/about-us-inner-banner.jpg"
            className="bannerimg"
            alt="Not found"
          />
        </div>
      </div>
      <div className="container-fluid mt-5">
        <div className="white_box h-100">
          <div className="row">
            <div className="col-md-12 text-center py-4">
              <img
                src="/assets/images/refund.svg"
                alt="Not found"
                className="mw-100 policyImg"
              />
            </div>
            <div className="col-md-12">
              <p>
                Refunds (Full or Part) will be made for only those shipments which fall in the cancellation eligibility policy. The Refunds will be made onto the original mode of payments.
              </p>
              <ul className="fancylist">
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                  n case, where upon the request of customer, Kens World2Door Cargo LLC collects the goods from the premises notified by the customer and the customer later for any reason whatsoever cancels the shipment, the customer shall be liable to pay the charges for local transport, shipment handling charges, warehousing and storage charges as applicable at that time to Kens World2Door Cargo LLC.
                  </div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                  If any payment has been collected in advance against the shipment, in those cases the above charges mentioned for cancellation will be deducted along with the bank transfer charges and only the remaining payment will be refunded to the client.
                  </div>
                </li>
              </ul>
              {/* <h3 className="fw-bold mb-4">Quotation / Pricing Policy</h3>
              <ul className="fancylist">
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    Freight is calculated and charged to you based on the actual
                    weight or volume weight, whichever is higher. The weight and
                    dimensions taken in our warehouse will be considered as
                    final.
                  </div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    The freight & Add On service prices which appear on the
                    World2Door website/app at the time of booking are current
                    rates and are valid for a period of 7 days.
                  </div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    The Freight & Add On service prices which appear on the
                    website/app are non-negotiable.
                  </div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    The quotation is subject to change,
                    <ul>
                      <li>
                        If the actual weight and volume increases or decreases
                        respectively.
                      </li>
                      <li>
                        If the Mode/Service/Destination of Transportation is
                        changed.
                      </li>
                      <li>
                        If any Add On services are opted for at a later stage.
                      </li>
                      <li>
                        If there are any changes in the Add On services
                        selected.
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    For shipments considered Heavy or Oversized, the standard
                    rates appearing on the website/app will not be applicable. A
                    separate customized quotation will be sent to the customer
                    by mail for additional labor. a. Domestic shipments: If a
                    single piece weighs more than 35 kgs or is over-sized
                    (Length + Width + Height in cms is greater than 250 cms) or
                    if any one side is greater than 150 cms, there will be
                    additional labor charges. The cost of per laborer and the
                    number of laborers required will be decided on case to case
                    basis depending upon the actual weight and dimensions. b.
                    International shipments: If a single piece weighs more than
                    35 kgs or is over-sized (Length + Width + Height in cms is
                    greater than 250 cms) or if any one side is greater than 150
                    cms , there will be additional labor charges. For shipments
                    only upto the port, the cost per laborer and the number of
                    laborers required will be decided on case to case basis. The
                    labor cost at the destination will be informed to the
                    customer after checking with our destination partners.
                  </div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    For Fragile shipments the standard rates appearing on the
                    website/app will not be applicable. A separate customized
                    quotation will be sent to the customer by mail.
                  </div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    The inquiries for which the rates does on appear on the
                    website/app , a customized quotation will be sent to the
                    customer by mail.
                  </div>
                </li>
              </ul> */}

              {/* <h3 className="fw-bold mb-4">
                Weight & Size Restrictions on the Carriage
              </h3>
              <ul className="fancylist">
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    Air Freight Mode:
                    <ul>
                      <li>
                        <b>Wide Body Aircraft:</b>
                        <ul>
                          <li>
                            Maximum Dims per piece: (L) 300 x (W) 200 x (H) 157
                            in cms including Pallet
                          </li>
                          <li>
                            Maximum Weight per piece after packing: 500 kgs
                          </li>
                        </ul>
                      </li>
                      <li>
                        <b>Narrow Body Aircraft:</b>
                        <ul>
                          <li>
                            Maximum Dims per piece: (L) 100 x (W) 80 x (H) 80 in
                            cms including Pallet
                          </li>
                          <li>
                            Maximum Weight per piece after packing: 80 kgs
                          </li>
                        </ul>
                      </li>
                      <li>
                        <b>Freighter Aircraft:</b>
                        <ul>
                          <li>
                            Maximum Dims per piece: (L) 300 x (W) 200 x (H) 190
                            in cms including Pallet
                          </li>
                          <li>
                            Maximum Weight per piece after packing: 1000 kgs
                          </li>
                        </ul>
                      </li>
                      <li>
                        <b>Sea Freight Mode:</b>
                        <ul>
                          <li>
                            Maximum Dims per piece: (L) 580 x (W) 200 x (H) 190
                            in cms including Pallet
                          </li>
                          <li>
                            Maximum Weight per piece after packing: 1000 kgs
                          </li>
                        </ul>
                      </li>
                      <li>
                        <b>Land Freight Mode:</b>
                        <ul>
                          <li>
                            Maximum Dims per piece: (L) 580 x (W) 200 x (H) 150
                            in cms including Pallet
                          </li>
                          <li>
                            Maximum Weight per piece after packing: 500 kgs
                          </li>
                        </ul>
                      </li>
                      <li>
                        <b>Courier Mode:</b>
                        <ul>
                          <li>
                            Maximum Dims per piece: (L) 300 x (W) 200 x (H) 190
                            in cms including Pallet
                          </li>
                          <li>
                            Maximum Weight per piece after packing: 500 kgs
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
