import { DataService } from './dataService'

export const paymentGatewayService = {
    postPaymentApi:  async (data) => {
        return DataService.get(`payment/create_payment_link/${data.token}/${data.key}/${data.type}`)
    },
    payInvoice:  async (data) => {
        return DataService.post(`payment/pay_invoice/`, data)
    },
}


export default paymentGatewayService;