import React, { useContext, useState } from 'react'
import { Form } from "react-bootstrap-v5";
import { quoteService, productService } from "../../../services";
import { QuoteDetailContext } from '../GetQuoteDetail'
import { DISCOUNT } from "../../../Redux/type"
import helperFunction from '../helperFunction';

export default function OrderSummary() {
    const [token, setToken] = useState('')
    const { state, dispatch } = useContext(QuoteDetailContext);
    // const [customerDiscount, setCustomerDiscount] = useState((state && state.discount.customerDiscount) || 0)
    const checkToken = () => {
        let postToken = {
            referral_token: token,
        };
        productService.productReferralCode(postToken).then((res) => {
            if (res.status === true) {
                // setCustomerDiscount(res.data.customer_discount)
                dispatch({ type: DISCOUNT, payload: { ...state.discount, customerDiscount: res.data.customer_discount } })
            } else {
                let postPromo = {
                    promo_code: token,
                };
                productService.productPromoCode(postPromo).then((res) => {
                    if (res.status === true) {
                        // setCustomerDiscount(res.data.customer_discount);
                        dispatch({ type: DISCOUNT, payload: { ...state.discount, customerDiscount: res.data.customer_discount } })
                    }
                });
            }
        })
    }



    if (!state) {
        return 'Loading...'
    } else {
        const info = state.quoteDetails
        const insuranceValue = Number(state.insuranceValue)
        const storageValue = Number(state.storageValue)
        const serviceValue = Number(state.serviceValue)
        let discountAmount = 0

        let pckItmes = info.quote_request_items
        let tempCopy = JSON.parse(JSON.stringify(pckItmes))
        let itemchg = tempCopy.length !== 0 && tempCopy.map(item => Number(item.add_ons_items.packing_charges))
        let itemfrt = tempCopy.length !== 0 && tempCopy.map(item => Number(item.add_ons_items.freight_charges))

        const totalChg = itemchg ? itemchg.reduce((total, item) => Number(item) + total, 0) : 0
        const totalFrt = itemfrt ? itemfrt.reduce((total, item) => Number(item) + total, 0) : 0

        let packingAmount = (totalChg + totalFrt).toFixed(0)

        let totalAmt = totalChg + totalFrt + insuranceValue + storageValue + serviceValue
        let customerDiscount = state.discount.customerDiscount
        if (customerDiscount) {
            discountAmount = helperFunction.getReatailerDiscount(customerDiscount, totalAmt)
            totalAmt = totalAmt - discountAmount
            totalAmt = totalAmt.toFixed(0)

            if (state.discount.discountAmount === 0 || state.discount.discountAmount !== discountAmount) {
                // dispatch({ type: DISCOUNT, payload: { customerDiscount, discountAmount } })
            }
        }
        
        return (
            <div className="white_box h-100">
                <h5 className="mb-1">Order Summary</h5>
                <hr />
                <p className="fw-bold my-0 text-success">SERVICE</p>
                <table className="w-100">
                    <tbody>
                        <tr>
                            <td width="150px">{state.serviceType.typeWay || ''} {state.serviceType.type || ''}</td>
                            <td>-</td>

                            <td>AED {serviceValue}</td>
                        </tr>
                    </tbody>
                </table>
                <p className="fw-bold my-0 text-success">ADD-ONS</p>
                <table className="w-100">
                    <tbody>
                        <tr>
                            <td width="150px">Packing</td>
                            <td>-</td>

                            <td>AED {packingAmount}</td>
                        </tr>
                        <tr>
                            <td width="150px">Insurance premium</td>
                            <td>-</td>
                            <td>AED {insuranceValue}</td>
                        </tr>
                        <tr>
                            <td width="150px">Storage</td>
                            <td>-</td>
                            <td>AED {storageValue} </td>
                        </tr>
                    </tbody>
                </table>
                <hr />
                <p className="fw-bold mt-3 mb-0">Enter Referral code / Promo Code</p>
                {/* <small>Sales Staff/Retailer enter your code</small> */}
                <div className="input-group mb-3">
                    <input
                        className="form-control"
                        type="text"
                        placeholder="Code"
                        name="referral_code"
                        onChange={(e) => setToken(e.target.value)}

                    />
                    <button
                        type="button"
                        className="btn btn-dark"
                        onClick={() => checkToken()}
                    >
                        APPLY
                    </button>
                </div>

                <hr />

                <table className="w-100">
                    <tbody>
                        <tr>
                            <td width="150px" className="py-1">
                                Discount
                            </td>
                            <td>-</td>
                            <td>
                                AED {discountAmount ? Math.round(discountAmount) : 0}
                            </td>
                        </tr>
                        <tr>
                            <td width="150px" className="py-1">
                                Net
                            </td>
                            <td>-</td>
                            <td>
                                AED{" "}

                            </td>
                        </tr>
                        <tr>
                            <td width="150px" className="py-1">
                                Sub Total
                            </td>
                            <td>-</td>

                            <td>
                                AED{" "}

                            </td>
                        </tr>
                    </tbody>
                </table>

                <p className="fw-bold mt-3 mb-0 text-success">TAXES</p>

                <table className="w-100">
                    <tbody>
                        <tr>
                            <td width="150px" className="py-1">
                                Vat
                            </td>
                            <td>-</td>
                            <td>AED 0</td>
                        </tr>
                        <tr>
                            <td width="150px" className="py-1">
                                TRN
                            </td>
                            <td>-</td>
                            <td>A100480781200003</td>
                        </tr>
                    </tbody>
                </table>
                <hr />
                <table className="w-100">
                    <tbody>
                        <tr className="py-3 fw-bold">
                            <td width="150px" className="py-1">
                                Total
                            </td>
                            <td>-</td>
                            <td>

                                <td>
                                    AED {totalAmt}

                                </td>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        )
    }
}
