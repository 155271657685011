import React, { useState } from 'react'
import VolumeCalculator from '../Container/home/VolumeCalculator'

export default function HowItWorks() {
    const [isQuote, setIsQuote] = useState(false)
    const [quotes, setQuotes] = useState({
        number_of_packages: '',
        weight: '',
        volume: ''
    })
    const onSubmitClick = (value) => {
        setIsQuote(false)
        setQuotes({
            ...quotes,
            number_of_packages: value.totalPackage,
            weight: value.totalKg,
            volume: value.totalCBM
        })
    }

    const onCloseClick = () => {
        setIsQuote(false)
    }
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const handleShow = () => setShow(true);
    return (

        <section className="pb-5 pt-0">
            <div className="header_title">
                <div className="container-fluid">
                    <h1>How It Works</h1>
                    <img src="/assets/images/country.jpg" className="bannerimg" alt='Not found' />
                </div>
            </div>
            <div className="container-fluid mt-5">
                <div className="white_box h-100">

                    <div className="col-md-12 py-3 how_it_works_img">
                        <img src="/assets/images/how_it_works_img.svg" className="lg-screen" width="100%" alt='Not found' />
                        <img src="/assets/images/how_it_works_img_resp.svg" className="sm-screen" width="100%" alt='Not found' />
                        <button className="btn getquoteBtn mt-5 mb-0" onClick={() => setIsQuote(true)}>Get Quote</button>
                    </div>

                    <VolumeCalculator
                        show={isQuote}
                        onSubmit={(e) => onSubmitClick(e)}
                        onClose={() => onCloseClick()}
                    />

                </div>
            </div>
        </section>

    )
}
