import { useSelector } from "react-redux";
import { Storage } from "../../Storage/Storage";
import { useState } from "react";
import { DutyAndTaxList } from "./DutyAndTaxList";

export default function OrderSummary(props) {
  const [dutyModal,setDutyModal] = useState(false)
  const {
    initServiceAmt,
    deliverType,
    packingamount,
    insurance,
    storage,
    changeRefcode,
    initialrefCode,
    Total,
    freight,
    discount,
    retailerDiscount,
    item,
    onchange,
    percentValue,
    setPercentage,
    retailerPercentage,
    subTotalInit,
    setRetailerPercentage
  } = props;

  const Volumestate = useSelector((state) => state.volumeDetail.volumeData);
  const Volumeseastate = useSelector(
    (state) => state.VolumeSeaDetail.VolumeseaData
  );

  const Volumecourierstate = useSelector(
    (state) => state.VolumeCourierDetail.VolumeCourierdata
  );
  const Volumelandstate = useSelector(
    (state) => state.VolumeLandDetail.VolumeLanddata
  );

  let percent;
  let retailerper = 0;
  if (
    Volumestate &&
    Volumestate.all_data?.discount_data?.referral_token_details
      ?.customer_discount
  ) {
    percent =
      Volumestate.all_data.discount_data.referral_token_details
        .customer_discount;
    retailerper = Volumestate.all_data.discount_data.referral_token_details
      .store_discount
    setPercentage(percent)
    setRetailerPercentage(retailerper)
  } else if (
    Volumestate &&
    Volumestate.all_data?.discount_data?.referral_token_details
      ?.customer_discount
  ) {
    percent =
      Volumestate.all_data.discount_data.referral_token_details
        .customer_discount;
    retailerper = Volumestate.all_data.discount_data.referral_token_details
      .store_discount
    setPercentage(percent)
    setRetailerPercentage(retailerper)
  } else if (
    Volumestate &&
    Volumestate.all_data?.discount_data?.referral_token_details
      ?.customer_discount
  ) {
    percent =
      Volumestate.all_data.discount_data.referral_token_details
        .customer_discount;
    retailerper = Volumestate.all_data.discount_data.referral_token_details
      .store_discount
    setPercentage(percent)
    setRetailerPercentage(retailerper)
  } else if (
    Volumestate &&
    Volumestate.all_data?.discount_data?.referral_token_details
      ?.customer_discount
  ) {
    percent =
      Volumestate.all_data.discount_data.referral_token_details
        .customer_discount;
    retailerper = Volumestate.all_data.discount_data.referral_token_details
      .store_discount
    setPercentage(percent)
    setRetailerPercentage(retailerper)
  }

  if (
    Volumestate &&
    Volumestate.all_data?.discount_data?.promo_code_details?.customer_discount
  ) {
    percent =
      Volumestate.all_data.discount_data.promo_code_details.customer_discount;
    retailerper = Volumestate.all_data.discount_data.promo_code_details.store_discount
    setPercentage(percent)
    setRetailerPercentage(retailerper)
  }


  return (
    <div className="col-md-4">
      <div className="white_box h-100">
        <h5 className="mb-1">Order Summary</h5>
        {/* <small>Temp ID - 656989</small> */}
        <hr />

        {Storage.get("user-token") === "retailer" && (
          <>
            <div className="d-flex justify-content-between">
              <div>
                <h6 className="mb-1">Customer Incentive</h6>
                <small>{discount} AED </small>
              </div>

              <div>
                <h6 className="mb-1">Retailer Incentive</h6>
                <small>{retailerDiscount} AED ({retailerPercentage} %)</small>
              </div>
            </div>
            <hr />
          </>
        )}

        {/* {Storage.get("user-token") === "retailer" && (
            <>
              <h5 className="mb-1">Customer Incentive</h5>
              <small>120 AED</small>
              <hr />
            </>
          )}

          {Storage.get("user-token") === "retailer" && (
            <>
              <h5 className="mb-1">Retailer Incentive</h5>
              <small>100 AED</small>
              <hr />
            </>
          )} */}

        {deliverType && (
          <>
            <h5 className="mb-1">Services</h5>
            <div className="d-flex align-items-center my-2">
              <div className="service_icon me-3">
                {(deliverType == "DOORCOURIER" ||
                  deliverType == "PORTCOURIER" ||
                  deliverType == "CONSOLIDATIONCOURIER") && (
                    <img src="/assets/images/Flat.svg" alt="freight-image" />
                  )}

                {(deliverType == "PORTAIR" ||
                  deliverType == "CONSOLIDATIONAIR" ||
                  deliverType == "DOORAIR") && (
                    <img
                      src="/assets/images/plane.svg"
                      alt="freight-image"
                    />
                  )}

                {(deliverType == "DOORSEA" ||
                  deliverType == "PORTSEA" ||
                  deliverType == "CONSOLIDATIONSEA") && (
                    <img
                      src="/assets/images/shipment.svg"
                      alt="freight-image"
                    />
                  )}

                {(deliverType == "DOORLAND" || deliverType == "PORTLAND") && (
                  <img
                    src="/assets/images/delivery-truck.svg"
                    alt="freight-image"
                  />
                )}
              </div>

              <div>
                {(deliverType == "DOORCOURIER" ||
                  deliverType == "PORTCOURIER" ||
                  deliverType == "CONSOLIDATIONCOURIER") && (
                    <p className="fw-bold my-0">Courier Freight</p>
                  )}

                {(deliverType == "DOORAIR" ||
                  deliverType == "PORTAIR" ||
                  deliverType == "CONSOLIDATIONAIR") && (
                    <p className="fw-bold my-0">Air Freight</p>
                  )}

                {(deliverType == "DOORSEA" ||
                  deliverType == "PORTSEA" ||
                  deliverType == "CONSOLIDATIONSEA") && (
                    <p className="fw-bold my-0">Sea Freight</p>
                  )}

                {(deliverType == "DOORLAND" || deliverType == "PORTLAND") && (
                  <p className="fw-bold my-0">Road Freight</p>
                )}
              </div>
            </div>

            <table className="w-100">
              <tbody>
                {deliverType == "DOORCOURIER" && (
                  <tr>
                    <td width="150px">Door Delivery</td>
                    <td>-</td>
                    <td>
                      AED{" "}
                      {Volumecourierstate &&
                        Volumecourierstate.all_data.door_delivery
                          .door_grant_total}
                    </td>
                  </tr>
                )}
                {deliverType == "PORTCOURIER" && (
                  <tr>
                    <td width="150px">Port Delivery</td>
                    <td>-</td>
                    <td>
                      AED{" "}
                      {Volumecourierstate &&
                        Volumecourierstate.all_data.port_delivery
                          .port_grant_total}
                    </td>
                  </tr>
                )}

                {deliverType == "DOORAIR" && (
                  <tr>
                    <td width="150px">Door Delivery</td>
                    <td>-</td>
                    <td>
                      AED{" "}
                      {Volumestate &&
                        Volumestate.all_data.door_delivery.door_grant_total}
                    </td>
                  </tr>
                )}
                {deliverType == "PORTAIR" && (
                  <tr>
                    <td width="150px">Port Delivery</td>
                    <td>-</td>
                    <td>
                      AED{" "}
                      {Volumestate &&
                        Volumestate.all_data.port_delivery.port_grant_total}
                    </td>
                  </tr>
                )}

                {deliverType == "DOORSEA" && (
                  <tr>
                    <td width="150px">Door Delivery</td>
                    <td>-</td>
                    <td>
                      AED{" "}
                      {Volumeseastate &&
                        Volumeseastate.all_data.door_delivery.door_grant_total}
                    </td>
                  </tr>
                )}
                {deliverType == "PORTSEA" && (
                  <tr>
                    <td width="150px">Port Delivery</td>
                    <td>-</td>
                    <td>
                      AED{" "}
                      {Volumeseastate &&
                        Volumeseastate.all_data.port_delivery.port_grant_total}
                    </td>
                  </tr>
                )}
                {deliverType == "DOORLAND" && (
                  <tr>
                    <td width="150px">Door Delivery</td>
                    <td>-</td>
                    <td>
                      AED{" "}
                      {Volumelandstate &&
                        Volumelandstate.all_data.door_delivery.door_grant_total}
                    </td>
                  </tr>
                )}
                {deliverType == "PORTLAND" && (
                  <tr>
                    <td width="150px">Port Delivery</td>
                    <td>-</td>
                    <td>
                      AED{" "}
                      {Volumelandstate &&
                        Volumelandstate.all_data.port_delivery.port_grant_total}
                    </td>
                  </tr>
                )}

                {deliverType == "CONSOLIDATIONAIR" && (
                  <tr>
                    <td width="150px">Door Consolidation Delivery</td>
                    <td>-</td>
                    <td>
                      AED{" "}
                      {
                        Volumestate.data.consolidation_delivery
                          .consolidation_grant_total
                      }
                    </td>
                  </tr>
                )}

                {deliverType == "CONSOLIDATIONSEA" && (
                  <tr>
                    <td width="150px">Door Consolidation Delivery</td>
                    <td>-</td>
                    <td>
                      AED{" "}
                      {
                        Volumeseastate.data.consolidation_delivery
                          .consolidation_grant_total
                      }
                    </td>
                  </tr>
                )}
                <tr>
                  <td></td>
                  <td></td>
                  {deliverType == "DOORCOURIER" && (

                    <td>

                      Transit {Volumecourierstate &&
                        Volumecourierstate.all_data?.final.door_delivery?.door_delivery_tt} day
                    </td>

                  )}
                  {deliverType == "PORTCOURIER" && (

                    <td>
                      Transit {Volumecourierstate &&
                        Volumecourierstate.all_data?.final.port_delivery?.port_delivery_tt} day
                    </td>

                  )}

                  {deliverType == "DOORAIR" && (

                    <td>
                      Transit {Volumestate &&
                        Volumestate.all_data?.final.door_delivery?.door_delivery_tt} day
                    </td>

                  )}
                  {deliverType == "PORTAIR" && (

                    <td>
                      Transit {Volumestate &&
                        Volumestate.all_data?.final.port_delivery?.port_delivery_tt} day
                    </td>

                  )}

                  {deliverType == "DOORSEA" && (

                    <td>
                      Transit {Volumeseastate &&
                        Volumeseastate.all_data?.final?.door_delivery?.door_delivery_tt} day
                    </td>

                  )}
                  {deliverType == "PORTSEA" && (

                    <td>
                      Transit {Volumeseastate &&
                        Volumeseastate.all_data?.final.port_delivery?.port_delivery_tt} day
                    </td>

                  )}
                  {deliverType == "DOORLAND" && (

                    <td>
                      Transit {Volumelandstate &&
                        Volumelandstate.all_data?.final.door_delivery?.door_delivery_tt} day
                    </td>

                  )}
                  {deliverType == "PORTLAND" && (

                    <td>
                      Transit {Volumelandstate &&
                        Volumelandstate.all_data?.final.port_delivery?.port_delivery_tt} day
                    </td>

                  )}

                  {deliverType == "CONSOLIDATIONAIR" && (

                    <td>
                      Transit {
                        Volumestate.all_data.final?.consolidation_delivery?.consolidation_door_delivery_tt
                      } day
                    </td>

                  )}

                  {deliverType == "CONSOLIDATIONSEA" && (

                    <td>
                      Transit {
                        Volumeseastate.all_data?.final?.consolidation_delivery?.consolidation_delivery_tt
                      } day

                    </td>

                  )}

                  {deliverType == "CONSOLIDATIONCOURIER" && (

                    <td>
                      Transit {
                        Volumecourierstate.all_data.final?.consolidation_delivery?.consolidation_delivery_tt
                      } day

                    </td>

                  )}
                </tr>
              </tbody>
            </table>
            {/* <hr /> */}
          </>
        )}

        <p className="fw-bold my-0">ADD-ONS</p>
        <table className="w-100">
          <tbody>
            <tr>
              <td width="150px">Packing</td>
              <td>-</td>
              {/* <td>AED 0  {totalpacking}</td> */}
              <td>AED {packingamount}</td>
            </tr>
            <tr>
              <td width="150px">Insurance premium</td>
              <td>-</td>
              <td>AED {insurance ? Math.round(insurance) : "0"}</td>
            </tr>
            <tr>
              <td width="150px">Storage ( days )</td>
              <td>-</td>
              <td>AED {storage ? storage : "0"}</td>
            </tr>



          </tbody>
        </table>

        <hr />
        <table className="w-100">
          <tbody>
            <tr>
              <td width="150px">
                <span style={{ color: "#000" }}>Sub Total</span>
              </td>
              <td>-</td>
              <td>
                <span style={{ color: "#000" }}>
                  AED {subTotalInit}
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        <hr />
        <p className="fw-bold mt-3 mb-0">Enter Referral code / Promo Code</p>
        {/* <small>Sales Staff/Retailer enter your code</small> */}
        <div className="input-group mb-3">
          <input
            className="form-control"
            type="text"
            placeholder="Code"
            name="referral_code"
            value={initialrefCode}
            readOnly={item && item.referral_token ? true : false}
            onChange={(e) => changeRefcode(e.target.value)}
          />
          <button
            type="button"
            onClick={() => onchange(initServiceAmt)}
            className="btn btn-dark"
            disabled={item?.referral_token ? true : false}
          >
            APPLY
          </button>
        </div>

        {/* <hr />

          <p className="fw-bold mt-3 mb-0">Enter Promotion code</p>
          <div className="input-group mb-3">
            <input
              className="form-control"
              type="text"
              placeholder="Code"
              name="promotion_code"
              onChange={(e) => setPromoCodeData(e.target.value)}
            />
            <button
              type="button"
              onClick={() => codeHandler("promotion_code")}
              className="btn btn-dark"
            >
              APPLY
            </button>
          </div> */}
        <hr />

        <table className="w-100">
          <tbody>
            <tr>
              <td width="150px" className="py-1">
                Discount
              </td>
              <td>-</td>
              <td>
                AED {discount ? discount : "0"} ({percentValue} %)
              </td>
            </tr>
            {/* <tr>
              <td width="150px" className="py-1">
                Net
              </td>
              <td>-</td>
              <td>
                AED{" "}
                {initServiceAmt || 0}
              </td>
            </tr> */}
            {/* <tr>
              <td width="150px" className="py-1">
                Sub Total
              </td>
              <td>-</td>
              <td>
                AED{" "}
                {subTotalInit}
              </td>
            </tr> */}
          </tbody>
        </table>

        {/* <p className="fw-bold mt-3 mb-0" style={{ color: "#000" }}>TAXES</p> */}

        <table className="w-100">
          <tbody>
            <tr>
              <td width="150px" className="py-1">
                Duty and Tax
              </td> 
              <td>-</td>
              <td 
                onClick={()=> setDutyModal(true)}
                className="ps-3 text-decoration-underline fs-6 cursor-pointer" >Refer to the table</td>
            </tr>
          </tbody>
        </table>
        <table className="w-100">
          <tbody>
            <tr>
              <td width="150px" className="py-1">
                Vat
              </td>
              <td>-</td>
              <td >AED 0</td>
            </tr>
            {/* <tr>
              <td width="150px" className="py-1">
                TRN
              </td>
              <td>-</td>
              <td>A100480781200003</td>
            </tr> */}
          </tbody>
        </table>
        <hr />
        <table className="w-100">
          <tbody>
            <tr className="py-3 fw-bold">
              <td width="150px" className="py-1 text-uppercase" style={{ color: "#000", fontSize: "1rem" }}>
                Net Total
              </td>
              <td>-</td>
              <td>
                {/* AED {discount ? Math.round(discount) : 0} */}
                <td style={{ color: "#000", fontSize: "1rem" }}>
                  AED{" "}
                  {Total
                    ? Math.round(Total ? Total : 0)
                    : freight
                      ? freight
                      : 0}
                </td>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
       <DutyAndTaxList dutyModal={dutyModal} setDutyModal={setDutyModal} />
    </div>
  );
}
