import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap-v5';
import VolumeCalculator from '../Container/home/VolumeCalculator';
import VolumeCalculatorRetailer from '../Container/home/VolumneCalculatorRetailer';
import VolumeCalculatorStore from '../Container/home/VolumeCalculatorStore';
import AutoComplete from './AutoComplete';

import { Storage } from '../Storage'

export default function Slider() {
    const [isQuote, setIsQuote] = useState(false)
    const [isQuote1, setIsQuote1] = useState(false)
    const [isQuote2, setIsQuote2] = useState(false)
    const [quotes, setQuotes] = useState({
        number_of_packages: '',
        weight: '',
        volume: ''
    })


    const [isOpenModal, setisOpenModal] = useState(true)
    const [show, setShow] = useState(false);


    const handleClose = () => {
        setShow(false);
        setisOpenModal(true)
    }


    const onSubmitClick = (value) => {
        setIsQuote(false)
        setisOpenModal(true)
        setQuotes({
            ...quotes,
            number_of_packages: value.totalPackage,
            weight: value.totalKg,
            volume: value.totalCBM
        })
    }

    const onSubmitClick1 = (value) => {
        alert('hello')
    }

    const onCloseClick = () => {
        setIsQuote(!isQuote)
    }
    const onCloseClick1 = () => {
        setIsQuote1(!isQuote1)
    }
    const onCloseClick2 = () => {
        setIsQuote2(!isQuote2)
    }
    return (
        <>
            <div id="carouselExampleControls" className="carousel main-slider slide" data-bs-ride="carousel">
                <div className="getquote_div text-center">
                    {
                        <button type="button" onClick={() => setIsQuote(true)} className="btn getquoteBtn">Get Quote</button>
                        // Storage.get("user-token") !== "retailer" && Storage.get("user-token") !== "store" ?
                        //     <button type="button" onClick={() => setIsQuote(true)} className="btn getquoteBtn">Get Quote</button>
                        //         : Storage.get("user-token") == "store"
                        //         ? <button type="button" className="btn getquoteBtn"
                        //             onClick={() => setIsQuote2(true)} >
                        //             Get quote</button>
                        //         : <button type="button" className="btn getquoteBtn"
                        //             onClick={() => setIsQuote(true)} >
                        //             Get quote </button>
                    }
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active" data-bs-interval="5000">
                        <img src="assets/images/slider/3.jpg" className="w-100 desktop" alt='Not found' />
                        <img src="assets/images/slider/3_mobile.jpg" className="w-100 mobile" alt='Not found' />
                    </div>
                    <div className="carousel-item " data-bs-interval="5000">
                        <img src="assets/images/slider/7.jpg" className="w-100 desktop" alt='Not found' />
                        <img src="assets/images/slider/7_mobile.jpg" className="w-100 mobile" alt='Not found' />
                    </div>
                    <div className="carousel-item" data-bs-interval="5000">
                        <img src="assets/images/slider/2.jpg" className="w-100 desktop" alt='Not found' />
                        <img src="assets/images/slider/2_mobile.jpg" className="w-100 mobile" alt='Not found' />
                    </div>
                    <div className="carousel-item" data-bs-interval="5000">
                        <img src="assets/images/slider/5.jpg" className="w-100 desktop" alt='Not found' />
                        <img src="assets/images/slider/5_mobile.jpg" className="w-100 mobile" alt='Not found' />
                    </div>
                    <div className="carousel-item" data-bs-interval="5000">
                        <img src="assets/images/slider/6.jpg" className="w-100 desktop" alt='Not found' />
                        <img src="assets/images/slider/6_mobile.jpg" className="w-100 mobile" alt='Not found' />
                    </div>
                    <div className="carousel-item" data-bs-interval="5000">
                        <img src="assets/images/slider/1.png" className="w-100 desktop" alt='Not found' />
                        <img src="assets/images/slider/1_mobile.png" className="w-100 mobile" alt='Not found' />
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
            <Modal
                show={show}
                onHide={handleClose}
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Get Quote</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        isOpenModal ? <>
                            <form className={`p-3 p-md-3`}>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group mb-3">
                                            <label>From</label>
                                            <AutoComplete />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group mb-3">
                                            <label>To</label>
                                            <input placeholder="Destination" className="form-control" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group mb-3">
                                            <label>Email</label>
                                            <input placeholder="Email Address" className="form-control" type="text" />
                                        </div>
                                    </div>


                                    <div className="col-6">
                                        <div className="input-group mb-3">
                                            <label>Contact Number</label>
                                            <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ height: "auto" }}>code</button>
                                            <ul className="dropdown-menu">
                                                <li><a className="dropdown-item" href="#">Action</a></li>
                                                <li><a className="dropdown-item" href="#">Another action</a></li>
                                                <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                <li><hr className="dropdown-divider" /></li>
                                                <li><a className="dropdown-item" href="#">Separated link</a></li>
                                            </ul>
                                            <input type="number" className="form-control" aria-label="Text input with dropdown button" />
                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label>No of Pkgs</label>
                                            <input placeholder="Qty" className="form-control" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label>Breadth</label>
                                            <input placeholder="Breadth" className="form-control" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group mb-3">
                                            <label>Height</label>
                                            <input placeholder="Height" className="form-control" type="text" />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group mb-3">
                                            <label>Weight</label>
                                            <input placeholder="Weight" className="form-control" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group mb-3">
                                            <label>Length</label>
                                            <input placeholder="Length" className="form-control" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label>Total Volume (CBM)</label>
                                            <input placeholder="CBM" className="form-control" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label>Total Weight (KG)</label>
                                            <input placeholder="KG" className="form-control" type="text" />
                                        </div>
                                    </div>

                                    <div className="col-md-12 text-center">
                                        <div className="form-group mb-3">
                                            <Link to="/get-qoute" className="themebutton btn d-inline-flex align-items-center justify-content-center">Get Quote</Link>
                                        </div>
                                    </div>
                                </div>
                                <p className="text-center"><a onClick={() => setisOpenModal(false)} className="cursor-pointer">Don't know CBM?</a></p>
                            </form>
                        </> :
                            <>
                                <form className="p-3 p-md-4">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Product Name</label>
                                                <input placeholder="Product Name ex. tv" className="form-control" type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-12 text-center">
                                            <div className="form-group mb-3">
                                                <Link to="/get-qoute" className="themebutton btn d-inline-flex align-items-center justify-content-center">Get Quote</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-center"><a onClick={() => setisOpenModal(true)} className="cursor-pointer">Back</a></p>
                                </form>
                            </>
                    }
                </Modal.Body>
            </Modal>

            <VolumeCalculator
                show={isQuote}
                onSubmit={(e) => onSubmitClick(e)}
                onClose={() => onCloseClick()}
            />
            {/* <VolumeCalculatorRetailer
                show={isQuote1}
                onSubmit={(e) => onSubmitClick1(e)}
                onClose={() => onCloseClick1()}
            /> */}
            <VolumeCalculatorStore
                show={isQuote2}
                onSubmit={(e) => onSubmitClick1(e)}
                onClose={() => onCloseClick2()}
            />
        </>
    )
}
