import React, { useState, useEffect, useRef } from "react";
import { useHistory, Redirect } from "react-router";
import { Modal, Form, Col, Row, Nav, Tab } from "react-bootstrap-v5";
import AutoComplete from "./AutoComplete";
import {
	quoteService,
	productService,
	signupQuoteService,
} from "../../services";
import { UserDetails, UserTempLogin, ItemGoods } from "../../Redux/action";
import { toast } from "react-toastify";
import simpleReactValidator from "simple-react-validator";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import {
	VolumeDetailsAction,
	VolumeSeaAction,
	GetTokenAction,
	VolumeCourierAction,
	VolumeTntlandAction,
} from "../../Redux/action";
import { useDispatch } from "react-redux";
import { Storage } from "../../Storage/Storage";
import getRandomkeys from "./StringGenerator";
import Loader from "../../Components/Loader";
import helperFunction from "../../Components/Quotation/helperFunction"

import { validateEmail } from "../../utils/helper";

export default function VolumeCalculatorStore({ show, onClose }) {
	const [, setForceUpdate] = useState("");
	const validator = useRef(
		new simpleReactValidator({
			autoForceUpdate: { forceUpdate: setForceUpdate },
			messages: {
				required: "required",
			},
		})
	);

	const dispatch = useDispatch();
	const history = useHistory();
	const [loader, setLoader] = useState(false);
	const [usertype, setUsertype] = useState("");
	const [Keyword, setKeyword] = useState(null);
	const [Phonenumber, setPhonenumber] = useState("");
	const [itemValue, setItemValue] = useState([]);
	const [productList, setProductList] = useState([]);
	const [visiblility, setVisiblility] = useState(true);
	const [valLocation] = useState(false);
	const [OrginValue, setOrginValue] = useState("");
	const [DestinationValue, setDestinationValue] = useState("");
	const [goodValue, setGoodValue] = useState("");
	const [knownGoodValue, setKnownGoodValue] = useState("");
	const [dataToPost, setDataToPost] = useState("");
	const [productItemForArray, setProductItemForArray] = useState([]);

	const initial = { CBM: "", TKG: "", PC: "", KG: "", LN: "" };

	const [volumeList, setVolumeList] = useState([initial]);

	const [Codenumber, setCodenumber] = useState("");
	const [email, setEmail] = useState("");

	const [fullName, setFullName] = useState("");
	const [grandTotal, setGrandTotal] = useState({
		number_of_packages: 0,
		volume: 0,
		weight: 0,
	});

	const [productItem, setProductItem] = useState([]);

	useEffect(() => {
		let tpkg = 0,
			tCBM = 0,
			tKg = 0;

		volumeList.map((item) => {
			if (item.PC) {
				tpkg += Number(item.PC);
			}
			if (item.TKG) {
				tCBM += Number(item.CBM);
			}
			if (item.CBM) {
				tKg += Number(item.TKG);
			}
		});

		let tpqn = 0,
			tproductCBM = 0,
			tproductKg = 0;

		productItem &&
			productItem.map((item) => {
				if (item.no_of_pkgs) {
					tpqn += Number(item.no_of_pkgs);
				}
				if (item.total_volume_CBM) {
					tproductCBM += Number(item.total_volume_CBM);
				}
				if (item.total_weight_KG) {
					tproductKg += Number(item.total_weight_KG);
				}
			});

		setGrandTotal({
			number_of_packages: tpkg ? tpkg : tpqn,
			volume: tCBM ? tCBM.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0] : tproductCBM.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0],
			weight: tKg ? tKg : tproductKg,
		});
	}, [volumeList, productItem]);

	const onClickAdd = () => {
		let arry = [...volumeList];
		arry.push(initial);
		setVolumeList(arry);
	};

	const onClickDelete = (ind) => {
		let arry = [...volumeList];
		arry.splice(ind, 1);
		setVolumeList(arry);
	};

	const onChangeEvent = (key, event) => {
		let { name, value } = event.target;
		let arry = [...volumeList];
		let obj = arry[key];

		if (name !== "KG" && name !== "PD") {
			obj["CBM"] = getCBM(obj, name, value);
		}

		if (name === "KG" || name === "PC") {
			obj["TKG"] = getTKg(obj, name, value);
		}

		obj[name] = value;
		arry.splice(key, 1, obj);
		setVolumeList(arry);
	};

	const getTKg = (obj, act, count) => {
		switch (act) {
			case "KG":
				return obj.PC * count;
			case "PC":
				return count * obj.KG;
		}
	};

	const getCBM = (obj, act, count) => {
		switch (act) {
			case "PC":
				return (
					Number(count) *
					((Number(obj.LN) * Number(obj.WI) * Number(obj.HI)) / 1000000)
				).toString().match(/^-?\d+(?:\.\d{0,3})?/);
			case "LN":
				return (
					Number(obj.PC) *
					((Number(count) * Number(obj.WI) * Number(obj.HI)) / 1000000)
				).toString().match(/^-?\d+(?:\.\d{0,3})?/);
			case "WI":
				return (
					Number(obj.PC) *
					((Number(obj.LN) * Number(count) * Number(obj.HI)) / 1000000)
				).toString().match(/^-?\d+(?:\.\d{0,3})?/);
			case "HI":
				return (
					Number(obj.PC) *
					((Number(obj.LN) * Number(obj.WI) * Number(count)) / 1000000)
				).toString().match(/^-?\d+(?:\.\d{0,3})?/);
		}
	};

	// const isVisible = true;
	const toggleForm = () => {
		setVisiblility(!visiblility);
		setVolumeList([initial]);
		setOrginValue("");
		setDestinationValue("");
		setProductList([]);
		setProductItem([]);
		setProductItem([]);
		setItemValue([]);
	};

	const getOriginData = (e) => {

		if (e === "") {
			setOrginValue("");
		} else {
			let originData = {
				origin_country: e.country,
				origin_country_short_name: e.country_short_name,
				origin_city: e.city,
				origin_latitude: e.latitude,
				origin_longitude: e.longitude,
			};
			setOrginValue(originData);
		}
	};

	const getDestinationData = (e) => {
		console.log("e ....", e);
		if (e === "") {
			setDestinationValue("");
		} else {
			let destinationData = {
				destination_country: e.country,
				destination_country_short_name: e.country_short_name,
				destination_city: e.city,
				destination_latitude: e.latitude,
				destination_longitude: e.longitude,
			};
			setDestinationValue(destinationData);
		}
	};

	const searchHandler = (event) => {
		if (event.length >= 2) {
			setKeyword(event);
		}
	};

	/// for product search

	const handleChange = (getvalue) => {
		setItemValue(getvalue);
		new_conditionFun(getvalue);
	};

	const new_conditionFun = (my_selected_arr) => {
		let newArrayWithQunatity = [],
			firstItemArray = [];

		// console.log(my_selected_arr);
		if (my_selected_arr) {
			my_selected_arr.map((elem, i) => {
				let obj = productItem.find((o) => o.item_id === elem.data._id);
				if (productItem.length && obj !== undefined) {
					firstItemArray.push({
						item_id: elem.data._id,
						item_description: elem.data.product_name,
						no_of_pkgs: "",
						dimension: {
							kilogram: elem.data.product_dimentions[0].weight,
							length: elem.data.product_dimentions[0].length,
							width: elem.data.product_dimentions[0].width,
							height: elem.data.product_dimentions[0].height,
						},
						total_volume_CBM: elem.data.product_dimentions[0].volume,
						total_weight_KG: elem.data.product_gross_weight,
					});
					elem.data?.product_dimentions.length &&
						elem.data?.product_dimentions.map((product) => {
							newArrayWithQunatity.push({
								item_id: elem.data._id,
								item_description: elem.data.product_name,
								no_of_pkgs: "",
								dimension: {
									kilogram: product.weight,
									length: product.length,
									width: product.width,
									height: product.height,
								},
								total_volume_CBM: product.volume,
								total_weight_KG: elem.data.product_gross_weight,
							});
						});
				} else {
					firstItemArray.push({
						item_id: elem.data._id,
						item_description: elem.data.product_name,
						no_of_pkgs: "",
						dimension: {
							kilogram: elem.data.product_dimentions[0].weight,
							length: elem.data.product_dimentions[0].length,
							width: elem.data.product_dimentions[0].width,
							height: elem.data.product_dimentions[0].height,
						},
						total_volume_CBM: elem.data.product_dimentions[0].volume,
						total_weight_KG: elem.data.product_gross_weight,
					});
					elem.data?.product_dimentions.length &&
						elem.data?.product_dimentions.map((product) => {
							newArrayWithQunatity.push({
								item_id: elem.data._id,
								item_description: elem.data.product_name,
								no_of_pkgs: "",
								dimension: {
									kilogram: product.weight,
									length: product.length,
									width: product.width,
									height: product.height,
								},
								total_volume_CBM: product.volume,
								total_weight_KG: elem.data.product_gross_weight,
							});
						});
				}
			});
			setProductItem(firstItemArray);
			setProductItemForArray(newArrayWithQunatity);
			setItemValue(my_selected_arr);
		}
	};


	const mychangeEvent = (key, event, data) => {
		let newArraytwo = [...productItem];
		let newArrayProduct = [...productItemForArray];
		newArraytwo.map((elem, i) => {
			if (i === key) {
				elem.no_of_pkgs = event.target.value;
			}
		});

		newArrayProduct.map((item) => {
			if (item.item_id === data) {
				item.no_of_pkgs = event.target.value;
			}
		});
		setProductItem(newArraytwo);
		setProductItemForArray(newArrayProduct);
	};

	const onSecondClickDelete = (indtwo, data) => {
		let newArraytwo = [...productItem];
		newArraytwo.splice(indtwo, 1);
		setProductItem(newArraytwo);

		let dropdownArray = [...itemValue];
		dropdownArray.splice(indtwo, 1);
		setItemValue(dropdownArray);

		const filteredProduct = productItemForArray.filter(
			(item) => item.item_id !== data
		);
		setProductItemForArray(filteredProduct);
	};

	useEffect(() => {
		const timeOut = setTimeout(() => {
			getProductlist();
		}, 1000);
		return () => {
			clearTimeout(timeOut);
		};
	}, [Keyword]);

	// search: Keyword && Keyword.length >= 2 ? Keyword  :  null,

	const getProductlist = () => {
		let searchData = {
			search: Keyword,
		};
		productService.getProduct(searchData).then((result) => {
			if (result.status === true) {
				let Productarray = result.data.map((item) => ({
					value: item._id,
					label: item.product_name,
					data: item,
				}));
				setProductList(Productarray);
			} else {
				// toast.error(result.message);
			}
		});
	};

	const submitQuote = (serviceType) => {
		let postData = null;
		let random_key = getRandomkeys();
		dispatch(GetTokenAction(random_key));

		if (serviceType === "table") {
			if (volumeList.length >= 1) {
				let knownData = volumeList.map((item) => ({
					item_id: "",
					item_description: item.PD,
					no_of_pkgs: Number(item.PC),
					dimension: {
						kilogram: Number(item.KG),
						length: Number(item.LN),
						width: Number(item.WI),
						height: Number(item.HI),
					},
					total_volume_CBM: Number(item.CBM),
					total_weight_KG: Number(item.PC * item.KG),
					item_token: helperFunction.stringGenerator(24)
				}));

				postData = {
					...OrginValue,
					...DestinationValue,
					number_of_packages: grandTotal.number_of_packages,
					volume: grandTotal.volume <= 0.000 ? 0.00 : parseFloat(grandTotal.volume),
					weight: grandTotal.weight,
					items_goods: Number(goodValue),
					items: knownData,
					quote_token: random_key,
				};
			}

			var validate =
				+validator.current.fieldValid("search") &&
				validator.current.fieldValid("PD") &&
				validator.current.fieldValid("PC") &&
				validator.current.fieldValid("KG") &&
				validator.current.fieldValid("LN") &&
				validator.current.fieldValid("WI") &&
				validator.current.fieldValid("HI") &&
				validator.current.fieldValid("good value");

		} else if (serviceType === "filter") {
			if (productItemForArray.length > 0) {
				let grandWeight = 0;
				let grandVolume = 0;
				let array = [];
				productItemForArray &&
					productItemForArray.map((ele) => {
						array.push({
							...ele,
							total_weight_KG: Number(ele.no_of_pkgs * ele.dimension.kilogram),
							total_volume_CBM: Number(ele.no_of_pkgs * ele.total_volume_CBM),
							item_token: helperFunction.stringGenerator(24)
						});
						grandWeight += Number(ele.no_of_pkgs * ele.dimension.kilogram);
						grandVolume += Number(ele.no_of_pkgs * ele.total_volume_CBM);
					});

				postData = {
					...OrginValue,
					...DestinationValue,
					number_of_packages: grandTotal.number_of_packages,
					volume: parseFloat(grandVolume),
					weight: grandWeight,
					items_goods: Number(knownGoodValue),
					items: array,
					quote_token: random_key,
				};
			}

			var validate =
				+validator.current.fieldValid("search") &&
				validator.current.fieldValid("filter") &&
				validator.current.fieldValid("quantity") &&
				validator.current.fieldValid("know value");
		}

		if (validate) {
			if (Storage.get("user-token") === "store") {
				setDataToPost(postData);
				toggleForm();
			} else {
				setDataToPost(postData);
				toggleForm();
			}
		} else {
			validator.current.showMessages();
		}
	};

	// code  for serce  user info way email
	const checkEmail = (email) => {
		validateEmail(email) && quoteService
			.checkEmailApi({ user_email: email })
			.then((result) => {
				if (result.status === true) {

					// setReffCheck(true);
					setEmail(email);
					setFullName(result.data.user_full_name ? result.data.user_full_name : '')
					setPhonenumber(result.data.user_phone_number);
					setCodenumber(result.data.user_dial_code);
					setUsertype(result.data.user_type)
				} else {
					setEmail("");
					setEmail(email);
					// setReffCheck(false);
					validator.current.showMessageFor("email");
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// end code


	const apiCallFunction = async (data) => {
		const quoteToken = await productService.getQuoteToken();
		data.quote_token = quoteToken.data;
		const courierReQuotedata = await quoteService
			.postCourierQuoteStore(data);
		const airReQuotedata = await quoteService
			.postQuoteStore(data);
		const seaReQuotedata = await quoteService
			.postSeaQuoteStore(data);
		const landReQuotedata = await quoteService
			.postLandQuoteStore(data);

		const promise1 = courierReQuotedata;
		const promise2 = airReQuotedata;
		const promise3 = seaReQuotedata;
		const promise4 = landReQuotedata;

		return Promise.all([promise1, promise2, promise3, promise4]).then((response) => {
			if (
				(Array.isArray(response) && response.length && response[0].status === true) &&
				(Array.isArray(response) && response.length && response[1].status === true) &&
				(Array.isArray(response) && response.length && response[2].status === true) &&
				(Array.isArray(response) && response.length && response[3].status === true)
			) {
				setLoader(true);

				dispatch(VolumeCourierAction(response[0]));
				dispatch(VolumeDetailsAction(response[1]));
				dispatch(VolumeSeaAction(response[2]));
				dispatch(VolumeTntlandAction(response[3]));
				setVolumeList([initial])
				onClose()
				history.push("/get-quote");
			} else {
				setLoader(false);
			}
		});

	};


	const setQuotesPhone = (event, g) => {
		// setPhonenumber(event);
		if (g.target.value) {
			let num = g.target.value
				.split(" ")
				.slice(1)
				.join("")
				.replace(/[^a-zA-Z0-9]/g, "");
			let code = g.target.value.split(" ")[0];
			// matchNumber(Phonenumber);

			setPhonenumber(num);
			setCodenumber(code);
		}
	};

	const signupQuotesSubmit = (e) => {
		e.preventDefault();

		let Quotesdata = {
			user_email: email,
			user_full_name: fullName,
			user_phone_number: Phonenumber,
			user_dial_code: Codenumber,
			user_type: usertype,
			is_registered: false
		};

		let phoneValid = validator.current.fieldValid("email");

		switch (true) {
			case Quotesdata:
				phoneValid += validator.current.fieldValid("contact");
				validator.current.fieldValid("usertype");
				break;
		}

		if (phoneValid) {
			setLoader(true);
			let updatedData = dataToPost
			updatedData.referral_token = Storage.get('user-auth')?.retailer_detail[0]?.referral_token;
			setDataToPost(updatedData)

			signupQuoteService
				.signupQuotes(Quotesdata)
				.then((result) => {
					if (result.status === true) {
						toast.success(result.message);
						dataToPost.user_id = result.data._id;
						apiCallFunction(dataToPost, result.data._id);
					} else {
						toast.error(result.message);
						setLoader(false);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			validator.current.showMessages();
		}
	};

	const handleGoodValue = (value) => {
		setGoodValue(value)
		dispatch(ItemGoods(value))
	}


	return (
		<>
			<Modal show={show} onHide={() => onClose()} size="xl">
				<div className="text-end pe-3">
					<span className="cursor-pointer" onClick={() => onClose()}>Close</span>
				</div>
				<div className="modal-body">
					<div className="row">
						{visiblility ? (
							<Tab.Container
								id="left-tabs-example"
								className={valLocation ? "active" : "ddd"}
								defaultActiveKey="first_"
							>
								<div className="col-12 px-3">
									<h5 className="mb-3">Select Location</h5>
									<Form className="pb-4 ">
										<Row>
											<Col md={6}>
												<label>From</label>
												<AutoComplete getData={(e) => getOriginData(e)} />
												{validator.current.message(
													"search",
													OrginValue,
													"required",
													{ className: "text-danger text-capitalize" }
												)}
											</Col>
											<Col md={6}>
												<label>To</label>
												<AutoComplete getData={(e) => getDestinationData(e)} />
												{validator.current.message(
													"search",
													DestinationValue,
													"required",
													{ className: "text-danger text-capitalize" }
												)}
											</Col>
										</Row>
									</Form>
									<hr />
								</div>
								<Row>
									<h5 className="mb-3 text-center">Cargo Details</h5>
									<Col md={8} className="mx-auto text-center">
										<Nav variant="pills" className="mb-4">
											<Nav.Item className="col mx-1">
												<Nav.Link
													className="outline-primary square-gradient"
													eventKey="first"
												>
													(Weight & Dimension) Of Each <br /> Package Available
													<br />
													<b>Get Rates</b>
												</Nav.Link>
											</Nav.Item>
											<Nav.Item className="col mx-1">
												<Nav.Link
													className="outline-primary square-gradient"
													eventKey="second"
												>
													(Weight & Dimension) Of Each <br /> Package Not
													Available <br />
													<b>Get Rates</b>
												</Nav.Link>
											</Nav.Item>
										</Nav>
									</Col>

									<Col sm={12}>
										<Tab.Content>
											<Tab.Pane eventKey="first">
												<div className="col-12 px-3">
													<hr />
													<h5 className="mb-3 center">
														Enter Product Information
													</h5>
													<div className="table-responsive">
														<table className="table table-bordered text-center volume-calcu">
															<thead>
																<tr className="lh-sm">
																	<th rowspan="2" className="align-middle">
																		S.no.
																	</th>
																	<th rowspan="2" className="align-middle">
																		Item
																		<br />
																		Description
																	</th>
																	<th rowspan="2" className="align-middle">
																		No. of Pkgs
																	</th>
																	<th colspan="4">
																		Weight & Dimension per Package
																	</th>
																	<th rowspan="2" className="align-middle">
																		Total Volume
																		<br />
																		(CBM)
																	</th>
																	<th rowspan="2" className="align-middle">
																		Total Weight
																		<br />
																		(KG)
																	</th>
																	<th rowspan="2" className="align-middle">
																		Action
																	</th>
																</tr>
																<tr>
																	<th>Kilogram</th>
																	<th>Length</th>
																	<th>width</th>
																	<th>Height</th>
																</tr>
															</thead>
															<tbody>
																{volumeList.length > 0 &&
																	volumeList.map((list, key) => (
																		<tr key={key}>
																			<td>{key + 1}</td>
																			<td>
																				<input
																					className="tbl-input"
																					type="text"
																					onChange={(e) =>
																						onChangeEvent(key, e)
																					}
																					name="PD"
																					value={list.PD}
																					placeholder="Description"
																				/>
																				{validator.current.message(
																					"PD",
																					list.PD,
																					"required",
																					{ className: "text-danger customMsg" }
																				)}
																			</td>

																			<td>
																				<input
																					className="tbl-input"
																					type="number"
																					min="1"
																					onChange={(e) =>
																						onChangeEvent(key, e)
																					}
																					name="PC"
																					value={list.PC}
																					placeholder="No. of Count"
																				/>
																				{validator.current.message(
																					"PC",
																					list.PC,
																					"required",
																					{ className: "text-danger customMsg" }
																				)}
																			</td>

																			<td>
																				<input
																					min="1"
																					className="tbl-input"
																					type="number"
																					onChange={(e) =>
																						onChangeEvent(key, e)
																					}
																					name="KG"
																					value={list.KG}
																					placeholder="KG"
																				/>

																				{validator.current.message(
																					"KG",
																					list.KG,
																					"required",
																					{ className: "text-danger customMsg" }
																				)}
																			</td>

																			<td>
																				<input
																					min="1"
																					className="tbl-input"
																					type="number"
																					onChange={(e) =>
																						onChangeEvent(key, e)
																					}
																					name="LN"
																					value={list.LN}
																					placeholder="CM"
																				/>
																				{validator.current.message(
																					"LN",
																					list.LN,
																					"required",
																					{ className: "text-danger customMsg" }
																				)}
																			</td>

																			<td>
																				<input
																					min="1"
																					className="tbl-input"
																					type="number"
																					onChange={(e) =>
																						onChangeEvent(key, e)
																					}
																					name="WI"
																					value={list.WI}
																					placeholder="CM"
																				/>
																				{validator.current.message(
																					"WI",
																					list.WI,
																					"required",
																					{ className: "text-danger customMsg" }
																				)}
																			</td>

																			<td>
																				<input
																					min="1"
																					className="tbl-input"
																					type="number"
																					onChange={(e) =>
																						onChangeEvent(key, e)
																					}
																					name="HI"
																					value={list.HI}
																					placeholder="CM"
																				/>
																				{validator.current.message(
																					"HI",
																					list.HI,
																					"required",
																					{ className: "text-danger customMsg" }
																				)}
																			</td>

																			<td>
																				<input
																					className="tbl-input"
																					type="number"
																					disabled
																					name="CBM"
																					value={list.CBM <= 0.000 ? 0.001 : list.CBM}
																					placeholder="CBM"
																				/>
																			</td>

																			<td>
																				<input
																					className="tbl-input"
																					type="number"
																					disabled
																					name="TKG"
																					value={list.TKG}
																					placeholder="KG"
																				/>
																			</td>

																			<td>
																				<img
																					className="btn"
																					height="20"
																					src="/assets/images/delete.svg"
																					onClick={() => onClickDelete(key)}
																					alt="No found"
																				/>
																			</td>
																		</tr>
																	))}
															</tbody>
															<tfoot>
																<tr>
																	<th className="text-end">Total</th>
																	<th colSpan="1"></th>
																	<th colSpan="1">
																		{grandTotal.number_of_packages}
																	</th>
																	<th colSpan="4"></th>
																	<th className="text-center">
																		{grandTotal.volume <= 0.000 ? 0.001 : grandTotal.volume}
																	</th>
																	<th className="text-center">
																		{grandTotal.weight}
																	</th>
																	<th></th>
																</tr>
															</tfoot>
														</table>
													</div>
													<div className="d-flex justify-content-between">
														<div className="row">
															<div className="col-md-4 text-left">
																<p className="me-2 mb-0 fw-bold">
																	Total Shipment Value
																</p>
															</div>
															<div className="col-md-6">
																<input
																	className="form-control"
																	type="number"
																	min="1"
																	name="items_value"
																	// onChange={(e) => setGoodValue(e.target.value)}
																	onChange={(e) => handleGoodValue(e.target.value)}
																/>

																{validator.current.message(
																	"good value",
																	goodValue,
																	"required",
																	{ className: "text-danger" }
																)}
															</div>
														</div>

														<div className="text-right">
															<button
																className="themebutton px-4 ms-auto"
																onClick={() => onClickAdd()}
															>
																+ Add Item
															</button>
														</div>
													</div>

													<hr />

													<div className="row text-center">
														<div className="col-md-12">
															<button
																type="button"
																className="themebutton px-4"
																onClick={() => submitQuote("table")}
															>
																GET RATES
															</button>
														</div>
													</div>
												</div>
											</Tab.Pane>
											<Tab.Pane eventKey="second">
												<div className="col-12  px-3">
													<hr />
													<div className="d-flex justify-content-center mb-3">
														<div className="col-6">
															<h5 className="mb-3 text-center">
																Select Your Product
															</h5>

															{productList && (
																<ReactMultiSelectCheckboxes
																	className="Volumeselect form-control"
																	options={productList}
																	value={itemValue}
																	onChange={handleChange}
																	onInputChange={(e) => searchHandler(e)}
																	placeholder="Search Product"
																	name="item_description"
																// setState={productList}
																/>
															)}

															{validator.current.message(
																"filter",
																itemValue,
																"required",
																{ className: "text-danger" }
															)}
														</div>
													</div>

													<div className="table-responsive_">
														<table className="table table-bordered text-center volume-calcu">
															<thead>
																<tr className="lh-sm">
																	<th rowspan="2" className="align-middle">
																		S.no.
																	</th>
																	<th rowspan="2" className="align-middle">
																		Item Description
																	</th>
																	<th rowspan="2" className="align-middle">
																		Quantity
																	</th>
																	<th rowspan="2" className="align-middle">
																		Action
																	</th>
																</tr>
															</thead>

															<tbody>
																{productItem &&
																	productItem.length > 0 &&
																	productItem.map((item, key) => (
																		<tr key={key}>
																			<td>{key + 1}</td>

																			<td>
																				<input
																					type="text"
																					value={item.item_description}
																					className="form-control"
																					readOnly
																				></input>
																				{validator.current.message(
																					"description",
																					item.item_description,
																					"required",
																					{ className: "text-danger customMsg" }
																				)}
																			</td>

																			<td>
																				<input
																					className="tbl-input"
																					type="number"
																					min="1"
																					name="no_of_pkgs"
																					// value={item.no_of_pkgs}
																					onChange={(e) =>
																						mychangeEvent(key,
																							e,
																							item.item_id)
																					}
																				/>
																				{validator.current.message(
																					"quantity",
																					item.no_of_pkgs,
																					"required",
																					{ className: "text-danger customMsg" }
																				)}
																			</td>
																			<td>
																				<img
																					className="btn"
																					height="20"
																					src="/assets/images/delete.svg"
																					onClick={() =>
																						onSecondClickDelete(key,
																							item.item_id)
																					}
																					alt=" Not found"
																				/>
																			</td>
																		</tr>
																	))}
															</tbody>
														</table>

														<div className="row justify-content-end">
															<div className="col-md-4 text-end">
																<p className="me-2 mb-0 fw-bold">
																	Total Shipment Value
																</p>
															</div>
															<div className="col-md-3">
																<input
																	className="form-control"
																	type="number"
																	min="1"
																	name="items_value"
																	onChange={(e) =>
																		setKnownGoodValue(e.target.value)
																	}
																/>
																<div>
																	{validator.current.message(
																		"know value",
																		knownGoodValue,
																		"required",
																		{ className: "text-danger" }
																	)}
																</div>
															</div>
														</div>
													</div>

													<div className="row text-center">
														<div className="col-md-12">
															<button
																type="button"
																className="themebutton px-4"
																onClick={() => submitQuote("filter")}
															>
																GET RATES
															</button>
														</div>
													</div>
												</div>
											</Tab.Pane>
										</Tab.Content>
									</Col>
								</Row>
							</Tab.Container>
						) : (
							<Form
								className="px-5 pt-3"
								onSubmit={(e) => signupQuotesSubmit(e)}
							>
								<div className="row">
									<Col className="col-md-12">
										<Row>
											<Col md={5}>
												<h5 className="mb-3 center">
													Enter Personal Information
												</h5>
												<Row className="mt-3">
													<Col md={12}>
														<Form.Group className="mb-3" controlId="Email">
															<label className="mb-2">Email</label>
															<Form.Control
																type="email"
																name="user_email"
																placeholder="Enter Email"
																onChange={(e) => checkEmail(e.target.value)}
															/>
															{validator.current.message(
																"email",
																email,
																"required|email",
																{ className: "text-danger" }
															)}
														</Form.Group>
													</Col>
													<Col md={12}>
														<Form.Group className="mb-3">
															<label className="mb-2">Full Name</label>
															<Form.Control
																type="text"
																name="user_full_name"
																placeholder="Enter Full Name"
																value={fullName}
																onChange={(e) =>
																	setFullName(e.target.value)
																}
															/>
															{validator.current.message(
																"user_full_name",
																fullName,
																"required",
																{ className: "text-danger" }
															)}
														</Form.Group>
													</Col>
													<Col md={12}>
														<Form.Group className="mb-3" controlId="code">
															<label className="mb-2">Mobile Number</label>
															<PhoneInput
																className="telinput"
																country={"ae"}
																enableSearch
																enableAreaCodes={true}
																value={Codenumber + Phonenumber}
																onChange={(phone, f, g) =>
																	setQuotesPhone(phone, g)
																}
															/>
															{validator.current.message(
																"contact",
																Phonenumber,
																"required",
																{ className: "text-danger" }
															)}
														</Form.Group>
													</Col>

													<Col md={12}>
														<p className="mb-0">User Types</p>
														<div className="form-check mt-2">
															<input
																className="form-check-input"
																type="radio"
																name="inlineRadioOptions"
																id="inlineRadio1"
																value="TOURIST"
																checked={usertype === "TOURIST" ? true : false}
																onChange={(e) => setUsertype(e.target.value)}
															/>
															<label
																className="form-check-label"
																for="inlineRadio1"
															>
																Tourist
															</label>
														</div>
														<div className="form-check mt-2">
															<input
																className="form-check-input"
																type="radio"
																name="inlineRadioOptions"
																id="inlineRadio2"
																value="RESIDENT"
																checked={usertype === "RESIDENT" ? true : false}
																onChange={(e) => setUsertype(e.target.value)}
															/>
															<label
																className="form-check-label"
																for="inlineRadio2"
															>
																Residents
															</label>
														</div>
														<div className="form-check mt-2">
															<input
																className="form-check-input"
																type="radio"
																name="inlineRadioOptions"
																id="inlineRadio3"
																value="OVERSEASCUSTOMER"
																checked={usertype === "OVERSEASCUSTOMER" ? true : false}
																onChange={(e) => setUsertype(e.target.value)}
															/>
															<label
																className="form-check-label"
																for="inlineRadio3"
															>
																Overseas Shoppers
															</label>
														</div>
														{validator.current.message(
															"usertype",
															usertype,
															"required",
															{ className: "text-danger" }
														)}
													</Col>
													<Col md={12} className="mb-3 mt-4 mx-auto">
														<Form.Group className="d-flex mb-3 justify-content-between" controlId="Email">
															<button
																type="submit"
																className="themebutton px-4"
																onClick={() => toggleForm()}
															>
																Back
															</button>
															<button
																type="submit"
																className="themebutton px-4"
															>
																Request
															</button>
														</Form.Group>
													</Col>
												</Row>
											</Col>
											<Col md={7}>
												<div className="infobg">
													<img
														src="/assets/images/shoppingvector.jpg"
														alt=" Not found"
													/>
												</div>
											</Col>
										</Row>
									</Col>
								</div>
							</Form>
						)}
					</div>
				</div>
				{loader && (
					<div className="loader_lst">
						<img
							src="/assets/images/JOP.gif"
							className="bannerimg"
							alt="Not found"
						/>
					</div>
				)}
			</Modal>

		</>
	);
}
