import React, { useState } from 'react'
import { Modal } from "react-bootstrap-v5";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { paymentGatewayService, quoteService } from "../services"
import Loader from "./Loader";
import { useDispatch } from "react-redux";
import { proceedAction } from "../Redux/action";
import { Storage } from "../Storage";
import helperFunction from "./Quotation/helperFunction";

export default function ConfirmationModel({ show, data, onClose }) {

    const dispatch = useDispatch();

    const history = useHistory()
    const [checked, setChecked] = useState(false);
    const [loader, setLoader] = useState(false);



    const termsAccept = () => {
        setChecked(!checked)
    }

    const addTerms = () => {

        if (checked) {
            // console.log("data", JSON.stringify(data))
            setLoader(true);
            if ((Storage.get("user-token") === "retailer") || (Storage.get("user-token") === "store")) {
                quoteService.proceedAddToJobRetailer(data).then(res => {
                    if (res.status === true) {
                        dispatch(proceedAction(res.job_data))
                        history.push('/');
                    } else {
                        setLoader(false);
                        toast.error(res.message)
                    }
                }).catch(error => { console.log(error) })
            } else {
                quoteService.proceedAddToJob(data).then(res => {
                    if (res.status === true) {
                        performPaymentGateway();
                        dispatch(proceedAction(res.job_data))
                    } else {
                        toast.error(res.message)
                        setLoader(false);
                    }
                }).catch(error => { console.log(error) })
            }

        } else {
            toast.info('Please accept terms and policy before proceeding.')
        }
    }


    const performPaymentGateway = () => {

        let postData = {
            token: data.quote_token,
            key: helperFunction.stringGenerator(15),
            type: data.selected_courier_type
        }

        paymentGatewayService.postPaymentApi(postData).then(res => {
            if (res.status === true) {
                setLoader(false);
                onClose();
                window.location.href = res.data.url;
            } else {
                setLoader(false);
                toast.error(res.data.message);
                toast.error(res.message);
            }
        })

    }

    return (
        <>
            <Modal show={show} size="xl">




            <section className="pb-5 pt-0">
			<div className="container-fluid mt-5">
					<div className="row">
						<div className="col-md-12">
							<h3 className="fw-bold mb-4">Important Notice</h3>
							<p>
								This website/app is operated by, or on behalf of Kens World2Door Cargo LLC for providing the services of freight transport and management. By using the website/app you agree to comply with and be bound by the following “Terms and Conditions”.
							</p>
							<p>
								We reserve the right to amend these “Terms and Conditions” from time to time. Any changes will be posted on our website.
							</p>
							<h3 className="fw-bold mb-4">Scope of Application</h3>
							<p>
								The terms & conditions shall apply to all the shipments irrespective of any consideration to the size, volume and country of shipment or country of ultimate destination for all the services provided by Kens World2Door Cargo LLC. Deviations from these terms & conditions shall not apply unless agreed specifically in writing by Kens World2Door Cargo LLC.
							</p>
							<h3 className="fw-bold mb-4">REGISTRATION OF THE CUSTOMER</h3>
							<p>
								Where a customer intends to avail the services of Kens World2Door Cargo LLC, the customer requires registration on our website/app (Whichever is applicable) providing all the information accurately required in this regard.
							</p>
							<h3 className="fw-bold mb-4">Conditions for the Registration</h3>
							<ul className="fancylist">
								<li>
									<span>
										<img src="assets/images/checked.svg" alt="Not found" />
									</span>
									<div>
										Customer must be Major under the laws of applicable jurisdiction to create an account on our website/app to avail our services.
									</div>
								</li>
								<li>
									<span>
										<img src="assets/images/checked.svg" alt="Not found" />
									</span>
									<div>
										All the personal details you put on our website/App for the registration to avail our services must be true, accurate, complete and current. In case of change Customer must update on their account promptly.
									</div>
								</li>
								<li>
									<span>
										<img src="assets/images/checked.svg" alt="Not found" />
									</span>
									<div>
										Customer is responsible for maintaining the confidentiality of the account login and password created on Kens World2Door Cargo LLC website/App. In case anyone else uses your account, all the services availed shall be deemed to have been made by the customer or an agent of the customer and the customer will be responsible for using such services. If a customer believes someone has, without his/her authorization, accessed the account on Kens World2Door Cargo LLC website/app, please intimate Kens World2Door Cargo LLC immediately.
									</div>
								</li>
							</ul>
							<h3 className="fw-bold mb-4">Effect of Registration</h3>
							<p>
								Customer registration on Kens World2Door Cargo LLC website/app constitutes a legally binding contract between the "Customer" and "Kens World2Door Cargo LLC". If you do not agree to these “Terms and Conditions”, you do not have the right to access or use the services of Kens World2Door Cargo LLC.
							</p>
							<h3 className="fw-bold mb-4">Description of services</h3>
							<p>
								Kens World2Door Cargo LLC provides the following independent and consolidated services to its customers:
							</p>
							<p>
								<b>Domestic and International delivery:</b> Kens World2Door Cargo LLC provides the services of collecting /picking-up the goods/shipment of its customers from the provided pick up location in the U.A.E. and manage its delivery till the final location anywhere within U.A.E. and outside U.A.E.
							</p>
							<p>
								<b>Packing of Goods/Shipment:</b> Kens World2Door Cargo LLC provides packing services for the goods/shipment if required by the customer according to accepted international standards.
							</p>
							<p>
								<b>Warehousing Services:</b> Kens World2Door Cargo LLC provides for the necessary warehousing services of the goods/shipment collected on behalf of the customer upon request.
							</p>
							<p>
								<b>Consolidation of the Goods/Shipment:</b> Kens World2Door Cargo LLC provides services for the collection of cargo from various locations in the UAE and then consolidates all the goods in their warehouse as per the request of the customer.
							</p>
							<p>
								<b>Air / Sea / Courier / Land Freight Forwarding:</b> Kens World2Door Cargo LLC provides the freight services of moving the goods/shipment by various modes i.e. Air / Sea / Courier / Land as per the mode selected by the customer for their goods. It provides delivery services upto the port and upto the customer’s door, whichever the customer selects.
							</p>
							<p>
								<b>Insurance for the Goods:</b>
							</p>
							<ul className="fancylist">
								<li>
									<span>
										<img src="assets/images/checked.svg" alt="Not found" />
									</span>
									<div>
										Upon the request of the Customer, Kens World2Door Cargo LLC provides services of Insurance of the goods/shipments through Insurance company or their brokers and acts as an intermediary only on behalf of the customer. Please go through the Insurance Terms & Conditions displayed on the HOME PAGE next to the Insurance section displayed for complete information.
									</div>
								</li>
								<li>
									<span>
										<img src="assets/images/checked.svg" alt="Not found" />
									</span>
									<div>
										The insurance coverage offered on the website/app is only for the new goods. If the customer wishes to insure the old/used goods then he/she must send an email at info@world2door.com with the complete description of the old items, weight and value to determine if it can be insured. The Insurance premium offered on the app/website is only for newly purchased goods and the Insurance Premium for old & Used goods is different and should be checked with Kens World2Door staff separately.
									</div>
								</li>
							</ul>
							<h3 className="fw-bold mb-4">Payment to the Service Provider</h3>
							<ul className="fancylist">
								<li>
									<span>
										<img src="assets/images/checked.svg" alt="Not found" />
									</span>
									<div>
										The customer shall pay Kens World2Door Cargo LLC through it’s Online Payment Gateway or Cash or as otherwise agreed all sums immediately when due without reduction or deferment on account of any claim, counterclaim or set-off.
									</div>
								</li>
								<li>
									<span>
										<img src="assets/images/checked.svg" alt="Not found" />
									</span>
									<div>
										The payment will have to be made in UAE Dirhams (AED) only. The payment can be made through Master Card/Visa Card or Cash.
									</div>
								</li>
								<li>
									<span>
										<img src="assets/images/checked.svg" alt="Not found" />
									</span>
									<div>
										The order will be picked up upon confirming the booking; however, the shipment will be executed (Custom cleared and shipped out) only after the payment is made in full.
									</div>
								</li>
								<li>
									<span>
										<img src="assets/images/checked.svg" alt="Not found" />
									</span>
									<div>
										The customer can make an advance payment against each booking which will be adjusted with the final invoice amount.
									</div>
								</li>
								<li>
									<span>
										<img src="assets/images/checked.svg" alt="Not found" />
									</span>
									<div>
										In respect of all the money which is overdue, the customer shall be liable to pay to Kens World2Door Cargo LLC interest calculated at 2% per month on the balance amount due which is applicable in case of a delay.
									</div>
								</li>
								<li>
									<span>
										<img src="assets/images/checked.svg" alt="Not found" />
									</span>
									<div>
										In case the customer has made the payment and afterwards cancelled the shipment, the amount paid by the customer shall be refunded as per the eligibility criteria mentioned in the cancellation policy.
									</div>
								</li>
							</ul>
							<h3 className="fw-bold mb-4">
								Payment Confirmation to the Customer
							</h3>
							<p>
								Kens World2Door Cargo LLC will send confirmation of receipt of payment to the customer by email within 24 hours of receipt of payment.
							</p>
							<h3 className="fw-bold mb-4">Customer's Responsibilities</h3>
							<ul className="fancylist">
								<li>
									<span>
										<img src="assets/images/checked.svg" alt="Not found" />
									</span>
									<div>
										Upon Registration on Kens World2Door Cargo LLC Website/App to avail the services, the customer shall be responsible for providing the following accurate information:
									</div>
								</li>
								<li>
									<span>
										<img src="assets/images/checked.svg" alt="Not found" />
									</span>
									<div>
										Accurate description and weight/dimensions/value of the goods/articles in respect of which he intends to avail the services of Kens World2Door Cargo LLC.
									</div>
								</li>
								<li>
									<span>
										<img src="assets/images/checked.svg" alt="Not found" />
									</span>
									<div>
										Specify any fragile item with special handling instructions that may be required. In case of failure of stating the fragile item, any amount of loss or damage will be fully on customer’s account.
									</div>
								</li>
								<li>
									<span>
										<img src="assets/images/checked.svg" alt="Not found" />
									</span>
									<div>
										Provide the purchase invoice copy with the photograph of the item on Kens World2Door Cargo LLC website in case of new purchase. For old & used items, the itemized description, value and quantity of the goods is required.
									</div>
								</li>
								<li>
									<span>
										<img src="assets/images/checked.svg" alt="Not found" />
									</span>
									<div>
										Accurate address of pick-up location and the delivery location.
									</div>
								</li>
								<li>
									<span>
										<img src="assets/images/checked.svg" alt="Not found" />
									</span>
									<div>
										Correct selection of the mode of transit of goods/articles (by Air/Sea/Land/Courier).
									</div>
								</li>
								<li>
									<span>
										<img src="assets/images/checked.svg" alt="Not found" />
									</span>
									<div>
										Correct selection of the method of payment and timely payment.
									</div>
								</li>
								<li>
									<span>
										<img src="assets/images/checked.svg" alt="Not found" />
									</span>
									<div>
										Cardholder must retain a copy of transaction records and
										Merchant{" "}
									</div>
								</li>
								<li>
									<span>
										<img src="assets/images/checked.svg" alt="Not found" />
									</span>
									<div>
										Cardholder must retain a copy of transaction records and Merchant policies and rules.
									</div>
								</li>
								<li>
									<span>
										<img src="assets/images/checked.svg" alt="Not found" />
									</span>
									<div>
										If the credit card used doesn’t belong to the customer, we may ask for the credit card holder’s authorization for security purposes.
									</div>
								</li>
								<li>
									<span>
										<img src="assets/images/checked.svg" alt="Not found" />
									</span>
									<div>
										User is responsible for maintaining the confidentiality of his/her account.
									</div>
								</li>
							</ul>
							<h3 className="fw-bold mb-4">
								Service Provider’s Responsibilities
							</h3>
							<ul className="fancylist">
								<li>
									<span>
										<img src="assets/images/checked.svg" alt="Not found" />
									</span>
									<div>
										i. Once the customer provides the Kens World2Door Cargo LLC necessary information and makes the payment,
										Kens World2Door Cargo LLC shall be responsible for the following:
									</div>
								</li>
								<li>
									<span>
										<img src="assets/images/checked.svg" alt="Not found" />
									</span>
									<div>
										Kens World2Door Cargo LLC shall perform its duties with reasonable degree of care, diligence, skill and judgment.
									</div>
								</li>
								<li>
									<span>
										<img src="assets/images/checked.svg" alt="Not found" />
									</span>
									<div>
										Providing independent freight service only or Add-On services like Packing/Storage/Insurance services to the customer as requested and subject to additional cost.
									</div>
								</li>
								<li>
									<span>
										<img src="assets/images/checked.svg" alt="Not found" />
									</span>
									<div>
										Assess the correct weight & dimensions of the goods/articles as provided by the customer before transporting to the ultimate destination.
									</div>
								</li>
								<li>
									<span>
										<img src="assets/images/checked.svg" alt="Not found" />
									</span>
									<div>
										In case of any discrepancy in the weight or dimension of the goods/articles from the one provided by the customer, Kens World2Door Cargo LLC shall update the corrections and immediately inform the customer by email or on their website. The Weight & Dimensions taken in Kens World2Door Cargo LLC nominated warehouse will be considered final and correct. If customer disputes the discrepancy in the weight and dimension of the goods/shipment, the discrepancy can be verified by the customer by personally going to Kens World2Door Cargo LLC warehouse before the shipment is set in transit.
									</div>
								</li>
								<li>
									<span>
										<img src="assets/images/checked.svg" alt="Not found" />
									</span>
									<div>
										Kens World2Door Cargo LLC may open or inspect a shipment without notifying the customer for safety, security, customs or other regulatory reasons. If any item in the goods/shipment is found which is prohibited as per the General Prohibited List or as per the Origin/Destination country rules, then Kens World2Door Cargo LLC has all the rights to remove these goods from the shipment and keep the customer informed.
									</div>
								</li>
								<li>
									<span>
										<img src="assets/images/checked.svg" alt="Not found" />
									</span>
									<div>
										Transporting the goods/shipment as per the mode (Air/Sea/Land/Courier) selected by the customer to the ultimate destination within the agreed time subject to the customs approval and release at the Origin/Destination and for any unforeseen circumstances which is beyond Kens World2Door Cargo LLC control/Force Majure.
									</div>
								</li>
							</ul>
							<h3 className="fw-bold mb-4">Mis-declaration of Goods</h3>
							<p>
								The customer agrees to provide accurate information on the description of goods to Kens World2Door Cargo LLC. In the event the customer wrongly declares the goods intentionally or unintentionally, Kens World2Door Cargo LLC will not be held responsible for the consequences resulting from mis-declaration. In such an event, the customer will be solely accountable for any delay and penalty that may be applicable.
							</p>
							<h3 class="fw-bold mb-4">Prohibited Goods</h3>
							<p>The shipment should not contain any goods prohibited under the laws of the country of origin of shipment, the laws of
								country of ultimate destination, the international laws governing the transport of goods by road, sea, air &
								courier. The prohibited goods include but not limited to the following goods:
							</p>
							<ul class="fancylist">
								<li>i. Explosives/ Guns / Ammunition/ Weaponry etc.</li>
								<li>ii. Counterfeit Goods.</li>
								<li>iii. Perishable Items / Food Items.</li>
								<li>iv. Any Items in Liquids or Powder Form.</li>
								<li>v. Loose batteries.</li>
								<li>vi. Animals/Birds/ Wildlife Products.</li>
								<li>vii. Any Plants & Plant Material.</li>
								<li>viii. Lottery Tickets / Gambling Devices</li>
								<li>ix. Pornographic Films or Obscene Material.</li>
								<li>x. Drugs. </li>
								<li>xi. Medicines.</li>
								<li>xii. Jewellery / Currency/ Bullion / gem stones.</li>
								<li>xiii. Hazardous Waste/Material.</li>
								<li>xiv. Precious Metals.</li>
								<li>xv. Human remains.</li>
								<li>xvi. Ivory.</li>
								<li>xvii. Goods Categorized as Dangerous by IATA (International Air Transport Association).</li>
								<li>xviii. Any items Restricted as per individual country Regulations.</li>
							</ul>
							<h3 class="fw-bold mb-4">USE OF SERVICE OF THIRD PARTIES</h3>
							<p>The customer hereby expressly authorizes Kens World2Door Cargo
								LLC to procure all or any required services of third party
								(To act as its agent or sub-contractor) to Kens World2Door Cargo LLC to the customer as
								principal and enter into contracts on behalf of the customer for the:
							</p>
							<ul class="fancylist">
								<li>
									<span><img src="assets/images/checked.svg" alt="Not found" /></span>
									<div>
										i. Carriage of goods by any route or means or person from the place of shipment to the ultimate destination,
									</div>
								</li>
								<li>
									<span><img src="assets/images/checked.svg" alt="Not found" /></span>
									<div>
										ii. Storage, packing, trans-shipment, loading, unloading or handling of the goods by any person at any place
										and for any length of time,
									</div>
								</li>
								<li>
									<span><img src="assets/images/checked.svg" alt="Not found" /></span>
									<div>
										iii.Carriage or storage of goods in warehouse or during the transport with other goods of whatever nature,
										and to do such acts as may be in the opinion of the Kens World2Door Cargo LLC reasonably necessary in the
										performance of its obligations in the interests of the customer.
									</div>
								</li>
							</ul>
							<h3 class="fw-bold mb-4">MODE OF CARRIAGE</h3>
							<p>
								In every case where a service is requested to Kens World2Door Cargo LLC by the customer; however, if the mode of
								carriage selected by the customer is suspended, cannot be accepted by the carrier due to any reason unknown at the
								time of placing the booking or temporarily stopped due to unforeseen circumstances beyond the control of Kens
								World2Door Cargo LLC, the Service Provider should provide alternate mode to transport the goods and keep the
								customer informed and move the same after confirmation by the customer. The difference in freight due to change of
								mode should either be collected from the customer or returned to the customer, whichever is applicable.
							</p>
							<h3 class="fw-bold mb-4">PACKING REQUIREMENT</h3>
							<p>
								Packing is the responsibility of the customer. This means if the items are not in seaworthy/airworthy packing then
								Kens World2Door Cargo LLC Cargo LLC reserves the right to refuse shipping of your packages which will result in
								cancelation (Please refer to the cancelation/refund policy)
							</p>
							<h3 class="fw-bold mb-4">LIEN ON GOODS</h3>
							<ul class="fancylist">
								<li>
									<span><img src="assets/images/checked.svg" alt="Not found" /></span>
									<div>i. The Service Provider shall have general lien on all the goods and documents relating to goods in its
										custody against all the sums due at any time from the customer and is entitled to retain them unless the sum
										due is paid or settled.
									</div>
								</li>
								<li>
									<span><img src="assets/images/checked.svg" alt="Not found" /></span>
									<div>ii. In case the goods remain in custody of Kens World2Door Cargo LLC for more than three months and the
										customer does not come forward to claim the goods/shipment, then Kens World2Door Cargo LLC shall have the
										right to either sell them to cover the cost of storage and handling for that time span or destroy them
										completely to avoid further losses.
									</div>
								</li>
							</ul>
							<h3 class="fw-bold mb-4">INDEMNIFICATION</h3>
							<p>The customer agrees to hold the Kens World2Door Cargo LLC harmless and indemnified against:</p>
							<ul class="fancylist">
								<li>
									<span><img src="assets/images/checked.svg" alt="Not found" /></span>
									<div>i. Every liability, loss, damage, costs and expenses whatsoever (including without prejudice to the
										generality of the foregoing, all duties, taxes, impost, levies, deposits and outlays of whatsoever nature
										levied by any authority in relation to the goods) arising out of Kens World2Door Cargo LLC's action taken in
										accordance with the Custome's instructions or arising from any breach by the Customer of any condition
										contained herein,
									</div>
								</li>
								<li>
									<span><img src="assets/images/checked.svg" alt="Not found" /></span>
									<div>ii. Without prejudice to the above-mentioned any liability assumed or incurred by the Kens World2Door Cargo
										LLC while carrying out Customer’s instructions and as a result of which Kens World2Door Cargo LLC has
										reasonably become liable or may become liable to any other party,
									</div>
								</li>
								<li>
									<span><img src="assets/images/checked.svg" alt="Not found" /></span>
									<div>iii. All claims, costs and demands whatsoever and by whomsoever made in excess of the liability of the Kens
										World2Door Cargo LLC under these terms and conditions regardless of whether such claims, costs and demands
										arise from or in connection with the negligence or breach of duty of the Kens World2Door Cargo LLC, its
										servants, sub-contractors or agents, any other claim of general nature made against the Service Provider.
									</div>
								</li>
								<li>
									<span><img src="assets/images/checked.svg" alt="Not found" /></span>
									<div>iv. The transit time from origin to destination mentioned on the website are only estimates outlined on the
										basic shipping process. Kens World2Door Cargo LLC will not be responsible for any loss or inconvenience that
										may arise due to the delay in shipping of your packages.
									</div>
								</li>
							</ul>
							<h3 class="fw-bold mb-4">CUSTOMER'S LIABILITY IN CASE OF CANCELLATION OF SHIPMENT</h3>
							<p>In case, where upon the request of the customer, Kens World2Door Cargo LLC collects the goods from the premises
								notified by the customer and the customer later for any reason whatsoever cancels the shipment, the customer shall
								be liable to pay the charges for domestic transport, shipment handling and warehouse storage charges as applicable
								at that time to Kens World2Door Cargo LLC.
							</p>
							<h3 class="fw-bold mb-4">CUSTOMER’S LIABILITY FOR THE FAILURE TO RECEIVE GOODS</h3>
							<ul class="fancylist">
								<li>
									<span><img src="assets/images/checked.svg" alt="Not found" /></span>
									<div>i. In case the customer fails or acts negligently, delays or refuses to receive the goods or any part
										thereof, at the time and place when and where Kens World2Door Cargo LLC was requested to deliver the goods,
										Kens World2Door Cargo LLC shall be entitled to store the goods or any part thereof at the sole risk of the
										customer, whereupon the liability of Kens World2Door Cargo LLC in respect of such goods or that part thereof
										shall wholly cease and the cost of such storage shall accrue in favor of the customer. Also, the Insurance
										Policy will cease to exist if the customer fails to receive the goods immediately after the shipment is
										cleared from the customs.
									</div>
								</li>
								<li>
									<span><img src="assets/images/checked.svg" alt="Not found" /></span>
									<div>ii. In case the goods remain in custody of Kens World2Door Cargo LLC for more than three months and the
										customer does not come forward to claim the goods/shipment, then Kens World2Door Cargo LLC shall have the
										right to either sell them to cover the cost of storage and handling for that time span or destroy them
										completely to avoid further losses.
									</div>
								</li>
							</ul>
							<h3 class="fw-bold mb-4">STORAGE OF GOODS/SHIPMENT IN WAREHOUSE</h3>
							<p>Where the goods/shipment have been collected by Kens World2Door Cargo LLC or delivered to Kens World2Door Cargo LLC
								warehouse and the goods are held in the warehouse on the request of the customer, the customer shall be liable to
								pay the charges for the storage and handling of the goods in the warehouse.
							</p>
							<h3 class="fw-bold mb-4">LIMITATION OF LIABILITY OF THE SERVICE PROVIDER</h3>
							<p>a. Kens World2Door Cargo LLC maximum liability for any loss or damage caused to the goods will be as follows:</p>
							<ul class="fancylist">
								<li>
									<span><img src="assets/images/checked.svg" alt="Not found" /></span>
									<div>i. AED 367/- per shipment or the actual value of goods whichever is less for all International Shipments
										going out of UAE by any mode of transport.
									</div>
								</li>
								<li>
									<span><img src="assets/images/checked.svg" alt="Not found" /></span>
									<div>ii. AED 100/- per shipment or the actual value of goods whichever is less for Domestic Shipments within UAE
										by any modes of transport.
									</div>
								</li>
							</ul>
							<p>b. Kens World2Door Cargo LLC shall have no liability for any loss or damage caused to the goods and shall not be
								held liable to the extent that such loss or damage is caused by:
							</p>
							<ul class="fancylist">
								<li>
									<span><img src="assets/images/checked.svg" alt="Not found" /></span>
									<div>
										i. Strike, lock-out, stoppage or restraint of labor, the consequences of which the service provider is
										unable to avoid by the exercise of reasonable diligence.
									</div>
								</li>
								<li>
									<span><img src="assets/images/checked.svg" alt="Not found" /></span>
									<div>
										ii. Any cause or event which the Kens World2Door Cargo LLC is and the consequences whereof the service
										provider is unable to prevent by the exercise of reasonable diligence.
									</div>
								</li>
								<li>
									<span><img src="assets/images/checked.svg" alt="Not found" /></span>
									<div>
										iii. Except under special arrangements previously made in writing the Company accepts no responsibility for
										departure or arrival dates of goods.
									</div>
								</li>
							</ul>
							<h3 class="fw-bold mb-4">CUSTOM CLEARANCE</h3>
							<p>Kens World2Door Cargo LLC shall provide customs clearance services to the customer through our appointed
								customs brokers or vendors subject to the customs clearance regulations in the country of export and in
								the country of import.
								<br />Note: The Duty and Taxes if any at the destination country to be paid by the customer at the
								destination to our appointed agent at actuals before delivery of goods.
							</p>

							<h3 class="fw-bold mb-4">DELIVERY CONDITIONS</h3>
							<p>I. <b>Port Delivery:</b> <br />Port Delivery means the shipment will be delivered only up to the
								airport/seaport. The customs clearance
								and delivery at the destination port is not included in the scope of work.
							</p>
							<ul class="fancylist">
								<li><span><img src="assets/images/checked.svg" alt="Not found" /></span>
									<div>a. Inclusions:</div><br />
									<ul>
										<li>i. Delivery from Kens World2Door warehouse to third party vendor selected for International
											Transportation of customers goods.</li>
										<li>ii. Customs clearance at origin</li>
										<li>iii. Transportation of goods to the destination port through one of the transport modes
											(Air/Sea/Land)</li>
									</ul>
								</li>


								<li><span><img src="assets/images/checked.svg" alt="Not found" /></span>
									<div>b. Exclusions:</div><br />
									<ul>
										<li>i. Destination airlines/Shipping lines charges</li>
										<li>ii. Destination customs clearance</li>
										<li>iii. Destination transportation from the port to the final delivery place</li>
										<li>iv. Customs duty and taxes</li>
										<li> v. Shuttle services (In cases where there is no accessibility for trucks to reach the
											residence, smaller vehicles will be used multiple times)</li>
										<li>vi. Handy man services</li>
										<li>vii. Long walk (Where the distance from the offloading point to the customer’s residence is
											more
											than 20m )</li>
										<li>viii. Stair carry (In cases where the delivery is above 1st floor and there is no
											lift/elevator
											or cargo does not fit in the lift)</li>
										<li>ix. Heavy Weight shipment where a single piece weights more than 50 kgs.</li><br />
										<p>Note: All Charges in the exclusions but not limited and which are applicable must be paid to
											the
											relevant service providers at the destination by the customer.</p>

									</ul>
								</li>
							</ul>
							<p>I. <b>II. Door Delivery:</b> <br />In this service, the cargo is delivered to the final address of the customer
								at the destination.
							</p>
							<ul class="fancylist">
								<li><span><img src="assets/images/checked.svg" alt="Not found" /></span>
									<div>a. <b>Inclusions:</b></div><br />
									<ul>
										<li>i. Delivery from Kens World2Door warehouse to third party vendor selected for International
											Transportation of customers goods.</li>
										<li>ii. Customs clearance at origin</li>
										<li>iii. Transportation of goods to the destination port through one of the transport modes
											(Air/Sea/Land)</li>
										<li>iv. Destination Airlines/Shipping lines charges</li>
										<li>v. Destination customs clearance</li>
										<li>vi. Destination transportation from the port to the final destination</li>
									</ul>
								</li>
								<li><span><img src="assets/images/checked.svg" alt="Not found" /></span>
									<div>b.<b>Exclusions</b></div>
									<ul>
										<li>i. Customs Duty and Taxes</li>
										<li>ii. Demurrage & Storage Charges</li>
										<li>iii. Shuttle services (In cases where there is no accessibility for trucks to reach the
											residence, smaller vehicles will be used multiple times)</li>
										<li>iv. Handy man services</li>
										<li>v. Long walk (Where the distance from the offloading point to the customer’s residence is
											more
											than 20m)</li>
										<li>vi. Stair Carry (In cases where the delivery is Above 1st Floor and there is No Lift or
											Cargo
											does not fit in the Lift)</li>
										<li>vii. Heavy Weight shipment where a single piece weights more than 50kgs.
											viii. Debris Removal/ Unpacking</li>
										<li>ix. Over time Charges related to weekend Deliveries or outside regular working hours.

											<p>Note: All Charges in the Exclusions but not limited and which are applicable must be paid to the
												relevant service providers at the destination by the customer before delivery of goods.</p>
										</li>

									</ul></li>
							</ul>
							<h3 class="fw-bold mb-4">24. DISCLAIMER</h3>
							<ul class="fancylist">
								<li><span><img src="assets/images/checked.svg" alt="Not found" /></span>
									<div>
										i. Kens World2Door Cargo LLC shall not provide any services to the customers having
										Nationalities declared
										“Restricted Nationalities” by the U.A.E. Govt. from time to time.</div>
								</li>
								<li><span><img src="assets/images/checked.svg" alt="Not found" /></span>
									<div>
										ii. Kens World2Door Cargo LLC shall not provide any services to any customer intending services
										in the
										countries Declared “Hostile” by the U.A.E. Govt. from time to time.</div>
								</li>
								<li><span><img src="assets/images/checked.svg" alt="Not found" /></span>
									<div>
										iii. Kens World2Door Cargo LLC will not trade with or provide any services to OFAC and
										sanctioned countries.
									</div>
								</li>
							</ul>
							<h3 class="fw-bold mb-4">25.SEVERABILITY:</h3>
							<p>If any provision contained or referred to in the Transport Bill or term or condition contained herein
								contradicts with the Law of the land, government regulations, orders, or requirements of regulatory
								authority governing the mode of transport selected by the Customer, such provision shall remain
								applicable to the extent that it is not overridden thereby and the invalidity of any such provision
								shall not affect any other part hereof.</p>

							<h3 class="fw-bold mb-4">GOVERNING LAW: </h3>
							<p>The Kens World2Door Cargo LLC terms & conditions of services shall be governed by and subject to the laws
								of United Arab Emirates, without regard to the choice-of- law rules.</p>

							<h3 class="fw-bold mb-4">DISPUTE RESOLUTION: </h3>
							<p>“Kens World2Door Cargo LLC” and the “Customer” agree that any claim or dispute arising from the or in
								connection with this contract, whether under the Federal Law, State Law, Local Law, International
								Conventions and Regulations, shall be brought exclusively before the Courts of the U.A.E.</p>

							<h3 class="fw-bold mb-4">CODE OF ETHICS AND BUSINESS CONDUCT: </h3>
							<p>Kens World2Door Cargo LLC is committed to conducting operations with the highest standards of business
								conduct and ethics and thereby expects all vendors and agents to act within the spirit of our code of
								ethics and business conduct to maintain an on-going business relationship.</p>

							<h3 class="fw-bold mb-4">31. QUOTATION / PRICING POLICY</h3>
							<ul class="fancylist">
								<li><span><img src="assets/images/checked.svg" alt="Not found" /></span>
									<div>i. Freight is calculated and charged to you based on the actual weight or volume weight,
										whichever is higher. The weight and dimensions taken in our warehouse will be considered as
										final.
									</div>
								</li>
								<li><span><img src="assets/images/checked.svg" alt="Not found" /></span>
									<div>ii. The freight & Add On service prices which appear on the Kens World2Door Cargo LLC
										website/app at the time of booking are current rates and are valid for a period of 7 days.
									</div>
								</li>
								<li><span><img src="assets/images/checked.svg" alt="Not found" /></span>
									<div>iii. The Freight & Add On service prices which appear on the website/app are non-negotiable
										unless exceptions made and given in written by Kens World2Door Cargo LLC.
									</div>
								</li>
								<li><span><img src="assets/images/checked.svg" alt="Not found" /></span>
									<div>iv. The quotation is subject to change,<br />
										<ul>
											<li> a. If the actual weight and volume increases or decreases respectively.</li>
											<li> b. If the Mode/Service/Destination of Transportation is changed.</li>
											<li> c. If any Add On services are opted for at a later stage.</li>
											<li> d. If there are any changes in the Add On services selected.</li>
										</ul>

									</div>
								</li>
								<li>
									<span><img src="assets/images/checked.svg" alt="Not found" /></span>
									<div>v. For shipments considered Heavy or Oversized, the standard rates appearing on the website/app
										will not be applicable. A separate customized quotation will be sent to the customer by mail for
										additional labor.
									
									<ul>
										<li> a. Domestic shipments: If a single piece weighs more than 31 kgs or is over-sized
											<span> <code> (Length + (2*Width) + (2*Height))</code></span> in cms is greater than 250 cms or if any one side is greater than
											120 cms,there will be additional labor charges. The cost of per laborer and the number of laborers
											required will be decided on case to case basis depending upon the actual weight and dimensions.
										</li>
										<li> b. International shipments: If a single piece weighs more than 31 kgs or is over-sized
											(Length + (2* Width) + (2 * Height)) in cms is greater than 250 cms or if any one side is greater than
											120 cms , there will be additional labor charges. For shipments only upto the port, the cost per
											laborer and the number of laborers required will be decided on case to case basis. The labor cost at the
											destination will be informed to the customer after checking with our destination partners.
										</li>

									</ul>
									</div>
								</li>

								<li><span><img src="assets/images/checked.svg" alt="Not found" /></span>
									<div>vi. For Fragile shipments the standard rates appearing on the website/app will not be
										applicable. A separate customized quotation will be sent to the customer by mail.
									</div>
								</li>
								<li><span><img src="assets/images/checked.svg" alt="Not found" /></span>
									<div>vii. The inquiries for which the rates does on appear on the website/app , a customized
										quotation will be sent to the customer by mail.
									</div>
								</li>
							</ul>

							<h3 class="fw-bold mb-4">32. WEIGHT & SIZE RESTRCITIONS ON THE CARRIAGE </h3>

							<ul class="fancylist">
								<li><span><img src="assets/images/checked.svg" alt="Not found" /></span>
									<div>i. Air Freight Mode/Courier Mode: In Airfrt and Courier mode the size restrictions will depend
										upon the type of aircraft used for the particular sector by the airline/courier company. The
										customer is requested to double check with Kens World2Door Cargo on the acceptance of the cargo
										for the particular sector if he/she wants to ship its cargo before purchase of goods. Below are
										some of the types of aircraft and their size and weight restrictions.</div>
								</li>

								<ul>
									<li>a. Wide Body Aircraft: <br />
										Maximum Dims per piece: (L) - 250, (W) - 160, (H) - 155 in cms including Pallet
										Maximum Weight per piece after packing: 500 kgs
									</li>
									<li >b. Narrow Body Aircraft:<br />
										Maximum Dims per piece: (L) - 100, (W) - 80, (H) - 80 in cms including Pallet
										Maximum Weight per piece after packing: 80 kgs
									</li>
									<li>c.Freighter Aircraft:<br />
										Maximum Dims per piece: (L) - 300 (W) - 200 (H) - 190 in cms including Pallet
										Maximum Weight per piece after packing: 1000 kgs
									</li>
								</ul>
								<li><span><img src="assets/images/checked.svg" alt="Not found" /></span>
									<div>ii.Sea Freight Mode:<br />
										Maximum Dims per piece: (L) – 580, (W) - 200, (H) - 200 in cms including Pallet
										Maximum Weight per piece after packing: 1000 kgs
									</div>
								</li>
								<li><span><img src="assets/images/checked.svg" alt="Not found" /></span>
									<div>iii.Land Freight Mode:<br />
										Maximum Dims per piece: (L) - 580  (W) - 200 x (H) - 150 in cms including Pallet
										Maximum Weight per piece after packing: 500 kgs

									</div>
								</li>
							</ul>

						</div>
					</div>


                    <label htmlFor="terms" className="form-check-label" onClick={() => termsAccept()}>
                        <input type="checkbox" id="terms" checked={checked} /> &nbsp; I Accept all Terms and Conditions
                    </label>
                    <button className="themebutton px-5 mt-4" type='button' onClick={() => { addTerms() }} >
                        Proceed
                    </button>
			</div>
		</section >



















                {/* <span className="ms-auto p-2 cursor-pointer" onClick={() => onClose()}>Close</span>
                <div className="modal-body px-4">
                    <div className="row">
                        <h3 className="fw-bold mb-4">Terms & Policy</h3>
                        <div className="col-md-12">
                            <p>This website/app is operated by, or on behalf of Store2Door Cargo LLC for
                                providing the services of freight transport and management. By using the
                                website/app you agree to comply with and be bound by the following “Terms
                                and Conditions”.</p>
                            <p> We reserve the right to amend these “Terms and Conditions” from time to time.
                                Any changes will be posted on our website.</p>
                            <h3 className="fw-bold mb-4">Scope of Application</h3>
                            <p> The terms & conditions shall apply to all the shipments irrespective of any
                                consideration to the size, volume and country of shipment or country of
                                ultimate destination for all the services provided by Store2Door. Deviations
                                from these terms & conditions shall not apply unless agreed specifically in
                                writing by Store2Door.</p>
                            <h3 className="fw-bold mb-4">Registration if the Customer</h3>
                            <p> Where a customer intends to avail the services of Store2Door, the customer
                                requires registration on our website/app (Whichever is applicable) providing
                                all the information accurately required in this regard.</p>
                            <h3 className="fw-bold mb-4">Conditions for the Registration</h3>
                            <ul className="fancylist">
                                <li><span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>Customer must be Major under the laws of applicable jurisdiction to
                                        create an account on our website/app to avail our services.</div></li>
                                <li><span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>All the personal details you put on our website/App for the
                                        registration to avail our services must be true, accurate,
                                        complete and current. In case of change Customer must update
                                        on their account promptly.</div></li>
                                <li><span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>Customer is responsible for maintaining the confidentiality of
                                        the account login and password created on Store2Door website/App.
                                        In case anyone else uses your account, all the services availed
                                        shall be deemed to have been made by the customer or an agent of
                                        the customer and the customer will be responsible for using
                                        such services. If a customer believes someone has, without
                                        his/her authorization, accessed the account on Store2Door
                                        website/app, please intimate Store2Door immediately.</div></li>
                            </ul>


                            <h3 className="fw-bold mb-4">Effect of Registration</h3>
                            <p>Customer registration on Store2Door website/app constitutes a legally binding
                                contract between the "Customer" and "Store2Door". If you do not agree to these
                                “Terms and Conditions”, you do not have the right to access or use the services
                                of Store2Door.</p>

                            <h3 className="fw-bold mb-4">Description of services</h3>
                            <p>Store2Door provides the following independent and consolidated services to its customers:</p>

                            <p><b>Domestic and International delivery:</b> Store2Door provides the services
                                of collecting /picking-up the goods/shipment of its customers from the provided
                                pick up location in the U.A.E. and manage its delivery till the final location
                                anywhere within U.A.E. and outside U.A.E.</p>
                            <p><b>Packing of Goods/Shipment:</b> Store2Door provides packing services for the
                                goods/shipment if required by the customer according to accepted international standards.</p>
                            <p><b>Warehousing Services:</b> Store2Door provides for the necessary warehousing
                                services of the goods/shipment collected on behalf of the customer upon request.</p>
                            <p><b>Consolidation of the Goods/Shipment:</b> Store2Door provides services for
                                the collection of cargo from various locations in the UAE and then consolidates
                                all the goods in their warehouse as per the request of the customer.</p>
                            <p><b>Air / Sea / Courier / Land Freight Forwarding:</b> Store2Door provides the
                                freight services of moving the goods/shipment by various modes i.e. Air / Sea / Courier /
                                Land as per the mode selected by the customer for their goods. It provides delivery services
                                upto the port and upto the customer’s door, whichever the customer selects.</p>
                            <p><b>Insurance for the Goods:</b></p>
                            <ul className="fancylist">
                                <li><span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>Upon the request of the Customer, Store2Door provides services of Insurance
                                        of the goods/shipments through Insurance company or their brokers and acts
                                        as an intermediary only on behalf of the customer. Please go through the
                                        Insurance Terms & Conditions displayed on the HOME PAGE next to the Insurance
                                        section displayed for complete information.</div>
                                </li>
                                <li><span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>The insurance coverage offered on the website/app is only for the new goods.
                                        If the customer wishes to insure the old/used goods then he/she must send an
                                        email at info@store2door.ae with the complete description of the items, weight
                                        and value to determine if it can be insured.</div>
                                </li>
                            </ul>

                            <h3 className="fw-bold mb-4">Payment to the Service Provider</h3>
                            <ul className="fancylist">
                                <li><span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>The customer shall pay Store2Door through it’s Online Payment Gateway
                                        or Cash or as otherwise agreed all sums immediately when due without
                                        reduction or deferment on account of any claim, counterclaim or set-off.</div></li>
                                <li><span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>The payment will have to be made in UAE Dirhams (AED) only. The payment
                                        can be made through Master Card/Visa Card or Cash.</div></li>
                                <li><span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>The order will be picked up upon confirming the booking; however, the
                                        shipment will be executed (Custom cleared and shipped out) only after
                                        the payment is made in full.</div></li>
                                <li><span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>The customer can make an advance payment against each booking which will
                                        be adjusted with the final invoice amount.</div></li>
                                <li><span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>In respect of all the money which is overdue, the customer shall be liable
                                        to pay to Store2Door interest calculated at 2% per month on the balance
                                        amount due which is applicable in case of a delay.</div></li>
                                <li><span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>In case the customer has made the payment and afterwards cancelled the
                                        shipment, the amount paid by the customer shall be refunded as per the
                                        eligibility criteria mentioned in the cancellation policy.</div></li>
                            </ul>

                            <h3 className="fw-bold mb-4">Payment Confirmation to the Customer</h3>
                            <p>Store2Door will send confirmation of receipt of payment to the customer
                                by email within 24 hours of receipt of payment.</p>

                            <h3 className="fw-bold mb-4">Customer's Responsibilities</h3>

                            <ul className="fancylist">
                                <li><span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>Upon Registration on Store2Door Website/App to avail the services,
                                        the customer shall be responsible for providing the following accurate information</div></li>
                                <li><span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>Accurate description and weight of the goods/articles in respect of
                                        which he intends to avail the services of Store2Door.</div></li>
                                <li><span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>Specify any fragile item with special handling instructions that
                                        may be required. In case of failure of stating the fragile item,
                                        any amount of loss or damage will be fully on customer’s account.</div></li>
                                <li><span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>Upload the purchase invoice copy with the photograph of the item on
                                        Store2Door website in case of new purchase. For old & used items,
                                        the itemized description, value and quantity of the goods is required.</div></li>
                                <li><span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>Accurate address of pick-up location and the delivery location.</div></li>
                                <li><span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>Correct selection of the mode of transit of goods/articles
                                        (by Air/Sea/Road/Land).</div></li>
                                <li><span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>Correct selection of the method of payment and timely payment.</div></li>
                                <li><span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>Cardholder must retain a copy of transaction records and Merchant </div></li>
                                <li><span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>If the credit card used doesn’t belong to the customer, we may
                                        ask for the credit card holder’s authorization for security purposes.</div></li>
                                <li><span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>User is responsible for maintaining the confidentiality of his/her account</div></li>
                            </ul>


                            <h3 className="fw-bold mb-4">Service Provider’s Responsibilities</h3>
                            <ul className="fancylist">
                                <li><span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>i. Once the customer provides the Store2Door necessary information
                                        and makes the payment, Store2Door shall be responsible for the following:</div></li>
                                <li><span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>Store2Door shall perform its duties with reasonable degree of care,
                                        diligence, skill and judgment.</div></li>
                                <li><span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>Providing independent freight service only or Add-On services like
                                        Packing/Storage/Insurance services to the customer as requested and
                                        subject to additional cost.</div></li>
                                <li><span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>Assess the correct weight & dimensions of the goods/articles as
                                        provided by the customer before transporting to the ultimate destination.</div></li>
                                <li><span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>In case of any discrepancy in the weight or dimension of the
                                        goods/articles from the one provided by the customer, Store2Door
                                        shall update the corrections and immediately inform the customer
                                        by email or on their website. The Weight & Dimensions taken in
                                        Store2Door nominated warehouse will be considered final and correct.
                                        If customer disputes the discrepancy in the weight and dimension of
                                        the goods/shipment, the discrepancy can be verified by the customer
                                        by personally going to Store2Door warehouse before the shipment is
                                        set in transit.</div></li>
                                <li><span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>Store2Door may open or inspect a shipment without notifying the
                                        customer for safety, security, customs or other regulatory reasons.
                                        If any item in the goods/shipment is found which is prohibited as
                                        per the General Prohibited List or as per the Origin/Destination
                                        country rules, then Store2Door has all the rights to remove these
                                        goods from the shipment and keep the customer informed.</div></li>
                                <li><span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>Transporting the goods/shipment as per the mode (Air/Sea/Land/Courier)
                                        selected by the customer to the ultimate destination within the agreed
                                        time subject to the customs approval and release at the Origin/Destination
                                        and for any unforeseen circumstances which is beyond Store2Door control/Force
                                        Majure.</div></li>
                            </ul>
                            <h3 className="fw-bold mb-4">Mis-declaration of Goods</h3>
                            <p>The customer agrees to provide accurate information on the description of goods
                                to Store2Door. In the event the customer wrongly declares the goods intentionally
                                or unintentionally, Store2Door will not be held responsible for the consequences
                                resulting from mis-declaration. In such an event, the customer will be solely
                                accountable for any delay and penalty that may be applicable.</p>
                        </div>
                    </div>
                    <label htmlFor="terms" className="form-check-label" onClick={() => termsAccept()}>
                        <input type="checkbox" id="terms" checked={checked} /> &nbsp; I Accept all Terms and Conditions
                    </label>
                    <button className="themebutton px-5 mt-4" type='button' onClick={() => { addTerms() }} >
                        Proceed
                    </button>
                </div> */}

                <Loader isShow={loader} />
            </Modal>

        </>
    )
}
