import React, { useEffect, useState } from "react";
import commonApicall from "../commonApicall";

const StoreBookingTable = (props) => {
	const { listdata } = props;
	const getdata = commonApicall.getCalculation(listdata);

	const getPercent = (value, percent) => {
		return (Number (value) * Number (percent) / 100)
	}

	return (
		<table className="table table-bordered">
			<thead>
				<tr>
					<th>Sr. No.</th>
					<th>Booking ID</th>
					<th>Actual Amount</th>
					<th>Agreed Amount</th>
					<th>Value Of Goods</th>
					<th>Retailer Invoice No.</th>
					<th>Shipment Status</th>
				</tr>
			</thead>
			<tbody>
				{listdata &&
					listdata.length >= 0 &&
					listdata.map((item, keys) => {
						let amountAct = item.payment_information.actual_amount
						let amountAgr = item.payment_information.agreed_amount


						return (
							<tr>
								<td>{keys + 1}</td>
								<td>{item.job_token || "NA"}</td>
								<td>{amountAct}</td>
								<td>{amountAgr}</td>
								<td>{item.invoices.includes("invoice_value") || "NA"}</td>
								<td>{item.invoices.includes("invoice_number") || "NA"}</td>
								<td>{item.job_status || "NA"}</td>
							</tr>
						);
					})}
			</tbody>

			{listdata && listdata.length >= 0 && (
				<tfoot>
					{/* <tr>
						<td>
							<b>Total</b>
						</td>
						<td colspan="2"></td>
						<td>
							<b>{(getdata && getdata.agreed_amount + " AED") || "NA"}</b>
						</td>
						<td>
							<b>{(getdata && getdata.total_incentive + " AED") || "NA"}</b>
						</td>
						<td>
							<b>{(getdata && getdata.customer_incentive + " AED") || "NA"}</b>
						</td>
						<td>
							<b>{(getdata && getdata.retailer_incentive + " AED") || "NA"}</b>
						</td>
						<td>
							<b>{(getdata && getdata.incentive_payout + " AED") || "NA"}</b>
						</td>
						<td>
							<b>{(getdata && getdata.goods_value + " AED") || "NA"}</b>
						</td>
						<td colspan="2"></td>
					</tr> */}
				</tfoot>
			)}
		</table>
	);
};

export default StoreBookingTable;
