import React, { useContext } from 'react'
import {BookingDetailContext} from '../BookingDetails'

export default function Shipment() {
    const {state, disptach} = useContext(BookingDetailContext);
    if(!state) {
        return 'Loading...'
    } else {
        const info = state.bookingDetails.shipment_details
        const payment = state.bookingDetails.payment_information
    
        return (
            <div className='customer-back'>
                <h5 className="secHeading">Shipment Details</h5>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td className="py-1">Shipping Mode - </td>
                            <td className="py-1 text-end">  { info.shipment_mode } </td>
                        </tr>
                        <tr>
                            <td className="py-1">Shipping Status - </td>
                            <td className="py-1 text-end">{state.bookingDetails.job_status}</td>
                        </tr>
                        <tr>
                            <td className="py-1">Booking Amount - </td>
        <td className="py-1 text-end">{payment.booking_amount} AED</td>
                        </tr>
                        <tr>
                            <td className="py-1">Agreed Amount - </td>
                            <td className="py-1 text-end">{payment.agreed_amount} AED</td>
                        </tr>
                        <tr>
                            {/* <td className="py-1">Invoice Status - </td> */}
                            <td className="py-1">Payment Status - </td>
                            <td className="py-1 text-end"><button className='red'>{payment.payment_status}</button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}
