import React, { createContext, useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";
import reducer from "./BookingDetails/bookingDetailReducer";
import { jobBooking } from "../services/index";
import PickUpDetail from "../Components/BookingDetails/PickupDetail";
import CargoDetail from "../Components/BookingDetails/CargoDetail";
import OrederSummarey from "../Components/BookingDetails/OrederSummary";
import ShippingDoc from "../Components/BookingDetails/ShippingDoc";

import {
  BookingNo,
  SourceDestinaton,
  Customer,
  Receiver,
  Shipment,
  Tracking,
  Payment,
  Invoices,
  General,
  Cargo,
  AddOne,
} from "./BookingDetails/index";

import { BOOKING_DETAIL, TRACKING_DETAIL } from "../Redux/type";

const BookingDetailContext = createContext();

export default function BookingDetails() {
  const [state, dispatch] = useReducer(reducer);
  let { id } = useParams();
  useEffect(() => {
    getUserBookingDetails();
  }, []);

  const getUserBookingDetails = () => {
    let queryData = { job_id: id };
    try {
      jobBooking.getJobBookingDetails(queryData).then((result) => {
        if (result.status === true) {
          dispatch({
            type: BOOKING_DETAIL,
            payload: result.data,
          });
          getTrackingDetails(result.data.job_token); // get job tracking details
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getTrackingDetails = (jobToken) => {
    try {
      let queryData = { job_token: jobToken };
      jobBooking.getTrackingDetails(queryData).then((result) => {
        if (result.status === true) {
          dispatch({
            type: TRACKING_DETAIL,
            payload: result.data,
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  var clicks = 0;
  function next(e) {
    // e.preventDefault();
    clicks += 100;
    document.getElementById("trackingSec").scrollTo(100 + clicks, 0);
  }
  function prev(e) {
    // e.preventDefault();
    clicks -= 100;
    document.getElementById("trackingSec").scrollTo(100 + clicks, 0);
  }

  return (
    <BookingDetailContext.Provider value={{ state, dispatch }}>
      <section className="pb-5 pt-0">
        <div className="header_title">
          <div className="container-fluid">
            <h1>Booking Details</h1>
            <img
              src="/assets/images/country.jpg"
              className="bannerimg"
              alt="Not found"
            />
          </div>
        </div>
        <div className="container-fluid mt-5">
          <div className=" white_box h-100">
            <div className="container">
              <div className="row">
                <div className="col-md-12 mb-4">
                  <div className="row">
                    <div className="col-md-12 my-4">
                      <BookingNo />
                    </div>
                    <div className="col-md-12 d-flex align-items-center justify-content-center">
                      <Tracking />
                    </div>
                    <SourceDestinaton />
                    <div className="col-12">{/* <hr /> */}</div>
                    <div className="col-lg-4 col-md-4 mt-4 mb-4">
                      <Receiver />
                    </div>
                    {/* <div className="col-lg-3 col-md-6 border-end">
                      <Receiver />
                    </div> */}
                    <div className="col-lg-4 col-md-4 mt-4 mb-4">
                      <Shipment />
                    </div>
                    <div className="col-lg-4 col-md-4 mt-4 mb-4">
                      <CargoDetail />
                    </div>
                    {/* <div className="col-lg-3 col-md-6 border-end">
                      <General />
                    </div> */}
                  </div>
                  {/* <hr /> */}
                  <div className="row">
                    <div className="col-md-12 px-md-4">
                      <div className="row">
                        <ul
                          className="nav mb-3 tablist profiletab"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li className="nav-item" role="presentation">
                            <a
                              className="nav-link"
                              id="pickid"
                              data-bs-toggle="pill"
                              data-bs-target="#pickup_details"
                              type="button"
                            >
                              <span className="showondesk">
                                PickUp Detail / Add PickUp
                              </span>
                            </a>
                          </li>
                          <li className="nav-item" role="presentation">
                            <a
                              className="nav-link"
                              id="pills-invoices-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#Addone"
                              type="button"
                            >
                              <span className="showondesk">Add Ons</span>
                            </a>
                          </li>
                          {/* <li className="nav-item" role="presentation">
                            <a
                              className="nav-link"
                              id="pills-deliveryadd-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pillsdeliveryadd"
                              type="button"
                            >
                              <img
                                className="showonmob"
                                height="20"
                                src="/assets/images/delivery_address.svg"
                                alt="Not found"
                              />
                              <span className="showondesk">
                                Payment Details
                              </span>
                            </a>
                          </li> */}

                          {/* <li className="nav-item" role="presentation">
                            <a
                              className="nav-link"
                              id="pills-invoices-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pillsinvoices"
                              type="button"
                            >
                              <img
                                className="showonmob"
                                height="20"
                                src="/assets/images/invoice.svg"
                                alt="Not found"
                              />
                              <span className="showondesk">Invoices</span>
                            </a>
                          </li> */}
                          <li className="nav-item" role="presentation">
                            <a
                              className="nav-link"
                              id="pills-invoices-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pillsinvoices"
                              type="button"
                            >
                              <span className="showondesk">
                                Payment Detail / Pay Invoice
                              </span>
                            </a>
                          </li>
                          <li className="nav-item" role="presentation">
                            <a
                              className="nav-link"
                              id="pills-invoices-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#Order_Summary"
                              type="button"
                            >
                              <span className="showondesk">Order Summary</span>
                            </a>
                          </li>
                          <li className="nav-item" role="presentation">
                            <a
                              className="nav-link"
                              id="pills-invoices-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#ShippingDoc"
                              type="button"
                            >
                              <span className="showondesk">
                                Shipping Document
                              </span>
                            </a>
                          </li>
                          {/* <li className="nav-item" role="presentation">
                            <a
                              className="nav-link"
                              id="pills-invoices-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pillscargo"
                              type="button"
                            >
                              <span className="showondesk">
                                Additional Instruction
                              </span>
                            </a>
                          </li> */}
                          <li className="nav-item" role="presentation">
                            <a
                              className="nav-link"
                              id="pills-invoices-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#cargo"
                              type="button"
                            >
                              <span className="showondesk">Cargo Details</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-content" id="pills-tabContent">
                        <div
                          //   className="tab-pane fade show active"
                          //   id="pillsdeliveryadd"
                          //   role="tabpanel"
                          // >
                          //   <Payment />
                          className="tab-pane fade  show active"
                          id="pickup_details"
                          role="tabpanel"
                        >
                          <PickUpDetail />
                        </div>
                        <div
                          className="tab-pane fade"
                          id="pillsinvoices"
                          role="tabpanel"
                        >
                          <Invoices />
                        </div>
                        <div
                          className="tab-pane fade"
                          id="ShippingDoc"
                          role="tabpanel"
                        >
                          <ShippingDoc />
                          {/* <Cargo /> */}
                        </div>
                        <div
                          className="tab-pane fade"
                          id="cargo"
                          role="tabpanel"
                        >
                          <Cargo />
                        </div>
                        <div
                          className="tab-pane fade"
                          id="Addone"
                          role="tabpanel"
                        >
                          <AddOne />
                        </div>
                        <div
                          className="tab-pane fade"
                          id="pickup_details"
                          role="tabpanel"
                        >
                          <PickUpDetail />
                        </div>
                        <div
                          className="tab-pane fade"
                          id="Order_Summary"
                          role="tabpanel"
                        >
                          <OrederSummarey />
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-4 border-start px-md-4"> */}
                    {/* <h5 className="mt-4">Order Summary</h5>
                                    <div className="mt-3">
                                        <table width="100%" style={{ minWidth: '100%' }} className="table table-bordered lh-sm">
                                            <tbody>
                                                <tr>
                                                    <td colspan="3" className="gray-bold">
                                                        COURIER / Door To DOOR <br />
                                                        India - United States
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td className="text-right"><strong>Booked</strong></td>
                                                    <td className="text-right"><strong>Actual</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Freight
                                                    </td>
                                                    <td className="text-right">
                                                        AED 35,810.00
                                                    </td>
                                                    <td className="text-right">
                                                        AED 0.00
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" className="text-right smaller">
                                                        (Transit 10 days)
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div> */}
                    {/* <div className="service">
                      <AddOne />
                    </div>
                    <div className="service"> */}
                    {/* <h5 className="color-green">Taxes</h5>
                                        <table width="100%" style={{ minWidth: '100%' }} className="table table-bordered lh-sm">
                                            <tbody>
                                                <tr>
                                                    <td>Sub Total:</td>
                                                    <td >AED 35,810.00</td>
                                                    <td >AED 0.00</td>
                                                </tr>
                                                <tr>
                                                    <td>Discount:</td>
                                                    <td >AED 0.00</td>
                                                    <td >AED 0.00</td>
                                                </tr>
                                                <tr>
                                                    <td>Net:</td>
                                                    <td >AED 35,810.00</td>
                                                    <td >AED 0.00</td>
                                                </tr>
                                                <tr>
                                                    <td>VAT:</td>
                                                    <td >AED 0.00</td>
                                                    <td >AED 0.00</td>
                                                </tr>
                                                <tr>
                                                    <td>Gross:</td>
                                                    <td>AED 35,810.00</td>
                                                    <td>AED 0.00</td>
                                                </tr>
                                            </tbody>
                                        </table> */}
                    {/* </div>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </BookingDetailContext.Provider>
  );
}

export { BookingDetailContext };
