
import { DataService } from './dataService'

export const quoteService = {

    // user quotes api
    postQuote: async (data, authToken=null) => {
        let token = authToken ? {'Authorization' : authToken} : null
        return DataService.post('/user/get_air_quotes', data, token)
    },

    postSeaQuote: async (data, authToken=null) => {
        let token = authToken ? {'Authorization' : authToken} : null
        return DataService.post('/user/get_sea_quotes', data, token)
    },

    checkRefferalTokenApi: async (data) => {
        return DataService.post('/admin/check_retailer_token', data)
    },
    checkPromoApi: async (data) => {
        return DataService.post('/admin/check_promocode_foruser', data)
    },

    getCourierQuote: async (data, authToken=null) => {
        let token = authToken ? {'Authorization' : authToken} : null
        return DataService.post('/user/get_courier_quote', data, token)
    },
    getTntQuote: async (data, authToken=null) => {
        let token = authToken ? {'Authorization' : authToken} : null
        return DataService.post('/user/get_land_quote', data, token)
    },

    /// retailer quotes api 

    postQuoteRetialer: async (data) => {
        return DataService.post('/retailer/get_air_quotes', data)
    },
    postSeaQuoteRetialer: async (data) => {
        return DataService.post('/retailer/get_sea_quotes', data)
    },

    postCourierQuoteRetialer: async (data) => {
        return DataService.post('/retailer/get_courier_quote', data)
    },
    postLandQuoteRetialer: async (data) => {
        return DataService.post('/retailer/get_land_quote', data)
    },
    get_my_quote_list: async (data) => {
        return DataService.get('/retailer/get_my_quote_list', data)
    },
    get_my_quote_list_new: async (data) => {
        return DataService.get('/retailer/get_my_quote_list_new', data)
    },

    get_retailer_quote_list: async (data) => {
        return DataService.get('/retailer/get_retailer_quote_list', data)
    },

    get_retailer_quote_list_updated: async (data) => {
        return DataService.get('/retailer/get_retailer_quote_list_updated', data)
    },

    /// store quotes api

    postQuoteStore: async (data) => {
        return DataService.post('/store/get_air_quotes', data)
    },
    postSeaQuoteStore: async (data) => {
        return DataService.post('/store/get_sea_quotes', data)
    },

    postCourierQuoteStore: async (data) => {
        return DataService.post('/store/get_courier_quote', data)
    },
    postLandQuoteStore: async (data) => {
        return DataService.post('/store/get_land_quote', data)
    },

    ////

    packingList: async () => {
        return DataService.get('/user/get_packing')
    },
    retailerPackingList: async () => {
        return DataService.get('/retailer/get_packing')
    },
    storePackingList: async () => {
        return DataService.get('/store/get_packing')
    },
    insuranceList: async () => {
        return DataService.get('/user/get_insurance')
    },
    retailerInsuranceList: async () => {
        return DataService.get('/retailer/get_insurance')
    },
    storeInsuranceList: async () => {
        return DataService.get('/store/get_insurance')
    },
    storageList: async () => {
        return DataService.get('user/get_storage')
    },
    retailerStorageList: async () => {
        return DataService.get('retailer/get_storage')
    },
    storeStorageList: async () => {
        return DataService.get('store/get_storage')
    },

    uploadFile: async (data) => {
        return DataService.post('/user/add_document', data)
    },
    proceedAddToJob: async (data) => {
        return DataService.post('/user/proceed_add_to_job', data)
    },

    proceedAddToJobRetailer: async (data) => {
        return DataService.post('/retailer/proceed_add_to_job', data)
    },

    postSelectedCourier: async (data) => {
        return DataService.post('/user/selected_courier_type', data)
    },

    retailerSelectedCourier: async (data) => {
        return DataService.post('/retailer/selected_courier_type', data)
    },
    storeSelectedCourier: async (data) => {
        return DataService.post('/store/selected_courier_type', data)
    },

    /// check quotes api
    checkAirFreightApi: async (data) => {
        return DataService.post('/check/get_air_quotes', data)
    },

    checkSeaFreightApi: async (data) => {
        return DataService.post('/check/get_sea_quotes', data)
    },

    checkCourierFreightApi: async (data) => {
        return DataService.post('/check/get_courier_quote', data)
    },

    checkLandFreightApi: async (data) => {
        return DataService.post('/check/get_land_quote', data)
    },

    /// packing
    updatePackingApi: async (data) => {
        return DataService.post('/user/add_update_packing', data)
    },

    // retailer updatepacking
    updateRetailerPackingApi: async (data) => {
        return DataService.post('/retailer/add_update_packing', data)
    },

    // quote detail

    getQuotedetail: async (data) => {
        return DataService.get('/user/get_quote_detail', data)
    },
    getPublicQuotedetail: async (data) => {
        return DataService.get('/user/getquotedetail', data)
    },
    
    /// set insurance and storage

    proceedToFollowRetailerApi: async (data) => {
        return DataService.post('/retailer/setInsuranceAndStorage', data)
    },
    proceedToFollowStoreApi: async (data) => {
        return DataService.post('/store/setInsuranceAndStorage', data)
    },
    proceedToFollowApi: async (data) => {
        return DataService.post('/user/setInsuranceAndStorage', data)
    },
    proceedToFollowApiPublic: async (data) => {
        return DataService.post('/user/setInsuranceAndStoragePublic', data)
    },

    // code for chek email exist or not
    checkEmailApi: async (data) => {
        return DataService.post('/user/check_email', data)
    },
    // end

    // code for store data sd
    get_store_quote_list: async (data) => {
        return DataService.get('/store/get_store_new_quote_list', data)
    },

    //end

    // get quote detail 

    getQuoteDetailApi: async (data) => {
        return DataService.get('/user/quote_detail', data)
    },
    // send quoation mail 
    // sendQuoteMail: async (data) => {
    //     return DataService.post('/user/send_quotation_mail', data)
    // },    
    
    getDutyAndTaxList: async () => {
        return DataService.get('/admin/duty-and-tax/get_all')
    },

    // door request 
    addDoorRequest: async (data) => {
        return DataService.post('/user/add_door_request', data)
    },    
}

// store.subscribe(retailerDetails)

export default quoteService;    