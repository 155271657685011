import { DataService } from './dataService'

export const productService = {
    getProduct: async (data) => {
        return DataService.get('user/get_product', data)
    },

    getRetailerProduct: async (data) => {
        return DataService.get('retailer/get_product_list', data)
    },

    getProductwithsearch: async (data) => {
        return DataService.get('admin/search_product', data)
    },
    importProductData: async (data) => {
		return DataService.post('/admin/import_product', data)
	},
	exportProductData: async (data) => {
		return DataService.get('/admin/export_product_data', data)
	},
    productPromoCode: async (data) => {
		return DataService.post('/user/check_promo_code', data)
	},
    productReferralCode: async (data) => {
		return DataService.post('/user/check_retailer_token', data)
	},
    getQuoteToken: async (data) => {
		return DataService.get('/get_quote_token', data)
	},
    

}


export default productService;