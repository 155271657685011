import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { Storage } from '../Storage/Storage'
import { authServices, productService } from '../services'
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { MdModeEdit, MdDeleteForever, MdRemoveRedEye, MdCloudUpload, MdCloudDownload } from "react-icons/md";
const REACT_APP_API_BASEURL = process.env.REACT_APP_API_BASEURL

export default function Retailer() {

    const history = useHistory()
    // const [activeId, setActiveId] = useState('#editprofile')
    const state = useSelector(state => state.retailerDetails)
    const [list, setList] = useState([]);
    const [retailer,] = useState(Storage.get('user-auth'))
    const [password, setPassword] = useState({
        old_password: '',
        new_password: ''
    })
    useEffect(() => {
        getProduct()
    }, [])

    const getProduct = () => {
        let getData = {
            id: state.user.data._id,
        }

        productService.getProduct(getData).then(res => {
            setList(res.data)
        }).catch(error => {
            console.log(error)
        })
    }

    const Handlechange = (event) => {
        setPassword({ ...password, [event.target.name]: event.target.value });
    };

    const changePassword = (event) => {
        event.preventDefault();

        let postData = {
            retailer_email: state.user.data.retailer_email,
            old_password: password.old_password,
            new_password: password.new_password
        }
        authServices.change_retailer_password(postData).then(res => {
            if (res.status === true) {
                history.push('/')
                toast.success(res.message);
            } else {
                toast.error(res.message)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const onClickExport = (e) => {
        productService.exportProductData().then((res) => {
            if (res.status) {
                window.location.href = REACT_APP_API_BASEURL + '/' + res.data
                toast.success("file download successfully")
            } else {
                toast.error(res.message)
            }
        })
    }

    const onClickImport = (e) => {
        const formData = new FormData()
        formData.append('csv_file', e.target.files[0])
        productService.importProductData(formData).then((res) => {
            if (res.status) {
                toast.success("file upload successfully")
            } else {
                toast.error(res.message)
            }
        })
    }
    return (

        <section className="pb-5 pt-0">

            <div className="header_title">
                <div className="container-fluid">
                    <h1>Retailer Profile</h1>
                    <img src="/assets/images/country.jpg" className="bannerimg" alt='Not found' />
                </div>
            </div>
            <div className="container-fluid mt-5">

                <div className="white_box h-100">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 border-end">
                            <div className="profilesec ">
                                <div className="d-md-flex align-items-center">
                                    <div className="profile_pic"><img src="/assets/images/prof_logo.png" alt='Not found' /></div>

                                    <div className="flex-grow-1 text-start ps-md-3 ps-0">
                                        <h5 className="fw-bold">Retailer ({retailer && retailer.retailer_type})</h5>
                                        <p className="mb-0">Email - {retailer && retailer.retailer_email}</p>
                                        <p className="mb-0">Contact - +91 {retailer && retailer.phone_number}</p>
                                        <p className="mb-0">Contact Person - {retailer && retailer.contact_person}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6  text-left">
                            <p className="mb-0">Registered Address - {retailer && retailer.address}</p>
                            {/* <p className="mb-0">Total Discount -{retailer && retailer.total_discount}%</p>
                            <p className="mb-0">Customer Discount - {retailer && retailer.customer_discount}%</p>
                            <p className="mb-0">Incentive - {retailer && retailer.incentive}</p> */}
                            <p className="mb-0">Taxation details - {retailer && retailer.taxation_details}</p>

                        </div>
                        {/* <ul className="nav mb-3 tablist" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className="nav-link active" id="pills-customer-tab" data-bs-toggle="pill" data-bs-target="#pillscustomer" role="tab" aria-controls="pills-customer" aria-selected="true">Customer</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" id="pills-product-tab" data-bs-toggle="pill" data-bs-target="#pillsproduct" role="tab" aria-controls="pills-product" aria-selected="false">Products</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" id="pills-orders-tab" data-bs-toggle="pill" data-bs-target="#pillsorders" role="tab" aria-controls="pills-orders" aria-selected="false">Orders</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" id="pills-password-tab" data-bs-toggle="pill" data-bs-target="#pillspassword" role="tab" aria-controls="pills-password" aria-selected="false">Change Password</a>
                            </li>
                        </ul> */}
                        <div className="col-md-12">
                            <div className="tab-content" id="pills-tabContent">

                                <div className="tab-pane fade show active" id="pillscustomer" role="tabpanel" aria-labelledby="pills-customer-tab">
                                    <div className="d-flex align-items-center mb-4">
                                        {/* <h4 className="mb-0 me-auto">Users</h4> */}
                                    </div>
                                    {/* <div className="table-responsive">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>S no.</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Contact No.</th>
                                                    <th>Nationality</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr>
                                                    <td>1</td>
                                                    <td>Demo</td>
                                                    <td>sky@gmail.com</td>
                                                    <td>6598985478</td>
                                                    <td>India</td>
                                                    <td><span className="badge rounded-pill font-size-12 pointer bg-success">ACTIVE</span></td>
                                                    <td><button type="button" className="btn btn-primary btn-sm btn-rounded waves-effect waves-light">Follow up</button></td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>Demo</td>
                                                    <td>sky@gmail.com</td>
                                                    <td>6598985478</td>
                                                    <td>India</td>
                                                    <td><span className="badge rounded-pill font-size-12 pointer bg-success">ACTIVE</span></td>
                                                    <td><button type="button" className="btn btn-primary btn-sm btn-rounded waves-effect waves-light">Follow up</button></td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>Demo</td>
                                                    <td>sky@gmail.com</td>
                                                    <td>6598985478</td>
                                                    <td>India</td>
                                                    <td><span className="badge rounded-pill font-size-12 pointer bg-success">ACTIVE</span></td>
                                                    <td><button type="button" className="btn btn-primary btn-sm btn-rounded waves-effect waves-light">Follow up</button></td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>Demo</td>
                                                    <td>sky@gmail.com</td>
                                                    <td>6598985478</td>
                                                    <td>India</td>
                                                    <td><span className="badge rounded-pill font-size-12 pointer bg-success">ACTIVE</span></td>
                                                    <td><button type="button" className="btn btn-primary btn-sm btn-rounded waves-effect waves-light">Follow up</button></td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>Demo</td>
                                                    <td>sky@gmail.com</td>
                                                    <td>6598985478</td>
                                                    <td>India</td>
                                                    <td><span className="badge rounded-pill font-size-12 pointer bg-success">ACTIVE</span></td>
                                                    <td><button type="button" className="btn btn-primary btn-sm btn-rounded waves-effect waves-light">Follow up</button></td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>Demo</td>
                                                    <td>sky@gmail.com</td>
                                                    <td>6598985478</td>
                                                    <td>India</td>
                                                    <td><span className="badge rounded-pill font-size-12 pointer bg-success">ACTIVE</span></td>
                                                    <td><button type="button" className="btn btn-primary btn-sm btn-rounded waves-effect waves-light">Follow up</button></td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td>Demo</td>
                                                    <td>sky@gmail.com</td>
                                                    <td>6598985478</td>
                                                    <td>India</td>
                                                    <td><span className="badge rounded-pill font-size-12 pointer bg-success">ACTIVE</span></td>
                                                    <td><button type="button" className="btn btn-primary btn-sm btn-rounded waves-effect waves-light">Follow up</button></td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div> */}
                                </div>



                                <div className="tab-pane fade" id="pillsproduct" role="tabpanel" aria-labelledby="pills-product-tab">
                                    <div className="d-flex align-items-center mb-4">
                                        <h4 className="mb-0 me-auto">Products</h4>
                                    </div>
                                    <div className=" storeboxing h-100 pb-1">

                                        {
                                            Storage.get("user-token") === "retailer" ?
                                                <div className="row justify-content-end mb-3">

                                                    <label
                                                        htmlFor="upload"
                                                        className="themebutton px-4 uploadProducts"
                                                    >
                                                        <input
                                                            type="file"
                                                            id="upload"
                                                            accept=".csv"
                                                            hidden
                                                            onChange={(e) => onClickImport(e)}
                                                        />
                                                        <MdCloudDownload />
                                                        Import File
                                                    </label>
                                                    &nbsp;&nbsp;

                                                    <button className="themebutton px-4 uploadProducts"
                                                        onClick={() => onClickExport()}
                                                    >
                                                        <MdCloudUpload />
                                                        Export File
                                                    </button>
                                                    {/* <button className="btn btn-primary w-sm waves-effect waves-light mx-3 my-0"
                                        onClick={() => history.push('/add-product')}
                                    >Add Product
                                    </button> */}
                                                </div>
                                                : ''
                                        }

                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>S.NO.</th>
                                                        <th>Product Name</th>
                                                        <th>Product Volume</th>
                                                        <th>product_gross_weight</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        list.length > 0 && list.map((elem, i) => (
                                                            <tr key={i}>
                                                                <td>{i + 1}</td>
                                                                <td>{elem.product_name}</td>
                                                                <td>{elem.product_volume}</td>
                                                                <td>{elem.product_gross_weight} </td>
                                                                <td>{elem.product_status}</td>
                                                                <td className="actionTd">
                                                                    <Link
                                                                        to={{
                                                                            pathname: `/store-details/${elem._id}`,
                                                                            state: elem,
                                                                        }}
                                                                    ><MdRemoveRedEye /></Link> &nbsp;
                                                                    <MdModeEdit /> &nbsp;
                                                                    <MdDeleteForever className="text-danger" />
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }


                                                </tbody>
                                            </table>
                                        </div>

                                    </div>


                                </div>


                                <div className="tab-pane fade" id="pillsorders" role="tabpanel" aria-labelledby="pills-orders-tab">
                                    <div className="d-flex align-items-center mb-4">
                                        <h4 className="mb-0 me-auto">Users</h4>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>S no.</th>
                                                    <th>Order ID</th>
                                                    <th>Price</th>
                                                    <th>Quantity</th>
                                                    <th>Payment Types</th>
                                                    <th>Order Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>itme 1</td>
                                                    <td>100 AED</td>
                                                    <td>100</td>
                                                    <td>COD</td>
                                                    <td><span className="badge rounded-pill font-size-12 pointer bg-success">Active</span></td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>itme 1</td>
                                                    <td>100 AED</td>
                                                    <td>100</td>
                                                    <td>COD</td>
                                                    <td><span className="badge rounded-pill font-size-12 pointer bg-success">Active</span></td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>itme 1</td>
                                                    <td>100 AED</td>
                                                    <td>100</td>
                                                    <td>COD</td>
                                                    <td><span className="badge rounded-pill font-size-12 pointer bg-success">Active</span></td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>itme 1</td>
                                                    <td>100 AED</td>
                                                    <td>100</td>
                                                    <td>COD</td>
                                                    <td><span className="badge rounded-pill font-size-12 pointer bg-success">Active</span></td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>itme 1</td>
                                                    <td>100 AED</td>
                                                    <td>100</td>
                                                    <td>COD</td>
                                                    <td><span className="badge rounded-pill font-size-12 pointer bg-success">Active</span></td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>itme 1</td>
                                                    <td>100 AED</td>
                                                    <td>100</td>
                                                    <td>COD</td>
                                                    <td><span className="badge rounded-pill font-size-12 pointer bg-success">Active</span></td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td>itme 1</td>
                                                    <td>100 AED</td>
                                                    <td>100</td>
                                                    <td>COD</td>
                                                    <td><span className="badge rounded-pill font-size-12 pointer bg-success">Active</span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pillspassword" role="tabpanel" aria-labelledby="pills-password-tab">
                                    <div className="d-flex align-items-center mb-4">
                                        <h4 className="mb-0 me-auto">Change Password</h4>
                                    </div>
                                    <form onSubmit={(event) => { changePassword(event) }}>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group mb-3">
                                                            <label>Current Password</label>
                                                            <input className="form-control rounded_input" onChange={(e) => { Handlechange(e) }} name='old_password' type="password" placeholder="Current Password" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group mb-3">
                                                            <label>New Password</label>
                                                            <input className="form-control rounded_input" onChange={(e) => { Handlechange(e) }} name='new_password' type="password" placeholder="New Password" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group mb-3">
                                                            <label>Confirm Password</label>
                                                            <input className="form-control rounded_input" type="password" placeholder="Confirm Password" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <button type='submit' className="themebutton px-4">Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
