import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap-v5";
import { ImCross } from "react-icons/im";
import { Button, Spinner } from "react-bootstrap-v5";
import { quoteService } from "../../services";

export const DutyAndTaxList = ({dutyModal,setDutyModal}) => {
  const [adminList,setAdminList] = useState([])
  const [loader,setLoader] = useState(false)

  const getDetail = async () => {
    setLoader(true);
    await quoteService.getDutyAndTaxList().then((res) => {
      setLoader(false);
      if (res.status) {
        let finalData = res.data.reduce((acc, curr) => {
            let existingCountry = acc.find(item => item.country === curr.country);
            if (existingCountry) {
                existingCountry.data.push(curr);
            } else {
                acc.push({
                    country: curr.country,
                    data: [curr]
                });
            }
            return acc;
        }, []);
        // console.log(finalData,'finalData')
        setAdminList(finalData);
        // setAdminList(res.data);
      }
    });
  };

  useEffect(() => {
    getDetail();
  }, []);

  return (
    <Modal show={dutyModal}  dialogClassName="dialog800" scrollable={true}
      onHide={()=> setDutyModal(false)} >
        <div class="modal-header ps-4 pe-4 nav_button m-0 rounded-0 ">
            <h5 class="modal-title w-100 text-center">Duty And Taxes Table</h5>
            <ImCross className="fs-5" onClick={()=> setDutyModal(false)} />
        </div>
        <Modal.Body >
      {/* <div className="card">
        <div className="card-body"> */}
        <div className="ps-2 fs-6">
            <b>Note - The below mentioned Duty/Taxes % are only for Reference and subject to change</b>
        </div>
        <div className="ps-2 fs-6">
            <b>Note - Duty/Taxes to be Paid at Actuals at the time of Customs Clearance</b>
        </div>
          <div className="table-responsive border rounded ">
            <table className="table table-centered table-striped table-nowrap mb-0 ">
              <thead className="table-light">
                <tr>
                  <th className="pt-2 pb-2" >S No.</th>
                  <th className="pt-2 pb-2" >Country</th>
                  <th className="pt-2 pb-2" >Description</th>
                  <th className="pt-2 pb-2" >Duty %</th>
                  <th className="pt-2 pb-2" >Tax %</th>
                </tr>
              </thead>
              <tbody>
                {loader
                ? <tr><td colSpan={5} className="text-center"><Spinner animation="border" /></td></tr>
                : (adminList && adminList.length > 0)
                ? adminList?.map((item, key) => (
                    <tr key={key} className="border p-0">
                      <td className="pb-0 pt-1 text-body fw-bold">{(key + 1)}</td>
                      <td className="pb-0 pt-1 text-body">{item?.country}</td>
                      <td className="pb-0 pt-1 text-body">{item.data.map((itm)=> <div style={{marginBottom:"10px"}}>{itm?.description} </div>) }</td>
                      <td className="pb-0 pt-1 text-body">{item.data.map((itm)=> <div style={{marginBottom:"10px"}}>{itm?.duty} </div>) }</td>
                      <td className="pb-0 pt-1 text-body">{item.data.map((itm)=> <div style={{marginBottom:"10px"}}>{itm?.tax} </div>) }</td>
                    </tr>
                  ))
                : <tr><td colSpan={5} className="text-center">Data Not Available</td></tr>
                }
              </tbody>
            </table>
          </div>
        {/* </div>
      </div> */}
        </Modal.Body>
    </Modal>
  );
};
