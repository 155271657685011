import { DataService } from './dataService'

export const jobBooking = {
    getJobBookingDetails: async (data) => {
        return DataService.get('user/get_booking_detail', data)
    },
    getTrackingDetails: async (data) => {
        return DataService.get('user/get_tracking_details', data)
    },
}


export default jobBooking;