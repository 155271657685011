import React from 'react'

export default function TipsAndMyths() {
    return (

        <section className="pb-5 pt-0">
            <div className="header_title">
                <div className="container-fluid">
                    <h1>Tips & Myths</h1>
                    <p>Pls find attached the Country Guide. Pls find Myths/Tips below. Since Myths/Tips have lot of text which is necessary to explain and cannot be presented only in picture form we need to present it in a smart way in the website so that the page does not look text heavy. By tomm I will send u the FAQ’s as well.</p>
                    <img src="/assets/images/country.jpg" className="bannerimg" alt='Not found' />
                </div>
            </div>
            <div className="container-fluid mt-5">
                <div className="white_box h-100">
                    <div className="row">

                        {/* <div className="col-md-6">
                            <form>
                                <div className="form-group mb-3">
                                    <label className="fw-bold mb-2">Select Country </label>
                                    <select className="form-control rounded_input" >
                                        <option>Country Name</option>
                                    </select>
                                </div>

                            </form>
                        </div> */}

                        <div className="col-md-6 border-end ">
                            <h3 className="fw-bold my-4">Myths</h3>

                            <div className="faq_wrapper">
                                <a data-bs-toggle="collapse" href="#faq1" role="button" aria-expanded="false" aria-controls="collapseExample">
                                    Sea Frt is the cheapest
                                </a>
                                <div className="collapse show" id="faq1">
                                    For small shipment courier ( air) is a better option as it is fast as well as cheap. Sea frt are meant to handle large shipments and hence have minimum charges due to which they become expensive for small shipments.
                                </div>
                            </div>

                            <div className="faq_wrapper">
                                <a data-bs-toggle="collapse" className="collapsed" href="#faq2" role="button" aria-expanded="false" aria-controls="collapseExample">
                                    Freight amt should be 10%
                                </a>
                                <div className="collapse" id="faq2">
                                    20% of the value of goods purchased – This assumption is wrong as freight is based on the size and weight of the shipment irrespective of the value of the goods.
                                </div>
                            </div>

                            <div className="faq_wrapper">
                                <a data-bs-toggle="collapse" className="collapsed" href="#faq3" role="button" aria-expanded="false" aria-controls="collapseExample">
                                    Big Shipments move by Sea Frt only
                                </a>
                                <div className="collapse" id="faq3">
                                    Many assume that large shipment will always go by sea as freight is high by air. But we have many cases where customer in urgency moves even large shipments by air as long as dimensions and weight are acceptable on the particular aircraft.
                                </div>
                            </div>

                            <div className="faq_wrapper">
                                <a data-bs-toggle="collapse" className="collapsed" href="#faq4" role="button" aria-expanded="false" aria-controls="collapseExample">
                                    If the cargo is insured then you will get the full value back incase cargo is damaged
                                </a>
                                <div className="collapse" id="faq4">
                                    If the cargo is insured then you will get the full value back incase cargo is damaged – The packing is the responsibility of the sender. If the item is packed in Original Manufacturers Packing then it is covered by Insurance companies or If the Packing is done by professional packers ( IAM or FIDI Approved) then Insurance companies cover it. But if the Individual does his own packing by himself then Insurance company does not cover the goods in case of Damages.
                                </div>
                            </div>

                            <div className="faq_wrapper">
                                <a data-bs-toggle="collapse" className="collapsed" href="#faq5" role="button" aria-expanded="false" aria-controls="collapseExample">
                                    Manufacturers packing is good enough
                                </a>
                                <div className="collapse" id="faq5">
                                    Many times manufacturers packing is limited as theirs items come in full contrs to the retailers where loading and unloading is taken care by the company staff. But when a customer ships it, he sends it single piece as loose. The item is loaded and off loaded multiple times from contrs and vehicles till final delivery. The goods changes man hands. Hence additional packing is required in addition to the Manufacturers packing for safety. But it is not required in all cases.
                                </div>
                            </div>

                            <div className="faq_wrapper">
                                <a data-bs-toggle="collapse" className="collapsed" href="#faq6" role="button" aria-expanded="false" aria-controls="collapseExample">
                                    There is no duty when you carry brand new goods along with you
                                </a>
                                <div className="collapse" id="faq6">
                                    If customer carries brand new goods along with him in flight he liable to pay duty and taxes as per the law of the land.
                                </div>
                            </div>

                            <div className="faq_wrapper">
                                <a data-bs-toggle="collapse" className="collapsed" href="#faq7" role="button" aria-expanded="false" aria-controls="collapseExample">
                                    Freight amt should not be more than the value of goods
                                </a>
                                <div className="collapse" id="faq7">
                                    Freight is calculated on the weight and dims of the goods. It is independent of the value of the goods. So there are cases where Freight has been more that the value of goods. In cases where if the same items are not available in the customer’s country then there are customers who are ready to pay freight more than cost of goods.
                                </div>
                            </div>

                            <div className="faq_wrapper">
                                <a data-bs-toggle="collapse" className="collapsed" href="#faq8" role="button" aria-expanded="false" aria-controls="collapseExample">
                                    Freight is charged on the actual weight only
                                </a>
                                <div className="collapse" id="faq8">
                                    People understand weight hence they think carriers charge on weight only. It is not true. The carriers will check the actual wt. Then they will check the volume ( space occupied by the item) and convert it into volume wt. Then they will compare the actual wt vs volume wt and whichever is higher will be charged.
                                </div>
                            </div>

                            <div className="faq_wrapper">
                                <a data-bs-toggle="collapse" className="collapsed" href="#faq9" role="button" aria-expanded="false" aria-controls="collapseExample">
                                    Commercial qty of goods can be carried as personal goods
                                </a>
                                <div className="collapse" id="faq9">
                                    Commercial qty is when u have same item but in large quantities. Eg. 5 TV’s. When shipment is moved under personal name it cannot be in commercial quantity as it is assumed it is for personal use. If the Qty is commercial then it should move under company name.
                                </div>
                            </div>

                            <div className="faq_wrapper">
                                <a data-bs-toggle="collapse" className="collapsed" href="#faq10" role="button" aria-expanded="false" aria-controls="collapseExample">
                                    There is no duty for Personal Goods even if they are new
                                </a>
                                <div className="collapse" id="faq10">
                                    Any new items sent as cargo mode to any country will attract Duty and Taxes as per respective country laws. You might get some concessions if moving the items under Transfer of Residence but in general new items attract duty and taxes.
                                </div>
                            </div>
                            <div className="faq_wrapper">
                                <a data-bs-toggle="collapse" className="collapsed" href="#faq11" role="button" aria-expanded="false" aria-controls="collapseExample">
                                    Customs Duty and Taxes are calculated on the discounted price
                                </a>
                                <div className="collapse" id="faq11">
                                    As per international law customs duty and taxes are charged on the full price and not on the discounted price. The duty is calculated on CIF Value ( Cost + Insurance + Freight) while Vat is calculated on CIF value + applicable Duty.
                                </div>
                            </div>

                        </div>
                        <div className="col-md-6">
                            <h3 className="fw-bold my-4">Tips</h3>

                            <ul className="fancylist">
                                <li>
                                    <span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>Freight is charged on Gr. Wt or Vol Wt which ever is higher.</div>
                                </li>
                                <li>
                                    <span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>Internal Packing is extremely important as much as Outer packing. Do not cut corners with regards to Packing.</div>
                                </li>
                                <li>
                                    <span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>Consolidating goods and shipping it always proves to be cheaper.</div>
                                </li>
                                <li>
                                    <span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>Always Insure the goods even if the packing is very good.</div>
                                </li>
                                <li>
                                    <span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>Fragile Shipment to be wooden packed for safety.</div>
                                </li>
                                <li>
                                    <span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>Beware of shipping companies who give only verbal assurance without insurance policy.</div>
                                </li>
                                <li>
                                    <span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>Compare the prices of goods apple to apple ( same quality and brand) with the items in home country before making purchase decision.</div>
                                </li>
                                <li>
                                    <span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>Always go with known shipping agent or referred shipping agent for moving personal goods.</div>
                                </li>
                                <li>
                                    <span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>While checking for shipping rates always get the actual weight and the packed dimensions of the items to get accurate quotes.</div>
                                </li>
                                <li>
                                    <span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>Dimensions of the product should always be taken after the final packing is done.</div>
                                </li>
                                <li>
                                    <span><img src="assets/images/checked.svg" alt='Not found' /></span>
                                    <div>While noting the dimension of the packed box, always measure the dims of the maximum part for each length , width & height. </div>
                                </li>

                            </ul>
                        </div>

                        {/* 
                        <ul className="nav mb-3 tablist" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className="nav-link active" id="pills-myths-tab" data-bs-toggle="pill" data-bs-target="#pillsmyths" type="button" role="tab" aria-controls="pills-myths" aria-selected="true">Myths</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" id="pills-tips-tab" data-bs-toggle="pill" data-bs-target="#pillstips" type="button" role="tab" aria-controls="pills-tips" aria-selected="false">Tips</a>
                            </li>

                        </ul>
                        <div className="col-md-12">
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pillsmyths" role="tabpanel" aria-labelledby="pills-myths-tab">
                                    <ul className="fancylist">
                                        <li>
                                            <span><img src="assets/images/checked.svg" /></span>
                                            <div>Sea Frt is the cheapest – For small shipment courier ( air) is a better option as it is fast as well as cheap. Sea frt are meant to handle large shipments and hence have minimum charges due to which they become expensive for small shipments.</div>
                                        </li>
                                        <li>
                                            <span><img src="assets/images/checked.svg" /></span>
                                            <div>Freight amt should be 10% – 20% of the value of goods purchased – This assumption is wrong as freight is based on the size and weight of the shipment irrespective of the value of the goods. </div>
                                        </li>
                                        <li>
                                            <span><img src="assets/images/checked.svg" /></span>
                                            <div>Big Shipments move by Sea Frt only – Many assume that large shipment will always go by sea as freight is high by air. But we have many cases where customer in urgency moves even large shipments by air as long as dimensions and weight are acceptable on the particular aircraft.</div>
                                        </li>
                                        <li>
                                            <span><img src="assets/images/checked.svg" /></span>
                                            <div>If the cargo is insured then you will get the full value back incase cargo is damaged – The packing is the responsibility of the sender. If the item is packed in Original Manufacturers Packing then it is covered by Insurance companies or If the Packing is done by professional packers ( IAM or FIDI Approved) then Insurance companies cover it. But if the Individual does his own packing by himself then Insurance company does not cover the goods in case of Damages. </div>
                                        </li>
                                        <li>
                                            <span><img src="assets/images/checked.svg" /></span>
                                            <div>Manufacturers packing is good enough – Many times manufacturers packing is limited as theirs items come in full contrs to the retailers where loading and unloading is taken care by the company staff. But when a customer ships it, he sends it single piece as loose. The item is loaded and off loaded multiple times from contrs and vehicles till final delivery. The goods changes man hands. Hence additional packing is required in addition to the Manufacturers packing for safety. But it is not required in all cases. </div>
                                        </li>
                                        <li>
                                            <span><img src="assets/images/checked.svg" /></span>
                                            <div>There is no duty when you carry brand new goods along with you – If customer carries brand new goods along with him in flight he liable to pay duty and taxes as per the law of the land. </div>
                                        </li>
                                        <li>
                                            <span><img src="assets/images/checked.svg" /></span>
                                            <div>Freight amt should not be more than the value of goods – Freight is calculated on the weight and dims of the goods. It is independent of the value of the goods. So there are cases where Freight has been more that the value of goods. In cases where if the same items are not available in the customer’s country then there are customers who are ready to pay freight more than cost of goods.</div>
                                        </li>
                                        <li>
                                            <span><img src="assets/images/checked.svg" /></span>
                                            <div>Freight is charged on the actual weight only – People understand weight hence they think carriers charge on weight only. It is not true. The carriers will check the actual wt. Then they will check the volume ( space occupied by the item) and convert it into volume wt. Then they will compare the actual wt vs volume wt and whichever is higher will be charged.</div>
                                        </li>
                                        <li>
                                            <span><img src="assets/images/checked.svg" /></span>
                                            <div>Commercial qty of goods can be carried as personal goods – Commercial qty is when u have same item but in large quantities. Eg. 5 TV’s. When shipment is moved under personal name it cannot be in commercial quantity as it is assumed it is for personal use. If the Qty is commercial then it should move under company name. </div>
                                        </li>
                                        <li>
                                            <span><img src="assets/images/checked.svg" /></span>
                                            <div>There is no duty for Personal Goods even if they are new – Any new items sent as cargo mode to any country will attract Duty and Taxes as per respective country laws. You might get some concessions if moving the items under Transfer of Residence but in general new items attract duty and taxes.</div>
                                        </li>
                                        <li>
                                            <span><img src="assets/images/checked.svg" /></span>
                                            <div>Customs Duty and Taxes are calculated on the discounted price – As per international law customs duty and taxes are charged on the full price and not on the discounted price. The duty is calculated on CIF Value ( Cost + Insurance + Freight) while Vat is calculated on CIF value + applicable Duty.</div>
                                        </li>
                                    </ul>
                                </div>

                                <div className="tab-pane fade" id="pillstips" role="tabpanel" aria-labelledby="pills-tips-tab">.
                                    <ul className="fancylist">
                                        <li>
                                            <span><img src="assets/images/checked.svg" /></span>
                                            <div>Freight is charged on Gr. Wt or Vol Wt which ever is higher.</div>
                                        </li>
                                        <li>
                                            <span><img src="assets/images/checked.svg" /></span>
                                            <div>Internal Packing is extremely important as much as Outer packing. Do not cut corners with regards to Packing.</div>
                                        </li>
                                        <li>
                                            <span><img src="assets/images/checked.svg" /></span>
                                            <div>Consolidating goods and shipping it always proves to be cheaper.</div>
                                        </li>
                                        <li>
                                            <span><img src="assets/images/checked.svg" /></span>
                                            <div>Always Insure the goods even if the packing is very good.</div>
                                        </li>
                                        <li>
                                            <span><img src="assets/images/checked.svg" /></span>
                                            <div>Fragile Shipment to be wooden packed for safety.</div>
                                        </li>
                                        <li>
                                            <span><img src="assets/images/checked.svg" /></span>
                                            <div>Beware of shipping companies who give only verbal assurance without insurance policy.</div>
                                        </li>
                                        <li>
                                            <span><img src="assets/images/checked.svg" /></span>
                                            <div>Compare the prices of goods apple to apple ( same quality and brand) with the items in home country before making purchase decision.</div>
                                        </li>
                                        <li>
                                            <span><img src="assets/images/checked.svg" /></span>
                                            <div>Always go with known shipping agent or referred shipping agent for moving personal goods.</div>
                                        </li>
                                        <li>
                                            <span><img src="assets/images/checked.svg" /></span>
                                            <div>While checking for shipping rates always get the actual weight and the packed dimensions of the items to get accurate quotes.</div>
                                        </li>
                                        <li>
                                            <span><img src="assets/images/checked.svg" /></span>
                                            <div>Dimensions of the product should always be taken after the final packing is done.</div>
                                        </li>
                                        <li>
                                            <span><img src="assets/images/checked.svg" /></span>
                                            <div>While noting the dimension of the packed box, always measure the dims of the maximum part for each length , width & height. </div>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                   */}

                    </div>
                </div>
            </div>
        </section>
    )
}
