import React, { useState, useContext } from 'react'
import { useParams } from "react-router"
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { QuoteDetailContext } from '../GetQuoteDetail'
import UserAuthentication from "../UserAuthentication";
import { SERVICE_TYPE, SERVICE_VALUE } from '../../../Redux/type';
import { quoteService } from '../../../services';

export default function Freights({ resetPckInsStorage }) {
    const { id } = useParams()
    const { state, dispatch } = useContext(QuoteDetailContext);
    const [showAuth, setShowAuth] = useState(false);
    const userInfo = useSelector((state) => state.userDetails);
    const history = useHistory();

    const handleRadio = (e, type, typeWay) => {
        if (userInfo.isLogged) {
            dispatch({ type: SERVICE_TYPE, payload: { type, typeWay } })
            dispatch({ type: SERVICE_VALUE, payload: e.target.value })

            let param = {
                selected_courier_type: type,
                quote_token: state.quoteDetails.quote_token,
            };

            quoteService.postSelectedCourier(param)

            // Set to initial value
            resetPckInsStorage()

        } else {
            setShowAuth(!showAuth)
        }

    }

    const handleFreightValue = (freight) => {
        if (state.serviceValue === 0) {
            dispatch({ type: SERVICE_VALUE, payload: freight })
        }
        return true
    }
    const handleProceed = (e) => {
        history.push("/get-quote-detail/" + id)
    }
    const handleAuth = () => {
        setShowAuth(!showAuth)
    }

    if (!state) {
        return 'Loading...'
    } else {
        const info = state.quoteDetails
        const courier = info.quote_request_couriers
        const air = info.quote_request_airs
        const sea = info.quote_request_seas
        const land = info.quote_request_lands
        const serviceRadio = state.serviceType

        return (
            <>
                <div className="white_box">
                    <div className="table-responsive" style={{ overflow: "inherit" }}>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Service</th>
                                    <th>Port Delivery</th>
                                    <th>Door Delivery</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* Courier Freight ---start--- */}
                                {
                                    courier.length > 0
                                    && ('final' in courier[0])
                                    && (
                                        (
                                            'port_delivery' in courier[0].final
                                            && courier[0].final.port_delivery.port_grant_total
                                        )
                                        || (
                                            'door_delivery' in courier[0].final
                                            && courier[0].final.door_delivery.door_grant_total
                                        )
                                        || (
                                            'consolidation_delivery' in courier[0].final
                                            && courier[0].final.consolidation_delivery.consolidation_grant_total
                                        )
                                    ) && <tr className="border-bottom">
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <div className="service_icon">
                                                    <img src="/assets/images/Flat.svg" alt="Not found" />
                                                </div>
                                                <div className="flex-grow-1 ps-3">
                                                    <h6 className="mb-1">
                                                        <b className="text-success">COURIER</b> Freight
                                                    </h6>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {
                                                courier.length > 0
                                                    && ('final' in courier[0])
                                                    && ('port_delivery' in courier[0].final)
                                                    && courier[0].final.port_delivery.port_grant_total
                                                    ? <>
                                                        <input
                                                            type="radio"
                                                            id="cr-port"
                                                            name="selector"
                                                            value={courier[0].final.port_delivery.port_grant_total}
                                                            onChange={(e) => handleRadio(e, 'PORT', 'COURIER')}

                                                            checked={serviceRadio.type === 'PORT' && serviceRadio.typeWay === 'COURIER' ? handleFreightValue(courier[0].final.port_delivery.port_grant_total) : false}
                                                        />
                                                        {/* <div className="check"></div> */}
                                                        <label for="cr-port" className="d-block">
                                                            <h6 className="text-success mb-1 ps-4">
                                                                AED {courier[0].final.port_delivery.port_grant_total}
                                                            </h6>
                                                        </label>
                                                        <p>Appro. Transit Time {courier[0].final.port_delivery.port_delivery_tt} Days</p>
                                                    </>
                                                    : "Not Applicable"
                                            }
                                        </td>
                                        <td>
                                            <>
                                                {
                                                    courier.length > 0
                                                        && ('final' in courier[0])
                                                        && ('door_delivery' in courier[0].final)
                                                        && courier[0].final.door_delivery.door_grant_total
                                                        ? <>
                                                            <input
                                                                type="radio"
                                                                id="cr-door"
                                                                name="selector"
                                                                value={courier[0].final.door_delivery.door_grant_total}
                                                                onChange={(e) => handleRadio(e, 'DOOR', 'COURIER')}
                                                                checked={serviceRadio.type === 'DOOR' && serviceRadio.typeWay === 'COURIER' ? handleFreightValue(courier[0].final.door_delivery.door_grant_total) : false}
                                                            />
                                                            {/* <div className="check"></div> */}
                                                            <label for="cr-door" className="d-block">
                                                                <h6 className="text-success mb-1 ps-4">
                                                                    AED {courier[0].final.door_delivery.door_grant_total}
                                                                </h6>
                                                            </label>
                                                            <p>Appro. Transit Time {courier[0].final.door_delivery.door_delivery_tt} Days</p>
                                                        </>
                                                        : "Not Applicable"
                                                }
                                                <div className="consolidation-relative">
                                                    {
                                                        courier.length > 0
                                                            && ('final' in courier[0])
                                                            && ('consolidation_delivery' in courier[0].final)
                                                            && courier[0].final.consolidation_delivery.consolidation_grant_total
                                                            ? <>
                                                                <input
                                                                    type="radio"
                                                                    id='cr-cn'
                                                                    name="selector"
                                                                    value={courier[0].final.consolidation_delivery.consolidation_grant_total}
                                                                    onChange={(e) => handleRadio(e, 'CONSOLIDATION', 'COURIER')}
                                                                    checked={serviceRadio.type === 'CONSOLIDATION' && serviceRadio.typeWay === 'COURIER' ? handleFreightValue(courier[0].final.consolidation_delivery.consolidation_grant_total) : false}
                                                                />
                                                                {/* <div className="check"></div> */}
                                                                <label for="cr-cn" className="d-block">
                                                                    <h6 className="text-success mb-1 ps-4">
                                                                        AED {courier[0].final.consolidation_delivery.consolidation_grant_total}
                                                                    </h6>
                                                                </label>
                                                                <p>Appro. Transit Time {courier[0].final.consolidation_delivery.consolidation_door_delivery_tt} Days</p>
                                                            </>
                                                            : ""
                                                    }
                                                </div>
                                            </>
                                        </td>
                                    </tr>}
                                {/* Courier Freight ---end--- */}

                                {/* Air Freight ---start--- */}
                                {
                                    air.length > 0
                                    && ('final' in air[0])
                                    && (
                                        (
                                            'port_delivery' in air[0].final
                                            && air[0].final.port_delivery.port_grant_total
                                        )
                                        || (
                                            'door_delivery' in air[0].final
                                            && air[0].final.door_delivery.door_grant_total
                                        )
                                        || (
                                            'consolidation_delivery' in air[0].final
                                            && air[0].final.consolidation_delivery.consolidation_grant_total
                                        )
                                    ) && <tr className="border-bottom">
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <div className="service_icon">
                                                    <img src="/assets/images/plane.svg" alt="Not found" />
                                                </div>
                                                <div className="flex-grow-1 ps-3">
                                                    <h6 className="mb-1">
                                                        <b className="text-success">AIR</b> Freight
                                                    </h6>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {
                                                air.length > 0
                                                    && ('final' in air[0])
                                                    && ('port_delivery' in air[0].final)
                                                    && air[0].final.port_delivery.port_grant_total
                                                    ? <>
                                                        <input
                                                            type="radio"
                                                            id='ar-port'
                                                            name="selector"
                                                            value={air[0].final.port_delivery.port_grant_total}
                                                            onChange={(e) => handleRadio(e, 'PORT', 'AIR')}
                                                            checked={
                                                                serviceRadio.type === 'PORT' && serviceRadio.typeWay === 'AIR'
                                                                    ? handleFreightValue(air[0].final.port_delivery.port_grant_total)
                                                                    : false
                                                            }
                                                        />
                                                        {/* <div className="check"></div> */}
                                                        <label for="ar-port" className="d-block">
                                                            <h6 className="text-success mb-1 ps-4">
                                                                AED {air[0].final.port_delivery.port_grant_total}
                                                            </h6>
                                                        </label>
                                                        <p>Appro. Transit Time {air[0].final.port_delivery.port_delivery_tt} Days</p>
                                                    </>
                                                    : "Not Applicable"
                                            }
                                        </td>
                                        <td>
                                            {
                                                air.length > 0
                                                    && ('final' in air[0])
                                                    && ('door_delivery' in air[0].final)
                                                    && air[0].final.door_delivery.door_grant_total
                                                    ? <>
                                                        <input
                                                            type="radio"
                                                            name="selector"
                                                            id="ar-door"
                                                            value={air[0].final.door_delivery.door_grant_total}
                                                            onChange={(e) => handleRadio(e, 'DOOR', 'AIR')}
                                                            checked={serviceRadio.type === 'DOOR' && serviceRadio.typeWay === 'AIR' ? handleFreightValue(air[0].final.door_delivery.door_grant_total) : false}
                                                        />
                                                        {/* <div className="check"></div> */}
                                                        <label for="ar-door" className="d-block">
                                                            <h6 className="text-success mb-1 ps-4">
                                                                AED {air[0].final.door_delivery.door_grant_total}
                                                            </h6>
                                                        </label>
                                                        <p>Appro. Transit Time {air[0].final.door_delivery.door_delivery_tt} Days</p>
                                                    </>
                                                    : "Not Applicable"
                                            }
                                            <div className="consolidation-relative">
                                                {
                                                    air.length > 0
                                                        && ('final' in air[0])
                                                        && ('consolidation_delivery' in air[0].final)
                                                        && air[0].final.consolidation_delivery.consolidation_grant_total
                                                        ? <>
                                                            <input
                                                                type="radio"
                                                                id='ar-cn'
                                                                name="selector"
                                                                value={air[0].final.consolidation_delivery.consolidation_grant_total}
                                                                onChange={(e) => handleRadio(e, 'CONSOLIDATION', 'AIR')}
                                                                checked={serviceRadio.type === 'CONSOLIDATION' && serviceRadio.typeWay === 'AIR' ? handleFreightValue(air[0].final.consolidation_delivery.consolidation_grant_total) : false}
                                                            />
                                                            {/* <div className="check"></div> */}
                                                            <label for="ar-cn" className="d-block">
                                                                <h6 className="text-success mb-1 ps-4">
                                                                    AED {air[0].final.consolidation_delivery.consolidation_grant_total}
                                                                </h6>
                                                            </label>
                                                            <p>Appro. Transit Time {air[0].final.consolidation_delivery.consolidation_door_delivery_tt} Days</p>
                                                        </>
                                                        : ""
                                                }
                                            </div>
                                        </td>
                                    </tr>}
                                {/* Air Freight ---end--- */}

                                {/* Sea Freight ---start--- */}

                                {
                                    sea.length > 0
                                    && ('final' in sea[0])
                                    && (
                                        (
                                            'port_delivery' in sea[0].final
                                            && sea[0].final.port_delivery.port_grant_total
                                        )
                                        || (
                                            'door_delivery' in sea[0].final
                                            && sea[0].final.door_delivery.door_grant_total
                                        )
                                        || (
                                            'consolidation_delivery' in sea[0].final
                                            && sea[0].final.consolidation_delivery.consolidation_grant_total
                                        )
                                    ) && <tr className="border-bottom">
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <div className="service_icon">
                                                    <img src="/assets/images/shipment.svg" alt="Not found" />
                                                </div>
                                                <div className="flex-grow-1 ps-3">
                                                    <h6 className="mb-1">
                                                        <b className="text-success">SEA</b> Freight
                                                    </h6>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {
                                                sea.length > 0
                                                    && ('final' in sea[0])
                                                    && ('port_delivery' in sea[0].final)
                                                    && sea[0].final.port_delivery.port_grant_total
                                                    ? <>
                                                        <input
                                                            type="radio"
                                                            name="selector"
                                                            id='sea-port'
                                                            value={sea[0].final.port_delivery.port_grant_total}
                                                            onChange={(e) => handleRadio(e, 'PORT', 'SEA')}
                                                            checked={serviceRadio.type === 'PORT' && serviceRadio.typeWay === 'SEA' ? handleFreightValue(sea[0].final.port_delivery.port_grant_total) : false}
                                                        />
                                                        {/* <div className="check"></div> */}
                                                        <label for="sea-port" className="d-block">
                                                            <h6 className="text-success mb-1 ps-4">
                                                                AED {sea[0].final.port_delivery.port_grant_total}
                                                            </h6>
                                                        </label>
                                                        <p>Appro. Transit Time {sea[0].final.port_delivery.port_delivery_tt} Days</p>
                                                    </>
                                                    : "Not Applicable"
                                            }
                                        </td>
                                        <td>
                                            {
                                                sea.length > 0
                                                    && ('final' in sea[0])
                                                    && ('door_delivery' in sea[0].final)
                                                    && sea[0].final.door_delivery.door_grant_total
                                                    ? <>
                                                        <input
                                                            type="radio"
                                                            name="selector"
                                                            id='sea-door'
                                                            value={sea[0].final.door_delivery.door_grant_total}
                                                            onChange={(e) => handleRadio(e, 'DOOR', 'SEA')}
                                                            checked={serviceRadio.type === 'DOOR' && serviceRadio.typeWay === 'SEA' ? handleFreightValue(sea[0].final.door_delivery.door_grant_total) : false}
                                                        />
                                                        {/* <div className="check"></div> */}
                                                        <label for="sea-door" className="d-block">
                                                            <h6 className="text-success mb-1 ps-4">
                                                                AED {sea[0].final.door_delivery.door_grant_total}
                                                            </h6>
                                                        </label>
                                                        <p>Appro. Transit Time {sea[0].final.door_delivery.door_delivery_tt} Days</p>
                                                    </>
                                                    : "Not Applicable"
                                            }
                                            <div className="consolidation-relative">
                                                {
                                                    sea.length > 0
                                                        && ('final' in sea[0])
                                                        && ('consolidation_delivery' in sea[0].final)
                                                        && sea[0].final.consolidation_delivery.consolidation_grant_total
                                                        ? <>
                                                            <input
                                                                type="radio"
                                                                id='sea-cn'
                                                                name="selector"
                                                                value={sea[0].final.consolidation_delivery.consolidation_grant_total}
                                                                onChange={(e) => handleRadio(e, 'CONSOLIDATION', 'SEA')}
                                                                checked={serviceRadio.type === 'CONSOLIDATION' && serviceRadio.typeWay === 'SEA' ? handleFreightValue(sea[0].final.consolidation_delivery.consolidation_grant_total) : false}
                                                            />
                                                            {/* <div className="check"></div> */}
                                                            <label for="sea-cn" className="d-block">
                                                                <h6 className="text-success mb-1 ps-4">
                                                                    AED {sea[0].final.consolidation_delivery.consolidation_grant_total}
                                                                </h6>
                                                            </label>
                                                            <p>Appro. Transit Time {sea[0].final.consolidation_delivery.consolidation_door_delivery_tt} Days</p>
                                                        </>
                                                        : ""
                                                }
                                            </div>
                                        </td>
                                    </tr>}
                                {/* Sea Freight ---end--- */}



                                {/* Land Freight ---start--- */}

                                {
                                    land.length > 0
                                    && ('final' in land[0])
                                    && (
                                        (
                                            'port_delivery' in land[0].final
                                            && land[0].final.port_delivery.port_grant_total
                                        )
                                        || (
                                            'door_delivery' in land[0].final
                                            && land[0].final.door_delivery.door_grant_total
                                        )
                                        || (
                                            'consolidation_delivery' in land[0].final
                                            && land[0].final.consolidation_delivery.consolidation_grant_total
                                        )
                                    ) && <tr className="border-bottom">
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <div className="service_icon">
                                                    <img src="/assets/images/delivery-truck.svg" alt="Not found" />
                                                </div>
                                                <div className="flex-grow-1 ps-3">
                                                    <h6 className="mb-1">
                                                        <b className="text-success">LAND</b> Freight
                                                    </h6>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {
                                                land.length > 0
                                                    && ('final' in land[0])
                                                    && ('port_delivery' in land[0].final)
                                                    && land[0].final.port_delivery.port_grant_total
                                                    ? <>
                                                        <input
                                                            type="radio"
                                                            name="selector"
                                                            id='land-port'
                                                            value={land[0].final.port_delivery.port_grant_total}
                                                            onChange={(e) => handleRadio(e, 'PORT', 'LAND')}
                                                            checked={serviceRadio.type === 'PORT' && serviceRadio.typeWay === 'LAND' ? handleFreightValue(land[0].final.port_delivery.port_grant_total) : false}
                                                        />
                                                        {/* <div className="check"></div> */}
                                                        <label for="land-port" className="d-block">
                                                            <h6 className="text-success mb-1 ps-4">
                                                                AED {land[0].final.port_delivery.port_grant_total}
                                                            </h6>
                                                        </label>
                                                        <p>Appro. Transit Time {land[0].final.port_delivery.port_delivery_tt} Days</p>
                                                    </>
                                                    : "Not Applicable"
                                            }
                                        </td>
                                        <td>
                                            {
                                                land.length > 0
                                                    && ('final' in land[0])
                                                    && ('door_delivery' in land[0].final)
                                                    && land[0].final.door_delivery.door_grant_total
                                                    ? <>
                                                        <input
                                                            type="radio"
                                                            name="selector"
                                                            id='land-door'
                                                            value={land[0].final.door_delivery.door_grant_total}
                                                            onChange={(e) => handleRadio(e, 'DOOR', 'LAND')}
                                                            checked={serviceRadio.type === 'DOOR' && serviceRadio.typeWay === 'LAND' ? handleFreightValue(land[0].final.door_delivery.door_grant_total) : false}
                                                        />
                                                        {/* <div className="check"></div> */}
                                                        <label for="land-door" className="d-block">
                                                            <h6 className="text-success mb-1 ps-4">
                                                                AED {land[0].final.door_delivery.door_grant_total}
                                                            </h6>
                                                        </label>
                                                        <p>Appro. Transit Time {land[0].final.door_delivery.door_delivery_tt} Days</p>
                                                    </>
                                                    : "Not Applicable"
                                            }
                                            <div className="consolidation-relative">
                                                {
                                                    land.length > 0
                                                        && ('final' in land[0])
                                                        && ('consolidation_delivery' in land[0].final)
                                                        && land[0].final.consolidation_delivery.consolidation_grant_total
                                                        ? <>
                                                            <input
                                                                type="radio"
                                                                id='land-cn'
                                                                name="selector"
                                                                value={land[0].final.consolidation_delivery.consolidation_grant_total}
                                                                onChange={(e) => handleRadio(e, 'CONSOLIDATION', 'LAND')}
                                                                checked={serviceRadio.type === 'CONSOLIDATION' && serviceRadio.typeWay === 'LAND' ? handleFreightValue(land[0].final.consolidation_delivery.consolidation_grant_total) : false}
                                                            />
                                                            {/* <div className="check"></div> */}
                                                            <label for="land-cn" className="d-block">
                                                                <h6 className="text-success mb-1 ps-4">
                                                                    AED {land[0].final.consolidation_delivery.consolidation_grant_total}
                                                                </h6>
                                                            </label>
                                                            <p>Appro. Transit Time {land[0].final.consolidation_delivery.consolidation_door_delivery_tt} Days</p>
                                                        </>
                                                        : ""
                                                }
                                            </div>
                                        </td>
                                    </tr>}
                                {/* Land Freight ---end--- */}

                            </tbody>
                        </table>
                        {showAuth && <UserAuthentication toggleAuth={handleAuth} handleProceed={handleProceed} />}
                    </div>
                </div>
            </>
        )
    }
}
