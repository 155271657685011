import React, {useEffect, useState} from "react"
import PhoneInput from "react-phone-input-2";
import {Country,State,City} from "country-state-city";
import helperFunction from "./Quotation/helperFunction"
import Select from "react-select";


export const UserProfile = (props) => {
  const [country, setCountry] = useState(Country.getAllCountries());
  const [countryCode, setCountryCode] = useState("");
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const { detail, valueHandler, submitHandler, phoneHandler, validation } = props;

  // console.log(detail);

  const reload = (()=>{
    window.location.reload()
  })

  const onCountryChange = (e) => {
    const {value,name} = e.target
    if (name === "country") {
      let findIso = country.find((item) => item.name===value)
      setStates(State.getStatesOfCountry(findIso.isoCode));
      setCountryCode(findIso.isoCode);
    } else if (name === "state") {
      let findIso = states.find((item) => item.name===value)
      setCities(City.getCitiesOfState(countryCode, findIso.isoCode));
    }
    valueHandler(e)
  }

  useEffect(() => {
    console.log(detail,'detail')
    let statefind ;
    let tempCountryCode ;
    if (detail?.country && detail?.state && detail?.city) {
      let findIso = country.find((item) => item.name===detail.country)
      statefind = State.getStatesOfCountry(findIso.isoCode)
      tempCountryCode = findIso.isoCode
      setStates(statefind);
      setCountryCode(tempCountryCode);
      let findIso1 = statefind.find((item) => item.name===detail.state)
      setCities(City.getCitiesOfState(tempCountryCode, findIso1.isoCode));
    }
  }, [])
  

  return (
    <form onSubmit={(e) => submitHandler(e, "user")}>
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group mb-3">
                <label>Full Name</label>
                <input
                  className="form-control rounded_input"
                  type="text"
                  placeholder="First Name"
                  name="user_full_name"
                  value={detail && detail.user_full_name}
                  onChange={(e) => valueHandler(e)}
                />
                {validation.current.message("First Name", detail?.user_full_name, "required", {
                  className: "text-danger",
                })}

              </div>
              </div>
              <div className="mb-3 col-6">
              <div className="form-group">
                  <label>Address</label>
                  <input
                    name="address"
                    type="text"
                    className={"form-control rounded_input"}
                    onChange={(e) => valueHandler(e)}
                    value={detail?.address}
                    placeholder="Enter Address"
                  />
                  {validation.current.message(
                  "Address",
                  detail?.address,
                  "required",
                  { className: "text-danger" }
                )}
                </div>
                </div>

            <div className="col-md-6">
              <div className="form-group mb-3">
                <label>Email</label>
                <input
                  className="form-control rounded_input"
                  type="text"
                  name="user_email"
                  placeholder="Email Address"
                  value={detail && detail.user_email}
                  onChange={(e) => valueHandler(e)}
                />
                {validation.current.message("Email", detail?.user_email, "required|email", {
                  className: "text-danger",
                })}
              </div>
            </div>

            <div className="form-group mb-3 col-3">
                  <label>Country </label>
                  <select className="form-control rounded_input" name="country" 
                    value={detail?.country}
                    onChange={(e) => onCountryChange(e)}
                  >
                    <option value="">Select Country</option>
                    {country.map((item, key) => (
                      <option key={key} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {validation.current.message(
                  "Country",
                  detail?.country,
                  "required",
                  { className: "text-danger" }
                )}
                </div>
            <div className="form-group mb-3 col-3">
                  <label>State</label>
                  <select className="form-control rounded_input" name="state" 
                  value={detail?.state}
                  onChange={(e) => onCountryChange(e)} 
                  >
                    <option value="">Select State</option>
                    {states.map((item, key) => (
                      <option key={key} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {validation.current.message(
                  "State",
                  detail?.state,
                  "required",
                  { className: "text-danger" }
                )}
                </div>

            <div className="col-md-6">
              <div className="form-group mb-3">
                <label>Phone No.</label>
                <PhoneInput
                  className="telinput"
                  country={"ae"}
                  enableSearch
                  enableAreaCodes={true}
                  onChange={(phone, f, g) =>
                    phoneHandler(phone, g)
                  }
                  value={detail && detail.user_dial_code + detail.user_phone_number}
                />
                {validation.current.message("Phone no.", detail?.user_phone_number, "required", {
                  className: "text-danger",
                })}
              </div>
            </div>
            <div className="form-group mb-3 col-3">
                  <label>City</label>
                  <select className="form-control rounded_input" name="city"
                    onChange={(e) => onCountryChange(e)}
                    value={detail?.city}
                  >
                    <option value="">Select City</option>
                    {cities.map((item, key) => (
                      <option key={key} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {validation.current.message(
                  "City",
                  detail?.city,
                  "required",
                  { className: "text-danger" }
                )}
                </div>

                <div className="col-md-3">
              <div className="form-group mb-3">
                <label>Postal Code</label>
                <input
                  className="form-control rounded_input"
                  type="text"
                  name="postal_code"
                  placeholder="Postal Code"
                  value={detail && detail?.postal_code}
                  onChange={(e) => valueHandler(e)}
                />
                {validation.current.message("Postal Code", detail?.postal_code, "required", {
                  className: "text-danger",
                })}
              </div>
            </div>



            <div className="col-md-12">
              <div>
                {" "}
                <label>Gender</label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="user_gender"
                  id="inlineRadio1"
                  value="MALE"
                  checked={
                    detail && detail.user_gender == "MALE" ? true : false
                  }
                  onChange={(e) => valueHandler(e)}
                />
                <label className="form-check-label" htmlFor="inlineRadio1">
                  Male
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="user_gender"
                  id="inlineRadio2"
                  value="FEMALE"
                  checked={
                    detail && detail.user_gender == "FEMALE" ? true : false
                  }
                  onChange={(e) => valueHandler(e)}
                />

                <label className="form-check-label" htmlFor="inlineRadio2">
                  Female
                </label>
              </div>
              {validation.current.message("Gender", detail?.user_gender, "required", {
                className: "text-danger",
              })}
            </div>


            <div className="col-md-12 mt-3">
              <div>
                {" "}
                <label>User Types</label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="user_type"
                  id="type1"
                  value="TOURIST"
                  checked={
                    detail && detail.user_type == "TOURIST" ? true : false
                  }
                  onChange={(e) => valueHandler(e)}
                />
                <label className="form-check-label" htmlFor="type1">
                  TOURIST
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="user_type"
                  id="type2"
                  value="RESIDENT"
                  checked={
                    detail && detail.user_type == "RESIDENT" ? true : false
                  }
                  onChange={(e) => valueHandler(e)}
                />
                <label className="form-check-label" htmlFor="type2">
                  RESIDENT
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="user_type"
                  id="type3"
                  value="OVERSEASCUSTOMER"
                  checked={
                    detail && detail.user_type == "OVERSEASCUSTOMER" ? true : false
                  }
                  onChange={(e) => valueHandler(e)}
                />
                <label className="form-check-label" htmlFor="type3">
                  OVERSEAS CUSTOMER
                </label>
              </div>
              {validation.current.message("User Type", detail?.user_type, "required", {
                className: "text-danger",
              })}
            </div>
            <div className="col-md-12 mt-3">
              <button type="submit" className="themebutton px-4">Update</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export const RetailerProfile = ({ detail, valueHandler, phoneHandler, submitHandler }) => {
  return (
    <form onSubmit={(e) => submitHandler(e, "user")}>
      <div className="row">
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-12">
              <div className="form-group mb-3">
                <label>Retailer Name</label>
                <input
                  readOnly
                  className="form-control rounded_input"
                  type="text"
                  placeholder="First Name"
                  value={detail && detail.retailer_name}
                  name="retailer_name"
                  onChange={(e) => valueHandler(e)}
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group mb-3">
                <label>Email</label>
                <input
                  readOnly
                  className="form-control rounded_input"
                  type="text"
                  placeholder="Email Address"
                  value={detail && detail.retailer_email}
                  name="retailer_email"
                  onChange={(e) => valueHandler(e)}
                />
              </div>
            </div>
            {/* <div className="col-md-12">
              <div className="form-group mb-3">
                <label>Phone No.</label>
                <PhoneInput
                  className="telinput"
                  country={"ae"}
                  enableSearch
                  enableAreaCodes={true}
                  onChange={(phone, f, g) =>
                    phoneHandler(phone, g)
                  }
                  value={detail && detail.user_dial_code + detail.user_phone_number}
                />
              </div>
            </div> */}

            <div className="col-md-12">
              <div className="form-group mb-3">
                <label>Referral code</label>
                <input
                  readOnly
                  className="form-control rounded_input"
                  type="text"
                  placeholder="Phone No."
                  value={detail && detail.referral_token}
                  name="retailer_token"
                  onChange={(e) => valueHandler(e)}
                />
              </div>
            </div>
            <div className="col-md-12">
              <button type="submit" disabled className="themebutton px-4">Update</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
