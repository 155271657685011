import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer>
      <section className="pb-3">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center pb-5">
              <Link to="">
                <img
                  height="100"
                  src="/assets/images/NewStore2doorlogo.png"
                  alt="Not found"
                />
              </Link>
            </div>
            <div className="col-md-3">
              <h5 className="fw-bold mb-4">COMPANY</h5>
              <ul>
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>

                <li>
                  <Link to="/insurance-terms-conditions">
                    Insurance Terms & Conditions
                  </Link>
                </li>
                <li>
                  <Link to="/cancellation-policy">Cancellation Policy</Link>
                </li>
                <li>
                  <Link to="/refund-policy">Refund Policy</Link>
                </li>
                <li>
                  <Link to="/terms-and-conditions">General Terms & Conditions</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <h5 className="fw-bold mb-4">HELP</h5>
              <ul>
                <li>
                  <Link to="/faq">FAQ</Link>
                </li>
                <li>
                  <Link to="/country-guide">Country Guides</Link>
                </li>
                <li>
                  <Link to="/prohibited-items">Prohibited items list</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <h5 className="fw-bold mb-4">SOCIAL MEDIA</h5>
              <ul>
                <li>
                  <a href="https://www.facebook.com/World2Door/" target="_blank">
                    <img
                      src="/assets/images/fb_icon.svg"
                      alt="Not found"
                    />{" "}
                    &nbsp; Facebook
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/world2doorplatform/" target="_blank">
                    <img
                      src="/assets/images/insta_icon.svg"
                      alt="Not found"
                    />{" "}
                    &nbsp; Instagram
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/world2door" target="_blank">
                    <img
                      src="/assets/images/twitter.svg"
                      alt="Not found"
                      height={20}
                    />{" "}
                    &nbsp; Twitter
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/world2door/" target="_blank">
                    <img
                      src="/assets/images/linkedin.svg"
                      alt="Not found"
                      height={20}
                    />{" "}
                    &nbsp; Linkedin
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <h5 className="fw-bold mb-4">CONTACT</h5>
              <p>
                Kens World2Door Cargo LLC 508 Suite , Office 24 Dusseldorf Business
                Point Al Barsha 1, Dubai, UAE
              </p>
              <ul>
                <li>
                  <a href="mailto:info@world2door.com">
                    <img src="/assets/images/email.svg" alt="Not found" />
                    &nbsp; info@world2door.com
                  </a>
                </li>

                <li>
                  <a href="tel:044468444">
                    <img src="/assets/images/phone.svg" alt="Not found" />
                    &nbsp; 04-4468444
                  </a>
                </li>
              </ul>
              <h5 className="fw-bold mb-4">Cards Accepted:</h5>
              <p>
              <img src="/assets/images/cards02.png" alt="Not found" />
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="footer_bottom">
        © 2023. All rights reserved &nbsp;
        <span>{process.env.REACT_APP_BUILD_TIME}</span>
      </div>
    </footer>
  );
}
