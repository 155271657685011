import React, { useContext } from 'react'
import {BookingDetailContext} from '../BookingDetails'

export default function PickUpDetail() {
    const {state, disptach} = useContext(BookingDetailContext);
    if(!state) {
        return 'Loading...'
    } else {
        const info = state.bookingDetails.schedule_pickup.pickup;
       return (
            <>
                <div className=''>
                    <div className=''>
                        <button type='button' className='pickup-btn'>Add Pickup</button>
                    </div>
                   
                    <div className='row'>
                        {
                             info.map((item, key) => {
                              return(
                                <div className='col-lg-4 col-md-4 col-sm-12'>
                                <h4>Pickup {key+1}</h4>
                                <div className='pickup-back'>
                                    <h6 className="secHeading">PICKUP LOCATION</h6>
                                        <table width="100%">
                                            <tbody>
                                                <tr>
                                                    <td className="py-1">Type - </td>
                                                    <td className="py-1 text-end"> {item.pickup_type} </td>
                                                </tr>
                                                <tr>
                                                    <td className="py-1">Location - </td>
                                                    <td className="py-1 text-end">{item.pickup_address_type}</td>
                                                </tr>
                                                <tr>
                                                    <td className="py-1">Address - </td>
                                                    <td className="py-1 text-end">{item.pickup_address}</td>
                                            </tr>
                                            <tr>
                                                    <td className="py-1">Pickup Date and Time - </td>
                                                    <td className="py-1 text-end">{item.pickup_date + item.pickup_time}</td>
                                                </tr>
                                            </tbody>
                                    </table>
                                    <h6 className="secHeading">CONTACT PERSON AT PICKUP LOCATION</h6>
                                        <table width="100%">
                                            <tbody>
                                                <tr>
                                                    <td className="py-1">Name - </td>
                                                    <td className="py-1 text-end"> {item.full_name} </td>
                                                </tr>
                                                <tr>
                                                    <td className="py-1">Email - </td>
                                                    <td className="py-1 text-end">{item.email}</td>
                                                </tr>
                                                <tr>
                                                    <td className="py-1">Phone - </td>
                                                    <td className="py-1 text-end">+{item.phone_number}</td>
                                                </tr>
                                            </tbody>
                                    </table>
                                    <h6 className="secHeading">WHAT TO PICKUP</h6>
                                        <table width="100%">
                                            <tbody>
                                                <tr>
                                                    <td className="py-1">Receipt / invoice Ref - </td>
                                                    <td className="py-1 text-end"> {item.invoice_id} </td>
                                                </tr>
                                                <tr>
                                                    <td className="py-1">Description - </td>
                                                    <td className="py-1 text-end">{item.what_to_pickup}</td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                </div>
                            </div>
                              )
                             })
                        }
                    </div>
                
                </div>
            </>
        )
    }
}
