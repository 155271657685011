import React, { useState, useEffect, useRef } from "react";
import OtpInput from "react-otp-input";
import { useHistory, Redirect } from "react-router";
import AutoCompleteProduct from "react-autocomplete";
import { Modal, Form, Col, Row, Nav, Tab, Button, Spinner } from "react-bootstrap-v5";
import AutoComplete from "./AutoComplete";
import {
  quoteService,
  productService,
  signupQuoteService,
  authServices,
} from "../../services";
import { UserDetails, UserTempLogin, ItemGoods } from "../../Redux/action";
import { toast } from "react-toastify";
import simpleReactValidator from "simple-react-validator";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import {
  VolumeDetailsAction,
  VolumeSeaAction,
  GetTokenAction,
  VolumeCourierAction,
  VolumeTntlandAction,
} from "../../Redux/action";
import { useDispatch } from "react-redux";
import { Storage } from "../../Storage/Storage";
import getRandomkeys from "./StringGenerator";
import Loader from "../../Components/Loader";
import helperFunction from "../../Components/Quotation/helperFunction";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {  BsFillInfoCircleFill } from "react-icons/bs";

import { validateEmail } from "../../utils/helper";

export default function VolumeCalculator({ show, onClose }) {
  const [, setForceUpdate] = useState("");
  const validator = useRef(
    new simpleReactValidator({
      autoForceUpdate: { forceUpdate: setForceUpdate },
      messages: {
        required: "required",
      },
    })
  );
  const initial1 = {
    item_id: "",
    type: "",
    item_description: "",
    no_of_pkgs: "",
    dimension: {
      kilogram: "",
      length: "",
      width: "",
      height: "",
    },
    total_volume_CBM: "",
    total_weight_KG: "",
    item_token: helperFunction.stringGenerator(24),
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const [loader, setLoader] = useState({
    air_loader: false,
    sea_loader: false,
    courier_loader: false,
    land_loader: false,
  });
  const kgRef = useRef(null);
  const lgRef = useRef(null);
  const wdRef = useRef(null);
  const heightRef = useRef(null);
  const volRef = useRef(null);
  const [usertype, setUsertype] = useState("");
  const [deliverer, setDeliverer] = useState(null);
  const [Keyword, setKeyword] = useState(null);
  const [productLoader, setProductLoader] = useState(false);
  const [Phonenumber, setPhonenumber] = useState("");
  const [itemValue, setItemValue] = useState([]);
  const [productList, setProductList] = useState([]);
  const [visiblility, setVisiblility] = useState(true);
  const [valLocation] = useState(false);
  const [OrginValue, setOrginValue] = useState("");
  const [DestinationValue, setDestinationValue] = useState("");
  const [goodValue, setGoodValue] = useState("");
  const [knownGoodValue, setKnownGoodValue] = useState("");
  const [dataToPost, setDataToPost] = useState("");

  // state for redirection
  const [airredirect, setAirRedirect] = useState(false);
  const [seaRedirect, setSeaRedirect] = useState(false);
  const [courierRedirect, setCourierRedirect] = useState(false);
  const [landRedirect, setLandRedirect] = useState(false);

  const [checkEmailVerified, setCheckEmailVerified] = useState(true);
  const [otp, setOtp] = useState("");

  const [Codenumber, setCodenumber] = useState("");
  const [email, setEmail] = useState("");
  const [refferalToken, setReffToken] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [refferalCheck, setReffCheck] = useState(true);
  const [fullName, setFullName] = useState("");

  const [grandTotal, setGrandTotal] = useState({
    number_of_packages: 0,
    volume: 0,
    weight: 0,
  });
  const [productModal, setProductModal] = useState({
    open: false,
    data: null,
    index: 0,
  });
  const [productItemForArray, setProductItemForArray] = useState([initial1]);

  const onClickAdd = () => {
    let addArray = [...productItemForArray];
    addArray.push(initial1);
    setProductItemForArray(addArray);
  };

  const onChangeEvent = (key, event, subkey) => {
    let { name, value } = event.target;
    let arry = [...productItemForArray];
    let obj = arry[key];

    if (name != "kilogram" && name != "item_description") {
      obj["total_volume_CBM"] = getCBM(obj, name, value);
    }

    if (name === "kilogram" || name === "no_of_pkgs") {
      obj["total_weight_KG"] = getTKg(obj, name, value);
    }

    if (subkey) {
      obj[subkey][name] = value;
    } else {
      obj[name] = value;
    }
    arry.splice(key, 1, obj);
    setProductItemForArray(arry);
    getGrandTotal(arry);
  };

  const getTKg = (obj, act, count) => {
    switch (act) {
      case "kilogram":
        return obj.no_of_pkgs * count;
      case "no_of_pkgs":
        return count * obj.dimension.kilogram;
    }
  };

  const getCBM = (obj, act, count) => {
    switch (act) {
      case "no_of_pkgs":
        return (
          Number(count) *
          ((Number(obj.dimension.length) *
            Number(obj.dimension.width) *
            Number(obj.dimension.height)) /
            1000000)
        ).toFixed(3);
      case "length":
        return (
          Number(obj.no_of_pkgs) *
          ((Number(count) *
            Number(obj.dimension.width) *
            Number(obj.dimension.height)) /
            1000000)
        ).toFixed(3);
      case "width":
        return (
          Number(obj.no_of_pkgs) *
          ((Number(obj.dimension.length) *
            Number(count) *
            Number(obj.dimension.height)) /
            1000000)
        ).toFixed(3);
      case "height":
        return (
          Number(obj.no_of_pkgs) *
          ((Number(obj.dimension.length) *
            Number(obj.dimension.width) *
            Number(count)) /
            1000000)
        ).toFixed(3);
    }
  };

  // const isVisible = true;
  const toggleForm = (e, back) => {
    if (back) {
      e.preventDefault();
      if (checkEmailVerified) {
        setVisiblility(!visiblility);
      } else {
        setCheckEmailVerified(true);
      }
    } else {
      setVisiblility(!visiblility);
      setProductItemForArray([initial1]);
      setOrginValue("");
      setDestinationValue("");
      setProductList([]);
      setItemValue([]);
    }
  };

  const getOriginData = (e) => {
    if (e === "") {
      setOrginValue("");
    } else {
      let originData = {
        origin_country: e.country,
        origin_country_short_name: e.country_short_name,
        origin_city: e.city,
        origin_latitude: e.latitude,
        origin_longitude: e.longitude,
      };
      setOrginValue(originData);
    }
  };

  const getDestinationData = (e) => {
    if (e === "") {
      setDestinationValue("");
    } else {
      let destinationData = {
        destination_country: e.country,
        destination_country_short_name: e.country_short_name,
        destination_city: e.city,
        destination_latitude: e.latitude,
        destination_longitude: e.longitude,
      };
      setDestinationValue(destinationData);
    }
  };

  const searchHandler = (event) => {
    setItemValue(event.target.value);
    if (event.target.value.length >= 2) {
      setKeyword(event.target.value);
    } else {
      setKeyword("");
    }
  };

  const handleChange = (getvalue) => {
    setItemValue(getvalue);
    new_conditionFun(getvalue);
  };

  const addSelectedProduct = () => {
    // console.log(productModal.data,'productModal.data')
    let item = [];
    let itemObj = productList.find((item) => item.label === productModal.data);
    let findItemObj = productItemForArray.find(
      (item) => item.item_description === productModal.data
    );
    if (findItemObj) {
    } else {
      for (let i = 0; i < itemObj.data.product_dimentions.length; i++) {
        item.push({
          type: "select",
          item_id: itemObj.data._id,
          item_description: itemObj.data.product_name,
          no_of_pkgs: "1",
          dimension: {
            kilogram: itemObj.data.product_dimentions[i].weight,
            length: itemObj.data.product_dimentions[i].length,
            width: itemObj.data.product_dimentions[i].width,
            height: itemObj.data.product_dimentions[i].height,
          },
          total_volume_CBM: itemObj.data.product_gross_volume,
          total_weight_KG: itemObj.data.product_gross_weight,
        });
      }
    }
    let tempItem = productItemForArray;
    tempItem.splice(productModal.index, 1, ...item);
    setProductItemForArray(tempItem);
    setProductModal({ open: false, data: null, index: 0 });
    setProductList([]);
    setItemValue("");
    setKeyword("");
    getGrandTotal(tempItem);
  };

  const new_conditionFun = (val) => {
    setProductModal({ ...productModal, data: val });
  };

  //   console.log("productitemaray", productItemForArray)

  const onSecondClickDelete = (key, item) => {
    let arr = [...productItemForArray];
    if (item.type === "select") {
      let filteredArr = arr.filter(
        (itm) => itm.item_description !== item.item_description
      );
      setProductItemForArray(filteredArr);
      getGrandTotal(filteredArr);
    } else {
      arr.splice(key, 1);
      setProductItemForArray(arr);
      getGrandTotal(arr);
    }
  };

  useEffect(() => {
    const timeOut = setTimeout(() => {
      getProductlist();
    }, 1000);
    return () => {
      clearTimeout(timeOut);
    };
  }, [Keyword]);

  // search: Keyword && Keyword.length >= 2 ? Keyword  :  null,

  const getProductlist = () => {
    let searchData = {
      search: Keyword && Keyword.length >= 2 ? Keyword : null,
    };
    setProductLoader(true)
    productService.getProduct(searchData).then((result) => {
      setProductLoader(false)
      if (result.status === true) {
        let Productarray = result.data.map((item) => ({
          value: item._id,
          label: item.product_name,
          data: item,
        }));
        setProductList(Productarray);
      } else {
        // toast.error(result.message);
      }
    });
  };

  const submitQuote = () => {
    // setLoader(true)
    let postData = null;
    let validate = null;
    let random_key = getRandomkeys();

    validator.current.fields["filter"] = true;
    validate =
      +validator.current.fieldValid("search") &&
      validator.current.fieldValid("item_description") &&
      validator.current.fieldValid("no_of_pkgs") &&
      validator.current.fieldValid("kilogram") &&
      validator.current.fieldValid("length") &&
      validator.current.fieldValid("width") &&
      validator.current.fieldValid("height") &&
      validator.current.fieldValid("deliverer") &&
      validator.current.fieldValid("good value");
      validator.current.fieldValid("type");
    if (productItemForArray.length > 0) {
      let grandWeight = 0;
      let grandVolume = 0;
      let array = [];
      productItemForArray &&
        productItemForArray.map((ele) => {
          array.push({
            ...ele,
            // total_weight_KG: Number(ele.no_of_pkgs * ele.dimension.kilogram),
            // total_volume_CBM: Number(ele.no_of_pkgs * ele.total_volume_CBM),
            item_token: helperFunction.stringGenerator(24),
          });
          grandWeight += Number(ele.no_of_pkgs * ele.dimension.kilogram);
          grandVolume += Number(ele.no_of_pkgs * ele.total_volume_CBM);
        });
      postData = {
        ...OrginValue,
        ...DestinationValue,
        number_of_packages: grandTotal.number_of_packages,
        volume: grandTotal.volume,
        weight: grandTotal.weight,
        items_goods: Number(knownGoodValue),
        items: [...(postData?.items || []), ...array],
        quote_token: random_key,
        referral_token: refferalToken,
        promo_code: promoCode,
        deliverer: deliverer,
      };
      validate =
        +validator.current.fieldValid("search") &&
        validator.current.fieldValid("quantity");
    }
    if (productItemForArray.length === 0) {
      validate =
        +validator.current.fieldValid("search") &&
        validator.current.fieldValid("filter") &&
        validator.current.fieldValid("quantity");
    }

    if (validate && postData) {
      if (Storage.get("user-auth")) {
        if(Storage.get("user-token") === "store"){
          toggleForm();
          setDataToPost(postData);
        }else{
          setLoader({
            air_loader: true,
            sea_loader: true,
            courier_loader: true,
            land_loader: true,
          });
          apiCallFunction(postData);
        }

      } else {
        setDataToPost(postData);
        toggleForm();
      }
    } else {
      validator.current.showMessages();
    }
    //})
  };

  const checkRefferalToken = (reffToken) => {
    let postPromo = {
      referral_token: reffToken,
    };
    quoteService.checkPromoApi(postPromo).then((res) => {
      if (res.status === true) {
        setReffCheck(true);
        if (res.data.isreftoken) {
          setReffToken(reffToken);
        }
        if (res.data.ispromo) {
          setPromoCode(reffToken);
          console.log("===", reffToken);
        }
      } else {
        setReffToken("");
        setReffCheck(false);
        validator.current.showMessageFor("refferal_token");
      }
    });

    /* quoteService
      .checkRefferalTokenApi({ referral_token: reffToken })
      .then((result) => {
        if (result.status === true) {
          setReffCheck(true);
          setReffToken(reffToken);
        } else {
            let postPromo = {
              promo_code: reffToken,
            };
            quoteService.checkPromoApi(postPromo).then((res) => {
              if (res.status === true) {
                setReffCheck(true);
                setReffToken(reffToken);
              } else {
                setReffToken("");
                setReffCheck(false);
                validator.current.showMessageFor("refferal_token");
              }
            });
        }
      })
      .catch((err) => {
        console.log(err);
      }); */
  };

  // code  for serce  user info way email
  const checkEmail = (email) => {
    // if(email){
    //   setEmail(email);

    // }else{
    //   setEmail("");
    //   validator.current.showMessageFor("email");
    // }

    validateEmail(email) &&
      quoteService
        .checkEmailApi({ user_email: email })
        .then((result) => {
          if (result.status === true) {
            // setReffCheck(true);
            setEmail(email);
            setFullName(
              result.data.user_full_name ? result.data.user_full_name : ""
            );
            setPhonenumber(result.data.user_phone_number);
            setCodenumber(result.data.user_dial_code);
            setUsertype(result.data.user_type);
          } else {
            setEmail("");
            setEmail(email);
            // setReffCheck(false);
            validator.current.showMessageFor("email");
          }
        })
        .catch((err) => {
          console.log(err);
        });
  };
  // end code

  const apiCallFunction = async (data) => {
    const quoteToken = await productService.getQuoteToken();
    data.quote_token = quoteToken.data;
    dispatch(GetTokenAction(quoteToken.data));

    let courierReQuotedata;
    let airReQuotedata;
    let seaReQuotedata;
    let landReQuotedata;

    if(Storage.get("user-token") === "retailer"){
      courierReQuotedata = await quoteService.postCourierQuoteRetialer(data);
      airReQuotedata = await quoteService.postQuoteRetialer(data);
      seaReQuotedata = await quoteService.postSeaQuoteRetialer(data);
      landReQuotedata = await quoteService.postLandQuoteRetialer(data);
    }else if(Storage.get("user-token") === "store"){
      courierReQuotedata = await quoteService.postCourierQuoteStore(data);
      airReQuotedata = await quoteService.postQuoteStore(data);
      seaReQuotedata = await quoteService.postSeaQuoteStore(data);
      landReQuotedata = await quoteService.postLandQuoteStore(data);
    }else{
      courierReQuotedata = await quoteService.getCourierQuote(data);
      airReQuotedata = await quoteService.postQuote(data);
      seaReQuotedata = await quoteService.postSeaQuote(data);
      landReQuotedata = await quoteService.getTntQuote(data);
    }

    const promise1 = courierReQuotedata;
    const promise2 = airReQuotedata;
    const promise3 = seaReQuotedata;
    const promise4 = landReQuotedata;

    return Promise.all([promise1, promise2, promise3, promise4]).then(
      (response) => {
        if (
          Array.isArray(response) &&
          response.length &&
          response[0].status === true &&
          Array.isArray(response) &&
          response.length &&
          response[1].status === true &&
          Array.isArray(response) &&
          response.length &&
          response[2].status === true &&
          Array.isArray(response) &&
          response.length &&
          response[3].status === true
        ) {
          // code for send quoatation mail
          // quoteService.sendQuoteMail({ quote_token: quoteToken.data });
          //end
          setLoader({
            air_loader: false,
            sea_loader: false,
            courier_loader: false,
            land_loader: false,
          });

          dispatch(VolumeCourierAction(response[0]));
          dispatch(VolumeDetailsAction(response[1]));
          dispatch(VolumeSeaAction(response[2]));
          dispatch(VolumeTntlandAction(response[3]));
          setProductItemForArray([initial1]);
          onClose();
          history.push("/get-quote");
        } else {
          setLoader({
            air_loader: false,
            sea_loader: false,
            courier_loader: false,
            land_loader: false,
          });
        }
      }
    );
  };

  const setQuotesPhone = (event, g) => {
    // setPhonenumber(event);
    if (g.target.value) {
      let num = g.target.value
        .split(" ")
        .slice(1)
        .join("")
        .replace(/[^a-zA-Z0-9]/g, "");
      let code = g.target.value.split(" ")[0];
      // matchNumber(Phonenumber);

      setPhonenumber(num);
      setCodenumber(code);
    }
  };

  const handleResendOTP = () => {
    let params = { user_email: email };
    authServices.resentOtp(params).then((result) => {
      if (result.status === true) {
        toast.success("OTP send to your registered email");
      } else {
        toast.error(result.message);
      }
    });
  };

  const handleSubmitOTP = (event) => {
    event.preventDefault();
    if (otp && otp.length === 6) {
      let params = { user_email: email, user_otp: otp };
      authServices.otpVerify(params).then((result) => {
        if (result.status === true) {
          signupQuotesSubmit(event);
          toast.success(result.message);
        } else {
          toast.error(result.message);
        }
      });
    } else {
      toast.error("Please Enter OTP");
    }
  };

  const signupQuotesSubmit = (e) => {
    e.preventDefault();

    let Quotesdata = {
      user_email: email,
      user_full_name: fullName,
      user_phone_number: Phonenumber,
      user_dial_code: Codenumber,
      user_type: usertype,
      is_registered: false,
    };

    let phoneValid = 0;

    if (Quotesdata) {
      phoneValid +=
        validator.current.fieldValid("email") &&
        validator.current.fieldValid("contact") &&
        validator.current.fieldValid("usertype");
    }

    if (phoneValid) {
      setLoader({
        air_loader: true,
        sea_loader: true,
        courier_loader: true,
        land_loader: true,
      });

      signupQuoteService
        .signupQuotes(Quotesdata)
        .then((result) => {
          if (result.status === true) {
            if (result.data.email_verify === "NO") {
              setLoader({
                air_loader: false,
                sea_loader: false,
                courier_loader: false,
                land_loader: false,
              });
              setCheckEmailVerified(false);
            } else {
              // toast.success(
              //   "quotation has been successfully sent on your mail"
              // );
              // onClose();
              if(Storage.get("user-token") === "store"){
                let postData = dataToPost
                postData.user_id = result.data?._id
                apiCallFunction(postData);
              }else{
                toggleForm();
                Storage.set("user-token", result.data);
                Storage.set("user-auth", result.data);
                // dispatch(UserDetails({ data: result.data }));
                const uDetail = {
                  user_email: result.data.user_email,
                  user_id: result.data._id,
                  user_full_name: result.data.user_full_name,
                  user_phone_number: result.data.user_phone_number,
                  user_dial_code: result.data.user_dial_code,
                  user_type: result.data.user_type,
                };
                // Set temporory login
                dispatch(UserTempLogin({ data: uDetail }));
  
                let updatedData = dataToPost;
  
                if (refferalToken !== "") {
                  updatedData.referral_token = refferalToken;
                  dataToPost.referral_token = refferalToken;
                }
                if (promoCode !== "") {
                  updatedData.promo_code = promoCode;
                  dataToPost.promo_code = promoCode;
                }
                setDataToPost(updatedData);
                apiCallFunction(dataToPost);
              }
            }
          } else {
            toast.error(result.message);
            setLoader({
              air_loader: false,
              sea_loader: false,
              courier_loader: false,
              land_loader: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      validator.current.showMessages();
    }
  };

  const handleGoodValue = (value) => {
    setGoodValue(value);
    // dispatch(ItemGoods(value))
  };
  let productCount = 0;

  const getGrandTotal = (itemarr) => {
    let arry = [...itemarr];
    let gTotal = {
      number_of_packages: 0,
      volume: 0,
      weight: 0,
    };
    for (let i = 0; i < arry.length; i++) {
      if (
        i > 0 &&
        arry[i].type === "select" &&
        arry[i - 1].item_description === arry[i].item_description
      ) {
      } else {
        gTotal.number_of_packages += arry[i]?.no_of_pkgs
          ? Number(arry[i].no_of_pkgs)
          : 0;
        gTotal.volume += arry[i]?.total_volume_CBM
          ? Number(arry[i].total_volume_CBM)
          : 0;
        gTotal.weight += arry[i]?.total_weight_KG
          ? Number(arry[i].total_weight_KG)
          : 0;
      }
    }
    setGrandTotal({ ...gTotal, volume: gTotal.volume.toFixed(3) });
  };

  // console.log(productItemForArray,'productItemForArray')

  return (
    <>
      <Modal show={show} dialogClassName="dialog-custom" onHide={onClose}>
        <div className="text-end pe-3">
          <span className="cursor-pointer" onClick={() => onClose()}>
            Close
          </span>
        </div>
        <div className="modal-body">
          <div className="row">
            {visiblility ? (
              <Tab.Container
                id="left-tabs-example"
                className={valLocation ? "active" : "ddd"}
                defaultActiveKey="first_"
              >
                <div className="col-12 px-3">
                  <h5 className="mb-3">Select Location</h5>
                  <Form className="pb-4 ">
                    <Row>
                      <Col md={6}>
                        <label>From</label>
                        <AutoComplete getData={(e) => getOriginData(e)} />
                        {validator.current.message(
                          "search",
                          OrginValue,
                          "required",
                          { className: "text-danger text-capitalize" }
                        )}
                      </Col>
                      <Col md={6}>
                        <label>To</label>
                        <AutoComplete getData={(e) => getDestinationData(e)} />
                        {validator.current.message(
                          "search",
                          DestinationValue,
                          "required",
                          { className: "text-danger text-capitalize" }
                        )}
                      </Col>
                    </Row>
                  </Form>
                  <Row>
                    <Col>
                      <div className="row">
                        <div className="col-md-3 d-flex pt-1 pe-0">
                          Goods will be delivered to World2door by
                          <div className="mt-1">
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip id="tooltip-disabled">
                                  <table className="m-0">
                                    <tbody>
                                      <tr>
                                        <td>Retailer</td>
                                        <td className="ps-2 pe-2">-</td>
                                        <td>
                                          Goods will be delivered by Retailer to
                                          W2D Warehouse.
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Self</td>
                                        <td className="ps-2 pe-2">-</td>
                                        <td>
                                          Goods will be delivered by Customer to
                                          W2D Warehouse.
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>World2Door</td>
                                        <td className="ps-2 pe-2">-</td>
                                        <td>
                                          Goods will be picked up by World2Door
                                          team from the customer location
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </Tooltip>
                              }
                            >
                              <span className="d-inline-block">
                                <BsFillInfoCircleFill
                                  className="fs-4 pb-2"
                                  style={{ color: "#ffa500" }}
                                />
                              </span>
                            </OverlayTrigger>
                          </div>{" "}
                          -
                        </div>
                        <div className="col-md-6 d-flex ps-0">
                          <div className="form-check mt-1 position-relative pb-3">
                            <input
                              className="form-check-input mt-2"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio1"
                              value="RETAILER"
                              checked={deliverer === "RETAILER" ? true : false}
                              onChange={(e) => setDeliverer(e.target.value)}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio1"
                            >
                              Retailer
                            </label>
                            {validator.current.message(
                              "deliverer",
                              deliverer,
                              "required",
                              {
                                className: "text-danger customMsg",
                              }
                            )}
                          </div>
                          <div className="form-check ms-3 mt-1">
                            <input
                              className="form-check-input mt-2"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio1"
                              value="SELF"
                              checked={deliverer === "SELF" ? true : false}
                              onChange={(e) => setDeliverer(e.target.value)}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio1"
                            >
                              Self
                            </label>
                          </div>
                          <div className="form-check ms-3 mt-1">
                            <input
                              className="form-check-input mt-2"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio1"
                              value="WORLD2DOOR"
                              checked={
                                deliverer === "WORLD2DOOR" ? true : false
                              }
                              onChange={(e) => setDeliverer(e.target.value)}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio1"
                            >
                              World2door
                            </label>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr className="mt-0" />
                </div>
                <Row>
                  <h5 className="mb-1 text-center">Cargo Details</h5>
                  <Col sm={12}>
                    <div className="col-12 px-3">
                      <hr />
                      <div className="d-flex justify-content-center mb-3"></div>
                      <div className="table-responsive">
                        <table className="table table-bordered text-center volume-calcu">
                          <thead>
                            <tr className="lh-sm">
                              <th rowspan="2" className="align-middle">
                                S.no.
                              </th>
                              <th
                                rowspan="2"
                                className="align-middle"
                                style={{ minWidth: "175px" }}
                              >
                                Product Detail
                              </th>
                              <th rowspan="2" className="align-middle">
                                Item Description
                              </th>
                              <th rowspan="2" className="align-middle">
                                No. of Pkgs/ <br /> Quantity
                              </th>
                              <th colspan="4">
                                Weight & Dimension per Package
                              </th>
                              <th
                                rowspan="2"
                                className="align-middle"
                                ref={volRef}
                              >
                                Total Volume
                                <br />
                                (CBM)
                              </th>
                              <th rowspan="2" className="align-middle">
                                Total Weight
                                <br />
                                (KG)
                              </th>
                              <th rowspan="2" className="align-middle">
                                Action
                              </th>
                            </tr>
                            <tr>
                              <th ref={kgRef}>Kilogram</th>
                              <th ref={lgRef}>Length</th>
                              <th ref={wdRef}>width</th>
                              <th ref={heightRef}>Height</th>
                            </tr>
                          </thead>
                          <tbody>
                            {productItemForArray &&
                              productItemForArray.length > 0 &&
                              productItemForArray.map((item, key) => {
                                if (
                                  key > 0 &&
                                  item.item_description &&
                                  item.type === "select" &&
                                  productItemForArray[key - 1]
                                    .item_description === item.item_description
                                ) {
                                } else {
                                  productCount += 1;
                                }
                                if (
                                  key > 0 &&
                                  item.item_description &&
                                  item.type === "select" &&
                                  productItemForArray[key - 1]
                                    .item_description === item.item_description
                                ) {
                                } else {
                                  return (
                                    <tr key={key}>
                                      <td>{productCount}</td>
                                      <td style={{ padding: "11px 10px",position:"relative" }}>
                                        <div className="form-group">
                                          <select
                                            className="form-control normal_input"
                                            name="type"
                                            disabled={
                                              item?.type &&
                                              item.type === "select"
                                                ? true
                                                : false
                                            }
                                            value={item?.type}
                                            onChange={(e) => {
                                              if (e.target.value === "select") {
                                                setProductModal({
                                                  modal: true,
                                                  data: null,
                                                  index: key,
                                                });
                                              }else{
                                                onChangeEvent(key, e)
                                              }
                                            }}
                                          >
                                            <option value="">
                                              Select
                                            </option>
                                            <option value="enter">
                                              Enter manually
                                            </option>
                                            <option value="select">
                                              Select from list
                                            </option>
                                          </select>
                                          <span  style={{ bottom: "-5px",left:"0px",position:"absolute" }}>
                                          {validator.current.message(
                                          "type",
                                          item.type,
                                          "required",
                                          {
                                            className:
                                              "text-danger customMsg text-start ps-4",
                                          },
                                        )}
                                          </span>
                                        </div>
                                      </td>
                                      <td style={{ textAlign: "left" }}>
                                        <input
                                          type="text"
                                          value={item.item_description}
                                          className="tbl-input"
                                          name="item_description"
                                          onChange={(e) =>
                                            onChangeEvent(key, e)
                                          }
                                          placeholder="Description"
                                          readOnly={
                                            item.type === "select"
                                              ? true
                                              : false
                                          }
                                        ></input>
                                        {validator.current.message(
                                          "description",
                                          item.item_description,
                                          "required",
                                          {
                                            className:
                                              "text-danger customMsg text-start ps-4",
                                          },
                                          {
                                            style: { bottom: "-10px" },
                                          }
                                        )}
                                      </td>

                                      <td>
                                        <input
                                          className="tbl-input"
                                          type="number"
                                          min="1"
                                          name="no_of_pkgs"
                                          placeholder="Quantity"
                                          value={item.no_of_pkgs}
                                          onChange={(e) =>
                                            onChangeEvent(key, e)
                                          }
                                        />
                                        {validator.current.message(
                                          "quantity",
                                          item.no_of_pkgs,
                                          "required",
                                          {
                                            className: "text-danger customMsg",
                                          }
                                        )}
                                      </td>
                                      {(productItemForArray &&
                                        item.type === "select" &&
                                        productItemForArray.filter(
                                          (item3) =>
                                            item3.item_description &&
                                            item3.item_description ===
                                              item.item_description
                                        ).length > 1 && (
                                          <td colSpan={4} className="p-0">
                                            <table
                                              className="table w-100 table-bordered text-center volume-calcu mb-0"
                                              style={{ minWidth: "auto" }}
                                            >
                                              <tbody>
                                                {productItemForArray &&
                                                  productItemForArray.length >
                                                    0 &&
                                                  productItemForArray.map(
                                                    (itemprod) => {
                                                      if (
                                                        itemprod.item_description ===
                                                        item.item_description
                                                      ) {
                                                        return (
                                                          <tr className="lh-sm">
                                                            <td
                                                              style={{
                                                                width:
                                                                  kgRef.current
                                                                    ?.offsetWidth,
                                                              }}
                                                            >
                                                              <input
                                                                className="tbl-input text-dark"
                                                                type="text"
                                                                disabled
                                                                value={
                                                                  itemprod
                                                                    .dimension
                                                                    .kilogram
                                                                }
                                                              />
                                                            </td>
                                                            <td
                                                              style={{
                                                                width:
                                                                  lgRef.current
                                                                    ?.offsetWidth,
                                                              }}
                                                            >
                                                              <input
                                                                className="tbl-input text-dark"
                                                                type="text"
                                                                disabled
                                                                value={
                                                                  itemprod
                                                                    .dimension
                                                                    .length
                                                                }
                                                              />
                                                            </td>
                                                            <td
                                                              style={{
                                                                width:
                                                                  wdRef.current
                                                                    ?.offsetWidth,
                                                              }}
                                                            >
                                                              <input
                                                                className="tbl-input text-dark"
                                                                type="text"
                                                                disabled
                                                                value={
                                                                  itemprod
                                                                    .dimension
                                                                    .width
                                                                }
                                                              />
                                                            </td>
                                                            <td
                                                              style={{
                                                                width:
                                                                  heightRef
                                                                    .current
                                                                    ?.offsetWidth,
                                                              }}
                                                            >
                                                              <input
                                                                className="tbl-input text-dark"
                                                                type="text"
                                                                disabled
                                                                value={
                                                                  itemprod
                                                                    .dimension
                                                                    .height
                                                                }
                                                              />
                                                            </td>
                                                          </tr>
                                                        );
                                                      }
                                                    }
                                                  )}
                                              </tbody>
                                            </table>
                                          </td>
                                        )) || (
                                        <>
                                          <td
                                            style={{
                                              width: kgRef.current?.offsetWidth,
                                            }}
                                          >
                                            <input
                                              className="tbl-input"
                                              type="number"
                                              min="1"
                                              disabled={
                                                item.type === "select"
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) =>
                                                onChangeEvent(
                                                  key,
                                                  e,
                                                  "dimension"
                                                )
                                              }
                                              name="kilogram"
                                              value={item.dimension.kilogram}
                                              placeholder="Kilogram"
                                            />
                                            {validator.current.message(
                                              "kilogram",
                                              item.dimension.kilogram,
                                              "required",
                                              {
                                                className:
                                                  "text-danger customMsg",
                                              }
                                            )}
                                          </td>
                                          <td
                                            style={{
                                              width: lgRef.current?.offsetWidth,
                                            }}
                                          >
                                            <input
                                              className="tbl-input"
                                              type="number"
                                              min="1"
                                              disabled={
                                                item.type === "select"
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) =>
                                                onChangeEvent(
                                                  key,
                                                  e,
                                                  "dimension"
                                                )
                                              }
                                              name="length"
                                              value={item.dimension.length}
                                              placeholder="Length"
                                            />
                                            {validator.current.message(
                                              "length",
                                              item.dimension.length,
                                              "required",
                                              {
                                                className:
                                                  "text-danger customMsg",
                                              }
                                            )}
                                          </td>
                                          <td
                                            style={{
                                              width: wdRef.current?.offsetWidth,
                                            }}
                                          >
                                            <input
                                              className="tbl-input"
                                              type="number"
                                              min="1"
                                              disabled={
                                                item.type === "select"
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) =>
                                                onChangeEvent(
                                                  key,
                                                  e,
                                                  "dimension"
                                                )
                                              }
                                              name="width"
                                              value={item.dimension.width}
                                              placeholder="Width"
                                            />
                                            {validator.current.message(
                                              "width",
                                              item.dimension.width,
                                              "required",
                                              {
                                                className:
                                                  "text-danger customMsg",
                                              }
                                            )}
                                          </td>
                                          <td
                                            style={{
                                              width:
                                                heightRef.current?.offsetWidth,
                                            }}
                                          >
                                            <input
                                              className="tbl-input"
                                              type="number"
                                              min="1"
                                              disabled={
                                                item.type === "select"
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) =>
                                                onChangeEvent(
                                                  key,
                                                  e,
                                                  "dimension"
                                                )
                                              }
                                              name="height"
                                              value={item.dimension.height}
                                              placeholder="Height"
                                            />
                                            {validator.current.message(
                                              "height",
                                              item.dimension.height,
                                              "required",
                                              {
                                                className:
                                                  "text-danger customMsg",
                                              }
                                            )}
                                          </td>
                                        </>
                                      )}

                                      <td
                                      // style={{width:volRef.current?.offsetWidth}}
                                      >
                                        <input
                                          className="tbl-input text-dark"
                                          type="text"
                                          disabled
                                          value={item?.total_volume_CBM || 0}
                                        />
                                      </td>

                                      <td>
                                        <input
                                          className="tbl-input text-dark"
                                          type="text"
                                          disabled
                                          value={item?.total_weight_KG || 0}
                                        />
                                      </td>
                                      <td>
                                        <img
                                          className="btn"
                                          height="20"
                                          src="/assets/images/delete.svg"
                                          onClick={() =>
                                            onSecondClickDelete(key, item)
                                          }
                                          alt=" Not found"
                                        />
                                      </td>
                                    </tr>
                                  );
                                }
                              })}
                          </tbody>
                          <tfoot>
                            <tr>
                              <th className="text-end">Total</th>
                              <th colSpan="1"></th>
                              <th colSpan="1"></th>
                              <th colSpan="1">
                                {grandTotal.number_of_packages}
                              </th>
                              <th colSpan="4"></th>
                              <th className="text-center">
                                {grandTotal.volume <= 0.0
                                  ? 0.0
                                  : grandTotal.volume}
                              </th>
                              <th className="text-center">
                                {grandTotal.weight}
                              </th>
                              <th></th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="row w-50"></div>

                        <div className="text-right">
                          <button
                            className="themebutton px-4 ms-auto"
                            onClick={() => onClickAdd()}
                          >
                            + Add Item
                          </button>
                        </div>
                      </div>
                      <div className="row text-center">
                        <div
                          className="col-md-2 pe-0 text-start"
                          style={{ paddingTop: "10px" }}
                        >
                          <p className="mb-0 pt-1 fw-bold text-start">
                            Total Goods Value (AED)
                          </p>
                        </div>
                        <div
                          className="col-md-3 pe-5 ps-0"
                          style={{ paddingTop: "10px" }}
                        >
                          <input
                            className="form-control"
                            type="number"
                            min="1"
                            name="items_value"
                            onChange={(e) => {
                              setKnownGoodValue(e.target.value);
                              handleGoodValue(e.target.value);
                            }}
                          />

                          {validator.current.message(
                            "good value",
                            goodValue,
                            "required",
                            { className: "text-danger" }
                          )}
                        </div>
                        <div className="col-md-4 pt-2">
                          <button
                            type="button"
                            className="themebutton px-5 py-2"
                            onClick={() => submitQuote()}
                          >
                            GET RATES
                          </button>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Tab.Container>
            ) : (
              <Form
                className="px-5 pt-3"
                // onSubmit={(e) => checkEmailVerified ? signupQuotesSubmit(e) : handleSubmitOTP(e)}
              >
                <div className="row">
                  <Col className="col-md-12">
                    <Row>
                      <Col md={5}>
                        <h5 className="mb-3 center">
                          {checkEmailVerified
                            ? "Enter Personal Information"
                            : "Enter OTP"}
                        </h5>
                        <Row
                          className="mt-3 h-100"
                          style={{
                            height: checkEmailVerified ? "80%" : "100%",
                          }}
                        >
                          {checkEmailVerified ? (
                            <>
                              <Col md={12}>
                                <Form.Group className="mb-3" controlId="Email">
                                  <label className="mb-2">Email</label>
                                  <Form.Control
                                    type="email"
                                    name="user_email"
                                    placeholder="Enter Email"
                                    onChange={(e) => checkEmail(e.target.value)}
                                  />
                                  {validator.current.message(
                                    "email",
                                    email,
                                    "required|email",
                                    { className: "text-danger" }
                                  )}
                                </Form.Group>
                              </Col>
                              <Col md={12}>
                                <Form.Group className="mb-3">
                                  <label className="mb-2">Full Name</label>
                                  <Form.Control
                                    type="text"
                                    name="user_full_name"
                                    placeholder="Enter Full Name"
                                    value={fullName}
                                    onChange={(e) =>
                                      setFullName(e.target.value)
                                    }
                                  />
                                  {validator.current.message(
                                    "user_full_name",
                                    fullName,
                                    "required",
                                    { className: "text-danger" }
                                  )}
                                </Form.Group>
                              </Col>

                              <Col md={12}>
                                <Form.Group className="mb-3" controlId="code">
                                  <label className="mb-2">Mobile Number</label>
                                  <PhoneInput
                                    className="telinput"
                                    country={"ae"}
                                    enableSearch
                                    enableAreaCodes={true}
                                    value={Codenumber + Phonenumber}
                                    onChange={(phone, f, g) =>
                                      setQuotesPhone(phone, g)
                                    }
                                  />
                                  {validator.current.message(
                                    "contact",
                                    Phonenumber,
                                    "required",
                                    { className: "text-danger" }
                                  )}
                                </Form.Group>
                              </Col>

                              <Col md={12}>
                                <Form.Group className="mb-3">
                                  <label className="mb-2">Referral Code</label>
                                  <Form.Control
                                    type="text"
                                    name="refferal_token"
                                    placeholder="Enter Refferal Code"
                                    onChange={(e) =>
                                      checkRefferalToken(e.target.value)
                                    }
                                  />
                                  {validator.current.message(
                                    "refferal_token",
                                    refferalCheck,
                                    "accepted",
                                    { className: "text-danger" }
                                  )}
                                </Form.Group>
                              </Col>

                              <Col md={12}>
                                <p className="mb-0">User Types</p>
                                <div className="form-check mt-2">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="inlineRadioOptions"
                                    id="inlineRadio1"
                                    value="TOURIST"
                                    checked={
                                      usertype === "TOURIST" ? true : false
                                    }
                                    onChange={(e) =>
                                      setUsertype(e.target.value)
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    for="inlineRadio1"
                                  >
                                    Tourist
                                  </label>
                                </div>
                                <div className="form-check mt-2">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="inlineRadioOptions"
                                    id="inlineRadio2"
                                    value="RESIDENT"
                                    checked={
                                      usertype === "RESIDENT" ? true : false
                                    }
                                    onChange={(e) =>
                                      setUsertype(e.target.value)
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    for="inlineRadio2"
                                  >
                                    Resident
                                  </label>
                                </div>
                                <div className="form-check mt-2">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="inlineRadioOptions"
                                    id="inlineRadio3"
                                    value="OVERSEASCUSTOMER"
                                    checked={
                                      usertype === "OVERSEASCUSTOMER"
                                        ? true
                                        : false
                                    }
                                    onChange={(e) =>
                                      setUsertype(e.target.value)
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    for="inlineRadio3"
                                  >
                                    Overseas Shopper
                                  </label>
                                </div>
                                {validator.current.message(
                                  "usertype",
                                  usertype,
                                  "required",
                                  { className: "text-danger" }
                                )}
                              </Col>
                            </>
                          ) : (
                            <Col
                              md={12}
                              style={{ height: "65%", display: "flex" }}
                            >
                              <div className="form-floating w-100 d-flex justify-content-center align-items-center flex-column">
                                <h5 className="mb-3">
                                  OTP has been sent to you email address
                                </h5>
                                <OtpInput
                                  value={otp}
                                  onChange={setOtp}
                                  numInputs={6}
                                  renderSeparator={<span>-</span>}
                                  renderInput={(props) => <input {...props} />}
                                  inputStyle={{
                                    width: "45px",
                                    textAlign: "center",
                                    height: " 45px",
                                    padding: " 0px",
                                    margin: "0px 5px",
                                    border: "2px solid #3a3a3a",
                                    borderRadius: "5px",
                                    fontSize: "25px",
                                  }}
                                />
                                <div
                                  className="mt-3 d-flex"
                                  style={{
                                    width: "100%",
                                    marginLeft: "135px",
                                    fontSize: "20px",
                                    color: "#1616b7",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => handleResendOTP(e)}
                                >
                                  Resend
                                </div>
                              </div>
                            </Col>
                          )}
                          <Col md={12} className="mb-3 mt-4 mx-auto">
                            <Form.Group
                              className="d-flex justify-content-between mb-3"
                              controlId="Email"
                            >
                              <button
                                className="themebutton px-4"
                                onClick={(e) => toggleForm(e, true)}
                              >
                                Back
                              </button>
                              <button
                                type="submit"
                                className="themebutton px-4"
                                onClick={(e) =>
                                  checkEmailVerified
                                    ? signupQuotesSubmit(e)
                                    : handleSubmitOTP(e)
                                }
                                // onClick={() => showQuotes()}
                              >
                                Request
                              </button>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={7}>
                        <div className="infobg">
                          <img
                            src="/assets/images/shoppingvector.jpg"
                            alt=" Not found"
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </div>
              </Form>
            )}
          </div>
        </div>

        {(loader.air_loader == true ||
          loader.sea_loader == true ||
          loader.courier_loader == true ||
          loader.land_loader == true) && (
          <div className="loader_lst">
            <img
              src="/assets/images/JOP.gif"
              className="bannerimg"
              alt="Not found"
            />
          </div>
        )}
        {/* <Loader isShow={loader} /> */}
      </Modal>

      <Modal
        show={productModal.modal}
        size="md"
        onHide={() => setProductModal({ modal: false, data: null, index: 0 })}
      >
        <div className="d-flex justify-content-center m-md-5 my-5 mx-1 row">
          <div className="col-12">
            <h5 className="mb-2 pt-2 text-left">Select Product Information</h5>
            <div className="mt-3 mb-2">
              {productList && productModal.modal && (
                <div className="autocomplete-wrapper">
                  <AutoCompleteProduct
                    getItemValue={(item) => item.label}
                    items={productList}
                    renderMenu={(item) => (
                      <div className="dropdown">{item}</div>
                    )}
                    renderItem={(item, isHighlighted) => (
                      <div
                        className={`item ${
                          isHighlighted ? "selected-item" : ""
                        }`}
                      >
                        {item.label}
                      </div>
                    )}
                    autoFocus={true}
                    inputProps={{
                      placeholder: "Enter Product",
                      autoFocus: true,
                    }}
                    value={itemValue}
                    onChange={(e) => searchHandler(e)}
                    onSelect={(val) => handleChange(val)}
                  />
                  {productLoader ? <Spinner animation="border" /> : ""}
                </div>
              )}

              {validator.current.message("filter", itemValue, "required", {
                className: "text-danger",
              })}
            </div>
          </div>
          <div className="d-flex justify-content-center mt-3">
            <Button
              variant="success"
              className="px-5 me-3 themebutton"
              onClick={() =>
                setProductModal({ modal: false, data: null, index: 0 })
              }
            >
              Back
            </Button>
            <Button
              variant="success"
              className="px-5 themebutton"
              onClick={() => addSelectedProduct()}
            >
              ADD
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
