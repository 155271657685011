import React, { useEffect, useState } from "react";
import { authServices } from "../services";
import countryguide from "../Function/countryguide.json";

export default function CountryGuide() {
  useEffect(() => {
    getCountrylist();
  }, []);

  const [countrylist, setcountrylist] = useState([]);
  const [selCountryData, setSelCountryData] = useState({});

  const getCountrylist = async () => {
    await authServices.countrylist().then((result) => {
      if (result.status === true) {
        setcountrylist(result.data);
        if(result?.data && result.data[0]?.country_name){
          setSelCountryData(countryguide[result.data[0].country_name])
        }
      }
    });
  };

  const handleChange = (e) => {
    if (e.target.value) setSelCountryData(countryguide[e.target.value]);
  };


  return (
    <section className="pb-5 pt-0">
      <div className="header_title">
        <div className="container-fluid">
          <h1>Country Guides</h1>
          <p>Please enter information below and click on the "Track" button.</p>
          <img
            src="/assets/images/country.jpg"
            className="bannerimg"
            alt="Not found"
          />
        </div>
      </div>
      <div className="container-fluid mt-5">
        <div className="white_box h-100">
          <div className="row">
            <div className="col-md-6">
              <form>
                <div className="form-group mb-3">
                  <label className="fw-bold mb-2">Select Country </label>
                  <select
                    className="form-control rounded_input"
                    onChange={(e) => handleChange(e)}
                  >
                    {countrylist.map((item, key) => (
                      <option key={key} value={item.country_name}>
                        {item.country_name}
                      </option>
                    ))}
                  </select>
                </div>
              </form>
            </div>

            <ul className="nav mb-3 tablist" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link active"
                  id="pills-documents-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pillsdocuments"
                  type="button"
                  role="tab"
                  aria-controls="pills-documents"
                  aria-selected="true"
                >
                  Documents Required
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pillsProfile"
                  type="button"
                  role="tab"
                  aria-controls="pills-Profile"
                  aria-selected="false"
                >
                  Prohibited Items
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link"
                  id="pills-pillsSpecificInformation-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pillsSpecificInformation"
                  type="button"
                  role="tab"
                  aria-controls="pills-contact"
                  aria-selected="false"
                >
                  Specific Information
                </a>
              </li>
            </ul>

            <div className="col-md-12">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pillsdocuments"
                  role="tabpanel"
                  aria-labelledby="pills-documents-tab"
                >
                  <ul className="fancylist">
                    {(selCountryData &&
                      selCountryData["Documents Required"] &&
                      Array.isArray(selCountryData["Documents Required"]) &&
                      selCountryData["Documents Required"].map((item) => (
                        <li>
                          <span>
                            <img
                              src="assets/images/checked.svg"
                              alt="Not found"
                            />
                          </span>
                          <div>{item}</div>
                        </li>
                      ))) || <li>No guidance available</li>}
                  </ul>
                </div>

                <div
                  className="tab-pane fade"
                  id="pillsProfile"
                  role="tabpanel"
                  aria-labelledby="pills-Profile-tab"
                >
                  .
                  <p>
                    The shipment should not contain any goods prohibited under
                    the laws of the country of origin of shipment, the laws of
                    country of ultimate destination, the international laws
                    governing the transport of goods by road, sea, air &
                    courier. At the booking stage the customer must sign an
                    undertaking by accepting the terms & conditions for the
                    goods/shipment in respect World2Door is providing services
                    do not contain any of the prohibited goods. The prohibited
                    goods include but not limited to the following goods:-
                  </p>
                  <ul className="fancylist">
                    {(selCountryData &&
                      selCountryData["Prohibited Items"] &&
                      Array.isArray(selCountryData["Prohibited Items"]) &&
                      selCountryData["Prohibited Items"].map((item) => (
                        <li>
                          <span>
                            <img
                              src="assets/images/checked.svg"
                              alt="Not found"
                            />
                          </span>
                          <div>{item}</div>
                        </li>
                      ))) || <li>No guidance available</li>}
                  </ul>
                </div>

                <div
                  className="tab-pane fade"
                  id="pillsSpecificInformation"
                  role="tabpanel"
                  aria-labelledby="pills-pillsSpecificInformation-tab"
                >
                  <ul className="fancylist">
                    {(selCountryData &&
                      selCountryData["Specific Information"] &&
                      Array.isArray(selCountryData["Specific Information"]) &&
                      selCountryData["Specific Information"].map((item) => (
                        <li>
                          <span>
                            <img
                              src="assets/images/checked.svg"
                              alt="Not found"
                            />
                          </span>
                          <div>{item}</div>
                        </li>
                      ))) || <li>No guidance available</li>}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
