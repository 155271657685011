import { DataService } from './dataService'
export const authServices = {
	login: async (data) => {
		return DataService.post('/user/sign_in', data)
	},
	registration: async (data) => {
		return DataService.post('/user/sign_up', data)
	},

	verification: async (data, header) => {
		return DataService.post('/user/verify_account', data, header)
	},

	countrylist: async (data) => {
		return DataService.get('/user/get_countries', data)
	},

	resendotp: async (data, header) => {
		return DataService.post('/user/resend_otp', data, header)
	},
	retailer_login: async (data) => {
		return DataService.post('/retailer/retailer_login', data)
	},
	change_user_password: async (data) => {
		return DataService.post('/user/change_user_password', data)
	},
	change_retailer_password: async (data) => {
		return DataService.post('/retailer/change_retailer_password', data)
	},

	store_login: async (data) => {
		return DataService.post('/store/store_login', data)
	},

	forgetpassword: async (data) => {
		return DataService.post('/user/forgot_password', data);
	},
	/// profile api

	profileUpdateUser: async (data) => {
		return DataService.post('/user/update_profile', data)
	},

	getProfileUser: async () => {
		return DataService.get('/user/get_my_profile')
	},

	getProfileRetailer: async () => {
		return DataService.get('/retailer/get_my_profile')
	},

	resetPassword: async (data) => {
		return DataService.post('/user/reset_password', data);
	},

	otpVerify: async (data) => {
		return DataService.post('/user/email_verify', data)
	},

	resentOtp: async (data) => {
		return DataService.post('user/resend_email_otp', data)
	},
}

export default authServices