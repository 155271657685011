import { QUOTE_DETAIL, SERVICE_TYPE, SERVICE_VALUE, STORAGE, INSURANCE, DISCOUNT } from "../../Redux/type"

const initialState = {
    quoteDetails: {},
    serviceType: {type:'', typeWay:''},
    serviceValue: 0,
    insuranceValue: 0,
    storageValue: 0,
    discount: {customerDiscount: 0, discountAmount: 0},
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case QUOTE_DETAIL:
            return {
                ...state,
                quoteDetails: action.payload
            }

        case SERVICE_TYPE:
            return {
                ...state,
                serviceType: action.payload
            }
        case SERVICE_VALUE:
            return {
                ...state,
                serviceValue: action.payload
            }
        case INSURANCE:
            return {
                ...state,
                insuranceValue: action.payload
            }
        case STORAGE:
            return {
                ...state,
                storageValue: action.payload
            }
        case DISCOUNT:
            return {
                ...state,
                discount: action.payload
            }
    
        default:
            return { ...state }
    }
}

export default reducer