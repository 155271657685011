import React, { useContext } from 'react'
import {BookingDetailContext} from '../BookingDetails'

export default function Cargo() {
    const {state, disptach} = useContext(BookingDetailContext);
    if(!state) {
        return 'Loading...'
    } else {
        const info = state.bookingDetails.quote_request_items
        return (
            <div>
                <h5 className="secHeading">Cargo Details</h5>
                
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Weight (KG)</th>
                            <th>Length (CM)</th>
                            <th>Width (CM)</th>
                            <th>Height (CM)</th>
                            <th>Description</th>
                            <th>Package</th>
                            <th>Total Weight (KG)</th>
                            <th>Total Volume (CBM)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            info.map(item=>{
                                return (
                                        <tr>
                                            <td> { item.actual.height } </td>
                                            <td> { item.actual.length } </td>
                                            <td> { item.actual.width } </td>
                                            <td> { item.actual.height } </td>
                                            <td> { item.actual.item_description } </td>
                                            <td> { item.actual.no_of_pkgs } </td>
                                            <td> { item.actual.total_weight_KG } </td>
                                            <td> { item.actual.total_volume_CBM } </td>
                                        </tr>
                                    )
                            })
                        }
                        
                    </tbody>
                </table>
            </div>
        )
    }
}
