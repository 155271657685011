import React, { useContext } from 'react'
import {BookingDetailContext} from '../BookingDetails'

export default function OrederSummarey() {
    const {state, disptach} = useContext(BookingDetailContext);
    if(!state) {
        return 'Loading...'
    } else {
        const info = state.bookingDetails.shipment_details;
        const payment = state.bookingDetails.payment_information;
    
        return (
            <div className='pickup-back'>
                <h5 className="secHeading">Service</h5>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td className="py-1">{info.servic_type}
                            <span className='order-summery'> {info.servic_type} Delivery</span>
                            </td>
                            <td className="py-1 text-end"> AED {payment.breakdown.freight} AED <span className='order-summery'>(Appro. T/T 11 days)</span></td>
                        </tr>
                    </tbody>
                    </table>
                <h5 className="secHeading">Add-Ons</h5>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td className="py-1">Packing - </td>
                            <td className="py-1 text-end">AED {payment.breakdown.packing_charge}</td>
                        </tr>
                        <tr>
                            <td className="py-1">Insurance - </td>
                            <td className="py-1 text-end">AED {payment.breakdown.insurance}</td>
                        </tr>
                        <tr>
                            <td className="py-1">Storage - </td>
                            <td className="py-1 text-end">AED {payment.breakdown.storage}</td>
                        </tr>
                    </tbody>
                    </table>
                        {/* <div className='d-flex justify-content-between mt-3 mb-3'>
                        <h5 className="secHeading"> REFERRAL / PROMO CODE </h5>
                        <button type='button' className='pickup-btn'></button>
                        </div> */}
                            
                        <table width="100%">
                    <tbody>
                        
                        <tr>
                            <td className="py-1">Sub Total - </td>
                            <td className="py-1 text-end">AED {payment.breakdown.bookingAmount}</td>
                        </tr>
                        <tr>
                            <td className="py-1">Discount - </td>
                            <td className="py-1 text-end">{payment.breakdown.discount_amount}</td>
                        </tr>
                        {/* <tr>
                            <td className="py-1">Net - </td>
                            <td className="py-1 text-end">AED {payment.breakdown.bookingAmount}</td>
                        </tr> */}
                        <h6 className="mt-4">Taxes</h6>
                        <tr>
                            <td className="py-1">Vat - </td>
                            <td className="py-1 text-end">AED 0.00</td>
                        </tr>
                        <tr>
                            <td className="py-1">Net Total - </td>
                            <td className="py-1 text-end">AED {payment.breakdown.bookingAmount}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}
