import { data_decrypt, data_encrypt } from "./crypto";
export class Storage {
  static set(key, value) {
    localStorage.setItem(key, data_encrypt(value));
  }

  static get(key) {
    let item = localStorage.getItem(key);
    if (item) {
      return data_decrypt(item);
    } else {
      return false;
    }
  }

  static remove(key, type) {
    localStorage.removeItem(key);
    if (key === "user-token") {
      if (type == "store") {
        window.location.href = "/store_login";
      } else if (type == "management") {
        window.location.href = "/management_login";
      } else if (type == "retailer") {
        window.location.href = "/retailer_login";
      } else {
        window.location.href = "/login";
      }
    }
  }

  static removeAuth() {
    localStorage.removeItem("user-token");
    localStorage.removeItem("user-auth");
    localStorage.removeItem("persist:root");
  }
}
