import React from "react";
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom";

export default function BookingConfirm() {
  const history = useHistory();

  const bookingState = useSelector(state => state.ProceedDetail.ProdceedData)
  const QuotesState = useSelector(state => state.QuotesDetails.QuotesData);
  console.log("bookingState========>",bookingState);
  console.log("QuotesState========>",QuotesState);
  const getDate = (date) => {
    var ori_date = new Date(date).getDate();
    var tomonth = new Date(date).getMonth() + 1;
    var toyear = new Date(date).getFullYear();
    var original_date = ori_date + "-" + tomonth + "-" + toyear;
    return original_date;
  };

  return (
    <section className="pb-5 pt-0">
      <div className="header_title">
        <div className="container-fluid text-center">
          <h1>
            {bookingState && bookingState.shipment_details.origin}
            <span className="px-md-4 px-2">
              <img src="/assets/images/arrow_right.svg" alt='Not found' />
            </span>{" "}

            {bookingState && bookingState.shipment_details.destination}
          </h1>
          <img src="/assets/images/country.jpg" className="bannerimg" alt='Not found' />
        </div>
      </div>
      <div className="container-fluid mt-5">
        <div className="white_box mb-3">
          <div className="d-flex">
            <div className="me-4">
              <img src="/assets/images/checked.svg" width="50px" alt='Not found' />
            </div>

            <div className="flex-grow-1">
              <p className="position-relative mb-0">
                <h3>Welcome Onboard</h3>
              </p>
              <p className="mb-0 me-5 fw-bold"> Booking No.- {bookingState && bookingState.job_token}</p>
              <p className="mb-1 fw-bold"> Booking Amount. - (AED) {QuotesState.completeAmount} </p>
              <p className="mb-0">
                {/* <small>{getDate(bookingState.created_at)}</small> */}
              </p>

              <h6>
                Your request for {bookingState && bookingState.job_token}   booking has been accepted. We
                will contact you shortly{" "}
              </h6>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <div className="white_box">
              <h6 className="mb-2 fw-bold">Customer Details</h6>

              <div className="row">
                <div className="col-md-5 border-end">
                  <div className="text-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="90"
                      height="90"
                      fill="#ddd"
                      className="bi bi-person"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"></path>
                    </svg>
                    <p style={{ wordBreak: "break-all" }}>{bookingState && bookingState.customer_details.name}</p>
                  </div>
                </div>

                <div className="col-md-7">
                  <div className="mb-3">
                    <p className="mb-0"> Phone</p>
                    <small>{bookingState && bookingState.customer_details.mobile_number}</small>
                  </div>

                  <div>
                    <p className="mb-0"> Email</p>
                    <small>{bookingState && bookingState.customer_details.email}</small>
                  </div>
                  {/* <table className="w-100">
                          <tbody>
                            <tr>
                              <td>Phone :</td>
                              <td>+9000111222</td>
                            </tr>
                            <tr>
                              <td>Email :</td>
                              <td>lorem@ipsum.com</td>
                            </tr>
                            
                          </tbody>
                      </table> */}
                </div>
              </div>

              {/* <div className="row">
                <div className="col-md-4">
                  <span>Phone -</span>
                </div>
                <div className="col-md-6">
                  <span>+9000111222</span>
                </div>
                <div className="col-md-4">
                  <span>Email -</span>
                </div>
                <div className="col-md-6">
                  <span>lorem@ipsum.com</span>
                </div>
              </div> */}
            </div>
          </div>

          <div className="col-md-8">
            <div className="white_box">
              <div className="row">
                <div className="col-md-6 border-end">
                  <h6 className="mb-3 fw-bold">Pickup Request</h6>
                  <div className="row">
                    <div className="col-md-12">
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <td>Request By </td>
                            <td><small>{bookingState && bookingState.customer_details.name || bookingState.customer_details.email}</small></td>
                          </tr>
                          {/* <tr>
                            <td>Store Address </td>
                            <td><small>55, dummy address, USA</small></td>
                          </tr> */}
                          <tr>
                            <td>Mobile Number </td>
                            <td><small>{bookingState && bookingState.customer_details.mobile_number}</small></td>
                          </tr>
                          <tr>
                            <td>Pickup Req. Ref </td>
                            <td><small>{bookingState && bookingState.added_by.added_by_id}</small></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    {/* <p>Request By</p>
                    </div>
                    <div className="col-md-6">
                      <small>Mr Lorem Ipsum</small>
                    </div>
                    <div className="col-md-6">
                      <p>Store Address</p>
                    </div>
                    <div className="col-md-6">
                      <small>55, dummy address, USA</small>
                    </div>

                    <div className="col-md-6">
                      <p>Mobile Number</p>
                    </div>
                    <div className="col-md-6">
                      <small>+9100011122</small>
                    </div>
                    <div className="col-md-6">
                      <p className="mb-4">Pickup Req. Ref </p>
                    </div>
                    <div className="col-md-6">
                      <small>#000771/130421/US</small>
                    </div> */}
                  </div>
                </div>

                <div className="col-md-6 px-4">
                  <h6 className="mb-3 fw-bold">Delivery Address</h6>
                  <p className="fw-bold">{bookingState && bookingState.receiver_details.receiver_name}</p>
                  <div className="row">
                    <div className="col-md-6">
                      <p className="mb-0">Address</p>
                    </div>
                    <div className="col-md-6">
                      <small>{bookingState && bookingState.receiver_details.address}</small>
                    </div>
                    <div className="col-md-6">
                      <p className="mb-0">Mobile Number</p>
                    </div>
                    <div className="col-md-6">
                      <small>{bookingState && bookingState.receiver_details.pickup_contact}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="white_box mt-3">
              <div className="row">
                <div className="col-md-6 border-end">
                  <div>
                    <h6 className="mb-0 fw-bold">Customer Details</h6>
                    <h5 className="fw-bold my-4">
                      {bookingState && bookingState.shipment_details.shipment_mode} &nbsp;{" "}
                      <small className="text-muted">
                        {
                          (bookingState && bookingState.shipment_details.shipment_mode == "DOORSEA" || bookingState.shipment_details.shipment_mode == "DOORAIR" ||
                            bookingState.shipment_details.shipment_mode == "DOORCOURIER" ||
                            bookingState.shipment_details.shipment_mode == "DOORROAD") ?
                            "Door Delivery" : "Port Delivery"
                        }
                        {/* {
                          bookingState && bookingState.shipment_details.shipment_mode == "DOORAIR" ?
                          "Door Delivery" : "Port Delivery"
                          }
                          {
                          bookingState && bookingState.shipment_details.shipment_mode == "DOORCOURIER" ?
                          "Door Delivery" : "Port Delivery"
                          }
                          {
                          bookingState && bookingState.shipment_details.shipment_mode == "DOORROAD" ?
                          "Door Delivery" : "Port Delivery"
                          } */}
                      </small>
                    </h5>
                  </div>

                  <div className="p-3 my-4 orgDestination">
                    <div className="d-flex align-items-center">
                      <div className="me-5">
                        <span className="text-muted">Origin</span>
                        <p className="mb-1"> {bookingState && bookingState.shipment_details.origin}</p>
                      </div>

                      <div className="me-5">
                        <span className="px-2">
                          <img src="/assets/images/arrrow_right_dark.svg" alt='Not found' />
                        </span>
                      </div>

                      <div className="ms-3">
                        <span className="text-muted">Destination</span>
                        <p className="mb-1">{bookingState && bookingState.shipment_details.destination}</p>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <h5 className="mb-1 fw-bold">{QuotesState.stateItem ? QuotesState.stateItem.number_of_packages : 0}</h5>
                      <p className="mb-1"> Total Package </p>
                    </div>

                    <div className="col-md-4">
                      <h5 className="mb-1 fw-bold">{QuotesState.stateItem ? QuotesState.stateItem.weight : 0}</h5>
                      <p className="mb-1">Weight Kg</p>
                    </div>

                    <div className="col-md-4">
                      <h5 className="mb-1 fw-bold">{QuotesState.stateItem ? QuotesState.stateItem.volume : 0}</h5>
                      <p className="mb-1">Volume</p>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-12">
                      <div>
                        <h6 className="mb-3 fw-bold">Pickup Request</h6>
                        <div className="row">
                          <div className="col-md-12">
                            <table className="w-100">
                              <thead>
                                <th>Packing Type</th>
                                <th>Packing Charge</th>
                                <th>Item Description</th>
                              </thead>
                              <tbody>
                                {
                                  QuotesState.packingarray && QuotesState.packingarray.length > 0 &&
                                  QuotesState.packingarray.map(item => {
                                    return (
                                      <tr>
                                        <td>{item.packing_type || "NA"}</td>
                                        <td>AED {item.packing_charges}</td>
                                        <td>{item.item_description}</td>
                                      </tr>
                                    )
                                  })
                                }
                              </tbody>
                            </table>
                          </div>
                          {/* <div className="col-md-6">
                        <p></p>
                      </div>
                      <div className="col-md-6">
                        <p>Packing</p>
                      </div>
                      <div className="col-md-6">
                        <p>AED 0.00</p>
                      </div>

                      <div className="col-md-6">
                        <p>Packing</p>
                      </div>
                      <div className="col-md-6">
                        <p>AED 0.00</p>
                      </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="px-3">
                    <h6 className="mb-3 fw-bold">Charges Summary</h6>
                    <div className="row">
                      <div className="col-md-12">
                        <table className="w-100">
                          <tbody>
                            <tr>
                              <td width="300px" > <h5>Basic Freight</h5></td>
                              <td>AED {QuotesState && QuotesState.selected_freight}</td>
                            </tr>
                            <tr>
                              <td width="300px"><h5>Packing</h5></td>
                              <td>AED {QuotesState.packing}</td>
                            </tr>
                            <tr>
                              <td width="300px"><h5>Insurance</h5></td>
                              <td>AED {QuotesState.insurance.insurance_amount ? QuotesState.insurance.insurance_amount : 0.00}</td>
                            </tr>

                            <tr>
                              <td width="300px"><h5>Storage</h5></td>
                              <td>AED {QuotesState.storage.storage_amount ? QuotesState.storage.storage_amount : 0.00}</td>
                              
                            </tr>
                            <tr>
                              <td width="300px"><h5> Sub Total </h5></td>
                              <td>AED {QuotesState.subtotal_amount ? QuotesState.subtotal_amount : QuotesState.selected_freight ? QuotesState.selected_freight : 0.00}</td>
                            </tr>
                            <tr>
                              <td width="300px"><h5>Discount</h5></td>
                              <td>AED {QuotesState.discount_value || "0.00"}</td>
                            </tr>

                            {/* <tr>
                              <td width="300px"><h5>Net </h5></td>
                              <td>AED 0.00</td>
                            </tr> */}
                            <tr>
                              <td width="300px"><h5>Vat</h5></td>
                              <td>AED 0.00</td>
                            </tr>
                          </tbody>
                        </table>

                        <hr />
                        <table className="w-100">
                          <tbody>
                            <tr className="py-3 fw-bold">
                              <td width="300px" className="py-1">
                              <h5> Net Total</h5>
                              </td>
                              <td>-</td>
                              <td>AED {QuotesState.completeAmount ? QuotesState.completeAmount : QuotesState.selected_freight ? QuotesState.selected_freight : 0}</td>
                            </tr>
                          </tbody>
                        </table>

                        <hr />
                        {/* <table className="w-100">
                          <tbody>
                            <tr className="py-3 fw-bold">
                              <td width="300px" className="py-1">
                                Payment Received
                              </td>
                              <td>-</td>
                              <td>AED {QuotesState.subtotal_amount ? QuotesState.subtotal_amount : QuotesState.selected_freight ? QuotesState.selected_freight : 0}</td>
                            </tr>
                          </tbody>
                        </table> */}

                        <button type="button" className="themebutton w-50 mt-3" onClick={() => history.push(`/booking-details/${bookingState._id}`)}>
                          Take me to my booking
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
