import React, { useState } from "react";
import { useHistory } from "react-router";
import { authServices } from "../../services";
import { toast } from "react-toastify";
import OtpInput from 'react-otp-input';
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const SignUpOtp = () => {
  const [otp, setOtp] = useState('');
  const history = useHistory();
  const dispatch = useDispatch()
  const {id} = useParams()

  const handleResend = (e) => {
    e.preventDefault();
    let params = {user_email:id}
    authServices.resentOtp(params).then((result) => {
      if (result.status === true) {
          toast.success("OTP send to your registered email");
      } else {
        toast.error(result.message);
      }
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (otp && otp.length === 6) {
      // let details = history.location.state;
      let params = {}
      // if(details.data){
        params = {user_email:id,user_otp:otp}
      // }else{
      //   params = {user_email:details.data.email,user_otp:otp}
      // }
      authServices.otpVerify(params).then((result) => {
        if (result.status === true) {
          // if(details.data){
          //   Storage.set("user-token", details.data);
          //   Storage.set("user-auth", details.data);
          //   dispatch(UserDetails({ data: details.data }));
          //   history.push({
          //     pathname: "/",
          //     msg: details.message,
          //   });
          // }else{
            history.push("/login");
            toast.success(result.message);
          // }
        } else {
          toast.error(result.message);
        }
      });
    } else {
      toast.error('Please Enter OTP')
    }
  };

  return (
    <div className="loginpage">
      <img
        src="assets/images/login_bg.jpg"
        className="loginbg"
        alt="Not found"
      />
      <div className="flex-grow-1 text-center loginchar">
      </div>
      <div className="loginbox">
        <h2 className="mb-5">Sign in OTP</h2>
        <form className="w-100" onSubmit={(event) => handleSubmit(event)}>

          <div className="form-floating w-100 d-flex justify-content-center align-items-center mb-5">
          <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
              inputStyle={{
                width: '45px',
                textAlign: 'center',
                height:' 45px',
                padding:' 0px',
                margin: '0px 5px',
                border:"2px solid #3a3a3a",
                borderRadius:"5px",
                fontSize:'25px'
              }}
            />
          </div>
          <div className="mt-3 d-flex">
            <button className="themebutton w-100 me-3" onClick={(e) => handleResend(e)}>
              Resend
            </button>
            <button className="themebutton w-100" type="submit">
              Submit
            </button>
          </div>


        </form>
      </div>
    </div>
  );
};

export default SignUpOtp;
