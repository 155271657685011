import React, { useState } from 'react';
import './App.css';
import { BrowserRouter, Switch } from 'react-router-dom';
import Header from './Includes/Header';
import Footer from './Includes/Footer';
// import firebase from "./Components/Firebase/firebase";
import MyRoutes from "./Routes/Route";
import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

function App() {

  const [header, SetHeader] = useState(<Header />)


  // useEffect(() => {
  //   const messaging = firebase.messaging()
  //   messaging.requestPermission().then(() => {
  //     return messaging.getToken()
  //   }).then(token => {
  //     Storage.set("FCM", token)
  //   }).catch((error) => {
  //     console.log(error)
  //   })
  // }, [])

  const isStateChange = (state) => {
    SetHeader(<Header user={state} />)
  }


  return (
    <>

      <BrowserRouter>
        {header}
        <Switch>

          <MyRoutes isStateChange={(e) => isStateChange(e)} />

        </Switch>
        <ToastContainer draggableDirection="y" />
        <Footer></Footer>
      </BrowserRouter>

    </>
  );
}

export default App;