
import React, { useState, useEffect } from 'react'
import { storageService } from "../services/storageService"

export default function MyInvoices() {

    const [invoiceList, setInvoiceList] = useState([]);
    const [loader, setLoader] = useState(false);
    console.log("invoiceList=====>", invoiceList);
    useEffect(() => {
        getInvoiceList();
    }, [])

    const getInvoiceList = () => {
        setLoader(true)
        storageService.getInvoicesApi().then((res) => {
            if (res.status === true) {
                setInvoiceList(res.data)
                setLoader(false)
            } else {
                setLoader(false)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    function timeConverter(UNIX_timestamp) {
        var timestamp = UNIX_timestamp
        var date = new Date(timestamp);
        return date.getDate() +
            "/" + (date.getMonth() + 1) +
            "/" + date.getFullYear()
    }


    return (
        <div className="row">
            <div className="col-md-12">
                <div className="table-responsive">
                    <table className="table table-striped ">
                        <thead>
                            <tr>
                                <th>
                                    S.no.
                                </th>
                                <th>Job Number</th>
                                <th>Quote Number</th>
                                 <th>Courier Type</th>
                                {/* <th>Shipment</th> */}
                                <th>Amount</th>
                                <th>Date </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                invoiceList && invoiceList.length > 0 &&
                                invoiceList.map((item, key) => {
                                    return (
                                        <tr>
                                            <td> {key + 1}</td>
                                            <td>{item.job_list.job_token}</td>
                                            <td>{item.job_list.quote_token}</td>
                                            <td>{item.job_list.shipment_details.servic_type}</td>
                                            {/* <td width="300px"><span style={{ wordBreak: "break-all" }}>{item.invoice_list._id}</span></td> */}
                                              <td>{item.invoice_list.gross_amount}</td>
                                            <td>{timeConverter(item.job_list.created_at)}</td>
                                            
                                        </tr>
                                    )
                                })
                            }


                        </tbody>
                    </table>

                    {
                        invoiceList && invoiceList.length <= 0 &&
                        <p className="text-dark text-center bg-warning">List not available</p>
                    }


                </div>
            </div>
            {loader && (
                <div className="loader_lst">
                    <img
                        src="/assets/images/JOP.gif"
                        className="bannerimg"
                        alt="Not found"
                    />
                </div>
            )}
        </div>
    )
}
