import { DataService } from './dataService'

export const enquiryService = {
    
    getInquiries: async () => {
        return DataService.get('/retailer/get_retailer_inquiry_list')
    },

    getEnquiryapi: async (data) => {
        return DataService.get('/retailer/get_retailer_inquiry_list', data)
    },
}


export default enquiryService;