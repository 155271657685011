import React, { useState, useEffect, useContext } from 'react'
import { Button, Modal, Col, Row, Table, Form, Spinner } from 'react-bootstrap-v5'
import { QuoteDetailContext } from '../GetQuoteDetail'
import { QUOTE_DETAIL } from '../../../Redux/type';
import { quoteService } from '../../../services';
import helperFunction from '../helperFunction';
import { getFreight, manageItems, getPlaceInfo, manageItemsF2 } from './quoteFunctions';

const DECIMALLENGTH = 3
export default function Packing({ showPacking, handlePacking }) {
	const { country, state, dispatch } = useContext(QuoteDetailContext);
	const [loading, setLoading] = useState(false);
	const [packaginTypes, setPackaginTypes] = useState([]);
	const [userPackTypes, setUserPackTypes] = useState([]);
	const [packingValue, setPackaginValue] = useState(1);
	const [updatedItems, setUpdatedItems] = useState([]);
	const [itemCharges, setItemCharges] = useState([]);
	const [itemFreights, setItemFreights] = useState([]);

	const handleClose = () => handlePacking(false);

	useEffect(() => {
		quoteService.packingList().then(result => {
			if (result.status === true) {
				setPackaginTypes(result.data.packings)
			}
		})
		if (state) {
			const info = state.quoteDetails
			let pckItmes = info.quote_request_items
			let tempCopy = JSON.parse(JSON.stringify(pckItmes))
			let actualItems = tempCopy.length && tempCopy.map(item => item.actual)

			let itemchg = tempCopy.length && tempCopy.map(item => Number(item.add_ons_items.packing_charges))
			let itemfrt = tempCopy.length && tempCopy.map(item => Number(item.add_ons_items.freight_charges))
			let itempck = tempCopy.length && tempCopy.map(item => item.add_ons_items.packing_type)

			setItemCharges(itemchg)
			setItemFreights(itemfrt)
			setUserPackTypes(itempck)
			setUpdatedItems(actualItems)
		}
	}, [])

	useEffect(() => {
	}, [updatedItems])

	useEffect(() => {

	}, [itemCharges])


	const SinglePacking = () => {

	}
	const allPacking = async (packValue) => {
		let items = state.quoteDetails.quote_request_items
		items.map(async (item, index) => {
			// handleSelectPacking( packValue, index )
			setTimeout(() => {
				handleSelectPacking(packValue, index)
			}, (index + 1) * 5000)
		})
	}
	const handleSelectPacking = async (value, index) => {
		setLoading(true)
		// const { value } = event.target;

		// separate actual items data
		let items = state.quoteDetails.quote_request_items
		let tempCopy = JSON.parse(JSON.stringify(items))
		let actualItemOriginal = tempCopy.length && tempCopy.map(item => item.actual)
		let bookedItems = updatedItems

		if (value && value !== 'NONE') {
			let itemDimension = {
				length: actualItemOriginal[index]['length'],
				height: actualItemOriginal[index]['height'],
				width: actualItemOriginal[index]['width'],
				weight: actualItemOriginal[index]['kilogram'],
			}

			let type = helperFunction.toCamelCase(value);
			let actualUnit = helperFunction.calculateItem(itemDimension, type)
			let dap = helperFunction.increasingDimension(itemDimension, type) // dimension after packing
			let afterUnit = helperFunction.calculateItem(dap, type)
			let diff = (afterUnit - actualUnit)
			diff = diff.toFixed(DECIMALLENGTH)

			//----START---- This will be for freight calculation -----START---

			let weight = helperFunction.calculateWeight(itemDimension.weight, diff, type)
			let volume = helperFunction.calculateVolume(dap).toFixed(DECIMALLENGTH);

			bookedItems[index]['length'] = dap.length
			bookedItems[index]['height'] = dap.height
			bookedItems[index]['width'] = dap.width
			bookedItems[index]['kilogram'] = Number(weight)
			bookedItems[index]['packing_type'] = value
			bookedItems[index]['total_volume_CBM'] = volume * Number(bookedItems[index]['no_of_pkgs'])
			bookedItems[index]['total_weight_KG'] = weight * Number(bookedItems[index]['no_of_pkgs'])

			//----END---- This will be for freight calculation -----END---
			let packingCalculateArray = packaginTypes.map((item) => {
				return ({ perUnit: item.rate_per_cmb, minimum: item.minimum });
			});

			let chgs = helperFunction.calculatePrice(actualUnit, bookedItems[index]['no_of_pkgs'], type, packingCalculateArray)
			bookedItems[index]['packing_charges'] = Number(chgs)

			// let udpateItemCharge = JSON.parse(JSON.stringify(itemCharges))
			let udpateItemCharge = itemCharges
			udpateItemCharge[index] = chgs

			freight(index).then(frtRes => {
				bookedItems[index]['freight_charges'] = frtRes
				// let upcktype = JSON.parse(JSON.stringify(userPackTypes))
				let upcktype = userPackTypes
				upcktype[index] = value
				setItemCharges(udpateItemCharge)
				setUpdatedItems(bookedItems) // Updating state
				setUserPackTypes(upcktype)
				setLoading(false)
			})
		} else {
			let upcktype = userPackTypes
			setLoading(false)
			setUserPackTypes(upcktype)
		}
	}

	const freight = async (index) => {
		let qitems = state.quoteDetails.quote_request_items
		let tempCopy = JSON.parse(JSON.stringify(qitems))
		let actualItemOriginal = tempCopy.length && tempCopy.map(item => item.actual)
		let itemToken = tempCopy.length && tempCopy.map(item => item.item_token)
		let bookedItems = updatedItems
		let totalPackages = bookedItems.reduce((total, item) => Number(item.no_of_pkgs) + total, 0)

		let totalVolume = bookedItems.reduce((total, item, currIndex) => {
			let tv = index === currIndex ? (Number(item.total_volume_CBM)) : Number(actualItemOriginal[currIndex].total_volume_CBM)
			return tv + total
		}, 0)

		let totalWeight = bookedItems.reduce((total, item, currIndex) => {
			let tw = index === currIndex ? (Number(item.total_weight_KG)) : Number(actualItemOriginal[currIndex].total_weight_KG)
			return tw + total
		}, 0)

		let items = manageItems(bookedItems, actualItemOriginal, index, itemToken)

		const originData = await getPlaceInfo(country.origin_city).then(data => { return data })
		const destinationData = await getPlaceInfo(country.destination_city).then(data => { return data })
		let postData = {
			origin_country: originData.origin_country,
			origin_country_short_name: originData.origin_country_short_name,
			origin_city: originData.origin_city,
			origin_latitude: originData.origin_latitude,
			origin_longitude: originData.origin_longitude,
			destination_country: destinationData.origin_country,
			destination_country_short_name: destinationData.origin_country_short_name,
			destination_city: destinationData.origin_city,
			destination_latitude: destinationData.origin_latitude,
			destination_longitude: destinationData.origin_longitude,

			number_of_packages: totalPackages,
			volume: Number(totalVolume.toFixed(DECIMALLENGTH)),
			weight: Number(totalWeight.toFixed(DECIMALLENGTH)),
			items_goods: Number(country.items_goods),

			items: items,
			quote_token: state.quoteDetails.quote_token,
			referral_token: '',
			promo_code: '',
		}

		var finalFrt = 0
		return getFreight(state.serviceType, postData).then(ress => {
			// let itemFrt = JSON.parse(JSON.stringify(itemFreights))
			let itemFrt = itemFreights
			finalFrt = ress - state.serviceValue // Deduct freight
			itemFrt[index] = finalFrt
			setItemFreights(itemFrt)
			return finalFrt
		})
	}

	const savePacking = () => {
		let qitems = state.quoteDetails.quote_request_items
		let tempCopy = JSON.parse(JSON.stringify(qitems))
		let itemToken = tempCopy.length && tempCopy.map(item => item.item_token)
		let mf2 = manageItemsF2(updatedItems, itemToken)
		let packingItem = {
			items: mf2,
			quote_token: state.quoteDetails.quote_token,
		};

		quoteService.updatePackingApi(packingItem).then(result => {
			if (result.status === true) {
				updatedItems.length && updatedItems.map((item, index) => {
					qitems[index]['add_ons_items'] = item
				})
				state.quoteDetails.quote_request_items = qitems
				dispatch({ type: QUOTE_DETAIL, payload: state.quoteDetails })
				handleClose()
			} else {
				alert('error')
			}
		})
	}

	if (!state) {
		return 'Loading...'
	} else {
		const info = state.quoteDetails
		var packingItmes = info.quote_request_items
		let chrg = itemCharges.length ? itemCharges.reduce((a, b) => Number(a) + Number(b)) : 0
		let frt = itemFreights.length ? itemFreights.reduce((a, b) => Number(a) + Number(b)) : 0
		let totalFreightCharge = chrg + frt

		return (
			<Modal show={showPacking} onHide={handleClose} size="xl">
				<Modal.Header closeButton>
					<Modal.Title>Packing</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<Row className="justify-content-md-center">

						<Col md={3}>
							<Button
								className='quoteTabbtn'
								variant={packingValue == 2 ? 'success' : 'secondary'}
								checked={packingValue === 2}
								onClick={() => setPackaginValue(2)}
							>
								<center>
									<span className="showondesk">Same Packing For All</span>
								</center>
							</Button>{' '}
						</Col>
						<Col md={3}>
							<Button
								className='quoteTabbtn active'
								variant={packingValue == 1 ? 'success' : 'secondary'}
								checked={packingValue === 1}
								onClick={() => setPackaginValue(1)}
							>
								<center>
									<span className="showondesk">Different Packing For All</span>
								</center>
							</Button>{' '}
						</Col>
					</Row>
					<Row >
						<Col md="auto">
							{/* Display packings */}
							{packingValue === 2 && packaginTypes.length > 0 
							&& <><h6>Select Packing Type</h6>
							<div className="mb-3 mt-1">
								{
								packaginTypes.map((item) => (
									<Form.Check
										key={item.packing_type}
										style={{ textTransform: 'capitalize' }}
										inline
										label={item.packing_type}
										value={item.packing_type}
										name="packingType"
										type="radio"
										id={`inline-${item}-1`}
										onChange={(e) => allPacking(e.target.value)}
									/>
								))
								}
								<Form.Check
										key='NONE'
										style={{ textTransform: 'capitalize' }}
										inline
										label='NONE'
										value='NONE'
										name="packingType"
										type="radio"
										id={`inline-dd-1`}
										onChange={(e) => allPacking('NONE')}
									/>
							</div>
							</>
							}
						</Col>
					</Row>
					<Row className="justify-content-md-center">
						<Col>
							<Table striped bordered hover>
								<thead>
									<tr>
										<th rowSpan="2">S.No.</th>
										<th rowSpan="2">Product Description</th>
										<th rowSpan="2">Pkgs (No.)</th>
										<th colSpan="4">Weight & Dimension per Package</th>
										<th >Packing Type </th>
										<th width="50">Packing Charges</th>
										<th width="50">Freight Charges</th>
									</tr>
									<tr>
										<th>Kilogram</th>
										<th>Length</th>
										<th>width</th>
										<th>Height</th>
									</tr>
								</thead>
								<tbody>
									{
										packingItmes.length > 0
										&& packingItmes.map((item, index) => {

											if (
												item.actual.kilogram === 0
												|| item.actual.length === 0
												|| item.actual.width === 0
												|| item.actual.height === 0
												|| item.actual.no_of_pkgs === 0
											) {
												return false
											} else
												return <tr key={item.actual.item_description + index}>
													<td>{index + 1}</td>
													<td>{item.actual.item_description}</td>
													<td>{item.actual.no_of_pkgs}</td>
													<td>{item.actual.kilogram}</td>
													<td>{item.actual.length}</td>
													<td>{item.actual.width}</td>
													<td>{item.actual.height}</td>
													<td>
														<Form.Select
															name="packing_type"
															onChange={(e) => handleSelectPacking(e.target.value, index)}
															value={userPackTypes[index]}
														>
															<option key="none" value="NONE">None</option>
															{
																packaginTypes.length > 0 && packaginTypes.map((item) => {
																	return <option key={item.packing_type + '-pck'} value={item.packing_type}>{item.packing_type}</option>
																})
															}

														</Form.Select>
													</td>

													<td>
														{itemCharges[index] ? itemCharges[index] : 0}
													</td>
													<td>
														{itemFreights[index] ? itemFreights[index] : 0}
													</td>
												</tr>
										})

									}

								</tbody>
							</Table>
						</Col>
					</Row>
					<Row>
						<Col md={4}>
							<p>Total Packing charges: {totalFreightCharge}</p>
						</Col>
						<Col md={{ span: 4, offset: 4 }}>
							<Button variant="primary" onClick={() => savePacking()}>
								Save Changes
							</Button>
							{loading && <Spinner animation="grow" variant="warning" />}
						</Col>
					</Row>
				</Modal.Body>

				<Modal.Footer> </Modal.Footer>
			</Modal>
		)
	}
}
