import React, { useState, useEffect } from "react";
import { quoteService } from "../../services";
import { Modal, ListGroup, Col, Row, Form } from "react-bootstrap-v5";
import helperFunction from "./helperFunction";
import { Storage } from "../../Storage";

const DECIMALLENGTH = 3;

const PackageModal = (props) => {
  const [packingCalculateData, setPackingCalculateData] = useState([])
  const [packingState, setPackingState] = useState({
    packing: [],
    packaginTypes: [],
    charges: 0,
    freight: 0,
    bookedItems: [],
    freight_charges: [],
  });
  // const [noneFlag, setNoneFlag] = useState(false);
  const [itemFreight, setItemFreight] = useState([0, 0, 0]);

  const {
    isShow,
    onHide,
    // SinglePacking,
    // AllPacking,
    originData,
    destinationData,
    volumestate,
    serviceType,
    serviceAmount,
    saveHandle,
    settingSpin,
    resetPack,
    callPackage,
    setCallPackage
  } = props;

  const isRetailer = Storage.get("user-token") === "retailer"
  const isStore = Storage.get("user-token") === "store"

  useEffect(() => {
    getPackingList();
  }, [volumestate]);


  useEffect(() => {
    setPackingState({
      ...packingState,
      charges: 0,
      freight: 0,
    })
  }, [resetPack])

  useEffect(() => {

  }, [packingState])

  const handleCloseModal = () => {
    let frtChg = []
    let nonearray = packingState.packing;
    for(let i = 0; i < nonearray.length; i++){
      nonearray[i].packing_charges = 0;
      nonearray[i].packing_type = "none";
      frtChg.push(0)
    }
      
    setPackingState({
      ...packingState,
      charges: 0,
      freight: 0,
      freight_charges: frtChg,
      packing: nonearray,
    })
    onHide()
  }

  const getPackingList = async () => {

    let packingCall;

    if (isRetailer) {
      packingCall = quoteService.retailerPackingList()
    } else if (isStore) {
      packingCall = quoteService.storePackingList()
    } else {
      packingCall = quoteService.packingList()
    }

    await packingCall.then((result) => {
      if (result.status === true) {

        let packArray = [];
        result.data?.packings.map((item) => {
          return packArray.push({ packing_type: item.packing_type });
        });
        packArray[packArray.length] = { packing_type: "none" };

        let packingCalculateArray = result.data?.packings.map((item) => {
          return ({ perUnit: item.rate_per_cmb, minimum: item.minimum });
        });

        setPackingCalculateData(packingCalculateArray)

        let addOnshere = [],
          fcharge = [];
        volumestate.items &&
          volumestate.items.map((item, key) => {
            addOnshere.push({
              ...item,
              after_packing_dimension: {
                kilogram: "",
                length: "",
                width: "",
                height: "",
              },
              after_packing_total_volume_CBM: "",
              after_packing_total_weight_KG: "",
              packing_charges: 0,
              freight_charges: 0,
            });
            fcharge.push(0);
          });

        let ch =
          addOnshere &&
          addOnshere.reduce(
            (total, item) => Number(item.packing_charges) + total,
            0
          );
          

        setPackingState({
          ...packingState,
          packaginTypes: packArray,
          bookedItems: addOnshere,
          packing: addOnshere,
          charges: ch,
          freight_charges: fcharge,
        });
      }
    });
  };

  const SinglePacking = () => {
    let array = [...packingState.packaginTypes];
    array.unshift({ packing_type: "Select-Packing" })
    setPackingState({
      ...packingState,
      packaginTypes: array
    })
  }
  const AllPacking = () => {
    let array = [...packingState.packaginTypes];
    array.shift({ packing_type: "Select-Packing" })
    setPackingState({
      ...packingState,
      packaginTypes: array
    })
  }

   useEffect(() => {
    if(callPackage){
      packingRadiohandler({
        target: {
          value: 'none'
        }
      })

      let frtChg = []
      let nonearray = packingState.packing;
      for(let i = 0; i < nonearray.length; i++){
        nonearray[i].packing_charges = 0;
        nonearray[i].packing_type = "none";
        frtChg.push(0)
      }

      
      setPackingState({
        ...packingState,
        charges: 0,
        freight: 0,
        freight_charges: frtChg,
        packing: nonearray
      })
      
      saveHandle({
        ...packingState,
        charges: 0,
        freight: 0,
        freight_charges: frtChg,
        packing: nonearray
      })
      setCallPackage(0)
    }
  }, [callPackage])


  const packingRadiohandler = (e) => {
    const list = packingState.bookedItems;
    let pVal =  e.target.value
    list &&
      list.map((item, index) => {
        packingSelectedhandler(pVal, index, "packing_type");

        return (item.packing_type = pVal);
      });

    // setPackingState({ ...packingState, bookedItems: list });
  };

  //console.log("packingCalculateData .....", packingCalculateData)


  const packingSelectedhandler = (value, index, selType) => {
    let bookedItems = packingState.bookedItems;
    let activePacking = packingState.packing;

    let tempCopy = JSON.parse(JSON.stringify(volumestate.items));
    // let list = packingState.packing;

    // separate actual items data
    let actualItemOriginal = tempCopy.length && tempCopy.map((item) => item);

    //if (value !== "none") {
    if (value !== "Select-Packing" && value !== "none") {
      // list[index] = value; // To show selected packing type

      let itemDimension = {
        length: actualItemOriginal[index].dimension["length"],
        height: actualItemOriginal[index].dimension["height"],
        width: actualItemOriginal[index].dimension["width"],
        weight: actualItemOriginal[index].dimension["kilogram"],
      };

      // setNoneFlag(false)
      let type = helperFunction.toCamelCase(value);

      let actualUnit = helperFunction.calculateItem(itemDimension, type); //

      let dap = helperFunction.increasingDimension(itemDimension, type); // dms after packing

      let afterUnit = helperFunction.calculateItem(dap, type);

      let diff = (afterUnit - actualUnit).toFixed(DECIMALLENGTH);

      let weight = helperFunction.calculateWeight(
        itemDimension.weight,
        diff,
        type
      );

      let volume = helperFunction.calculateVolume(dap).toFixed(3);

      if (Array.isArray(activePacking)) {
        activePacking[index]["packing_type"] = value;
        setPackingState({
          ...packingState,
          packing: activePacking,
        });
      }

      if (Array.isArray(bookedItems) && Object(dap).length) {
        bookedItems[index]["packing_type"] = value;
        bookedItems[index]["after_packing_dimension"]["length"] = dap.length;
        bookedItems[index]["after_packing_dimension"]["height"] = dap.height;
        bookedItems[index]["after_packing_dimension"]["width"] = dap.width;
        bookedItems[index]["after_packing_dimension"]["kilogram"] =
          Number(weight);
        bookedItems[index]["after_packing_total_volume_CBM"] =
          volume * Number(bookedItems[index]["no_of_pkgs"]);
        bookedItems[index]["after_packing_total_weight_KG"] =
          weight * Number(bookedItems[index]["no_of_pkgs"]);

        bookedItems[index]["total_volume_CBM"] =
          volume * Number(bookedItems[index]["no_of_pkgs"]);
        bookedItems[index]["total_weight_KG"] =
          weight * Number(bookedItems[index]["no_of_pkgs"]);

        let chgs = helperFunction.calculatePrice(
          actualUnit,
          bookedItems[index]["no_of_pkgs"],
          type,
          packingCalculateData
        );

        bookedItems[index]["packing_charges"] = Number(
          chgs.toFixed(DECIMALLENGTH)
        );

        bookedItems[index][selType] = value;

        let totalCharges = bookedItems.reduce(
          (total, item) => Number(item.packing_charges) + total,
          0
        );

        packingState.charges = Number(Math.round(totalCharges));
        setPackingState({
          ...packingState,
          bookedItems: bookedItems,
          // packing: list
        });
      }

      getFreightAmountFun(index);
    } else {
      let actualpacking = packingState.bookedItems;
      // If admin remove packing from item
      actualpacking[index]["after_packing_dimension"]["length"] =
        actualpacking[index].dimension.length;
      actualpacking[index]["after_packing_dimension"]["height"] =
        actualpacking[index].dimension.height;
      actualpacking[index]["after_packing_dimension"]["width"] =
        actualpacking[index].dimension.width;
      actualpacking[index]["after_packing_dimension"]["kilogram"] =
        actualpacking[index].dimension.kilogram;

      actualpacking[index]["after_packing_total_volume_CBM"] = "";

      actualpacking[index]["after_packing_total_weight_KG"] = "";

      let nonearray = packingState.packing;
      nonearray[index].packing_charges = 0;
      nonearray[index].packing_type = "none";
      let nonefreight = itemFreight;
      nonefreight[index] = 0;

      let tf = nonefreight.reduce((a, b) => Number(a) + Number(b));
      let tc = nonearray
        .map((item) => item.packing_charges)
        .reduce((prev, curr) => prev + curr, 0);
      setPackingState({
        charges: tc,
        freight: tf,
        packaginTypes: packingState.packaginTypes        ,
        packing: nonearray,
        freight_charges: nonefreight,
        
        bookedItems: actualpacking,
      });
    }
  };

  const getFreightAmountFun = (index) => {
    let tempCopy = JSON.parse(JSON.stringify(volumestate.items));
    // separate actual items data
    let actualItemOriginal = tempCopy.length && tempCopy.map((item) => item);

    let totalPackages =
      packingState.bookedItems &&
      packingState.bookedItems.reduce(
        (total, item) => Number(item.no_of_pkgs) + total,
        0
      );

    let BeforetotalVolume =
      packingState.bookedItems &&
      packingState.bookedItems.reduce(
        (total, item, currIndex) => {
          let tv =
            index === currIndex
              ? Number(item.total_volume_CBM)
              : Number(actualItemOriginal[currIndex].total_volume_CBM);
          return tv + total;
        },
        //Number(item.total_volume_CBM) + total,
        0
      );

    let BeforetotalWeight =
      packingState.bookedItems &&
      packingState.bookedItems.reduce(
        (total, item, currIndex) => {
          let tw =
            index === currIndex
              ? Number(item.total_weight_KG)
              : Number(actualItemOriginal[currIndex].total_weight_KG);
          return tw + total;
        },
        //Number(item.total_weight_KG) + total,
        0
      );

    let items =
      packingState.bookedItems &&
      packingState.bookedItems.map((item, idx) => {
        if (
          packingState &&
          packingState.bookedItems &&
          Array.isArray(packingState.bookedItems)
        ) {
          let after = item.after_packing_dimension,
            before = item.dimension;

          return {
            item_id: item.item_id,
            item_description: item.item_description,
            no_of_pkgs: Number(item.no_of_pkgs),
            dimension: {
              kilogram: after.kilogram
                ? Number(after.kilogram)
                : Number(before.kilogram),
              length: after.length
                ? Number(after.length)
                : Number(before.length),
              width: after.width ? Number(after.width) : Number(before.width),
              height: after.height
                ? Number(after.height)
                : Number(before.height),
            },
            total_volume_CBM: item.after_packing_total_volume_CBM
              ? item.after_packing_total_volume_CBM
              : item.total_volume_CBM,

            total_weight_KG: item.after_packing_total_weight_KG
              ? item.after_packing_total_weight_KG
              : item.total_weight_KG,
            item_token: item.item_token,
          };
        }
      });

    let postData = {
      origin_country: originData.country,
      origin_country_short_name: originData.country_short_name,
      origin_city: originData.city,
      origin_latitude: originData.latitude,
      origin_longitude: originData.longitude,
      destination_country: destinationData.country,
      destination_country_short_name: destinationData.country_short_name,
      destination_city: destinationData.city,
      destination_latitude: destinationData.latitude,
      destination_longitude: destinationData.longitude,
      number_of_packages: totalPackages,
      volume: Number(BeforetotalVolume.toFixed(3)),
      weight: Number(BeforetotalWeight.toFixed(3)),
      items_goods: volumestate.items_goods,
      items: items,
      quote_token: volumestate.quote_token,
    };

    let getFinalQuotezKey;
    if (
      serviceType === "PORTAIR" ||
      serviceType === "PORTSEA" ||
      serviceType === "PORTCOURIER" ||
      serviceType === "PORTLAND"
    ) {
      getFinalQuotezKey = "port_delivery";
    }
    if (
      serviceType === "DOORAIR" ||
      serviceType === "DOORSEA" ||
      serviceType === "DOORCOURIER" ||
      serviceType === "DOORLAND"
    ) {
      getFinalQuotezKey = "door_delivery";
    }
    if (
      serviceType === "CONSOLIDATIONAIR" ||
      serviceType === "CONSOLIDATIONSEA" ||
      serviceType === "CONSOLIDATIONCOURIER"
    ) {
      getFinalQuotezKey = "consolidation_delivery";
    }

    let k = getFinalQuotezKey.replace("_delivery", "");
    if (["PORTAIR", "DOORAIR", "CONSOLIDATIONAIR"].includes(serviceType)) {
      settingSpin(true);
      quoteService.checkAirFreightApi(postData).then((result) => {
        if (result.status === true) {
          let frtAmt =
            result.all_data.final[getFinalQuotezKey][
            `${k}_grant_total`
            ];
          FinalBookingAmtFun(frtAmt, index);
        } else {
          settingSpin(false);
        }
      });
    } else if (
      ["PORTSEA", "DOORSEA", "CONSOLIDATIONSEA"].includes(serviceType)
    ) {
      settingSpin(true);
      quoteService.checkSeaFreightApi(postData).then((result) => {
        if (result.status === true) {
          let frtAmt =
            result.all_data.final[getFinalQuotezKey][
            `${k}_grant_total`
            ];
          FinalBookingAmtFun(frtAmt, index);
        } else {
          settingSpin(false);
        }
      });
    } else if (
      ["PORTCOURIER", "DOORCOURIER", "CONSOLIDATIONCOURIER"].includes(
        serviceType
      )
    ) {
      settingSpin(true);
      quoteService.checkCourierFreightApi(postData).then((result) => {
        if (result.status === true) {
          let frtAmt = result.all_data.door_delivery.door_grant_total;
          FinalBookingAmtFun(frtAmt, index);
        } else {
          settingSpin(false);
        }
      });
    } else if (["PORTLAND", "DOORLAND"].includes(serviceType)) {
      settingSpin(true);
      quoteService.checkLandFreightApi(postData).then((result) => {
        if (result.status === true) {
          let frtAmt =
            result.all_data.final[getFinalQuotezKey][
            `${k}_grant_total`
            ];
          FinalBookingAmtFun(frtAmt, index);
        } else {
          settingSpin(false);
        }
      });
    }
  };

  const FinalBookingAmtFun = (frtAmt, index) => {

    let balanceAmt = frtAmt - Number(serviceAmount);
    let finalBalanceAmt = 0;
    let itemFreightss = itemFreight;
    // console.log("balanceAmt ....", balanceAmt);

    itemFreightss[index] = balanceAmt;
    setItemFreight(itemFreightss);
    // set balance amount for perticular item
    finalBalanceAmt = itemFreight.reduce((a, b) => a + b); // get total frieght

    let Freight = packingState.bookedItems;
    Freight[index]["freight_charges"] = itemFreight[index];
    setPackingState({
      ...packingState,
      freight: finalBalanceAmt,
      freight_charges: itemFreight,
      packing: Freight,
    });
    settingSpin(false);
    return true;
  };

  //   setFinalBookingAmt = (frtAmt, index) => {
  //     const { bookedItems } = this.state
  //     // let balanceAmt = Number(frtAmt) - Number(this.state.bookingAmount)
  //     let balanceAmt = Number(frtAmt) - Number(this.props.itemsBookingAmount)
  //     let finalBalanceAmt = 0

  //     this.itemFreight[index] = Number(balanceAmt) // set balance amount for perticular item
  //     bookedItems[index]['freight_charges'] = this.itemFreight[index]
  //     finalBalanceAmt = this.itemFreight.reduce((a, b) => Number(a) + Number(b)) // get total frieght

  //     let price = this.itemPrice.reduce((a, b) => Number(a) + Number(b)) // get total frieght

  //     this.setState({ freight: finalBalanceAmt, charges: price })

  //     return true
  // }

  // console.log("---> ", packingState.packing)

  return (
    <Modal show={isShow} size="xl">
      <Modal.Header>
        <Modal.Title>Packing</Modal.Title>
        <span className="ms-auto p-2 cursor-pointer" onClick={()=>handleCloseModal()}>
          Close
        </span>
      </Modal.Header>
      <Modal.Body className="pb-0">
        <Row>
          <Col md={12}>
            <ul
              className="nav getQuotetablist justify-content-center"
              id="myTab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="quoteTabbtn active"
                  id="all-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#all"
                  type="button"
                  role="tab"
                  aria-controls="all"
                  aria-selected="true"
                  onClick={() => AllPacking()}
                >
                  <center>
                    <span className="showondesk">Same Packing For All</span>
                  </center>
                </button>
              </li>
              <li className="nav-item me-3" role="presentation">
                <button
                  className="quoteTabbtn"
                  id="individual-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#individual"
                  type="button"
                  role="tab"
                  aria-controls="individual"
                  aria-selected="true"
                  onClick={() => SinglePacking()}
                >
                  <center>
                    <span className="showondesk">
                      Different Packing For All
                    </span>
                  </center>
                </button>
              </li>


            </ul>

            <div className="tab-content">



              <div
                className="tab-pane"
                id="individual"
                role="tabpanel"
                aria-labelledby="individual-tab"
              >
                {/* {console.log("packingtype===>", packingType)} */}
                <table className="table table-bordered text-center volume-calcu">
                  <thead>
                    <tr>
                      <th rowspan="2">S.No.</th>
                      <th rowspan="2">Product Description</th>
                      <th rowspan="2">Pkgs (No.)</th>
                      <th colspan="4">Weight & Dimension per Package</th>
                      <th rowSpan="2">Packing</th>
                      <th rowSpan="2">
                        Packing <br /> Charges <br /> AED
                      </th>
                      <th rowspan="2">
                        Freight <br /> Charges <br /> AED
                      </th>
                    </tr>
                    <tr>
                      <th>Kilogram</th>
                      <th>Length</th>
                      <th>width</th>
                      <th>Height</th>
                    </tr>
                  </thead>

                  <tbody>
                    {packingState.packing &&
                      packingState.packing.map((list, idx) => {
                        {/* console.log("----> ", list.packing_type) */ }
                        return (
                          <tr>
                            <td>{idx + 1}</td>
                            <td>{list.item_description}</td>
                            <td>{list.no_of_pkgs}</td>
                            <td>{list.dimension.kilogram}</td>
                            <td>{list.dimension.length}</td>
                            <td>{list.dimension.width}</td>
                            <td>{list.dimension.height}</td>

                            <td>
                              <select
                                className="form-control"
                                name="packing"
                                onChange={(e) =>
                                  packingSelectedhandler(
                                    e.target.value,
                                    idx,
                                    "packing_type",
                                    {
                                      weight: list.dimension.kilogram,
                                      length: list.dimension.length,
                                      width: list.dimension.width,
                                      height: list.dimension.height,
                                    }
                                  )
                                }
                              >
                                {/* <option value="none">--None--</option> */}
                                {packingState &&
                                  packingState.packaginTypes.length > 0 &&
                                  packingState.packaginTypes.map(
                                    (item, key) => {
                                      if (
                                        item.packing_type === list.packing_type
                                      ) {
                                        return (
                                          <option
                                            selected
                                            disabled
                                            value={list.packing_type}
                                            key={key}
                                          >
                                            {list.packing_type}
                                          </option>
                                        );
                                      } else {
                                        return (
                                          <option
                                            value={item.packing_type}
                                            key={key}
                                          >
                                            {item.packing_type}
                                          </option>
                                        );
                                      }
                                    }
                                  )}
                              </select>
                            </td>

                            <td>{list.packing_charges}</td>
                            <td>
                              {packingState.freight_charges &&
                                packingState.freight_charges[idx]}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>

                <div className="d-flex justify-content-between mb-3">
                  <div className="col-4">
                    <label>Total Packing charges - </label> &nbsp;
                    <span className="fw-bold">
                      {Number(packingState.charges) + Number(packingState.freight)}
                      &nbsp; AED
                    </span>
                    {/* <div>
                      <label>Freight charge - </label> &nbsp;
                      <span className="fw-bold">
                        {packingState.freight}
                        &nbsp; AED
                      </span>
                    </div> */}
                  </div>
                  <div className="col-3">
                    <button
                      type="button"
                      onClick={() => saveHandle(packingState)}
                      className="btn ms-auto themebutton w-sm"
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>

              <div
                className="tab-pane active"
                id="all"
                role="tabpanel"
                aria-labelledby="all-tab"
              >
                <div className="form-group px-3 pb-3">
                  <h6>Select Packing Type</h6>
                  <div className="form-check form-check-inline d-flex">
                    {packingState &&
                      packingState.packaginTypes.map((item, mykey) => {
                        return (
                          <div key={mykey} className="me-5">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="packing"
                              id={`cartonpacking${mykey}`}
                              value={item.packing_type}
                              onChange={(e) => packingRadiohandler(e)}
                            />
                            <label
                              className="form-check-label"
                              for={`cartonpacking${mykey}`}
                            >
                              {item.packing_type}
                            </label>
                          </div>
                        );
                      })}
                  </div>
                </div>

                <table className="table table-bordered text-center volume-calcu">
                  <thead>
                    <tr>
                      <th rowspan="2">S.No.</th>
                      <th rowspan="2">Product Description</th>
                      <th rowspan="2">Pkgs (No.)</th>
                      <th colspan="4">Weight & Dimension per Package</th>
                      <th rowSpan="2">Packing</th>
                      <th rowSpan="2">
                        Packing <br /> Charges <br /> AED
                      </th>
                      <th rowspan="2">
                        Freight <br /> Charges <br /> AED
                      </th>
                    </tr>
                    <tr>
                      <th>Kilogram</th>
                      <th>Length</th>
                      <th>width</th>
                      <th>Height</th>
                    </tr>
                  </thead>
                  <tbody>
                    {packingState.packing &&
                      packingState.packing.map((item, idx) => {
                        return (
                          <tr>
                            <td>{idx + 1}</td>
                            <td>{item.item_description}</td>
                            <td>{item.no_of_pkgs}</td>
                            <td>{item.dimension.kilogram}</td>
                            <td>{item.dimension.length}</td>
                            <td>{item.dimension.width}</td>
                            <td>{item.dimension.height}</td>
                            <td>{item.packing_type}</td>
                            <td>{item.packing_charges}</td>
                            <td>
                              {packingState.freight_charges &&
                                packingState.freight_charges[idx]}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <div className="d-flex justify-content-between mb-3">
                  <div className="col-4">
                    <label>Total Packing charges - </label> &nbsp;
                    <span className="fw-bold">
                      {Number(packingState.charges) + Number(packingState.freight)}
                      &nbsp; AED
                    </span>
                    {/* <div>
                      <label>Freight charge - </label> &nbsp;
                      <span className="fw-bold">
                        {packingState.freight}
                        &nbsp; AED
                      </span>
                    </div> */}
                  </div>
                  <div className="col-3">
                    <button
                      type="button"
                      onClick={() => saveHandle(packingState)}
                      className="btn ms-auto themebutton w-sm"
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default PackageModal;
