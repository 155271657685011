import React, { useEffect, useContext, useState } from 'react'
import { quoteService } from '../../../services';
import { Form } from "react-bootstrap-v5";
import { QuoteDetailContext } from '../GetQuoteDetail'
import helperFunction from '../helperFunction';
import { INSURANCE, STORAGE } from '../../../Redux/type';
import Packing from './Packing';

export default function AddOn() {
    const [insurance, setInsurance] = useState([])
    const [storage, setStorage] = useState([])
    const [showPacking, setShowPacking] = useState(false)
    const { country, state, dispatch } = useContext(QuoteDetailContext);

    useEffect(()=> {
        quoteService.insuranceList().then(result => {
            if(result.status){
                setInsurance(result.data[0])
            }
        })
    }, [])

    useEffect(()=> {
        quoteService.storageList().then(result => {
            if(result.status){
                setStorage(result.data)
            }
        })
    }, [])

    const getTotalVolume = () => {
        const quoteDetails = state.quoteDetails
		if (Object.keys(quoteDetails).length > 0) {
			let vol = quoteDetails.quote_request_items.reduce((total, item) => Number(item.actual.total_volume_CBM) + total, 0)
			return vol.toFixed(3)
		}
		return 0
	}

    const handleInsurance = (e) => {
        if(e.target.checked){
            let insuranceAmt = helperFunction.calculateInsurancePer(
                    insurance.minimum_aed,
                    insurance.insurance_premium,
                    state.serviceValue,
                    country.items_goods
                )
            insuranceAmt = insuranceAmt.toFixed(0)

            dispatch({
                type: INSURANCE,
                payload: Number(insuranceAmt)
            })

        } else {
            dispatch({
                type: INSURANCE,
                payload: 0
            })
        }
    }

    const handleStorage = (e) => {
        let noOfDay, storageRate, volume, handlingIn, handlingOut
        noOfDay = e.target.value
        storageRate = storage.storages.find(item=> item.tarrif === 'storage_charges')
        handlingIn = storage.storages.find(item=> item.tarrif === 'handling_in')
        handlingOut = storage.storages.find(item=> item.tarrif === 'handling_out')
        volume = getTotalVolume()

        let storageAmt = helperFunction.calculateStorage(noOfDay, storageRate.rate, volume, handlingIn.rate, handlingOut.rate)
        storageAmt = storageAmt.toFixed(0)
        dispatch({
            type: STORAGE,
            payload: Number(storageAmt)
        })
    }

    if (!state) {
        return 'Loading...'
    } else {
        const info = state.quoteDetails
        
        return (
            <>
                <div className="white_box mt-4">
                    <h5 className="mb-1">Select Add - Ons (if required)</h5>
                    <p className="text-danger">
                        Note - Packing and insurance recommended
                    </p>
                    <div className="row mt-3">
                        <div className="col-md-4 border-end">
                            <div className="form-group mb-3">
                                <label>
                                    <span className="d-block fw-bold">PACKING</span>
                                </label>
                                <div>
                                    <button
                                        className="btn themebutton mt-3 w-sm"
                                        type="button"
                                        onClick={()=>setShowPacking(true)}

                                    >
                                        Add & View Packing
                                    </button>
                                    {showPacking && <Packing showPacking={showPacking} handlePacking={setShowPacking} />}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 border-end">
                            <div className="form-group mb-3">
                                <label>
                                    <span className="d-block  fw-bold">INSURANCE </span>
                                    <small>Insurance amount</small>
                                </label>
                                <input
                                    className="form-control"
                                    type="number"
                                    name="items_goods"
                                    min="0"
                                    value={country.items_goods}
                                />
                            </div>

                            <div className="form-group mb-3">
                                <label>
                                    <small>Select if insurance required.</small>
                                </label>
                                <Form>
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        label="Calculate Insurance"
                                        onChange={(e)=>handleInsurance(e)}
                                        checked={state.insuranceValue ? true : false}
                                    />
                                </Form>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group mb-3">
                                <label>
                                    <span className="d-block  fw-bold">STORAGE</span>
                                    <small>Enter number of Days</small>
                                </label>
                                <input
                                    className="form-control"
                                    name="storageValue"
                                    type="number"
                                    min="0"
                                    value={state.storageValue}
                                    onChange={(e)=>handleStorage(e)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
