import React, { createContext, useState, useEffect, useReducer } from 'react'
import { useHistory } from "react-router-dom";
import { useParams } from "react-router"
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row, Spinner } from 'react-bootstrap-v5'
import { toast } from "react-toastify";
import { quoteService } from "../../services";
import reducer from './quoteDetailReducer';
import { QUOTE_DETAIL, SERVICE_TYPE, INSURANCE, STORAGE, DISCOUNT } from "../../Redux/type"
import { UserTempLogin } from '../../Redux/action';
import UserAuthentication from "./UserAuthentication";
import Freights from './quoteDetails/Freights';
import AddOn from './quoteDetails/AddOn';
import Cargo from './quoteDetails/Cargo';
import OrderSummary from './quoteDetails/OrderSummary';
import AutoComplete from "../../Container/home/AutoComplete";
// import getRandomkeys from "../../Container/home/StringGenerator";
import { getPlaceInfo, manageItemsF2 } from "./quoteDetails/quoteFunctions"
import {
	GetquoteAction,
	GetTokenAction,
	VolumeDetailsAction
  } from "../../Redux/action";

import Loader from "../../Components/Loader";

const QuoteDetailContext = createContext()

export default function GetQuoteDetail() {
	const { id } = useParams()
	const [loader, setLoader] = useState(false);
	const [quoteDetails, setQuoteDetails] = useState({});
	const [country, setCounrty] = useState({});
	const [showCargoItems, setShowCargoItems] = useState(false)
	const [place, setPlace] = useState({ origin: {}, destination: {} });
	const [state, dispatch] = useReducer(reducer);
	const history = useHistory();
	const reduxDispatch = useDispatch();
	const userInfo = useSelector((state) => state.userDetails);

	useEffect(() => {
		fetchDetail()
	}, [])

	const fetchDetail = () => {
		userInfo.isLogged ? getDetail() : getDetailPublic()
	}

	// For callback `setQuoteDetailList`
	useEffect(() => {
		getSourceAndDestination()
		handleServiceType()
	}, [quoteDetails])
	// End

	const resetPakcingNone = () => {
		let pckItmes = quoteDetails.quote_request_items
		let tempCopy = JSON.parse(JSON.stringify(pckItmes))
		let actualItems = tempCopy.length && tempCopy.map(item => item.actual)

		let updatedItems = actualItems
		
		let itemToken = tempCopy.length && tempCopy.map(item => item.item_token)
		let mf2 = manageItemsF2(updatedItems, itemToken)
		let packingItem = {
			items: mf2,
			quote_token: state.quoteDetails.quote_token,
		};

		quoteService.updatePackingApi(packingItem).then(result => {
			if (result.status === true) {
				updatedItems.length && updatedItems.map((item, index) => {
					pckItmes[index]['add_ons_items'] = item
				})
				state.quoteDetails.quote_request_items = pckItmes
				dispatch({ type: QUOTE_DETAIL, payload: state.quoteDetails })
				dispatch({ type: DISCOUNT, payload: { customerDiscount: 0, discountAmount: 0 } })
			} else {
				alert('error')
			}
		})
	}

	const resetPckInsStorage = () => {
		dispatch({ type: STORAGE, payload: 0 })

		dispatch({ type: INSURANCE, payload: 0})

		dispatch({ type: DISCOUNT, payload: { customerDiscount: 0, discountAmount: 0 } })

		resetPakcingNone()
	}
	
	const getDetail = () => {
		let postData = { quote_token: id }
		quoteService.getQuotedetail(postData).then((res) => {
			if (res.status === true) {
				let quoteData = res.data[0]
				let quoteItem = quoteData.quote_request_items
				setQuoteDetails(res.data[0]);
				dispatch({ 
					type: QUOTE_DETAIL, 
					payload: res.data[0] 
				})
				if(quoteItem.length > 0){
					let item = quoteItem[0]
					let insurance = ("insurance" in item) ? item.insurance : 0
					let storage = ("storage" in item) ? item.storage : 0

					dispatch({
						type: STORAGE,
						payload: Number(storage)
					})

					dispatch({
						type: INSURANCE,
						payload: Number(insurance)
					})

				}
			}
		}).catch(err => {
			console.log(err);
		})
	}

	// Create seperate function for non-logged in user
	const getDetailPublic = () => {
		let postData = { quote_token: id }
		quoteService.getPublicQuotedetail(postData).then((res) => {
			if (res.status === true) {
				let quoteData = res.data[0]
				let quoteItem = quoteData.quote_request_items
				setQuoteDetails(res.data[0]);
				dispatch({ 
					type: QUOTE_DETAIL, 
					payload: res.data[0] 
				})
				if(quoteItem.length > 0){
					let item = quoteItem[0]
					let insurance = ("insurance" in item) ? item.insurance : 0
					let storage = ("storage" in item) ? item.storage : 0

					dispatch({
						type: STORAGE,
						payload: Number(storage)
					})

					dispatch({
						type: INSURANCE,
						payload: Number(insurance)
					})

				}

				const uDetail = {
					user_email: quoteData.user_detail[0].user_email,
					user_id: quoteData.user_detail[0]._id,
					user_full_name: quoteData.user_detail[0].user_full_name,
					user_phone_number: quoteData.user_detail[0].user_phone_number,
					user_dial_code: quoteData.user_detail[0].user_dial_code,
					user_type: quoteData.user_detail[0].user_type,
				  }

				reduxDispatch(UserTempLogin({ data: uDetail }));
			}
		}).catch(err => {
			console.log(err);
		})
	}

	const handleServiceType = () => {
		if (!Object.keys(quoteDetails).length) return
		const sType = quoteDetails.selected_courier_type

		let sKey1, sKey2;

		if (sType.includes("PORT")) sKey1 = 'PORT'
		if (sType.includes("DOOR")) sKey1 = 'DOOR'
		if (sType.includes("CONSOLIDATION")) sKey1 = 'CONSOLIDATION'

		if (sType.includes("AIR")) sKey2 = 'AIR'
		if (sType.includes("COURIER")) sKey2 = 'COURIER'
		if (sType.includes("SEA")) sKey2 = 'SEA'
		if (sType.includes("LAND")) sKey2 = 'LAND'

		if (sKey1 && sKey2) {
			const srType = { type: sKey1, typeWay: sKey2 }
			dispatch({ type: SERVICE_TYPE, payload: srType })
		}
	}

	const getSourceAndDestination = async () => {
		if (Object.keys(quoteDetails).length > 0) {
			let arr = ['quote_request_airs', 'quote_request_couriers', 'quote_request_lands', 'quote_request_seas']
			let result = {}
			for (let x of arr) {
				if (quoteDetails[x].length > 0) {
					result = quoteDetails[x][0]['request_data']

					let origin = await getPlaceInfo(result.origin_city)
					let destination = await getPlaceInfo(result.destination_city)

					let oData = {
						country: origin.origin_country,
						country_short_name: origin.origin_country_short_name,
						city: origin.origin_city,
						latitude: origin.origin_latitude,
						longitude: origin.origin_longitude
					}
					let dData = {
						country: destination.origin_country,
						country_short_name: destination.origin_country_short_name,
						city: destination.origin_city,
						latitude: destination.origin_latitude,
						longitude: destination.origin_longitude
					}
					setPlace({ origin: oData, destination: dData })
					setCounrty(result)
					let volumeData = {
						origin_city: origin.origin_city,
						destination_city: destination.origin_city,
						number_of_packages: getTotalPackages(),
						weight: getTotalWeight(),
						volume: getTotalVolume()
					}
					reduxDispatch(VolumeDetailsAction({request_data: volumeData}));
					break;
				}
			}
		}
	}

	const getTotalPackages = (cargoItems = []) => {
		let crgItems = cargoItems.length > 0 ? cargoItems : quoteDetails.quote_request_items
		if (Object.keys(quoteDetails).length > 0) {
			return crgItems.reduce((total, item) => Number(item.actual.no_of_pkgs) + total, 0)
		}
		return 0
	}
	const getTotalWeight = (cargoItems = []) => {
		let crgItems = cargoItems.length > 0 ? cargoItems : quoteDetails.quote_request_items
		if (Object.keys(quoteDetails).length > 0) {
			return crgItems.reduce((total, item) => Number(item.actual.total_weight_KG) + total, 0)
		}
		return 0
	}
	const getTotalVolume = (cargoItems = []) => {
		let crgItems = cargoItems.length > 0 ? cargoItems : quoteDetails.quote_request_items
		if (Object.keys(quoteDetails).length > 0) {
			let vol = crgItems.reduce((total, item) => Number(item.actual.total_volume_CBM) + total, 0)
			return vol.toFixed(3)
		}
		return 0
	}

	const getStructuerdItem = (cargoItems) => {
		return cargoItems.map((item, idx) => {
			let actualItem = item.actual
			return {
				"item_id": "",
				"item_description": actualItem.item_description,
				"no_of_pkgs": actualItem.no_of_pkgs,
				"dimension": {
					"kilogram": actualItem.kilogram,
					"length": actualItem.length,
					"width": actualItem.width,
					"height": actualItem.height,
				},
				"total_volume_CBM": actualItem.total_volume_CBM,
				"total_weight_KG": actualItem.total_weight_KG,
				"item_token": item.item_token
			}
		})
	}

	const getPostItems = (quoteItems = []) => {
		let cargoItems = quoteItems.length === 0 ? quoteDetails.quote_request_items : quoteItems

		let structuredItems = getStructuerdItem(cargoItems)
				
		let postData = {
			origin_country: place.origin.country,
			origin_country_short_name: place.origin.country_short_name,
			origin_city: place.origin.city,
			origin_latitude: place.origin.latitude,
			origin_longitude: place.origin.longitude,
			destination_country: place.destination.country,
			destination_country_short_name: place.destination.country_short_name,
			destination_city: place.destination.city,
			destination_latitude: place.destination.latitude,
			destination_longitude: place.destination.longitude,
			number_of_packages: getTotalPackages(cargoItems),
			volume: getTotalVolume(cargoItems),
			weight: getTotalWeight(cargoItems),
			items_goods: Number(country.items_goods),
			items: structuredItems,
			quote_token: id,
			referral_token: ""
		};

		return postData
	}

	const handleSubmit = () => {

		if(state.serviceValue === 0){
			toast.info("Please Select Atleast One Service")
			return false
		}
		
		let apiData = {
			insurance: state.insuranceValue,
			storage: state.storageValue,
			quote_token: id
		}

		let pckItmes = quoteDetails.quote_request_items
		let tempCopy = JSON.parse(JSON.stringify(pckItmes))
		let itemchg = tempCopy.length !== 0 && tempCopy.map(item => Number(item.add_ons_items.packing_charges))
		let itemfrt = tempCopy.length !== 0 && tempCopy.map(item => Number(item.add_ons_items.freight_charges))

		const totalChg = itemchg ? itemchg.reduce((total, item) => Number(item) + total, 0) : 0
		const totalFrt = itemfrt ? itemfrt.reduce((total, item) => Number(item) + total, 0) : 0

		let insurance = {}
		let storage = {}
		let packingAmt = totalChg + totalFrt
		let cargoItems = quoteDetails.quote_request_items.length ? quoteDetails.quote_request_items : []
		let deliverytype = `${state.serviceType.type + state.serviceType.typeWay}`
		let deliverytotal = state.serviceValue
		let subTotalInit = state.serviceType.subTotalInit
		let structuredItems = getStructuerdItem(cargoItems)
		let discountAmount = state.discount.discountAmount
		let freightCharge = state.serviceValue
		let completeAmount = 0    //  subTotal ? subTotal : deliverytotal
		let transistType = 10

		if(quoteDetails.quote_request_items.length){
			if('insurance' in quoteDetails.quote_request_items[0]){
				insurance = quoteDetails.quote_request_items[0].insurance
			}
			if('storage' in quoteDetails.quote_request_items[0]){
				storage = quoteDetails.quote_request_items[0].storage
			}
		}

		if(state.insuranceValue){
			insurance = {
				insurance_amount : state.insuranceValue
			}
		}
		if(state.storageValue){
			storage = {
				storage_amount : state.storageValue
			}
		}

		let postData = {
			packing: packingAmt,
			insurance: insurance,
			storage: storage,
			type: deliverytype,
			selected_freight: deliverytotal,
			subtotal_amount: subTotalInit,
			packingarray: structuredItems,
			stateItem: getPostItems(),
			discount_value: discountAmount,
			freightChargeOnly: freightCharge,
			completeAmount: completeAmount,
			transist_type: transistType
		};

		quoteService.proceedToFollowApi(apiData).then((response) => {
			if (response.status === true) {
				reduxDispatch(GetquoteAction(postData));
				reduxDispatch(GetTokenAction(id));
				history.push("/get-instruction")
			}
		}).catch((error) => {
			console.log(error);
		})
	}

	const reQuote = async (quoteItems = []) => {
		setLoader(true);
		let postData = getPostItems(quoteItems)

		const token = quoteDetails.user_detail.length 
						&& quoteDetails.user_detail[0].user_authtoken

		const courierData = await quoteService.getCourierQuote(postData, token)
		const landData = await quoteService.getTntQuote(postData, token)
		const airData = await quoteService.postQuote(postData, token)
		const seaData = await quoteService.postSeaQuote(postData, token)

		let finalData = {
			quote_request_couriers: [{ request_data: postData, final: {} }],
			quote_request_lands: [{ request_data: postData, final: {} }],
			quote_request_airs: [{ request_data: postData, final: {} }],
			quote_request_seas: [{ request_data: postData, final: {} }],
		}

		if(courierData.status === true){
			finalData.quote_request_couriers[0].final = courierData.data.final
		}
		if(landData.status === true){
			finalData.quote_request_lands[0].final = landData.data
		}
		if(airData.status === true){
			finalData.quote_request_airs[0].final = airData.data
		}
		if(seaData.status === true){
			finalData.quote_request_seas[0].final = seaData.data
		}
		let finalObject = {
			...state.quoteDetails,
			...finalData
		}
		dispatch({ type: QUOTE_DETAIL, payload: finalObject })
		setLoader(false)
		window.location.reload()
		
	}

	return (
		<QuoteDetailContext.Provider value={{ country, state, dispatch }}>
			<Loader isShow={loader} />
			<section className="pb-5 pt-0">
				<div className="header_title header_title_sp">
					<div className="container-fluid text-center">
						<h1>
							{/* {Object.keys(country).length > 0 && country.origin_city} */}
							{place.origin.city && place.origin.city}
							<span className="px-md-4 px-2">
								<img
									src="/assets/images/arrow_right.svg"
									alt="Not found"
								/>
							</span>
							{/* {Object.keys(country).length > 0 && country.destination_city} */}
							{place.destination.city && place.destination.city}
						</h1>
						<p>
							Pkgs {getTotalPackages()} {" "}| Weight{" "} {getTotalWeight()} kg | Volume{" "} {getTotalVolume()}
						</p>

						<div className="calculator container mt-5 mb-3 text-start" style={{ position: "relative", zIndex: 2 }}
						>
							<div className="px-5 newOriginSelect">
								<label>From</label>
								<br />
								{/* <span>{Object.keys(country).length > 0 && country.origin_city}</span> */}
								<AutoComplete
									getData={(e) => setPlace({ ...place, origin: e })}
									prefillState={place.origin}
									name="Origin"
									className="calculate_input"
									key="origin-data"
								/>

							</div>
							<div className="px-5 newOriginSelect">
								<label>To</label>
								<br />
								{/* <span>{Object.keys(country).length > 0 && country.destination_city}</span> */}
								<AutoComplete
									getData={(e) => setPlace({ ...place, destination: e })}
									prefillState={place.destination}
									placeholder="Destination"
									name="destination"
									className="calculate_input"
									key="dest-data"
								/>
							</div>
							<div className="col px-3">
								<label>No. of Pkgs</label>
								<br />
								<span>{getTotalPackages()}</span>
							</div>
							<div className="col px-3">
								<label>Weight</label>
								<br />
								<span>{getTotalWeight()}</span>
							</div>
							<div className="col px-3 ">
								<label>Volume</label>
								<br />
								<span>{getTotalVolume()}</span>
								<br />
							</div>

							{<div className="col py-0 pl-3 pe-0">
								<button
									className="themebutton px-4 ms-auto"
									onClick={() => {
										reQuote();
									}}
								>
									Re-quote
								</button>
							</div>}

						</div>

						<div className="container text-end  col py-0 pl-3 pe-0 " style={{ paddingRight: 160 }}>
							<p
								className=" pointer mb-5 fw-9 volumeDesign "
								// onClick={}
							>
								<button className="themebutton px-4 ms-auto" onClick={()=>setShowCargoItems(true)} > Edit Cargo Details</button>
							</p>
						</div>

						<img
							src="/assets/images/country.jpg"
							className="bannerimg"
							alt="Not found"
						/>
					</div>
				</div>

				{showCargoItems && <Cargo showCargoItems={showCargoItems} handleCargo={setShowCargoItems} reQuote={reQuote} />}

				{/* Services / Showing Freight ---Start--- */}

				<div className="container-fluid mt-5">
					<div className="row">
						<div className="col-md-8">
							<Freights resetPckInsStorage={resetPckInsStorage} />
							<AddOn />
						</div>

						<div className="col-md-4">
							<OrderSummary />
						</div>
					</div>

					<Row>
						<Col md={4}>
							<button
								className="btn themebutton mt-3 w-sm"
								type="button"
								onClick={() => handleSubmit()}

							>
								Proceed
							</button>
						</Col>
					</Row>
				</div>

				{/* Services / Showing Freight ---End--- */}
			</section>
		</QuoteDetailContext.Provider>
	)
}

export { QuoteDetailContext }
