import React from "react";

export default function Loader({ isShow }) {
  return (
    <>
      {isShow && (
        <div className="loader_lst">
          <img
            src="/assets/images/JOP.gif"
            className="bannerimg"
            alt="Not found"
          />
        </div>
      )}
    </>
  );
}
