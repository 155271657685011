import React, { useEffect, useState } from "react";
// import { Modal } from "react-bootstrap-v5";
// import { MdEmail, MdPhone, MdLocationOn } from "react-icons/md";
import { Link } from "react-router-dom";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import DatePicker from "react-datepicker";

const StoreList = [
  { value: "All Stores", label: "All Stores" },
  { value: 1, label: "Stores 1" },
  { value: 1, label: "Stores 2" },
  { value: 1, label: "Stores 3  " },
];

export default function AllBookingsManagement() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  // const [show, setShow] = useState(false);
  // const [Editshow, setEditshow] = useState(false);
  // const [DeleteShow, setDeleteShow] = useState(false)

  // const OpenDeleteHandle = () => setDeleteShow(true)
  // const CloseDeletehandle = () => setDeleteShow(false)

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  // const editHandle = () => setEditshow(true)
  // const CloseeditHandle = () => setEditshow(false)

  return (
    <>
      <section className="pb-5 pt-0">
        <div className="header_title">
          <div className="container-fluid">
            <div className="d-flex justify-content-between">
              <h1>All Bookings</h1>
              {/* <button type="button" className="themebutton" >Add Stores</button> */}
            </div>
            <img
              alt="Not found"
              src="assets/images/login_bg.jpg"
              className="bannerimg"
            />
          </div>
        </div>
        <div className="container-fluid mt-5">
          <div className="white_box h-100 pb-1">
            <div className="row mb-3">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-2">
                    <p>Period</p>
                  </div>
                  <div className="col-md-5">
                    <label>From</label>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                  </div>

                  <div className="col-md-5">
                    <label>To</label>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-2">
                    <p>Quaternaly</p>
                  </div>
                  <div className="col-md-5">
                    <label>Select Quaterly</label>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      dateFormat="yyyy, QQQ"
                      showQuarterYearPicker
                    />
                  </div>
                  <div className="col-md-5">
                    <label>Select Month </label>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-2">
                    <label>Select Stores</label>
                  </div>
                  <div className="col-md-4">
                    <ReactMultiSelectCheckboxes
                      options={StoreList}
                      className="storeSelect form-control"
                      placeholder="Search Stores"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-md-3">
                                <input type="text" placeholder="Search by Id, Store Name" className="form-control" />
                            </div> */}

            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Booking ID</th>
                    <th>Actual Amount</th>
                    <th>Agreed Amount</th>
                    <th>Total Incentive (20%)</th>
                    <th>Customer Incentive (10%)</th>
                    <th>Retailer Incentive (10%)</th>
                    <th>Incentive Payout</th>
                    <th>Value Of Goods</th>
                    <th>Retailer Invoice No.</th>
                    <th>Payment Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>123455</td>
                    <td>2500 AED</td>
                    <td>2500 AED</td>
                    <td>500 AED</td>
                    <td>250 AED</td>
                    <td>250 AED</td>
                    <td>250 AED</td>
                    <td>5000 AED</td>
                    <td>abc123</td>
                    <td>Delivered</td>

                  </tr>

                  <tr>
                    <td>2</td>
                    <td>823782</td>
                    <td>6500 AED</td>
                    <td>5900 AED</td>
                    <td>1180 AED</td>
                    <td>590 AED</td>
                    <td>590 AED</td>
                    <td>590 AED</td>
                    <td>6000 AED</td>
                    <td>xyz000</td>
                    <td>Arrived at Dest Apt</td>
                  </tr>

                  <tr>
                    <td>3</td>
                    <td>298399</td>
                    <td>5000 AED</td>
                    <td>4000 AED</td>
                    <td>800 AED</td>
                    <td>400 AED</td>
                    <td>400 AED</td>
                    <td>0 AED</td>
                    <td>4500 AED</td>
                    <td>mno823</td>
                    <td>Departed</td>
                  </tr>
                </tbody>

                <tfoot>
                  <tr>
                    <td>
                      <b>Total</b>
                    </td>
                    <td colspan="2"></td>
                    <td>
                      <b>12400 AED</b>
                    </td>
                    <td>
                      <b>2480 AED</b>
                    </td>
                    <td>
                      {" "}
                      <b>1240 AED</b>
                    </td>
                    <td>
                      {" "}
                      <b>1240 AED</b>
                    </td>
                    <td>
                      {" "}
                      <b>840 AED</b>
                    </td>
                    <td>
                      {" "}
                      <b>15500 AED</b>
                    </td>
                    <td colspan="2"></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
