import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import helperFunction from "./helperFunction";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { toast } from "react-toastify";
import quoteService from "../../services/quoteService";

export default function   TableService({
  totaldeliveryrate,
  errormessage,
  onDeliveryHandler,
  typofservice,
  VolumestateItems
}) {

  const [serviceData, setServiceData] = useState()

  const Volumestate = useSelector((state) => state.volumeDetail.volumeData);

  const Volumeseastate = useSelector(
    (state) => state.VolumeSeaDetail.VolumeseaData
  );

  const Volumecourierstate = useSelector(
    (state) => state.VolumeCourierDetail.VolumeCourierdata
  );

  const Volumelandstate = useSelector(
    (state) => state.VolumeLandDetail.VolumeLanddata
  );

  useEffect(() => {
    let result = helperFunction.getService(Volumestate, Volumeseastate, Volumecourierstate, Volumelandstate)
    // let serviceAmtofSelected = helperFunction.getFreightamount(typofservice, )
    setServiceData(result)
  }, [Volumestate, Volumeseastate, Volumecourierstate, Volumelandstate])

  const doorRequest = async (service_type) => {
    let params = {quote_token : VolumestateItems.quote_token,service:service_type}
    await quoteService.addDoorRequest(params).then((res)=> {
      if(res.status){
        toast.success("Your Rate request for Door Delivery has been sent to the World2Door Team. Someone from the team will get in touch with you very soon")
      }else{
        toast.error(res.message)
      }
    }).catch((err)=> {
      toast.error(err.message)
    })
  }


  return (
    <div className="white_box">
      {!totaldeliveryrate && errormessage && (
        <div className="alert-danger p-3 text-center">{errormessage}</div>
      )}
      <div className="table-responsive" style={{ overflow: "inherit" }}>
        <table className="table">
          <thead>
            <tr>
              {/* <th style={{ width: 50 }}></th> */}
              <th>Service</th>
              <th>
                Port Delivery
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="tooltip-disabled">
                      <b>Port Delivery</b>
                      <br />
                      means the shipment will be delivered only upto the
                      Destination Airport/Seaport. The customs clearance and
                      delivery at the destination is not included in the scope
                      of work. <br />
                      <br />
                      <b> Exclusions: </b><br />
                      Destination Airlines/Shipping Lines
                      Charges<br /> Destination Customs Clearance<br /> Destination
                      Transportation from the Port to the destination<br /> Customs
                      Duty /Taxes / Port Charges
                    </Tooltip>
                  }
                >
                  <span className="d-inline-block">
                    <BsFillInfoCircleFill className="fs-4 pb-2" />
                  </span>
                </OverlayTrigger>
              </th>
              <th>
                Door Delivery
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="tooltip-disabled">
                      <b>DOOR Delivery</b>
                      <br />
                      Door Delivery means the shipment will be delivered upto the Final Delivery Address i.e. Customer’s Residence. The destination customs clearance and delivery at the destination is included in the scope of work.
                      <br />
                      <br />
                      <b> Exclusions: </b><br />
                      Customs Duty /Taxes / Port Charges
                    </Tooltip>
                  }
                >
                  <span className="d-inline-block">
                    <BsFillInfoCircleFill className="fs-4 pb-2" />
                  </span>
                </OverlayTrigger>

              </th>
              {/* { (Volumestate.data.consolidation_delivery || Volumeseastate.data.consolidation_delivery || Volumecourierstate.data.consolidation_delivery) &&
                  <th>Consolidation <br/> Delivery</th>
                } */}
            </tr>
          </thead>

          <tbody>
            {(serviceData && (serviceData.courier_door_delivery !== "Not Applicable" || serviceData.port_door_delivery !== "Not Applicable" || serviceData.courier_consolidation_delivery !== "Not Applicable")) && (
              <tr className="border-bottom">
                <td>
                  <div className="d-flex align-items-center">
                    <div className="service_icon">
                      <img
                        src="/assets/images/Flat.svg"
                        alt="Not found"
                      />
                    </div>
                    <div className="flex-grow-1 ps-3">
                      <h6 className="mb-1">
                        <b className="text-success">COURIER</b> Freight
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              <b>COURIER MODE:</b>
                              <br />
                              This service is by Air. This mode is suited for small size shipments. The best suited services of one of the courier companies like Fedex, UPS, DHL , TNT etc will be used for shipping.
                              <br />
                            </Tooltip>
                          }
                        >
                          <span className="d-inline-block">
                            <BsFillInfoCircleFill className="fs-4 pb-2" />
                          </span>
                        </OverlayTrigger>
                      </h6>

                      {/* <small>
                              Discharge Apple valley, CA, USA
                            </small> */}
                    </div>
                  </div>
                </td>
                <td>


                  {serviceData.courier_port_delivery !== "Not Applicable" ? (
                    <>
                      <input
                        type="radio"
                        id="seven-option"
                        name="selector"
                        value={
                          serviceData.courier_port_delivery
                        }
                        onClick={(e) =>
                          onDeliveryHandler(
                            e,
                            "PORTCOURIER",
                            Volumecourierstate.all_data?.final?.port_delivery?.port_delivery_tt
                          )
                        }
                        checked={typofservice === "PORTCOURIER" ? true : false}
                      />
                      {/* <div className="check"></div> */}
                      <label for="seven-option" className="d-block">
                        <h6 className="text-success mb-1 ps-4">
                          <b>
                            AED &nbsp;
                            {
                              serviceData.courier_port_delivery
                            }
                          </b>
                          <a
                            className="custom-hover-dropdown"
                            href="javascript:void(0)"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="#198754"
                              className="ms-1 bi bi-caret-down-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                            <ul className="list-group shadow ">
                              {/* <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li>
                              <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li>
                              <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li>
                              <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li> */}
                            </ul>
                          </a>
                        </h6>
                      </label>

                      <p>Appro. Transit Time {Volumecourierstate.all_data?.final?.port_delivery?.port_delivery_tt} Days</p>
                    </>
                  ) : (
                    ""
                  )}
                </td>

                <td>
                  {serviceData.courier_door_delivery !== "Not Applicable" ? (
                    <>
                      <input
                        type="radio"
                        id="eight-option"
                        name="selector"
                        value={
                          serviceData.courier_door_delivery
                        }
                        onClick={(e) =>
                          onDeliveryHandler(
                            e,
                            "DOORCOURIER",
                            Volumecourierstate.all_data?.final?.door_delivery?.door_delivery_tt
                          )
                        }
                        checked={typofservice === "DOORCOURIER" ? true : false}
                      />
                      {/* <div className="check"></div> */}
                      <label for="eight-option" className="d-block">
                        <h6 className="text-success mb-1 ps-4">
                          <b>
                            AED &nbsp;
                            {
                              serviceData.courier_door_delivery
                            }
                          </b>
                          <a
                            className="custom-hover-dropdown"
                            href="javascript:void(0)"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="#198754"
                              className="ms-1 bi bi-caret-down-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                            <ul className="list-group shadow ">
                              {/* <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li>
                              <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li>
                              <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li>
                              <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li> */}
                            </ul>
                          </a>
                        </h6>
                      </label>

                      <p>Direct Appro. T/T {Volumecourierstate.all_data?.final?.door_delivery?.door_delivery_tt}  Days</p>
                    </>
                  ) : (
                    ""
                  )}

                  {serviceData.courier_consolidation_delivery !== "Not Applicable" && (
                    <div className="consolidation-relative">
                      <input
                        type="radio"
                        id="nine-option"
                        name="selector"
                        value={
                          serviceData.courier_consolidation_delivery
                        }
                        onClick={(e) =>
                          onDeliveryHandler(
                            e,
                            "CONSOLIDATIONCOURIER",
                            Volumecourierstate.all_data?.final?.consolidation_delivery?.consolidation_delivery_tt
                          )
                        }
                        checked={typofservice === "CONSOLIDATIONCOURIER" ? true : false}
                      />
                      {/* <div className="check"></div> */}
                      <label for="nine-option" className="d-block">
                        <h6 className="text-success mb-1 ps-4">
                          <b>
                            AED &nbsp;
                            {
                              serviceData.courier_consolidation_delivery
                            }
                          </b>
                          <a
                            className="custom-hover-dropdown"
                            href="javascript:void(0)"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="#198754"
                              className="ms-1 bi bi-caret-down-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                            <ul className="list-group shadow ">
                              {/* <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li>
                              <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li>
                              <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li>
                              <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li> */}
                            </ul>
                          </a>
                        </h6>
                      </label>

                      <p>Consolidation Appro. T/T {Volumecourierstate.all_data?.final?.consolidation_delivery?.consolidation_delivery_tt} Days</p>
                    </div>
                  )}
                </td>
              </tr>
            )}

            {(serviceData && (serviceData.air_port_delivery !== "Not Applicable" || serviceData.air_door_delivery !== "Not Applicable" || serviceData.air_consolidation_delivery !== "Not Applicable")) && (
              <tr className="border-bottom">
                <td>
                  <div className="d-flex align-items-center">
                    <div className="service_icon">
                      <img
                        src="/assets/images/plane.svg"
                        alt="Not found"
                      />
                    </div>
                    <div className="flex-grow-1 ps-3">

                      <h6 className="mb-1">
                        <b className="text-success">AIR</b> Freight
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              <b>AIR FREIGHT MODE:</b>
                              <br />
                             This service is by Air. This mode is suited for larger shipment which needs faster service. The airlines used for this service are Emirates, British Airways , Air India etc. They will provide only airport to airport service.  The Origin and Destination services will be handled by our agents.
                              <br />
                            </Tooltip>
                          }
                        >
                          <span className="d-inline-block">
                            <BsFillInfoCircleFill className="fs-4 pb-2" />
                          </span>
                        </OverlayTrigger>
                      </h6>

                      {/* <small>Discharge Apple valley, CA, USA</small> */}
                    </div>
                  </div>
                </td>
                <td>
                  {serviceData.air_port_delivery !== "Not Applicable" ? (
                    <>
                      <input
                        type="radio"
                        id="three-option"
                        name="selector"
                        value={Math.round(
                          serviceData.air_port_delivery
                        )}
                        // onClick={(e)=>setDeliverytotal(e.target.value)}
                        onClick={(e) =>
                          onDeliveryHandler(
                            e,
                            "PORTAIR",
                            Volumestate.all_data?.final?.port_delivery?.port_delivery_tt
                          )
                        }
                        checked={typofservice === "PORTAIR" ? true : false}
                      />

                      {/* <div className="check"></div> */}
                      <label for="three-option" className="d-block">
                        <h6 className="text-success mb-1 ps-4">
                          <b>
                            AED &nbsp;
                            {Math.round(
                              serviceData.air_port_delivery
                            )}
                          </b>
                          <a
                            className="custom-hover-dropdown"
                            href="javascript:void(0)"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="#198754"
                              className="ms-1 bi bi-caret-down-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                            <ul className="list-group shadow ">
                              {/* <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li>
                              <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li>
                              <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li>
                              <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li> */}
                            </ul>
                          </a>
                        </h6>
                      </label>
                      <p className="mb-0 lh-sm">Airport- {Volumestate.all_data?.near_city?.destinationResult?.city_name}</p>
                      <p>Appro. Transit Time {Volumestate.all_data?.final?.port_delivery?.port_delivery_tt} Days</p>
                    </>
                  ) : 
                  (
                    ""
                  )}
                </td>
                <td>
                  {serviceData.air_door_delivery !== "Not Applicable" ? (
                    <>
                      <input
                        type="radio"
                        id="four-option"
                        name="selector"
                        value={Math.round(
                          serviceData.air_door_delivery
                        )}
                        onClick={(e) =>
                          onDeliveryHandler(
                            e,
                            "DOORAIR",
                            Volumestate.all_data?.final?.door_delivery?.door_delivery_tt
                          )
                        }
                        checked={typofservice === "DOORAIR" ? true : false}
                      />
                      {/* <div className="check"></div> */}
                      <label for="four-option" className="d-block">
                        <h6 className="text-success mb-1 ps-4">
                          <b>
                            AED &nbsp;
                            {Math.round(
                              serviceData.air_door_delivery
                            )}
                          </b>
                          <a
                            className="custom-hover-dropdown"
                            href="javascript:void(0)"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="#198754"
                              className="ms-1 bi bi-caret-down-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                            <ul className="list-group shadow ">
                              {/* <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li>
                              <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li>
                              <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li>
                              <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li> */}
                            </ul>
                          </a>
                        </h6>
                      </label>
                      <p>Direct - Appro. T/T {Volumestate?.all_data?.final?.door_delivery?.door_delivery_tt} Days</p>
                    </>
                  ) : 
                  serviceData.air_consolidation_delivery === "Not Applicable" &&
                  Volumestate.all_data.country.destinationResult.air_on_request
                  ? 
                  <button className="nav_button" style={{padding:"5px 25px",margin:"0",fontSize:"15px",border:"0"}}
                  onClick={() => doorRequest("Air")}>
                    On Request
                  </button>
                  :(""
                  )}
                  {serviceData.air_consolidation_delivery !== "Not Applicable" && (
                    <div className="consolidation-relative">
                      <input
                        type="radio"
                        id="nine-option"
                        name="selector"
                        value={
                          serviceData.air_consolidation_delivery
                        }
                        onClick={(e) =>
                          onDeliveryHandler(
                            e,
                            "CONSOLIDATIONAIR",
                            Volumestate.all_data?.final?.consolidation_delivery?.consolidation_door_delivery_tt
                          )
                        }
                        checked={typofservice === "CONSOLIDATIONAIR" ? true : false}
                      />
                      {/* <div className="check"></div> */}
                      <label for="nine-option" className="d-block">
                        <h6 className="text-success mb-1 ps-4">
                          <b>
                            AED &nbsp;
                            {
                              serviceData.air_consolidation_delivery
                            }
                          </b>
                          <a
                            className="custom-hover-dropdown"
                            href="javascript:void(0)"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="#198754"
                              className="ms-1 bi bi-caret-down-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                            <ul className="list-group shadow ">
                              {/* <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li>
                              <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li>
                              <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li>
                              <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li> */}
                            </ul>
                          </a>
                        </h6>
                      </label>
                      <p>Consolidation Appro. T/T {Volumestate.all_data?.final?.consolidation_delivery?.consolidation_door_delivery_tt} Days</p>
                    </div>
                  )}
                </td>
              </tr>
            )}

            {(serviceData && (serviceData.sea_port_delivery !== "Not Applicable" || serviceData.sea_door_delivery !== "Not Applicable" || serviceData.sea_consolidation_delivery !== "Not Applicable")) && (
              <tr className="border-bottom">
                <td>
                  <div className="d-flex align-items-center">
                    <div className="service_icon">
                      <img
                        src="/assets/images/shipment.svg"
                        alt="Not found"
                      />
                    </div>
                    <div className="flex-grow-1 ps-3">
                      <h6 className="mb-1">
                        <b className="text-success">SEA</b> Freight
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              <b>SEA FREIGHT MODE:</b>
                              <br />
                              This service is by Sea. This mode is usually used when the quantity, size and volume of the goods is large. In this service we can move large shipments in 3 ways. In LCL ( Less Contr Load) and/or 20ft contr and/or 40ft contr.
                              <br />
                            </Tooltip>
                          }
                        >
                          <span className="d-inline-block">
                            <BsFillInfoCircleFill className="fs-4 pb-2" />
                          </span>
                        </OverlayTrigger>
                      </h6>
                      {/* <small>Discharge Apple valley, CA, USA</small> */}
                    </div>
                  </div>
                </td>
                <td>
                  {serviceData.sea_port_delivery !== "Not Applicable" ? (
                    <>
                      <input
                        type="radio"
                        id="five-option"
                        name="selector"
                        value={Math.round(
                          serviceData.sea_port_delivery
                        )}
                        onClick={(e) =>
                          onDeliveryHandler(
                            e,
                            "PORTSEA",
                            Volumeseastate.all_data?.final?.port_delivery?.port_delivery_tt
                          )
                        }
                        checked={typofservice === "PORTSEA" ? true : false}
                      />
                      {/* <div className="check"></div> */}
                      <label for="five-option" className="d-block">
                        <h6 className="text-success mb-1 ps-4">
                          <b>
                            AED &nbsp;
                            {Math.round(
                              serviceData.sea_port_delivery
                            )}
                          </b>
                          <a
                            className="custom-hover-dropdown"
                            href="javascript:void(0)"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="#198754"
                              className="ms-1 bi bi-caret-down-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                            <ul className="list-group shadow ">
                              {/* <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li>
                              <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li>
                              <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li>
                              <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li> */}
                            </ul>
                          </a>
                        </h6>
                      </label>
                      <p className="mb-0 lh-sm">Port- {Volumeseastate.all_data?.near_city?.destinationResult?.city_name}</p>
                      <p>Appro. Transit Time {Volumeseastate.all_data?.final?.port_delivery?.port_delivery_tt} Days</p>
                    </>
                  ) : (
                    ""
                  )}
                </td>
                <td>
                  {serviceData.sea_door_delivery !== "Not Applicable" ? (
                    <>
                      <input
                        type="radio"
                        id="six-option"
                        name="selector"
                        value={Math.round(
                          serviceData.sea_door_delivery
                        )}
                        onClick={(e) =>
                          onDeliveryHandler(
                            e,
                            "DOORSEA",
                            Volumeseastate.all_data?.final?.door_delivery?.door_delivery_tt
                          )
                        }
                        checked={typofservice === "DOORSEA" ? true : false}
                      />
                      {/* <div className="check"></div> */}
                      <label for="six-option" className="d-block">
                        <h6 className="text-success mb-1 ps-4">
                          <b>
                            AED &nbsp;
                            {Math.round(
                              serviceData.sea_door_delivery
                            )}
                          </b>
                          <a
                            className="custom-hover-dropdown"
                            href="javascript:void(0)"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="#198754"
                              className="ms-1 bi bi-caret-down-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                            <ul className="list-group shadow ">
                              {/* <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li>
                              <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li>
                              <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li>
                              <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li> */}
                            </ul>
                          </a>
                        </h6>
                      </label>

                      <p>Direct - Appro. T/T {Volumeseastate.all_data?.final?.door_delivery?.door_delivery_tt} Days</p>
                    </>
                  ) :
                  serviceData.sea_consolidation_delivery === "Not Applicable" &&
                  Volumestate.all_data.country.destinationResult.sea_on_request
                  ? 
                  <button className="nav_button" style={{padding:"5px 25px",margin:"0",fontSize:"15px",border:"0"}}
                  onClick={() => doorRequest("Sea")}>
                   On Request
                  </button>
                  :
                  (
                    ""
                  )}

                  {serviceData.sea_consolidation_delivery !== "Not Applicable" && (
                    <div className="consolidation-relative">
                      <input
                        type="radio"
                        id="ten-option"
                        name="selector"
                        value={
                          serviceData.sea_consolidation_delivery
                        }
                        onClick={(e) =>
                          onDeliveryHandler(
                            e,
                            "CONSOLIDATIONSEA",
                            Volumeseastate.all_data?.final?.consolidation_delivery?.consolidation_door_delivery_tt
                          )
                        }
                        checked={typofservice === "CONSOLIDATIONSEA" ? true : false}
                      />
                      {/* <div className="check"></div> */}
                      <label for="ten-option" className="d-block">
                        <h6 className="text-success mb-1 ps-4">
                          <b>
                            AED &nbsp;
                            {
                              serviceData.sea_consolidation_delivery
                            }
                          </b>
                          <a
                            className="custom-hover-dropdown"
                            href="javascript:void(0)"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="#198754"
                              className="ms-1 bi bi-caret-down-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                            <ul className="list-group shadow ">
                              {/* <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li>
                              <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li>
                              <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li>
                              <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li> */}
                            </ul>
                          </a>
                        </h6>
                      </label>

                      <p>Consolidation Appro. T/T {Volumeseastate.all_data?.final?.consolidation_delivery?.consolidation_door_delivery_tt} Days</p>
                    </div>
                  )}
                </td>
              </tr>
            )}

            {(serviceData && (serviceData.land_door_delivery !== "Not Applicable" || serviceData.land_port_delivery !== "Not Applicable")) && (
              <tr className="border-bottom">
                <td>
                  <div className="d-flex align-items-center">
                    <div className="service_icon">
                      <img
                        src="/assets/images/delivery-truck.svg"
                        alt="Not found"
                      />
                    </div>
                    <div className="flex-grow-1 ps-3">
                      <h6 className="mb-1">
                        <b className="text-success">Land</b> Freight
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              <b>LAND FREIGHT MODE:</b>
                              <br />
                              This service is by Land. This service is applicable for only those countries which are connected by Road. This mode suits shipments which are large in size.
                              <br />
                            </Tooltip>
                          }
                        >
                          <span className="d-inline-block">
                            <BsFillInfoCircleFill className="fs-4 pb-2" />
                          </span>
                        </OverlayTrigger>
                      </h6>
                      {/* <small>
                              Discharge Apple valley, CA, USA
                            </small> */}
                    </div>
                  </div>
                </td>

                <td>
                  {serviceData.land_port_delivery !== "Not Applicable" ? (
                    <>
                      <input
                        type="radio"
                        id="nine-option"
                        name="selector"
                        value={
                          serviceData.land_port_delivery
                        }
                        onClick={(e) =>
                          onDeliveryHandler(
                            e,
                            "PORTLAND",
                            Volumelandstate.all_data?.final?.port_delivery?.port_delivery_tt
                          )
                        }
                        checked={typofservice === "PORTLAND" ? true : false}
                      />
                      {/* <div className="check"></div> */}
                      <label for="nine-option" className="d-block">
                        <h6 className="text-success mb-1 ps-4">
                          <b>
                            AED &nbsp;
                            {
                              serviceData.land_port_delivery
                            }
                          </b>
                          <a
                            className="custom-hover-dropdown"
                            href="javascript:void(0)"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="#198754"
                              className="ms-1 bi bi-caret-down-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                            <ul className="list-group shadow ">
                              {/* <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li>
                              <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li>
                              <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li>
                              <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li> */}
                            </ul>
                          </a>
                        </h6>
                      </label>

                      <p>Appro. Transit Time {Volumelandstate.all_data?.final?.port_delivery?.port_delivery_tt} Days</p>
                    </>
                  ) : (
                    ""
                  )}
                </td>

                <td>

                {serviceData.land_consolidation_delivery !== "Not Applicable" && (
                    <div className="consolidation-relative">
                      <input
                        type="radio"
                        id="ten-option"
                        name="selector"
                        value={
                          serviceData.land_consolidation_delivery
                        }
                        onClick={(e) =>
                          onDeliveryHandler(
                            e,
                            "CONSOLIDATIONLAND",
                            Volumelandstate.all_data?.final?.consolidation_delivery?.consolidation_door_delivery_tt
                          )
                        }
                        checked={typofservice === "CONSOLIDATIONLAND" ? true : false}
                      />
                      {/* <div className="check"></div> */}
                      <label for="ten-option" className="d-block">
                        <h6 className="text-success mb-1 ps-4">
                          <b>
                            AED &nbsp;
                            {
                              serviceData.land_consolidation_delivery
                            }
                          </b>
                          <a
                            className="custom-hover-dropdown"
                            href="javascript:void(0)"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="#198754"
                              className="ms-1 bi bi-caret-down-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                            <ul className="list-group shadow ">
                              {/* <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li>
                              <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li>
                              <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li>
                              <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li> */}
                            </ul>
                          </a>
                        </h6>
                      </label>

                      <p>Express Appro. Transit Time {Volumelandstate.all_data?.final?.consolidation_delivery?.consolidation_door_delivery_tt} Days</p>
                    </div>
                  )}


{serviceData.land_door_delivery !== "Not Applicable" ? (
                    <>
                      <input
                        type="radio"
                        id="nine-option"
                        name="selector"
                        value={
                          serviceData.land_door_delivery
                        }
                        onClick={(e) =>
                          onDeliveryHandler(
                            e,
                            "DOORLAND",
                            Volumelandstate.all_data?.final?.door_delivery?.door_delivery_tt
                          )
                        }
                        checked={typofservice === "DOORLAND" ? true : false}
                      />
                      {/* <div className="check"></div> */}
                      <label for="nine-option" className="d-block">
                        <h6 className="text-success mb-1 ps-4">
                          <b>
                            AED &nbsp;
                            {
                              serviceData.land_door_delivery
                            }
                          </b>
                          <a
                            className="custom-hover-dropdown"
                            href="javascript:void(0)"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="#198754"
                              className="ms-1 bi bi-caret-down-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                            <ul className="list-group shadow ">
                              {/* <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li>
                              <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li>
                              <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li>
                              <li className="list-group-item">
                                <small className="text-muted">
                                  0.0005CBM = 1.000 KG
                                </small>
                              </li> */}
                            </ul>
                          </a>
                        </h6>
                      </label>

                      <p>Consolidation Appro. T/T {Volumelandstate.all_data?.final?.door_delivery?.door_delivery_tt} Days</p>
                    </>
                  ) :  Volumestate.all_data.country.destinationResult.land_on_request
                  ? 
                  <button className="nav_button" style={{padding:"5px 25px",margin:"0",fontSize:"15px",border:"0"}} 
                  onClick={() => doorRequest("Land")}>
                    Request
                  </button>
                  :
                  (
                    ""
                  )}
                </td>
              </tr>
            )}
          </tbody>

        </table>
      </div>
    </div>
  );
}
