import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { storageService } from "../../services";
const StoreList = (props) => {
    const [list, setList] = useState([]);


    const state = useSelector((state) => state.retailerDetails);

    useEffect(()=>{
        getStore();
    },[])

    const getStore = () => {
        let getData = {
          id: state.user.data._id,
        };
    
        storageService
          .getStores(getData)
          .then((res) => {
            setList(res.data);
          })
          .catch((error) => {
            console.log(error);
          });
      };

	return (
		<table className="table table-bordered">
			<thead>
				<tr>
					<th>Sr.No.</th>
					<th>Store Name</th>
					<th>Store Email</th>
					<th>Contact person</th>
					<th>Phone Number</th>
					<th>Address</th>
					<th>Status</th>				
				</tr>
			</thead>
			<tbody>
				{list &&
					list.length >= 0 &&
					list.map((item, keys) => {
					
						return (
							<tr>
								<td>{keys + 1}</td>
								<td>{item.store_name}</td>
								<td>{item.store_email}</td>
								<td>{item.contact_person}</td>
								<td>{item.phone_number}</td>
								<td>{item.address}</td>
								<td>{item.store_status}</td>				
							</tr>
						);
					})}
			</tbody>
		</table>
	);
};

export default StoreList;
