import React, { useContext } from 'react'
import {BookingDetailContext} from '../BookingDetails'

export default function CargoDetail() {
    const {state, disptach} = useContext(BookingDetailContext);
    if(!state) {
        return 'Loading...'
    } else {
        const info = state.bookingDetails.booking_item
        const goods = state.bookingDetails.shipment_details.value_of_goods
    
        return (
            <div className='customer-back'>
                <h5 className="secHeading">Booked Cargo Detail</h5>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td className="py-1">Pkgs - </td>
                            <td className="py-1 text-end"> {info.package} </td>
                        </tr>
                        <tr>
                            <td className="py-1">Weight - </td>
                            <td className="py-1 text-end">{info.kilogram}</td>
                        </tr>
                        <tr>
                            <td className="py-1">Volume - </td>
                            <td className="py-1 text-end">{info.total_volume_CBM}</td>
                        </tr>
                        <tr>
                            <td className="py-1">Value Of Goods - </td>
                            <td className="py-1 text-end">{goods} AED</td>
                        </tr>
                        
                    </tbody>
                </table>
            </div>
        )
    }
}
