export const LOGIN = 'LOGIN';
export const TEMPLOGIN = 'TEMPLOGIN';
export const RETAILERLOGIN = 'RETAILERLOGIN';
export const VOLUMECAL = 'VOLUMECAL';
export const VOLUMESEA = 'VOLUMESEA';
export const VOLUMECOURIER = 'VOLUMECOURIER';
export const VOLUMELAND = 'VOLUMELAND';
export const GETQUOTE = 'GETQUOTE';
export const GETTOKEN = 'GETTOKEN';
export const PROCEEDTOJOB = "PROCEEDTOJOB";
export const GETFREIGHT = "GETFREIGHT";
export const ITEM_GOODS = "ITEM_GOODS";
export const BOOKING_DETAIL = "BOOKING_DETAIL";
export const TRACKING_DETAIL = "TRACKING_DETAIL";
export const QUOTE_DETAIL = "QUOTE_DETAIL";
export const SERVICE_TYPE = "SERVICE_TYPE";
export const SERVICE_VALUE = "SERVICE_VALUE";
export const INSURANCE = "INSURANCE";
export const STORAGE = "STORAGE";
export const DISCOUNT = "DISCOUNT";