import React, { useState, useRef } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
// import { Alert } from 'react-bootstrap-v5';
import { authServices, quoteService } from "../../services";
import Verification from "./Verification";
import SimpleReactValidator from "simple-react-validator";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
// import 'react-toastify/dist/ReactToastify.css';
import { style, toast } from "react-toastify";
import { validateEmail } from "../../utils/helper";
import {Country,State,City} from "country-state-city";
import helperFunction from "../../Components/Quotation/helperFunction";
import Select from "react-select";

export default function Login() {
  const [, forceUpdate] = useState("");
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );

  const history = useHistory();

  const [successmsg] = useState();
  const [codeNumber, setCodeNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [confirmpwd, setConfirmpwd] = useState("");
  const [country, setCountry] = useState(Country.getAllCountries());
  const [countryCode, setCountryCode] = useState("");
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);

  const [Register, setRegister] = useState({
    user_full_name: "",
    user_email: "",
    user_password: "",
    address: "",
    // country: "",
    // state: "",
    // city: "",
    // postal_code: "",
    user_country: "",
    user_gender: "",
    user_firebase_token: "abcdefghijklm",
    user_device_token: "WEB",
    user_device_type: "WEB",
    is_terms_conditions: false,
    is_privacy_policy: false,
  });

  const Handlechange = (event) => {
    if (event.target.name === "is_terms_conditions") {
      setRegister({ ...Register, is_terms_conditions: event.target.checked });
    } else if (event.target.name === "is_privacy_policy") {
      setRegister({ ...Register, is_privacy_policy: event.target.checked });
    } else {
      setRegister({ ...Register, [event.target.name]: event.target.value });
    }
  };
  
  const checkEmail = (email) => {
    validateEmail(email) &&
      quoteService
        .checkEmailApi({ user_email: email })
        .then((result) => {
          if (result.status === true) {
            if (result.data.is_registered)
              toast.error("Email is already exists");
            setRegister({
              ...Register,
              user_country: "60ec38c6d19c537aaea07f0d",
              user_name: result.data.user_email,
              user_full_name: result.data.user_full_name,
              user_email: email,
            });
            setCodeNumber(result.data.user_dial_code);
            setPhoneNumber(result.data.user_phone_number);
          } else {
            setRegister({ ...Register, user_email: email });
          }
        })
        .catch((err) => {
          console.log(err);
        });
  };

  const HandleSubmit = async (event) => {
    event.preventDefault();

    const formValid = validator.current.allValid();

    if (formValid) {
      if (!Register.is_terms_conditions || !Register.is_privacy_policy) {
        toast.error(
          "Make sure if you have read Terms & condition and Privacy policy"
        );
        return false;
      }

      let postData = {
        ...Register,
        user_phone_number: phoneNumber,
        user_dial_code: codeNumber,
        // user_country: "60ec38c6d19c537aaea07f0d",
        user_country: Register.country,
        postalcode: Register.postal_code,
        user_name: Register.user_email,
      };
      delete postData.country

      await authServices.registration(postData).then((result) => {
        if (result.status === true) {
          history.push(`/verify-otp/${Register.user_email}`);
          toast.success(result.message);
        } else {
          toast.error(result.message);
        }
      });
    } else {
      validator.current.showMessages();
    }
  };

  const phoneNumberHandle = (event, g) => {
    // setPhonenumber(event);
    if (g.target.value) {
      let num = g.target.value
        .split(" ")
        .slice(1)
        .join("")
        .replace(/[^a-zA-Z0-9]/g, "");
      let code = g.target.value.split(" ")[0];
      setPhoneNumber(num);
      setCodeNumber(code);

    }
  };

  const onCountryChange = (e) => {
    const {value,name} = e.target
    if (name === "country") {
      let findIso = country.find((item) => item.name===value)
      setStates(State.getStatesOfCountry(findIso.isoCode));
      setCountryCode(findIso.isoCode);
    } else if (name === "state") {
      let findIso = states.find((item) => item.name===value)
      setCities(City.getCitiesOfState(countryCode, findIso.isoCode));
    }
    setRegister({...Register,[name]:value})
  }


  return (
    <div className="loginpage registerpagecustom">
      <img
        src="assets/images/login_bg.jpg"
        className="loginbg"
        alt="Not found"
      />
      <div className="flex-grow-1 text-center loginchar"></div>
      <div className="loginbox">
        <h2 className="mb-5">Customer Sign up</h2>
        <form
          className="w-100"
          onSubmit={(event) => HandleSubmit(event)}
          autocomplete="off"
        >
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="floatingInput"
              placeholder="full name"
              onChange={(event) => Handlechange(event)}
              name="user_full_name"
              value={Register.user_full_name}
              autocomplete="off"
            />
            <label for="floatingInput">Full Name</label>
            {validator.current.message(
              "Fullname",
              Register.user_full_name,
              "required|text",
              { className: "text-danger mb-2" }
            )}
          </div>

          <div className="form-floating mb-3">
            <input
              type="email"
              className="form-control"
              id="floatingInput"
              placeholder="name@example.com"
              onChange={(event) => checkEmail(event.target.value)}
              name="user_email"
              // autocomplete="false"
              // value={Register.user_email}
            />
            <label for="floatingInput">Email</label>
            {validator.current.message(
              "Email",
              Register.user_email,
              "required|email",
              { className: "text-danger mb-2" }
            )}
          </div>

          <div className="row">
            <div className="col-12">
              <div className="form-group mb-3">
                <label className="mb-2">Mobile Number</label>
                <PhoneInput
                  className="telinput"
                  country={"ae"}
                  enableSearch
                  enableAreaCodes={true}
                  value={codeNumber + phoneNumber}
                  onChange={(phone, f, g) => phoneNumberHandle(phone, g)}
                />
                {validator.current.message(
                  "phone number",
                  phoneNumber,
                  "required",
                  { className: "text-danger mb-2" }
                )}
              </div>
            </div>
          </div>
          <div className="row">
          <div className="mb-3 col-12">
                  <label className="form-label mb-1">Address</label>
                  <input
                    name="address"
                    type="text"
                    className={"form-control"}
                    onChange={(event) => Handlechange(event)}
                    placeholder="Enter Address"
                  />
                  {validator.current.message(
                  "Address",
                  Register.address,
                  "required",
                  { className: "text-danger" }
                )}
                </div>
                </div>

          <div className="row">
            {/* country  */}
          <div className="form-group mb-3 col-6">
                  <label className="mb-1">Select Country </label>
                  <select className="form-control" name="country" 
                    onChange={(e) => onCountryChange(e)}
                  >
                    <option value="">Select Country</option>
                    {country.map((item, key) => (
                      <option key={key} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {/* {validator.current.message(
                  "Country",
                  Register.country,
                  "required",
                  { className: "text-danger" }
                )} */}
                </div>


                {/* state  */}
                <div className="form-group mb-3 col-6">
                  <label className="mb-1">Select State </label>
                  <select className="form-control" name="state"
                    onChange={(e) => onCountryChange(e)}
                  >
                    <option value="">Select State</option>
                    {states.map((item, key) => (
                      <option key={key} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {/* {validator.current.message(
                  "State",
                  Register.state,
                  "required",
                  { className: "text-danger" }
                )} */}
                </div>
                </div>


          <div className="row">
            {/* city  */}
            <div className="form-group mb-3 col-6">
                  <label className="mb-1">Select City</label>
                  <select className="form-control" name="city"
                    onChange={(e) => onCountryChange(e,'city')}
                  >
                    <option value="">Select City</option>
                    {cities.map((item, key) => (
                      <option key={key} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {/* {validator.current.message(
                  "City",
                  Register.city,
                  "required",
                  { className: "text-danger" }
                )} */}
                </div>
                <div className="mb-3 col-6">
                  <label className="form-label mb-1">Postal Code</label>
                  <input
                    name="postal_code"
                    type="text"
                    className={"form-control"}
                    onChange={(event) => Handlechange(event)}
                    placeholder="Enter Postal Code"
                  />
                  {/* {validator.current.message(
                  "Postal Code",
                  Register.postal_code,
                  "required",
                  { className: "text-danger" }
                )} */}
                </div>
                </div>

          <div className="row">
            <div className=" col-12 mb-3">
              <p className="fw-bold mb-0">Select User Type</p>
              <label>
                <input type="radio" name="usertype" /> Resident
              </label>{" "}
              &nbsp;&nbsp;
              <label>
                <input type="radio" name="usertype" /> Tourist
              </label>{" "}
              &nbsp;&nbsp;
              <label>
                <input type="radio" name="usertype" /> Overseas Customer
              </label>
            </div>
            {/* <div className=" col-12 mb-3">
              <p className="fw-bold mb-0">Select Age Group</p>
              <label>
                <input type="radio" name="agegroup" /> 18 – 30
              </label>{" "}
              &nbsp;&nbsp;
              <label>
                <input type="radio" name="agegroup" /> 31 - 45
              </label>{" "}
              &nbsp;&nbsp;
              <label>
                <input type="radio" name="agegroup" /> 46 – 60
              </label>
            </div> */}
          </div>
          <div className="form-group mb-3">
            <p className="fw-bold mb-0">Gender</p>
            <label className="radio-inline" for="male">
              <input
                type="radio"
                value="MALE"
                name="user_gender"
                id="male"
                onChange={(event) => Handlechange(event)}
              />{" "}
              Male
            </label>{" "}
            &nbsp;&nbsp;
            <label className="radio-inline" for="female">
              <input
                type="radio"
                value="FEMALE"
                name="user_gender"
                id="female"
                onChange={(event) => Handlechange(event)}
              />{" "}
              Female
            </label>
            {validator.current.message(
              "gender",
              Register.user_gender,
              "required",
              { className: "text-danger mb-2" }
            )}
          </div>

          <div className="row">
            <div className=" col-6">
              <div className="form-floating mb-3">
                <input
                  type="password"
                  className="form-control"
                  id="floatingPassword"
                  placeholder="Password"
                  onChange={(event) => Handlechange(event)}
                  name="user_password"
                  // autocomplete="off"
                  autocomplete="new-password"
                />
                <label for="floatingPassword">Password</label>
                {validator.current.message(
                  "Password",
                  Register.user_password,
                  "required|min:8",
                  { className: "text-danger mb-2" }
                )}
              </div>
            </div>
            <div className=" col-6">
              <div className="form-floating mb-3">
                <input
                  type="password"
                  className="form-control"
                  id="floatingPassword"
                  placeholder="Password"
                  onChange={(event) => setConfirmpwd(event.target.value)}
                  name="ConfirmPassword"
                />
                <label for="floatingPassword">Confirm Password</label>
                <span style={{ color: "#dc3545" }}>
                  {validator.current.message(
                    "Confirm Password",

                    confirmpwd,
                    `required|in:${Register.user_password}`,
                    { messages: { in: "Passwords Does not match!" } }
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className=" col-12 mb-3">
              <label>
                <input
                  type="checkbox"
                  onChange={(event) => Handlechange(event)}
                  name="is_terms_conditions"
                />{" "}
                Terms & Condition
              </label>{" "}
              &nbsp;&nbsp;
              <label>
                <input
                  type="checkbox"
                  name="is_privacy_policy"
                  onChange={(event) => Handlechange(event)}
                />{" "}
                Privacy Policy
              </label>{" "}
              &nbsp;&nbsp;
            </div>
          </div>
          <div className="mt-3 col-12">
            <a
              href="https://www.world2door.com/terms-and-conditions"
              target="_blank"
            >
              Terms & Condition
            </a>{" "}
            <a href="https://www.world2door.com/privacy-policy" target="_blank">
              Privacy Policy
            </a>
            <button className="themebutton w-100" type="submit">
              Sign Up
            </button>
          </div>

          <div className="text-center mt-5">
            <p className="mb-1">Already have World2Door Account?</p>
            <Link to="/login"> Sign In</Link>
          </div>
        </form>

        {successmsg ? <Verification data={successmsg} /> : null}
      </div>
    </div>
  );
}
