import React, { useState, useEffect, useRef } from "react";
import { Modal, Col, Row, Button } from "react-bootstrap-v5";
import AutoCompleteProduct from "react-autocomplete";
import {
  quoteService,
  productService,
  signupQuoteService,
} from "../../services";
import { toast } from "react-toastify";
import simpleReactValidator from "simple-react-validator";
import "react-phone-input-2/lib/style.css";
import {
  VolumeDetailsAction,
  VolumeSeaAction,
  GetTokenAction,
  VolumeCourierAction,
  VolumeTntlandAction,
} from "../../Redux/action";
import { useDispatch } from "react-redux";
import { Storage } from "../../Storage/Storage";
import Loader from "../Loader";
import helperFunction from "./helperFunction";

const initial1 = {
  item_id: "",
  type: "enter",
  item_description: "",
  no_of_pkgs: "",
  dimension: {
    kilogram: "",
    length: "",
    width: "",
    height: "",
  },
  total_volume_CBM: "",
  total_weight_KG: "",
  item_token: helperFunction.stringGenerator(24),
};

export default function QuoteCalculator({
  show,
  onClose,
  orginValue,
  destinationValue,
  StateList,
  couriertype,
  setType,
  setInitTotal,
  setFreight,
  setSub,
  setPacking,
  setinsurance,
  setStore,
  insuranceFun,
  storageFun,
  setdiscount,
  setpercentage,
  setResetQts,
  setSubTotalInit,
}) {
  const [, setForceUpdate] = useState("");
  const validator = useRef(
    new simpleReactValidator({
      autoForceUpdate: { forceUpdate: setForceUpdate },
      messages: {
        required: "required",
      },
    })
  );

  const dispatch = useDispatch();
  const kgRef = useRef(null);
  const lgRef = useRef(null);
  const wdRef = useRef(null);
  const heightRef = useRef(null);
  const volRef = useRef(null);
  const [loader, setLoader] = useState();
  const [Keyword, setKeyword] = useState(null);
  const [productList, setProductList] = useState([]);
  const [visiblility, setVisiblility] = useState(true);
  const [itemValue, setItemValue] = useState([]);
  const [valLocation] = useState(false);
  const [OrginValue, setOrginValue] = useState(orginValue);
  const [DestinationValue, setDestinationValue] = useState(destinationValue);
  const [goodValue, setGoodValue] = useState(StateList?.items_goods);
  const [dataToPost, setDataToPost] = useState("");
  const [productItemForArray, setProductItemForArray] = useState(
    StateList.items
  );
  const [productModal, setProductModal] = useState({
    open: false,
    data: null,
    index: 0,
  });
  const [grandTotal, setGrandTotal] = useState({
    number_of_packages: StateList.number_of_packages,
    volume: StateList.volume,
    weight: StateList.weight,
  });
  let productCount = 0;

  const onClickAdd = () => {
    let addArray = [...productItemForArray];
    addArray.push(initial1);
    setProductItemForArray(addArray);
  };

  const onChangeEvent = (key, event, subkey) => {
    let { name, value } = event.target;
    let arry = [...productItemForArray];
    let obj = arry[key];

    if (name != "kilogram" && name != "item_description") {
      obj["total_volume_CBM"] = getCBM(obj, name, value);
    }

    if (name === "kilogram" || name === "no_of_pkgs") {
      obj["total_weight_KG"] = getTKg(obj, name, value);
    }

    if (subkey) {
      obj[subkey][name] = value;
    } else {
      obj[name] = value;
    }
    arry.splice(key, 1, obj);
    setProductItemForArray(arry);
    getGrandTotal(arry);
  };

  const getTKg = (obj, act, count) => {
    switch (act) {
      case "kilogram":
        return obj.no_of_pkgs * count;
      case "no_of_pkgs":
        return count * obj.dimension.kilogram;
    }
  };

  const getCBM = (obj, act, count) => {
    switch (act) {
      case "no_of_pkgs":
        return (
          Number(count) *
          ((Number(obj.dimension.length) *
            Number(obj.dimension.width) *
            Number(obj.dimension.height)) /
            1000000)
        ).toFixed(3);
      case "length":
        return (
          Number(obj.no_of_pkgs) *
          ((Number(count) *
            Number(obj.dimension.width) *
            Number(obj.dimension.height)) /
            1000000)
        ).toFixed(3);
      case "width":
        return (
          Number(obj.no_of_pkgs) *
          ((Number(obj.dimension.length) *
            Number(count) *
            Number(obj.dimension.height)) /
            1000000)
        ).toFixed(3);
      case "height":
        return (
          Number(obj.no_of_pkgs) *
          ((Number(obj.dimension.length) *
            Number(obj.dimension.width) *
            Number(count)) /
            1000000)
        ).toFixed(3);
    }
  };

  // const isVisible = true;
  const toggleForm = () => {
    setVisiblility(!visiblility);
    setProductItemForArray([]);
    setOrginValue("");
    setDestinationValue("");
  };

  const searchHandler = (event) => {
    setItemValue(event.target.value);
    if (event.target.value.length >= 2) {
      setKeyword(event.target.value);
    } else {
      setKeyword("");
    }
  };

  const handleChange = (getvalue) => {
    setItemValue(getvalue);
    new_conditionFun(getvalue);
  };

  const addSelectedProduct = () => {
    // console.log(productModal.data,'productModal.data')
    let item = [];
    let itemObj = productList.find((item) => item.label === productModal.data);
    let findItemObj = productItemForArray.find(
      (item) => item.item_description === productModal.data
    );
    if (findItemObj) {
    } else {
      for (let i = 0; i < itemObj.data.product_dimentions.length; i++) {
        item.push({
          type: "select",
          item_id: itemObj.data._id,
          item_description: itemObj.data.product_name,
          no_of_pkgs: "1",
          dimension: {
            kilogram: itemObj.data.product_dimentions[i].weight,
            length: itemObj.data.product_dimentions[i].length,
            width: itemObj.data.product_dimentions[i].width,
            height: itemObj.data.product_dimentions[i].height,
          },
          total_volume_CBM: itemObj.data.product_gross_volume,
          total_weight_KG: itemObj.data.product_gross_weight,
        });
      }
    }
    let tempItem = productItemForArray;
    tempItem.splice(productModal.index, 1, ...item);
    setProductItemForArray(tempItem);
    setProductModal({ open: false, data: null, index: 0 });
    setProductList([]);
    setItemValue("");
    setKeyword("");
    getGrandTotal(tempItem);
  };

  const new_conditionFun = (val) => {
    setProductModal({ ...productModal, data: val });
  };

  useEffect(() => {
    const timeOut = setTimeout(() => {
      getProductlist();
    }, 1000);
    return () => {
      clearTimeout(timeOut);
    };
  }, [Keyword]);

  // search: Keyword && Keyword.length >= 2 ? Keyword  :  null,
  const getProductlist = () => {
    let searchData = {
      search: Keyword,
    };
    productService.getProduct(searchData).then((result) => {
      if (result.status === true) {
        let Productarray = result.data.map((item) => ({
          value: item._id,
          label: item.product_name,
          data: item,
        }));
        setProductList(Productarray);
      } else {
        // toast.error(result.message);
      }
    });
  };

  const onSecondClickDelete = (key, item) => {
    let arr = [...productItemForArray];
    if (item.type === "select") {
      let filteredArr = arr.filter(
        (itm) => itm.item_description !== item.item_description
      );
      setProductItemForArray(filteredArr);
      getGrandTotal(filteredArr);
    } else {
      arr.splice(key, 1);
      setProductItemForArray(arr);
      getGrandTotal(arr);
    }
  };
  const submitQuote = () => {
    let postData = null;
    let knownData;

    if (Array.isArray(StateList.items) && StateList.items.length) {
      const randomkey = helperFunction.stringGenerator(24);
      StateList.items.map((val) => {
        knownData = productItemForArray.map((item, i) => ({
          ...item,
          item_token:
            StateList.items[i] && StateList.items[i].item_token
              ? StateList.items[i].item_token
              : randomkey,
          quote_request_id: val.quote_request_id,
          user_id: val.user_id,
          quote_token: val.quote_token,
        }));
      });
      postData = {
        deliverer:StateList?.deliverer,
        origin_country: orginValue.country,
        origin_country_short_name: orginValue.country_short_name,
        origin_city: orginValue.city,
        origin_latitude: orginValue.latitude,
        origin_longitude: orginValue.longitude,
        destination_country: destinationValue.country,
        destination_country_short_name: destinationValue.country_short_name,
        destination_city: destinationValue.city,
        destination_latitude: destinationValue.latitude,
        destination_longitude: destinationValue.longitude,
        number_of_packages: grandTotal.number_of_packages,
        volume:
          grandTotal.volume <= 0.0 ? 0.001 : parseFloat(grandTotal.volume),
        weight: grandTotal.weight,
        items_goods: Number(goodValue),
        items: knownData,
        quote_token: StateList.quote_token,
        selected_courier_type: couriertype,
        referral_token: StateList.referral_token || "",
      };
    }

    let validate = 0;
    validate =
      +validator.current.fieldValid("item_description") &&
      validator.current.fieldValid("no_of_pkgs") &&
      validator.current.fieldValid("kilogram") &&
      validator.current.fieldValid("length") &&
      validator.current.fieldValid("width") &&
      validator.current.fieldValid("height") &&
      validator.current.fieldValid("good value");
    if (validate) {
      if (Storage.get("user-auth")) {
        if (Storage.get("user-token") === "retailer") {
          setLoader(true);
          apiCallFunctionRetailer(postData);
        } else if (Storage.get("user-token") === "store") {
          setLoader(true);
          apiCallFunctionStore(postData);
        } else {
          setLoader(true);
          // console.log("postData ===> ", JSON.stringify(postData))
          apiCallFunction(postData);
          setSubTotalInit(0);
        }
      } else {
        setDataToPost(postData);
        toggleForm();
      }
    } else {
      validator.current.showMessages();
    }
  };

  const apiCallFunction = (data) => {
    quoteService
      .getCourierQuote(data)
      .then((result) => {
        if (result.status === true) {
          dispatch(VolumeCourierAction(result));
          setLoader(false);
          setType("");
          setInitTotal();
          setFreight(0);
          setSub(0);
          setPacking(0);
          setinsurance({
            items_goods: "",
            insurance_premium: 0,
            minimum_aed: 0,
            insurance_amount: 0,
          });
          setStore({
            storage_value: "",
            storage_charges: 0,
            handling_in: 0,
            handling_out: 0,
            minimum: 0,
            storage_amount: 0,
          });
          setResetQts(true);
          insuranceFun();
          storageFun();
          setdiscount(0);
          setpercentage(0);
          onClose();
        } else {
          toast.error(result.message);
          setLoader(false);
          onClose();
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // for air

    quoteService
      .postQuote(data)
      .then((result) => {
        if (result.status === true) {
          dispatch(VolumeDetailsAction(result));
          setLoader(false);
          setType("");
          setInitTotal();
          setFreight(0);
          setSub(0);
          setPacking(0);
          setResetQts(true);
          setinsurance({
            items_goods: "",
            insurance_premium: 0,
            minimum_aed: 0,
            insurance_amount: 0,
          });
          setStore({
            storage_value: "",
            storage_charges: 0,
            handling_in: 0,
            handling_out: 0,
            minimum: 0,
            storage_amount: 0,
          });
          setResetQts(true);
          setdiscount(0);
          setpercentage(0);
          insuranceFun();
          storageFun();
          onClose();
        } else {
          toast.error(result.message);
          setLoader(false);
          onClose();
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // for sea

    quoteService
      .postSeaQuote(data)
      .then((result) => {
        if (result.status === true) {
          dispatch(VolumeSeaAction(result));
          setLoader(false);
          setType("");
          setInitTotal();
          setFreight(0);
          setSub(0);
          setPacking(0);
          setinsurance({
            items_goods: "",
            insurance_premium: 0,
            minimum_aed: 0,
            insurance_amount: 0,
          });
          setStore({
            storage_value: "",
            storage_charges: 0,
            handling_in: 0,
            handling_out: 0,
            minimum: 0,
            storage_amount: 0,
          });
          setResetQts(true);
          setdiscount(0);
          setpercentage(0);
          insuranceFun();
          storageFun();
          onClose();
        } else {
          toast.error(result.message);
          setLoader(false);
          onClose();
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // // for Tnt land

    quoteService
      .getTntQuote(data)
      .then((result) => {
        if (result.status === true) {
          dispatch(VolumeTntlandAction(result));
          setLoader(false);
          setType("");
          setinsurance({
            items_goods: "",
            insurance_premium: 0,
            minimum_aed: 0,
            insurance_amount: 0,
          });
          setInitTotal();
          setFreight(0);
          setSub(0);
          setPacking(0);
          setStore({
            storage_value: "",
            storage_charges: 0,
            handling_in: 0,
            handling_out: 0,
            minimum: 0,
            storage_amount: 0,
          });
          setResetQts(true);
          setdiscount(0);
          setpercentage(0);
          insuranceFun();
          storageFun();
          onClose();
        } else {
          toast.error(result.message);
          setLoader(false);
          onClose();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const apiCallFunctionRetailer = (data) => {
    //for courier

    quoteService
      .postCourierQuoteRetialer(data)
      .then((result) => {
        if (result.status === true) {
          dispatch(VolumeCourierAction(result));
          setLoader(false);
          setType("");
          setinsurance({
            items_goods: "",
            insurance_premium: 0,
            minimum_aed: 0,
            insurance_amount: 0,
          });
          setInitTotal();
          setFreight(0);
          setSub(0);
          setPacking(0);
          setStore({
            storage_value: "",
            storage_charges: 0,
            handling_in: 0,
            handling_out: 0,
            minimum: 0,
            storage_amount: 0,
          });
          setResetQts(true);
          setdiscount(0);
          setpercentage(0);
          insuranceFun();
          storageFun();
          onClose();
        } else {
          toast.error(result.message);
          setLoader(false);
          onClose();
        }
      })
      .catch((err) => {
        console.log(err);
      });

    quoteService
      .postQuoteRetialer(data)
      .then((result) => {
        if (result.status === true) {
          dispatch(VolumeDetailsAction(result));
          setLoader(false);
          setinsurance({
            items_goods: "",
            insurance_premium: 0,
            minimum_aed: 0,
            insurance_amount: 0,
          });
          setType("");
          setInitTotal();
          setFreight(0);
          setSub(0);
          setPacking(0);
          setStore({
            storage_value: "",
            storage_charges: 0,
            handling_in: 0,
            handling_out: 0,
            minimum: 0,
            storage_amount: 0,
          });
          setResetQts(true);
          setdiscount(0);
          setpercentage(0);
          insuranceFun();
          storageFun();
          onClose();
        } else {
          toast.error(result.message);
          setLoader(false);
          onClose();
        }
      })
      .catch((err) => {
        console.log(err);
      });

    quoteService
      .postSeaQuoteRetialer(data)
      .then((result) => {
        if (result.status === true) {
          dispatch(VolumeSeaAction(result));
          setLoader(false);
          setinsurance({
            items_goods: "",
            insurance_premium: 0,
            minimum_aed: 0,
            insurance_amount: 0,
          });
          setType("");
          setInitTotal();
          setFreight(0);
          setSub(0);
          setPacking(0);
          setStore({
            storage_value: "",
            storage_charges: 0,
            handling_in: 0,
            handling_out: 0,
            minimum: 0,
            storage_amount: 0,
          });
          setResetQts(true);
          setdiscount(0);
          setpercentage(0);
          insuranceFun();
          storageFun();
          onClose();
        } else {
          toast.error(result.message);
          setLoader(false);
          onClose();
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // for Tnt land

    quoteService
      .postLandQuoteRetialer(data)
      .then((result) => {
        if (result.status === true) {
          dispatch(VolumeTntlandAction(result));
          setLoader(false);
          setinsurance({
            items_goods: "",
            insurance_premium: 0,
            minimum_aed: 0,
            insurance_amount: 0,
          });
          setType("");
          setInitTotal();
          setFreight(0);
          setSub(0);
          setPacking(0);
          setStore({
            storage_value: "",
            storage_charges: 0,
            handling_in: 0,
            handling_out: 0,
            minimum: 0,
            storage_amount: 0,
          });
          setResetQts(true);
          setdiscount(0);
          setpercentage(0);
          insuranceFun();
          storageFun();
          onClose();
        } else {
          toast.error(result.message);
          setLoader(false);
          onClose();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const apiCallFunctionStore = (data) => {
    // for courier

    quoteService
      .postCourierQuoteStore(data)
      .then((result) => {
        if (result.status === true) {
          setLoader(false);
          dispatch(VolumeCourierAction(result));
          setType("");
          setinsurance({
            items_goods: "",
            insurance_premium: 0,
            minimum_aed: 0,
            insurance_amount: 0,
          });
          setInitTotal();
          setFreight(0);
          setSub(0);
          setPacking(0);
          setStore({
            storage_value: "",
            storage_charges: 0,
            handling_in: 0,
            handling_out: 0,
            minimum: 0,
            storage_amount: 0,
          });
          setResetQts(true);
          setdiscount(0);
          setpercentage(0);
          insuranceFun();
          storageFun();
          onClose();
        } else {
          toast.error(result.message);
          setLoader(false);
          onClose();
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // for air
    quoteService
      .postQuoteStore(data)
      .then((result) => {
        if (result.status === true) {
          dispatch(VolumeDetailsAction(result));
          setLoader(false);
          setType("");
          setinsurance({
            items_goods: "",
            insurance_premium: 0,
            minimum_aed: 0,
            insurance_amount: 0,
          });
          setInitTotal();
          setFreight(0);
          setSub(0);
          setPacking(0);
          setStore({
            storage_value: "",
            storage_charges: 0,
            handling_in: 0,
            handling_out: 0,
            minimum: 0,
            storage_amount: 0,
          });
          setResetQts(true);
          setdiscount(0);
          setpercentage(0);
          insuranceFun();
          storageFun();
          onClose();
        } else {
          toast.error(result.message);
          setLoader(false);
          onClose();
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // for sea
    quoteService
      .postSeaQuoteStore(data)
      .then((result) => {
        if (result.status === true) {
          dispatch(VolumeSeaAction(result));
          setLoader(false);
          setinsurance({
            items_goods: "",
            insurance_premium: 0,
            minimum_aed: 0,
            insurance_amount: 0,
          });
          setType("");
          setInitTotal();
          setFreight(0);
          setSub(0);
          setPacking(0);
          setStore({
            storage_value: "",
            storage_charges: 0,
            handling_in: 0,
            handling_out: 0,
            minimum: 0,
            storage_amount: 0,
          });
          setResetQts(true);
          setdiscount(0);
          setpercentage(0);
          insuranceFun();
          storageFun();
          onClose();
        } else {
          toast.error(result.message);
          setLoader(false);
          onClose();
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // for land

    quoteService
      .postLandQuoteStore(data)
      .then((result) => {
        if (result.status === true) {
          setLoader(false);
          dispatch(VolumeTntlandAction(result));
          setType("");
          setinsurance({
            items_goods: "",
            insurance_premium: 0,
            minimum_aed: 0,
            insurance_amount: 0,
          });
          setInitTotal();
          setFreight(0);
          setSub(0);
          setPacking(0);
          setStore({
            storage_value: "",
            storage_charges: 0,
            handling_in: 0,
            handling_out: 0,
            minimum: 0,
            storage_amount: 0,
          });
          setdiscount(0);
          setResetQts(true);
          setpercentage(0);
          insuranceFun();
          storageFun();
          onClose();
        } else {
          toast.error(result.message);
          setLoader(false);
          onClose();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getGrandTotal = (itemarr) => {
    let arry = [...itemarr];
    let gTotal = {
      number_of_packages: 0,
      volume: 0,
      weight: 0,
    };
    for (let i = 0; i < arry.length; i++) {
      if (
        i > 0 &&
        arry[i].item_description &&
        arry[i].type === "select" &&
        arry[i - 1].item_description === arry[i].item_description
      ) {
      } else {
        gTotal.number_of_packages += arry[i]?.no_of_pkgs
          ? Number(arry[i].no_of_pkgs)
          : 0;
        gTotal.volume += arry[i]?.total_volume_CBM
          ? Number(arry[i].total_volume_CBM)
          : 0;
        gTotal.weight += arry[i]?.total_weight_KG
          ? Number(arry[i].total_weight_KG)
          : 0;
      }
    }
    setGrandTotal({ ...gTotal, volume: gTotal.volume.toFixed(3) });
  };

  return (
    <>
      <Modal show={show} onHide={() => onClose()} dialogClassName="dialog-custom">
        <div className="text-end pe-3">
          <span className="cursor-pointer" onClick={() => onClose()}>
            Close
          </span>
        </div>
        <Modal.Body className="modal-body">
          <div className="row">
            <Row>
              <h5 className="mb-3 text-center">Cargo Details</h5>
              <Col sm={12}>
                <div className="col-12 px-3">
                  <hr />
                  <h5 className="mb-3 center">Enter Product Information</h5>
                  <div className="table-responsive">
                    <table className="table table-bordered text-center volume-calcu">
                      <thead>
                        <tr className="lh-sm">
                          <th rowspan="2" className="align-middle">
                            S.no.
                          </th>
                          <th rowspan="2" className="align-middle">
                            Product Detail
                          </th>
                          <th rowspan="2" className="align-middle">
                            Item Description
                          </th>
                          <th rowspan="2" className="align-middle">
                            No. of Pkgs/ <br /> Quantity
                          </th>
                          <th colspan="4">Weight & Dimension per Package</th>
                          <th rowspan="2" className="align-middle" ref={volRef}>
                            Total Volume
                            <br />
                            (CBM)
                          </th>
                          <th rowspan="2" className="align-middle">
                            Total Weight
                            <br />
                            (KG)
                          </th>
                          <th rowspan="2" className="align-middle">
                            Action
                          </th>
                        </tr>
                        <tr>
                          <th ref={kgRef}>Kilogram</th>
                          <th ref={lgRef}>Length</th>
                          <th ref={wdRef}>width</th>
                          <th ref={heightRef}>Height</th>
                        </tr>
                      </thead>
                      <tbody>
                        {productItemForArray &&
                          productItemForArray.length > 0 &&
                          productItemForArray.map((item, key) => {
                            if (
                              key > 0 &&
                              item.item_description &&
                              item.type === "select" &&
                              productItemForArray[key - 1].item_description ===
                                item.item_description
                            ) {
                            } else {
                              productCount += 1;
                            }
                            if (
                              key > 0 &&
                              item.item_description &&
                              item.type === "select" &&
                              productItemForArray[key - 1].item_description ===
                                item.item_description
                            ) {
                            } else {
                              return (
                                <tr key={key}>
                                  <td>{productCount}</td>
                                  <td style={{ padding: "11px 10px" }}>
                                    <div className="form-group">
                                      <select
                                        className="form-control"
                                        name="type"
                                        disabled={
                                          item?.type && item.type === "select"
                                            ? true
                                            : false
                                        }
                                        value={item?.type}
                                        onChange={(e) => {
                                          if (e.target.value === "select") {
                                            setProductModal({
                                              modal: true,
                                              data: null,
                                              index: key,
                                            });
                                          }
                                        }}
                                      >
                                        <option value="enter">
                                          Enter manually
                                        </option>
                                        <option value="select">
                                          Select from list
                                        </option>
                                      </select>
                                    </div>
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      value={item.item_description}
                                      className="form-control"
                                      name="item_description"
                                      onChange={(e) => onChangeEvent(key, e)}
                                      placeholder="Description"
                                      readOnly={
                                        item.type === "select" ? true : false
                                      }
                                    ></input>
                                    {validator.current.message(
                                      "item_description",
                                      item.item_description,
                                      "required",
                                      {
                                        className:
                                          "text-danger customMsg text-start ps-4",
                                      },
                                      {
                                        style: { bottom: "-10px" },
                                      }
                                    )}
                                  </td>

                                  <td>
                                    <input
                                      className="tbl-input"
                                      type="number"
                                      min="1"
                                      name="no_of_pkgs"
                                      placeholder="Quantity"
                                      value={item.no_of_pkgs}
                                      onChange={(e) => onChangeEvent(key, e)}
                                    />
                                    {validator.current.message(
                                      "no_of_pkgs",
                                      item.no_of_pkgs,
                                      "required",
                                      {
                                        className: "text-danger customMsg",
                                      }
                                    )}
                                  </td>
                                  {(productItemForArray &&
                                    item.type === "select" &&
                                    productItemForArray.filter(
                                      (item3) =>
                                        item3.item_description &&
                                        item3.item_description ===
                                          item.item_description
                                    ).length > 1 && (
                                      <td colSpan={4} className="p-0">
                                        <table
                                          className="table w-100 table-bordered text-center volume-calcu mb-0"
                                          style={{ minWidth: "auto" }}
                                        >
                                          <tbody>
                                            {productItemForArray &&
                                              productItemForArray.length > 0 &&
                                              productItemForArray.map(
                                                (itemprod) => {
                                                  if (
                                                    itemprod.item_description ===
                                                    item.item_description
                                                  ) {
                                                    return (
                                                      <tr className="lh-sm">
                                                        <td
                                                          style={{
                                                            width:
                                                              kgRef.current
                                                                ?.offsetWidth,
                                                          }}
                                                        >
                                                          <input
                                                            className="tbl-input text-dark"
                                                            type="text"
                                                            disabled
                                                            value={
                                                              itemprod.dimension
                                                                .kilogram
                                                            }
                                                          />
                                                        </td>
                                                        <td
                                                          style={{
                                                            width:
                                                              lgRef.current
                                                                ?.offsetWidth,
                                                          }}
                                                        >
                                                          <input
                                                            className="tbl-input text-dark"
                                                            type="text"
                                                            disabled
                                                            value={
                                                              itemprod.dimension
                                                                .length
                                                            }
                                                          />
                                                        </td>
                                                        <td
                                                          style={{
                                                            width:
                                                              wdRef.current
                                                                ?.offsetWidth,
                                                          }}
                                                        >
                                                          <input
                                                            className="tbl-input text-dark"
                                                            type="text"
                                                            disabled
                                                            value={
                                                              itemprod.dimension
                                                                .width
                                                            }
                                                          />
                                                        </td>
                                                        <td
                                                          style={{
                                                            width:
                                                              heightRef.current
                                                                ?.offsetWidth,
                                                          }}
                                                        >
                                                          <input
                                                            className="tbl-input text-dark"
                                                            type="text"
                                                            disabled
                                                            value={
                                                              itemprod.dimension
                                                                .height
                                                            }
                                                          />
                                                        </td>
                                                      </tr>
                                                    );
                                                  }
                                                }
                                              )}
                                          </tbody>
                                        </table>
                                      </td>
                                    )) || (
                                    <>
                                      <td
                                        style={{
                                          width: kgRef.current?.offsetWidth,
                                        }}
                                      >
                                        <input
                                          className="tbl-input"
                                          type="number"
                                          min="1"
                                          disabled={
                                            item.type === "select"
                                              ? true
                                              : false
                                          }
                                          onChange={(e) =>
                                            onChangeEvent(key, e, "dimension")
                                          }
                                          name="kilogram"
                                          value={item.dimension.kilogram}
                                          placeholder="Kilogram"
                                        />
                                        {validator.current.message(
                                          "kilogram",
                                          item.dimension.kilogram,
                                          "required",
                                          { className: "text-danger customMsg" }
                                        )}
                                      </td>
                                      <td
                                        style={{
                                          width: lgRef.current?.offsetWidth,
                                        }}
                                      >
                                        <input
                                          className="tbl-input"
                                          type="number"
                                          min="1"
                                          disabled={
                                            item.type === "select"
                                              ? true
                                              : false
                                          }
                                          onChange={(e) =>
                                            onChangeEvent(key, e, "dimension")
                                          }
                                          name="length"
                                          value={item.dimension.length}
                                          placeholder="Length"
                                        />
                                        {validator.current.message(
                                          "length",
                                          item.dimension.length,
                                          "required",
                                          { className: "text-danger customMsg" }
                                        )}
                                      </td>
                                      <td
                                        style={{
                                          width: wdRef.current?.offsetWidth,
                                        }}
                                      >
                                        <input
                                          className="tbl-input"
                                          type="number"
                                          min="1"
                                          disabled={
                                            item.type === "select"
                                              ? true
                                              : false
                                          }
                                          onChange={(e) =>
                                            onChangeEvent(key, e, "dimension")
                                          }
                                          name="width"
                                          value={item.dimension.width}
                                          placeholder="Widtht"
                                        />
                                        {validator.current.message(
                                          "width",
                                          item.dimension.width,
                                          "required",
                                          { className: "text-danger customMsg" }
                                        )}
                                      </td>
                                      <td
                                        style={{
                                          width: heightRef.current?.offsetWidth,
                                        }}
                                      >
                                        <input
                                          className="tbl-input"
                                          type="number"
                                          min="1"
                                          disabled={
                                            item.type === "select"
                                              ? true
                                              : false
                                          }
                                          onChange={(e) =>
                                            onChangeEvent(key, e, "dimension")
                                          }
                                          name="height"
                                          value={item.dimension.height}
                                          placeholder="Height"
                                        />
                                        {validator.current.message(
                                          "height",
                                          item.dimension.height,
                                          "required",
                                          { className: "text-danger customMsg" }
                                        )}
                                      </td>
                                    </>
                                  )}

                                  <td
                                  // style={{width:volRef.current?.offsetWidth}}
                                  >
                                    <input
                                      className="tbl-input text-dark"
                                      type="text"
                                      disabled
                                      value={
                                        item.total_volume_CBM || 0
                                      }
                                    />
                                  </td>

                                  <td>
                                    <input
                                      className="tbl-input text-dark"
                                      type="text"
                                      disabled
                                      value={
                                        item.total_weight_KG || 0
                                      }
                                    />
                                  </td>
                                  <td>
                                    <img
                                      className="btn"
                                      height="20"
                                      src="/assets/images/delete.svg"
                                      onClick={() =>
                                        onSecondClickDelete(key, item)
                                      }
                                      alt=" Not found"
                                    />
                                  </td>
                                </tr>
                              );
                            }
                          })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th className="text-end">Total</th>
                          <th colSpan="2"></th>
                          <th colSpan="1">{grandTotal.number_of_packages}</th>
                          <th colSpan="4"></th>
                          <th className="text-center">
                            {grandTotal.volume <= 0.0
                              ? 0.001
                              : grandTotal.volume}
                          </th>
                          <th className="text-center">{grandTotal.weight}</th>
                          <th></th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="row">
                      <div className="col-md-4 text-left">
                        <p className="me-2 mb-0 fw-bold">
                          Total Shipment Value
                        </p>
                      </div>
                      <div className="col-md-6">
                        <input
                          className="form-control"
                          type="number"
                          min="1"
                          name="items_value"
                          value={goodValue}
                          onChange={(e) => setGoodValue(e.target.value)}
                        />

                        {validator.current.message(
                          "good value",
                          goodValue,
                          "required",
                          { className: "text-danger" }
                        )}
                      </div>
                    </div>
                    <div className="text-right">
                      <button
                        className="themebutton px-4 ms-auto"
                        onClick={() => onClickAdd()}
                      >
                        + Add Item
                      </button>
                    </div>
                  </div>
                  <hr />
                  <div className="row text-center">
                    <div className="col-md-12">
                      <button
                        type="button"
                        className="themebutton px-4"
                        onClick={() => submitQuote()}
                      >
                        GET RATES
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Loader isShow={loader} />
        <Modal
          show={productModal.modal}
          size="md"
          onHide={() => setProductModal({ modal: false, data: null, index: 0 })}
        >
          <div className="d-flex justify-content-center m-5 row">
            <div className="col-12">
              <h5 className="mb-2 pt-2 text-left">
                Select Product Information
              </h5>
              <div className="mt-3 mb-2">
                {productList && productModal.modal && (
                  <div className="autocomplete-wrapper">
                    <AutoCompleteProduct
                      getItemValue={(item) => item.label}
                      items={productList}
                      renderMenu={(item) => (
                        <div className="dropdown">{item}</div>
                      )}
                      renderItem={(item, isHighlighted) => (
                        <div
                          className={`item ${
                            isHighlighted ? "selected-item" : ""
                          }`}
                        >
                          {item.label}
                        </div>
                      )}
                      inputProps={{
                        placeholder: "Enter Product",
                        autoFocus: true,
                      }}
                      value={itemValue}
                      onChange={(e) => searchHandler(e)}
                      onSelect={(val) => handleChange(val)}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <Button
                variant="success"
                className="px-5 me-3 themebutton"
                onClick={() =>
                  setProductModal({ modal: false, data: null, index: 0 })
                }
              >
                Back
              </Button>
              <Button
                variant="success"
                className="px-5 themebutton"
                onClick={() => addSelectedProduct()}
              >
                ADD
              </Button>
            </div>
          </div>
        </Modal>
      </Modal>
    </>
  );
}
