import React from "react";

export default function ProhibitedItems() {
  return (
    <section className="pb-5 pt-0">
      <div className="header_title">
        <div className="container-fluid">
          <h1>Prohibited Items</h1>
          <img
            src="/assets/images/about-us-inner-banner.jpg"
            className="bannerimg"
            alt="Not found"
          />
        </div>
      </div>
      <div className="container-fluid mt-5">
        <div className="white_box h-100">
          <div className="row">
            <div className="col-md-6">
              <p>
                The shipment should not contain any goods prohibited under the
                laws of the country of origin of shipment, the laws of country
                of ultimate destination, the international laws governing the
                transport of goods by road, sea, air & courier. At the booking
                stage the customer must sign an undertaking by accepting the
                terms & conditions for the goods/shipment in respect World2Door
                is providing services do not contain any of the prohibited
                goods. The prohibited goods include but not limited to the
                following goods:-
              </p>
              <ul className="fancylist">
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>Explosives/ Guns / Ammunition/ Weaponry etc.</div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>Counterfeit Goods.</div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>Perishable Items / Food Items.</div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>Any Items in Liquids or Powder Form.</div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>Loose batteries.</div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>Animals/Birds/ Wildlife Products.</div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>Any Plants & Plant Material.</div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>Lottery Tickets / Gambling Devices</div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>Pornographic Films or Obscene Material.</div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>Drugs.</div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>Medicines.</div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>Jewellery / Currency/ Bullion / gem stones.</div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>Hazardous Waste/Material.</div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>Precious Metals.</div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>Human remains.</div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>Ivory.</div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    Goods Categorized as Dangerous by IATA (International Air
                    Transport Association).
                  </div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    Any items Restricted as per individual country Regulations.
                  </div>
                </li>
              </ul>
            </div>

            <div className="col-md-6">
              <img
                src="/assets/images/prohibited.svg"
                alt="Not found"
                width="100%"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
