

   //// custom function for generate random key
   function randomString(length, chars) {
    chars = chars.toString();
    var result = "";
    for (var i = 0; i < length; i++) {
      var string = chars
        .split("")
        .sort(function () {
          return 0.5 - Math.random();
        })
        .join("");
      result += string[0];
    }
    return result;
  }

  export default function getRandomkeys() {
    //S2D-353881134127-QU
    //00000001/W2DQ/AE/2022 
    return (
        
      randomString(8, "123456789") +
      "/" +
      "W2DQ" +
      "/" +
      "AE"+
      "/"+
      "2022"
    );
  }