import React, { useState } from "react";
import VolumeCalculator from '../Container/home/VolumeCalculator'

export default function About() {
  const [isQuote, setIsQuote] = useState(false)
  const [quotes, setQuotes] = useState({
    number_of_packages: '',
    weight: '',
    volume: ''
  })
  const onSubmitClick = (value) => {
    setIsQuote(false)
    setQuotes({
      ...quotes,
      number_of_packages: value.totalPackage,
      weight: value.totalKg,
      volume: value.totalCBM
    })
  }

  const onCloseClick = () => {
    setIsQuote(false)
  }
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);
  return (
    <section className="pb-5 pt-0">
      <div className="header_title">
        <div className="container-fluid">
          <h1>About Us</h1>


          <img
            src="/assets/images/about-us-inner-banner.jpg"
            alt="Not found"
            className="bannerimg"
          />
        </div>
      </div>
      <div className="container-fluid mt-5">
        <div className="white_box h-100 mb-3">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="fw-bold mb-4">Brand Promise</h3>

              <p>
                We envision a world where shopping, shipping and commerce are seamlessly entwined to ensure a 100% hassle-free and trusted experience for anyone shopping in any country. </p>

              <p>

                World2Door saw the problems in the world’s best shopping destination, Dubai, where travellers worldwide could not find a trustworthy fulfilment partner that could handle multiple shipments at a given time. </p>
            </div>
            <div className="col-lg-6 order-2  order-lg-0">
              <p>
                It just did not make sense.
              </p>

              <h3 className="fw-bold my-4">World2Door</h3>

              <p>
                Travellers and shoppers should be able to receive their precious cargo in their country of residence with minimal worry. They should not have to hunt down shipping companies, receive random numbers and quotes, wait long periods of time and ultimately lose interest.
              </p>
              <p>
                So many questions used to get in the way.
              </p>
              <p>
                How much will the shipping cost? Who will send it and can I trust them? How do I transport my shopping to the shipping company?
              </p>
              <p>
                World2Door is the single solution to all these challenges. We have developed a system that makes holiday shopping a fully hands-free and fun experience.
              </p>
              <p>
                We’re in the business of making shopping fun again, and business is booming!
              </p>
              <p>
                The smiles are flying in, our order numbers are skyrocketing and we’ve never felt stronger about our value proposition or our purpose in ensuring the best customer experiences.
              </p>
              <p>
                We’re expanding our network and will soon be in over 10 of the world’s best shopping destinations by 2025 helping you send your purchases back home with a smile. </p>
            </div>


            <div className="col-lg-6 ">
              <img className="mw-100" alt='Not found' src="/assets/images/about.png" width="100%" />
            </div>
            <div className="col-12 text-center">
              <button className="btn getquoteBtn mt-5 mb-0" onClick={() => setIsQuote(true)}>Get Quote</button>

            </div>
          </div>
        </div>
      </div>
      <VolumeCalculator
        show={isQuote}
        onSubmit={(e) => onSubmitClick(e)}
        onClose={() => onCloseClick()}
      />
    </section>
  );
}
