import React, { useRef, useState } from "react";
import { jobBooking } from "../services/index";
import moment from "moment";

export default function Track() {
  const tracking = useRef();
  const [trackngData, setTrackingData] = useState({});
  const trackShipment = () => {
    getTrackingDetails(tracking.current.value);
  };

  const getTrackingDetails = (jobToken) => {
    try {
      let queryData = { job_token: jobToken };
      jobBooking.getTrackingDetails(queryData).then((result) => {
        if (result.status === true) {
          setTrackingData(result.data);
        } else {
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <section className="pb-5 pt-0">
        <div className="header_title">
          <div className="container-fluid">
            <h1>Track Your Shipment</h1>
            <p>
              Please enter information below and click on the "Track" button.
            </p>
            <img
              src="/assets/images/track.jpg"
              className="bannerimg"
              alt="Not found"
            />
          </div>
        </div>
        <div className="container-fluid mt-5">
          <div className="white_box h-100">
            <div className="row">
              <div className="col-md-6 px-md-5 px-3">
                <form>
                  <div className="form-group mb-3">
                    <label className="fw-bold mb-2">Booking Ref. No. </label>
                    <input
                      className="form-control rounded_input"
                      ref={tracking}
                      placeholder="Booking REf. No"
                    />
                  </div>

                  <div className="form-group mb-3">
                    <button
                      type="button"
                      className="themebutton"
                      onClick={() => trackShipment()}
                    >
                      Track Shipment
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-md-6 px-md-5 px-3">
                <label className="fw-bold mb-2">
                  Expected Delivery Date : { trackngData?.expacted_date ? moment(trackngData.expacted_date).format('DD-MM-YYYY'):''}
                </label>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 px-3">
                <div id="trackingSec" className="trackingSec">
                  <div className="overflow-auto">
                    {Array.isArray(trackngData.job_status_values) &&
                      trackngData.job_status_values.map((item, key) => {
                        return (
                          <div
                            className={item.change_date !== 0 ? "active" : ""}
                          >
                            <div className={`trackingText-${key + 1}`}>
                              {item.text}
                            </div>
                            <span className="checkicon"></span>
                            <span>{item.job_status.replace(/_/g, " ")} </span>
                            <span>{item?.tracking_date && moment(item?.tracking_date).format('DD-MM-YYYY') || '-'} </span>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
