import React, { useContext, useState } from 'react'
import { jsPDF } from "jspdf";
import { renderToString } from "react-dom/server";
import { BookingDetailContext } from '../BookingDetails'
import {
    Modal,
    Button,
   
} from "react-bootstrap-v5";
import { toast } from "react-toastify";
import moment from "moment";
import InvoiceHtml from "../../Function/Invoice";
import PdfDownload from "../../Function/PdfDownload"
import { paymentGatewayService } from '../../services';
import helperFunction from '../Quotation/helperFunction';
import Loader from '../Loader';

export default function Invoices() {
    const { state, disptach } = useContext(BookingDetailContext);
    const [loader, setLoader] = useState(false);
    const [showModalData, setShowModelData] = useState({ showModal: false, invoiceView: 0 })
    const [loadingPdf, setLoadingPdf] = useState(false)
    if (!state) {
        return 'Loading...'
    } else {
        
        function timeConverter(UNIX_timestamp) {
            var timestamp = UNIX_timestamp
            var date = new Date(timestamp);
            return date.getDate() +
                "/" + (date.getMonth() + 1) +
                "/" + date.getFullYear()
        }
        const info = state.bookingDetails.invoice_details;
        const payment = state.bookingDetails.payment_information;
        const invData = state.bookingDetails;
        let amt = payment.agreed_amount;
        let adv = payment.job_advance_payment ? payment.job_advance_payment : 0;
        let os = Number(amt) - Number(adv)
       
        let handleShow = (value, index) => {
                     
            if (value === true) {
                setShowModelData({ showModal: value, invoiceView: index });
            } else {
                setShowModelData({ showModal: value, invoiceView: 0 });
            }
        };

        const downloadPdf = async (index) => {
            setLoadingPdf(true)
            const doc = new jsPDF();
            // 'p','pt', [800, 800]
            
            const string = renderToString(
                    <PdfDownload
                        invoiceItem={info[index]}
                        quoteToken={invData.job_token}
                        carrierData={invData.carrier_details}
                        receiverData={invData.receiver_details}
                        customerData={invData.customer_details}
                        jobId={invData._id}
                        bookingData={invData}
                    />
            );
            
            await doc.html(string, {
                async callback(doc) {
                    doc.save('W2D_'+info[index].invoice_number);
                },
                html2canvas: { scale: 0.22 },
                // autoPaging: false,
                x: 20,
                y: 5,
                // width: 800,
                // windoWidth: 800,
                // margin: 30,
            });
            setLoadingPdf(false)
        }

        const payInvoice = (amount, invoiceId) => {
            let postData = {
                token: state.bookingDetails.quote_token,
                quote_id: helperFunction.stringGenerator(15),
                type: state.bookingDetails.shipment_details.servic_type || state.bookingDetails.shipment_details.shipment_mode,
                amount,
                invoice_id: invoiceId
            }
            paymentGatewayService.payInvoice(postData).then(res => {
                if (res.status === true) {
                    setLoader(false);
                    window.location.href = res.data.url;
                } else {
                    setLoader(false);
                    toast.error(res.data.message);
                    toast.error(res.message);
                }
            })
        }
        

        return (
            
            <div className='pickup-back'>
                                {
                    info && info.length > 0 && (
                        <Modal
                            size="xl"
                            show={showModalData.showModal}
                            onHide={() => handleShow(false)}
                            aria-labelledby="example-modal-sizes-title-lg"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-modal-sizes-title-lg">
                                    Invoice
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <InvoiceHtml
                                    invoiceItem={info[showModalData.invoiceView]}
                                    quoteToken={invData.job_token}
                                    carrierData={invData.carrier_details}
                                    receiverData={invData.receiver_details}
                                    customerData={invData.customer_details}
                                    jobId={invData._id}
                                    bookingData={invData}
                                />
                            </Modal.Body>
                        </Modal>
                    )
                }
                {/* <div className='pickup-back'> */}
                <h5 className="my-4 fw-bold">Payment Details</h5>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td className="py-1"> Amount : <span className='order-summery'>{amt}</span></td>
                            <td className="py-1"> ADV Amount : <span className='order-summery'>{adv}</span></td>
                            <td className="py-1"> OS Amount :<span className='order-summery'>{os}</span></td>
                        </tr>
                    </tbody>
                </table>

                <h5 className="my-4 fw-bold " >Invoices</h5>
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Invoice#</th>
                                <th>Date</th>
                                <th>Amount</th>
                                {/* <th>Adj Amount</th>
                                <th>OS Amount</th> */}
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                info && info.length > 0 &&
                                info.map((item, key) => {
                                    return (
                                        <tr>
                                            <td> {item.invoice_number}</td>
                                            <td>{timeConverter(item.created_date)}</td>
                                            {/* <td>{item.balance} AED</td>
                                    <td>{item.paid} AED</td> */}
                                            <td>{item.gross_amount} AED</td>
                                            <td><span className="badge bg-primary">{item.status}</span></td>
                                            <td>
                                                <Button onClick={() => handleShow(true, key)}>
                                                    View
                                                </Button>
                                                {' '}
                                                {
                                                    loadingPdf === false
                                                    ? <Button className='ml-2' onClick={() => downloadPdf(key)}>
                                                        Download PDF
                                                    </Button>
                                                    : 'Please wait...'   
                                                    
                                                }
                                                {' '} 
                                                <Button variant="warning" onClick={() => payInvoice(item.gross_amount, item._id)}>
                                                    Pay
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                    <Loader isShow={loader} />
                </div>

            </div>

        )
    }
}
