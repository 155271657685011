import React, { useEffect, useState } from "react";
import { HashLink as Links } from "react-router-hash-link";
import VolumeCalculator from '../Container/home/VolumeCalculator';
import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.cjs.js'
import { Link } from "react-router-dom";
// import { useHistory } from "react-router";
import { Storage } from "../Storage";
import { useDispatch } from "react-redux";
import {
  UserDetails,
  VolumeDetailsAction,
  VolumeSeaAction,
  GetTokenAction,
  VolumeCourierAction,
  VolumeTntlandAction,
  getFreightAction,
} from "../Redux/action";
import { toast } from "react-toastify";
import VolumeCalculatorStore from "../Container/home/VolumeCalculatorStore";

const Header = (props) => {
  const dispatch = useDispatch();
  let url = process?.env?.REACT_APP_API_BASEURL
  const [isLogged, setIsLogged] = useState(
    props.user ? props.user.isLogged : false
  );

  const [userName, setUserName] = useState(
  );
  //code for slider
  const [isQuote, setIsQuote] = useState(false)
  const [quotes, setQuotes] = useState({
    number_of_packages: '',
    weight: '',
    volume: ''
  })


  // for auto logout after 15 min idle 
  const onIdle = () => {
    Logout()
  }
  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: 900_000,
    throttle: 500
  })

  const onSubmitClick = (value) => {
    setIsQuote(false)
    // setisOpenModal(true)
    setQuotes({
      ...quotes,
      number_of_packages: value.totalPackage,
      weight: value.totalKg,
      volume: value.totalCBM
    })
  }

  // const [isOpenModal, setisOpenModal] = useState(true)
  // const [show, setShow] = useState(false);


  // const handleClose = () => {
  //   setShow(false);
  //   setisOpenModal(true)
  // }

  const onCloseClick = () => {
    setIsQuote(!isQuote)
  }
  //end slider
  // const history = useHistory();
  const Logout = () => {
    if (Storage.get("user-token") == "store") {
      Storage.remove("user-token", "store");
      Storage.remove("freightData");
    } else if (Storage.get("user-token") == "management") {
      Storage.remove("user-token", "management");
      Storage.remove("freightData");
    }else if (Storage.get("user-token") == "retailer") {
      Storage.remove("user-token", "retailer");
      Storage.remove("freightData");
    } else {
      Storage.remove("user-token");
      Storage.remove("freightData");
    }

    Storage.remove("user-auth");
    Storage.remove("persist:root");
    dispatch(UserDetails());
    dispatch(VolumeDetailsAction());
    dispatch(VolumeSeaAction());
    dispatch(GetTokenAction());
    dispatch(VolumeCourierAction());
    dispatch(VolumeTntlandAction());
    dispatch(getFreightAction());
  };
  window.scrollTo(0, 0)

  useEffect(() => {
    if (props.user && props.user.isLogged) {
      setIsLogged(props.user.isLogged);
      if (props.user.user.data.store_name) {
        setUserName(props.user.user.data.store_name || '');
      } else if (props.user.user.data.user_full_name) {
        setUserName(props.user.user.data.user_full_name || '');
      } else if (props.user.user.data.retailer_name) {
        setUserName(props.user.user.data.retailer_name || '');
      }
    }
  }, [props]);

  // console.log("userName .....", userName)
  // console.log("userName .....", props.user)

  return (
    <nav className="navbar navbar-expand-lg fixed-top shadow navbar-light bg-white">
      {
        // Storage.get("user-token") === "store" ?
        //   <VolumeCalculatorStore
        //     show={isQuote}
        //     // onSubmit={(e) => onSubmitClick(e)}
        //     onClose={() => onCloseClick()}
        //   /> :
          <VolumeCalculator
            show={isQuote}
            // onSubmit={(e) => onSubmitClick(e)}
            onClose={() => onCloseClick()}
          />
      }
      {/* navbar for mobile devices 

            <div className="container-fluid mobilenav">
                <nav className="nav  nav-fill w-100">
                    <Link className="nav-link active" aria-current="page" to="/"><img src="/assets/images/home.svg" height="20" /></Link>
                    <li className="nav-item">
                        <Link className="nav-link px-3" to="/about">About US</Link>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown">
                            FAQS
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end" >
                            <li><Link className="dropdown-item" to="/faq">FAQs</Link></li>
                            <li><Link className="dropdown-item" to="/country-guide">Country Guide</Link></li>
                            <li><Link className="dropdown-item" to="/tips-myths">Tips/Myths</Link></li>
                        </ul>
                    </li>
                    <Link className="nav-link active" aria-current="page" to="/how-it-works"><img src="/assets/images/how_it_works.svg" height="20" /></Link>
                    <Link className="nav-link active" aria-current="page" to="/track"><img src="/assets/images/tracking.svg" height="20" /></Link>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src="/assets/images/gear.svg" height="20" />
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                            <li><Links className="dropdown-item" to="/services#delivery-modes">Delivery Modes</Links></li>
                            <li><Links className="dropdown-item" to="/services#packing-services">Packing Services</Links></li>

                            <li><Links className="dropdown-item" to="/services#storage-services">Storage Service</Links></li>
                            <li><Links className="dropdown-item" to="/services#insurance-services">Insurance Service</Links></li>
                            <li><Links className="dropdown-item" to="/services#consolidation-service">Consolidation Service</Links></li>
                        </ul>
                    </li>

                    {!isLogged ?
                        <>




                            <li className="nav-item">
                                <Link className="nav-link px-3" to="/login">
                                    <img src="/assets/images/login.svg" height="20" />
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link className="nav-link px-3" to="/register">
                                    <img src="/assets/images/register.svg" height="20" />
                                </Link>
                            </li>

                        </>
                        :
                        <>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src="/assets/images/login.svg" height="20" />
                                </a>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                                    <li><Link className="dropdown-item" to="/my-profile#editProfile">My Profile</Link></li>
                                    <li><Link className="dropdown-item" to="/my-profile#changePassword">Change Password</Link></li>
                                    <li><Link className="dropdown-item" to="/my-profile#orderhistory">Order History</Link></li>
                                    <li><Link className="dropdown-item" to="/my-profile#quotation">My Quotations</Link></li>
                                    <li><Link className="dropdown-item" to="/my-profile#bookings">My Bookings</Link></li>
                                    <li><Link className="dropdown-item" to="/my-profile#invoice">My Invoices</Link></li>
                                    <li><Link className="dropdown-item" to="/my-profile#documents">My Documents</Link></li>
                                    <li><p className="dropdown-item" id="pills-bookings-tab" data-bs-toggle="pill" data-bs-target="#pillsbookings">
                                        <button type="button" onClick={(e) => Logout(e)}> Logout </button>
                                    </p></li>
                                </ul>
                            </li>
                        </>
                    }
                </nav>
            </div >

             navbar for mobile devices */}

      {/* navbar for desktop devices */}

      <div className="container-fluid ">
        <Link className="navbar-brand" to="/">
          <img
            //src="/assets/images/store2door_logo_traced.svg"
            src="/assets/images/NewStore2doorlogo.png"
            alt="Not found"
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            {Storage.get("user-token") != "management" && (
              <li className="nav-item ">
                <Link
                  to="/"
                  className="nav-link active px-md-3"
                  aria-current="page"
                >
                  Home
                </Link>
              </li>
            )}

            {Storage.get("user-token") === "management" && (
              <Link
                className="nav-link outline-btn px-md-3 nav_button"
                to="/dashboard"
              >
                Dashboard
              </Link>
            )}

            <li className="nav-item">
              <Link className="nav-link px-md-3" to="/about">
                About US
              </Link>
            </li>

            <li className="nav-item dropdown">
              {/* <a className="nav-link " href="#" role="button" data-bs-toggle="dropdown">
                                FAQS
                            </a> */}
              <Link
                className="nav-link px-md-3 dropdown-toggle"
                data-bs-toggle="dropdown"
                to=""
              >
                How It Works
              </Link>
              <ul className="dropdown-menu dropdown-menu-end">
                <li>
                  <Link className="dropdown-item" to="/faq">
                    FAQs
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/how-it-works">
                    How it works
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/country-guide">
                    Country Guide
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/tips-and-myths">
                    Tips/Myths
                  </Link>
                </li>
              </ul>
            </li>

            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
              >
                Services
              </a>
              <ul className="dropdown-menu dropdown-menu-end">
                <li>
                  <Links
                    className="dropdown-item"
                    to="/services#delivery-modes"
                  >
                    Delivery Modes
                  </Links>
                </li>
                <li>
                  <Links
                    className="dropdown-item"
                    to="/services#packing-services"
                  >
                    Packing Services
                  </Links>
                </li>
                <li>
                  <Links
                    className="dropdown-item"
                    to="/services#storage-services"
                  >
                    Storage Services
                  </Links>
                </li>
                <li>
                  <Links
                    className="dropdown-item"
                    to="/services#insurance-services"
                  >
                    Insurance Services
                  </Links>
                </li>
                {/* <li>
                  <Links
                    className="dropdown-item"
                    to="/services#consolidation-service"
                  >
                    Consolidation Services
                  </Links>
                </li> */}
              </ul>
            </li>
            <li className="nav-item dropdown d-none">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
              >
                Informative Videos
              </a>
              <ul
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <Link className="dropdown-item" to="#">
                    Why Insurance is Important
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="#">
                    Why is Packing Important
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="#">
                    What is Volume and how Volume Wt is Calculated
                  </Link>
                </li>
              </ul>
            </li>
            {/* <li className="nav-item">
                {
                    console.log(Storage.get("user-token"))
                }
              {Storage.get("user-token") === "retailer" ? (
                <Link
                  className="nav-link outline-btn px-md-3 nav_button"
                  to="/store"
                >
                  Stores
                </Link>
              ) : (
                <Link
                  className="nav-link outline-btn px-md-3 nav_button"
                  to="/track"
                >
                  Track
                </Link>
              )}
            </li> */}
            {/* <li className="nav-item">
                <Link
                  className="nav-link px-md-3"
                  onClick={() => url && url.includes('dev') ? window.open("http://w2dweblink.s3-website.us-east-2.amazonaws.com/") : window.open("https://www.world2door.com/consolidation-shopping/")}
                  // href='http://w2dweblink.s3-website.us-east-2.amazonaws.com/'
                >
                  Consolidation Shopping
                </Link>
            </li> */}
            
            <li className="nav-item">
              {Storage.get("user-token") === "retailer" ||
                Storage.get("user-token") === "store" ? (
                <Link
                  className="nav-link outline-btn px-md-3 nav_button"
                  to="/dashboard"
                >
                  Dashboard
                </Link>
              ) : Storage.get("user-token") != "management" ? (
                <Link
                  className="nav-link px-md-3"
                  to="/track"
                >
                  Track
                </Link>
              ) : null}
            </li>
            {Storage.get("user-token") != "management" ? (

              <li className="nav-item"><a type="button" onClick={() => setIsQuote(true)} className="nav-link outline-btn px-md-3 nav_button" href="javascript:void(0)">Get Quote</a></li>

            ) : null
            }
            {/* 
            <li className="nav-link px-md-3">
              <button  className="btn getquoteBtn">Get Quote</button>
            </li> */}
            {!isLogged ? (
              <>
                <li className="nav-item">
                  <Link className="nav-link px-md-3" to="/login">
                    Login
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link px-md-3" to="/register">
                    Sign Up
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link"
                    href="#"
                  >
                    {userName}
                  </a>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img src="/assets/images/user.svg" alt="Not found" />
                  </a>

                  <ul
                    className="dropdown-menu dropdown-menu-end"
                    aria-labelledby="navbarDropdown"
                  >
                    {Storage.get("user-token") === "retailer" ||
                      (Storage.get("user-token") === "store" && (
                        <li>
                          <Link className="dropdown-item" to="/dashboard">
                            Dashboard
                          </Link>
                        </li>
                      ))}
                    {Storage.get("user-token") === "retailer" ? (
                      <li>
                        <Link className="dropdown-item" to="/retailer">
                          Retailer's Profile
                        </Link>
                      </li>
                    ) : Storage.get("user-token") === "store" ? (
                      <li>
                        <Link className="dropdown-item" to="/store-profile">
                          Store Profile
                        </Link>
                      </li>
                    ) : (
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/my-profile#editProfile"
                        >
                          My Profile
                        </Link>
                      </li>
                    )}
                    {Storage.get("user-token") === "retailer" ||
                      Storage.get("user-token") === "store" ||
                      Storage.get("user-token") === "management" ? (
                      ""
                    ) : (
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/my-profile#changePassword"
                        >
                          Change Password
                        </Link>
                      </li>
                    )}

                    {Storage.get("user-token") === "retailer" ? (
                      <li>
                        <Link className="dropdown-item" to="/store">
                          Store
                        </Link>
                      </li>
                    ) : Storage.get("user-token") != "management" &&
                      Storage.get("user-token") != "store" &&
                      Storage.get("user-token") != "retailer" ? (
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/my-profile#orderhistory"
                        >
                          Order History
                        </Link>
                      </li>
                    ) : null}
                    {Storage.get("user-token") === "retailer" ? (
                      <li>
                        <Link className="dropdown-item" to="/product">
                          Products
                        </Link>
                      </li>
                    ) : Storage.get("user-token") != "management" &&
                      Storage.get("user-token") != "store" ? (
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/my-profile#quotation"
                        >
                          My Quotations
                        </Link>
                      </li>
                    ) : null}

                    {Storage.get("user-token") === "user" ? (
                      <li>
                        <Link className="dropdown-item" to="/all-bookings">
                          My Bookings
                        </Link>
                      </li>
                    ) : null}
                    {Storage.get("user-token") === "retailer" ? (
                      <li>
                        {/* <Link
                      className="dropdown-item"
                      onClick={() => { toast.info('coming soon.') }}
                      >
                      My Invoices
                      </Link> */}
                      </li>
                    ) : Storage.get("user-token") != "management" &&
                      Storage.get("user-token") != "store" &&
                      Storage.get("user-token") != "retailer" ? (
                      <li>
                        {/* <Link
                          className="dropdown-item"
                          to="/my-profile#invoice"
                        >
                          My Invoices
                        </Link> */}
                      </li>
                    ) : null}
                    {Storage.get("user-token") === "retailer" ? (
                      <li>
                        {/* <Link
                          className="dropdown-item"
                          onClick={() => { toast.info('coming soon.') }}
                          >
                          My Documents
                          </Link> */}
                      </li>
                    ) : Storage.get("user-token") != "management" &&
                      Storage.get("user-token") != "store" &&
                      Storage.get("user-token") != "retailer" ? (
                      <li>
                        {/* <Link
                          className="dropdown-item"
                          to="/my-profile#documents"
                        >
                          My Documents
                        </Link> */}
                      </li>
                    ) : null}

                    {/* {
                        Storage.get('user-token') !== 'retailer' && 
                        <li><Link className="dropdown-item" to="/retailer">Retailer</Link></li>
                    } */}

                    { 
                      (Storage.get("user-token") !== "retailer"
                      && Storage.get("user-token") !== "store"
                      && Storage.get("user-token") !== "management"
                      && <li>
                        <Link
                          className="dropdown-item"
                          to="/my-profile#bookings"
                        >
                          My Bookings
                        </Link>
                      </li>
                      )
                    }

                    

                    <li>
                      <hr className="mb-1" />
                    </li>
                    <li>
                      <a
                        className="dropdown-item fw-bold"
                        onClick={(e) => Logout(e)}
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                </li>

              </>
            )}
          </ul>
        </div>
      </div>
      {/* navbar for desktop devices */}
    </nav>

  );
};
export default Header;
