import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import OrderHistory from "../Components/OrderHistory";
import MyDocuments from "../Components/MyDocuments";
import MyInvoices from "../Components/MyInvoices";
import MyQuotations from "../Components/SavedQuotes";
import MyBookings from "../Components/MyBookings";
import { authServices } from "../services";
import { Storage } from "../Storage/Storage";
import { toast } from "react-toastify";
import * as ProfileUpdate from "./ProfileUpdate";
import SimpleReactValidator from "simple-react-validator";
import StoreList from "./Bookings/StoreList";

export default function Profile() {
  const history = useHistory();

  const [, setForceUpdate] = useState("");
  const validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: setForceUpdate },
    })
  );

  const validatorTwo = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: setForceUpdate },
    })
  );

  const [activeId, setActiveId] = useState("#editprofile");
  const [user, setUser] = useState(Storage.get("user-auth"));
  const [profileDetail, setProfileDetail] = useState();

  const [password, setPassword] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const Handlechange = (event) => {
    setPassword({ ...password, [event.target.name]: event.target.value });
  };

  const location = useLocation();
  useEffect(() => {
    setActiveId(location.hash);
  }, [location]);

  const changePassword = (event) => {
    event.preventDefault();

    let formvalid = validatorTwo.current.allValid();
    if (formvalid) {
      let postData = {
        old_password: password.old_password,
        new_password: password.new_password,
      };
      authServices
        .change_user_password(postData)
        .then((res) => {
          if (res.status === true) {
            history.push("/");
            toast.success(res.message);
          } else {
            toast.error(res.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      validatorTwo.current.showMessages();
    }
  };

  // const validPass = () => {
  //   if (password?.confirm_password.password !== password?.confirm_password.rePassword) {
  //   }
  // };

  useEffect(() => {
    getProfileData();
  }, []);

  const getProfileData = () => {
    const profileCall = isManagement
      ? authServices.getProfileRetailer
      : authServices.getProfileUser;

    profileCall()
      .then((res) => {
        if (res.status === true) {
          let ObjectData = res.data;
          if (profileDetail?.user_dial_code) {
            ObjectData["user_dial_code"] = profileDetail?.user_dial_code;
          }
          ObjectData["postal_code"] = res.data?.postalcode;
          ObjectData["country"] = res.data?.user_country;
          console.log(ObjectData,'ObjectData')
          setProfileDetail(ObjectData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeHandler = (e) => {
    const { value, name } = e.target;
    setProfileDetail({ ...profileDetail, [name]: value });
  };

  const contactHandler = (event, g) => {
    if (g.target.value) {
      let num = g.target.value
        .split(" ")
        .slice(1)
        .join("")
        .replace(/[^a-zA-Z0-9]/g, "");
      let code = g.target.value.split(" ")[0];
      // matchNumber(Phonenumber);
      let coding = (profileDetail.user_phone_number = code);
      let numbering = (profileDetail.user_phone_number = num);
      setProfileDetail({ ...profileDetail, user_phone_number: numbering });
      setProfileDetail({ ...profileDetail, user_dial_code: coding });
    }
  };

  const submitProfile = (e, types) => {
    e.preventDefault();
    if (profileDetail) {
      let postData = {
        user_full_name: profileDetail.user_full_name,
        user_type: profileDetail.user_type,
        user_name: profileDetail.user_email,
        user_phone_number: profileDetail.user_phone_number,
        user_gender: profileDetail.user_gender,
        address: profileDetail.address,
        user_country: profileDetail.country,
        state: profileDetail.state,
        city: profileDetail.city,
        postalcode: profileDetail.postal_code,
      };

      let validate = validator.current.allValid();
      if (validate) {
        if (types == "user") {
          authServices
            .profileUpdateUser(postData)
            .then((res) => {
              if (res.status === true) {
                getProfileData();
                toast.success(res.message);
                window.location.reload();
              } else {
                toast.error(res.message);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        validator.current.showMessages();
      }
    }
  };

  const isManagement = Storage.get("user-token") === "management";
  // console.log("isManagement ....", isManagement);

  return (
    <section className="pb-5 pt-0">
      <div className="header_title">
        <div className="container-fluid">
          <h1>My Profile</h1>
          <img
            src="/assets/images/country.jpg"
            className="bannerimg"
            alt="Not found"
          />
        </div>
      </div>
      <div className="container-fluid mt-5">
        <div className="white_box h-100">
          <div className="row">
            {Storage.get("user-token") != "management" ? (
              <ul
                className="nav mb-3 tablist profiletab"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <Link
                    className={
                      "nav-link" +
                      (activeId === "#editProfile" ? " active " : "")
                    }
                    id="editprofile-tab"
                    to="/my-profile#editProfile"
                  >
                    <img
                      className="showonmob"
                      height="20"
                      src="/assets/images/edit_profile.svg"
                      alt="Not found"
                    />
                    <span className="showondesk">Edit Profile</span>
                  </Link>
                </li>

                <li className="nav-item" role="presentation">
                  <Link
                    className={
                      "nav-link" +
                      (activeId === "#changePassword" ? " active " : "")
                    }
                    to="/my-profile#changePassword"
                    id="changePassword-tab"
                  >
                    <img
                      className="showonmob"
                      height="20"
                      src="/assets/images/password.svg"
                      alt="Not found"
                    />
                    <span className="showondesk">Change Password</span>
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link
                    className={
                      "nav-link" +
                      (activeId === "#orderhistory" ? " active " : "")
                    }
                    to="/my-profile#orderhistory"
                    id="orderhistory-tab"
                  >
                    <img
                      className="showonmob"
                      height="20"
                      src="/assets/images/order_history.svg"
                      alt="Not found"
                    />
                    <span className="showondesk">Order History</span>
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link
                    className={
                      "nav-link" + (activeId === "#quotation" ? " active " : "")
                    }
                    to="/my-profile#quotation"
                    id="quotation-tab"
                  >
                    <img
                      className="showonmob"
                      height="20"
                      src="/assets/images/quotation.svg"
                      alt="Not found"
                    />
                    <span className="showondesk">My Quotations</span>
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link
                    className={
                      "nav-link" + (activeId === "#bookings" ? " active " : "")
                    }
                    to="/my-profile#bookings"
                    id="bookings-tab"
                  >
                    <img
                      className="showonmob"
                      height="20"
                      src="/assets/images/my_booking.svg"
                      alt="Not found"
                    />
                    <span className="showondesk">My Bookings</span>
                  </Link>
                </li>
                {/* <li className="nav-item" role="presentation">
                  <Link
                    className={
                      "nav-link" + (activeId === "#invoice" ? " active " : "")
                    }
                    to="/my-profile#invoice"
                    id="invoice-tab"
                  >
                    <img
                      className="showonmob"
                      height="20"
                      src="/assets/images/invoice.svg"
                      alt="Not found"
                    />
                    <span className="showondesk">My Invoices</span>
                  </Link>
                </li> */}
                {/* <li className="nav-item" role="presentation">
                  <Link
                    className={
                      "nav-link" + (activeId === "#documents" ? " active " : "")
                    }
                    to="/my-profile#documents"
                    id="documents-tab"
                  >
                    <img
                      className="showonmob"
                      height="20"
                      src="/assets/images/document.svg"
                      alt="Not found"
                    />
                    <span className="showondesk">My Documents</span>
                  </Link>
                </li> */}
              </ul>
            ) : (
              <ul
                className="nav mb-3 tablist profiletab"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <Link
                    className={
                      "nav-link" +
                      (activeId === "#editProfile" ? " active " : "")
                    }
                    id="editprofile-tab"
                    to="/my-profile#editProfile"
                  >
                    <img
                      className="showonmob"
                      height="20"
                      src="/assets/images/edit_profile.svg"
                      alt="Not found"
                    />
                    <span className="showondesk">Profile</span>
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link
                    className={
                      "nav-link" + (activeId === "#stores" ? " active " : "")
                    }
                    id="stores-tab"
                    to="/my-profile#stores"
                  >
                    <img
                      className="showonmob"
                      height="20"
                      src="/assets/images/edit_profile.svg"
                      alt="Not found"
                    />
                    <span className="showondesk">Stores</span>
                  </Link>
                </li>

                <li className="nav-item" role="presentation">
                  <Link
                    className={
                      "nav-link" +
                      (activeId === "#retailertoken" ? " active " : "")
                    }
                    to="/my-profile#retailertoken"
                    id="retailertoken-tab"
                  >
                    <img
                      className="showonmob"
                      height="20"
                      src="/assets/images/password.svg"
                      alt="Not found"
                    />
                    <span className="showondesk">Retailer-Token (Api key)</span>
                  </Link>
                </li>
              </ul>
            )}
            <div className="col-md-12">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className={
                    "tab-pane fade" +
                    (activeId === "#editProfile" ? " show active " : " ")
                  }
                  id="editprofile"
                  role="tabpanel"
                  aria-labelledby="editprofile-tab"
                >
                  {Storage.get("user-token") != "management" && (
                    <h5 className="my-4 fw-bold">Edit Profile</h5>
                  )}
                  {/* {
                  Storage.get("user-token") === "retailer" ?
                  <ProfileUpdate.RetailerProfile
                      detail={profileDetail}
                      valueHandler={(e)=>changeHandler(e)}
                  />:
                  <ProfileUpdate.UserProfile
                      detail={profileDetail}
                      valueHandler={(e)=>changeHandler(e)}
                      submitHandler={(e, type)=> submitProfile(e, type)}
                      phoneHandler={(p, g)=> contactHandler(p, g)}
                  />
              } */}
                  {isManagement ? (
                     profileDetail && <ProfileUpdate.RetailerProfile
                      detail={profileDetail}
                      valueHandler={(e) => changeHandler(e)}
                      submitHandler={(e, type) => submitProfile(e, type)}
                      phoneHandler={(p, g) => contactHandler(p, g)}
                      validation={validator}
                    />
                  ) : (
                    profileDetail && <ProfileUpdate.UserProfile
                      detail={profileDetail}
                      valueHandler={(e) => changeHandler(e)}
                      submitHandler={(e, type) => submitProfile(e, type)}
                      phoneHandler={(p, g) => contactHandler(p, g)}
                      validation={validator}
                    />
                  )}
                </div>
                <div
                  className={
                    "tab-pane fade" +
                    (activeId === "#changePassword" ? " show active " : " ")
                  }
                  id="changePassword"
                  role="tabpanel"
                  aria-labelledby="changePassword-tab"
                >
                  <h5 className="my-4 fw-bold">Change Password</h5>
                  <form
                    onSubmit={(event) => {
                      changePassword(event);
                    }}
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group mb-3">
                              <label>Current Password</label>
                              <input
                                className="form-control rounded_input"
                                onChange={(e) => {
                                  Handlechange(e);
                                }}
                                name="old_password"
                                type="password"
                                placeholder="Current Password"
                              />
                              {validatorTwo.current.message(
                                "Current Password",
                                password?.old_password,
                                "required",
                                {
                                  className: "text-danger",
                                }
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group mb-3">
                              <label>New Password</label>
                              <input
                                className="form-control rounded_input"
                                onChange={(e) => {
                                  Handlechange(e);
                                }}
                                name="new_password"
                                type="password"
                                placeholder="New Password"
                              />
                              {validatorTwo.current.message(
                                "New Password",
                                password?.new_password,
                                "required",
                                {
                                  className: "text-danger",
                                }
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group mb-3">
                              <label>Confirm Password</label>
                              <input
                                className="form-control rounded_input"
                                type="password"
                                placeholder="Confirm Password"
                                // onKeyUp={(e) =>  validPass(e)}
                                name="confirm_password"
                                onChange={(e) => {
                                  Handlechange(e);
                                  
                                  
                                }}
                              />
                              <span style={{ color: "#dc3545" }}>
                              {validatorTwo.current.message(
                                "Confirm Password",
                                // password?.confirm_password,
                                // `required|in:${password?.new_password}`,
                                // {
                                //   className: "text-danger",
                                // }
                                password?.confirm_password,
                                `required|in:${password?.new_password}`,
                                { messages: { in: "Password Does not match!" } }
                              )}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <button type="submit" className="themebutton px-4">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                {Storage.get("user-token") === "management" && (
                  <div
                    className={
                      "tab-pane fade" +
                      (activeId === "#stores" ? " show active " : " ")
                    }
                    id="stores"
                    role="tabpanel"
                    aria-labelledby="changePassword-tab"
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <StoreList />
                        {/* <div className="row">
                          <div className="col-md-12">
                            <div className="form-group mb-3">
                              <label>Api Key</label>
                              <p className="m-0">
                                {profileDetail && profileDetail.stores}
                              </p>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                )}
                {Storage.get("user-token") === "management" && (
                  <div
                    className={
                      "tab-pane fade" +
                      (activeId === "#retailertoken" ? " show active " : " ")
                    }
                    id="retailertoken"
                    role="tabpanel"
                    aria-labelledby="changePassword-tab"
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group mb-3">
                              <label>Api Key</label>
                              <p className="m-0">
                                {profileDetail && profileDetail.retailer_token}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  // code for store list
                  //end
                )}
                <div
                  className={
                    "tab-pane fade" +
                    (activeId === "#orderhistory" ? " show active " : " ")
                  }
                  id="orderhistory"
                  role="tabpanel"
                  aria-labelledby="orderhistory-tab"
                >
                  <h5 className="my-4 fw-bold">Order History</h5>
                  {!isManagement ? <OrderHistory /> : ""}
                </div>
                <div
                  className={
                    "tab-pane fade" +
                    (activeId === "#quotation" ? " show active " : " ")
                  }
                  id="quotation"
                  role="tabpanel"
                  aria-labelledby="quotation-tab"
                >
                  <h5 className="my-4 fw-bold">My Saved Quotes</h5>
                  {!isManagement ? <MyQuotations /> : ""}
                </div>
                <div
                  className={
                    "tab-pane fade" +
                    (activeId === "#bookings" ? " show active " : " ")
                  }
                  id="bookings"
                  role="tabpanel"
                  aria-labelledby="bookings-tab"
                >
                  {!isManagement ? <MyBookings /> : ""}
                </div>
                {/* <div
                  className={
                    "tab-pane fade" +
                    (activeId === "#invoice" ? " show active " : " ")
                  }
                  id="invoice"
                  role="tabpanel"
                  aria-labelledby="invoice-tab"
                >
                  <h5 className="my-4 fw-bold">My Invoices</h5>
                  {!isManagement ? <MyInvoices /> : ""}
                </div> */}
                <div
                  className={
                    "tab-pane fade" +
                    (activeId === "#documents" ? " show active " : " ")
                  }
                  id="documents"
                  role="tabpanel"
                  aria-labelledby="myDocuments-tab"
                >
                  <h5 className="my-4 fw-bold">My Documents</h5>
                  {!isManagement ? <MyDocuments /> : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
