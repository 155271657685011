import React, { useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { UserDetails } from "../../Redux/action";
import { useDispatch } from "react-redux";
import { Storage } from "../../Storage";
import { authServices } from "../../services";
import { toast } from "react-toastify";
import simpleReactValidator from "simple-react-validator";
export default function StoreLogin() {
  const [, setForceUpdate] = useState("");
  const validator = useRef(
    new simpleReactValidator({
      autoForceUpdate: { forceUpdate: setForceUpdate },
    })
  );
  const history = useHistory();
  const [store_email, setStoreEmail] = useState("");
  const [store_password, setPassword] = useState("");
  const [type] = useState("store");
  const dispatch = useDispatch();

  const submitHandle = (e) => {
    e.preventDefault();
    let postData = {
      store_email: store_email,
      store_password: store_password,
      type: type,
    };
    let validate = validator.current.allValid();
    if (validate) {
      if (postData) {
        authServices
          .store_login(postData)
          .then((result) => {
            if (result.status && result.data != null) {
              dispatch(UserDetails({ data: result.data }));
              Storage.set("user-token", postData.type);
              Storage.set("user-auth", result.data);
              history.push({
                pathname: "/",
                msg: "successfully login as a store",
              });
            } else {
              toast.error(result.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      validator.current.showMessages();
    }
  };

  return (
    <div className="loginpage registerpagecustom">
      <img
        src="assets/images/login_bg.jpg"
        className="loginbg"
        alt="Not found"
      />
      <div className="flex-grow-1 text-center loginchar"></div>
      <div className="loginbox">
        <h2 className="mb-5">Sign in as a Store</h2>
        <form className="w-100" onSubmit={(e) => submitHandle(e)}>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="floatingEmail"
              placeholder="Email"
              //   name="user_login"
              onChange={(e) => setStoreEmail(e.target.value)}
            />
            <label for="floatingEmail">Username (Email)</label>
            {validator.current.message("Email", store_email, "required", {
              className: "text-danger",
            })}
          </div>

          <div className="form-floating">
            <input
              type="password"
              className="form-control"
              id="floatingPassword"
              placeholder="Password"
              //   name="user_password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <label for="floatingPassword">Password</label>
            {validator.current.message("Password", store_password, "required", {
              className: "text-danger",
            })}
          </div>

          <div className="mt-3 col-12">
            <button className="themebutton w-100" type="submit">
              Login
            </button>
          </div>

          <div className="text-center mt-5">
            <div className="col-12">
              <hr />
              <p className="my-3">Already have a Account ? then login as Store </p>
            </div>

            <div className="col-12 mt-3">
              <div className="row justify-content-center">
                {/* <div className="col-4">
                  <Link to="/login" className="themebutton">
                    Customer
                  </Link>
                </div>
                <div className="col-4">
                  <Link to="/retailer_login" className="themebutton">
                    Retailer
                  </Link>
                </div>
                <div className="col-4">
                  <Link to="/management_login" className="themebutton">
                    Management
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
