import React from "react";

export default function InsuranceTermsConditions() {
  return (
    <section className="pb-5 pt-0">
      <div className="header_title">
        <div className="container-fluid">
          <h1>Insurance Terms & Conditions</h1>
          <img
            src="/assets/images/about-us-inner-banner.jpg"
            className="bannerimg"
            alt="Not found"
          />
        </div>
      </div>
      <div className="container-fluid mt-5">
        <div className="white_box h-100">
          <div className="row">
            <div className="col-md-12 text-center py-4">
              <img
                src="/assets/images/insurance_img.svg"
                alt="Not found"
                className="mw-100 policyImg"
              />
            </div>
            <div className="col-md-12">
              <h3 className="fw-bold mb-4">TRANSIT INSURANCE Coverage Policy</h3>
              <ul className="fancylist">
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    Without prejudice to the afore-mentioned, Kens World2Door Cargo LLC agrees on request of the customer to arrange transit insurance for their cargo on their behalf. Kens World2Door Cargo LLC acts solely as facilitator for the customer to arrange such insurance and does so subject to the limits of liability contained herein. Please go through the Insurance Terms & Conditions displayed in the Home page in the Insurance Terms & Conditions section. The Terms & Conditions are laid by Insurance Company and Kens World2Door Cargo LLC does not have the right to make any amendments to it whatsoever.
                  </div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    The insurance offered as an “Add On” service is through Insurance Company and the terms and conditions agreed upon is between the Insurance company and the customer. Kens World2Door Cargo LLC is in no way responsible for the claims denial and exceptions from the insurance company.
                  </div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    Certain countries may not be covered by the insurance company which are mentioned on our website/app. In such cases, the customer may have to find an insurance company on their own and get their cargo/shipment insured.
                  </div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    No insurance will be affected except upon express instructions given in writing by the customer and is confirmed and accepted by Kens World2Door Cargo LLC or through the “ADD ON” section in the website/ App and all insurances affected by Kens World2Door Cargo LLC are subject to the usual exceptions and conditions of the policies of insurance company or underwriters taking the risk.
                  </div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    In case the Customer does not give any instruction to effect the insurance by selecting the “ADD ON” option or in writing, the cargo will not be insured and Kens World2Door Cargo LLC will not be responsible for any loss or damage.
                  </div>
                </li>
              </ul>

              <h3 className="fw-bold mb-4">
                INSURANCE TERMS & CONDITIONS ON BEHALF OF INSURANCE COMPANY:
              </h3>

              <p>
                Subject Matter Insured: Household and Personal effects
                EXCLUDING: Furs, Cash, bank notes, stamps, deeds, tickets, travellers’ cheques, jewelry, watches and similar valuable articles, foodstuff, alcoholic beverages, coins and other collections of similar nature.
                Hereafter declared and valued by the assured but subject to the valuation clause, as attached.
              </p>

              <p>
                Basis of Valuation:Declared and valued at current market value at destinations and subject to valuation clause.
                Voyage(s):

              </p>
              <ul className="fancylist">
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>Voyage(s):</div>
                  <ul>
                    <li>
                      i. Voyage I –
                      From: United Arab Emirates
                      To: World
                    </li>
                    <li>

                      <div>Excluding:</div>
                      <ul>
                        <li>C.I.S Countries, Afghanistan, Iraq, Iran and Syria absolutely.</li>
                        <li>Continent of Africa – South Africa, Egypt, Morocco, Tunisia covered upto final destination - Other African countries upto no risk after discharge at destination port / airport.</li>

                      </ul>
                    </li>
                    <li>
                      Voyage II - Within GCC by Road
                    </li>
                    <li>Voyage III - Within UAE by Road</li>

                  </ul>

                </li>

              </ul>
              <h3 className="fw-bold mb-4">Sanction Limitation and Exclusion Clause:</h3>
              <ul className="fancylist">
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    As per wording below Under no circumstances shall this insurance contract be deemed to provide cover and no liability be incurred to pay any claim or provide any benefit hereunder to the extent that the provision of such cover, payment of such claim or provision of such benefit would expose insurers to any sanction, prohibition, or restriction under United Nations resolutions or the trade or economic sanctions, laws or regulations of the European Union, United Kingdom or United States of America or any other applicable trade or economic sanctions, laws or regulations.
                  </div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    For any shipment to / from UN Sanctioned Countries, prior intimation to be sent to underwriters for specific approval with additional information as may be required from time to time, prior to attachment of risk / commencement of transit.
                  </div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    Other voyages held covered on rates and conditions to be agreed prior to attachment of risk.
                  </div>
                </li>
              </ul>
              <h3 className="fw-bold mb-4">Conveyance(s):</h3>
              <ul className="fancylist">
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    Vessel and/or vessels (as per Institute Classification Clause) and/or airfreight and/or Road conveyance.
                  </div>
                </li>
              </ul>

              <h3 className="fw-bold mb-4">Valuation Limit of Shipments:</h3>
              <ul className="fancylist">
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    LIMIT ANY ONE SHIPMENT Household Goods – Dh. 1,000,000/-
                    LIMIT ANY ONE LOCATION Household Goods – Dh. 1,000,000/-
                  </div>
                </li>
              </ul>
              <h3 className="fw-bold mb-4">Clauses:</h3>
              <ul className="fancylist">
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    Household goods and personal effects full conditions- All risks (Professionally packed or store packed cargo only)
                  </div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    Institute Cargo Clauses (A) CI.382 1.1.09, as attached Institute Cargo Clauses (Air Cargo) CI.387 1.1.09, as attached Land Transit Clauses (All Risks), as attached
                  </div>
                </li>
              </ul>

              <h3 className="fw-bold mb-4">Transit Clause:</h3>
              <ul className="fancylist">
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    Cover hereunder attaches from the time representatives of Kens World2Door Cargo LLC picks up the packages, doing packing, moving the goods into their warehouse or collecting van, during the local transit, including any incidental storage in their own or rented warehouse prior to loading into a nominated vessels or shipment by Air or by Road and terminates when the goods are delivered to the customer or their agent at destination.
                    The cover is limited of the physical loss of or damage to the goods belongs to the Insured.
                  </div>
                </li>
              </ul>
              <h3 className="fw-bold mb-4">Notice of Claim:</h3>
              <ul className="fancylist">
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    To be provided as soon as possible within reasonable time since cargo is normally unpacked by Kens World2Door Cargo LLC or their agents at destination any losses would be discovered immediately. In other cases, we would require that claims are reported to us within 15 days of termination of risk under the policy. Claim procedures as attached
                  </div>
                </li>
              </ul>
              <h3 className="fw-bold mb-4">Storage Cover:</h3>
              <ul className="fancylist">
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    Provided goods are in a secure warehouse premises cover includes storage for upto 30 days from arrival at packer`s / forwarder`s premises in the country of origin and a further 30 days from arrival at the premises of the agent of packer`s / forwarder`s at destination.
                  </div>
                </li>
              </ul>
              <h3 className="fw-bold mb-4">Valuation Clause:</h3>
              <ul className="fancylist">
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    In case sum insured hereunder in respect of any article or articles being less than the present-day market value at destination then, in the event of loss of or damage the amount recoverable by the Assured, inclusive of reconditioning charges and replacement costs, shall be in every case only such proportion as the sum insured bears to the market value at destination.                  </div>
                </li>
              </ul>

              <h3 className="fw-bold mb-4">Replacement Clause:</h3>
              <ul className="fancylist">
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    In the event of loss or damage to any part of an insured item the basis of a claim settlement will be the appropriate cost of repairs or replacement of such part that the insured value bears to the current market value of the items involved plus charges for refitting.
                  </div>
                </li>
              </ul>

              <h3 className="fw-bold mb-4">Pairs and Sets Clause:</h3>
              <ul className="fancylist">
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    Where an insured item consists of articles in a pair or set the policy will only pay the value of any particular part which may be lost or damaged or the proportionate value of such part, without reference to any special value such article may have as part of such pair or set.
                  </div>
                </li>
              </ul>

              <h3 className="fw-bold mb-4">Antiques Clause:</h3>
              <ul className="fancylist">
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    In the event of damage to antique items insured hereunder, liability is restricted to the reasonable cost of repairs and no claim is recoverable in respect of depreciation. In no case shall liability hereunder for such repairs exceed the sum insured in respect of the damaged article.                 </div>
                </li>
              </ul>

              <h3 className="fw-bold mb-4">Exchange Rate:</h3>
              <ul className="fancylist">
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    Exchange rate prevailing as of the date of signing of proposal by the Insured.
                  </div>
                </li>
              </ul>
              <h3 className="fw-bold mb-4">Clauses :</h3>
              <ul className="fancylist">
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                  Institute War Clauses (Cargo) CI.385.1.1.09, as attached Institute Strike Clauses (Cargo) CI.386.1.1.09, as attached Institute War Clauses (Air Cargo) (excluding sendings by post) Institute Strike Clause (Air Cargo) CI.389.1.1.09, as attached Institute Classification Clause CI.354.1.1.01 Institute Radioactive Contamination, Chemical, Biological, Bio-chemical and Electromagnetic Weapons Exclusion Clause CI.370.10.11.03 Institute Cyber Attack Exclusion Clause CI.380.10.11.03 Institute Standard Conditions for cargo contracts Important Notice Clause Termination of transit clause – Terrorism Including Loading and Unloading
                  </div>
                </li>
              </ul>

              <h3 className="fw-bold mb-4">Warranties :</h3>
              <ul className="fancylist">
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  Warranted brand new goods only.
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  All items being shipped are declared for insurance at current market value at destination.
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  Shipped under deck except for containerized goods.
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  No single article to exceed USD 10,000/- unless agreed by Underwriters prior to attachment of risk.                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  Claims for non-delivery to be supported by appropriate documentary evidence.                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  All items subject to proof of value in the event of a claim.                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  Fragile items not to exceed 15% of sum insured or held covered at a marine premium rate of 3% on the value of shipment.                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  Warranted filled up proposal to be submitted to underwriter prior to attachment of risk.
                </li>
              
              </ul>
       

             <h5 className="fw-bold mb-4">EXCLUSIONS:</h5>
              <ul className="fancylist">
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    Rust, Oxidation or Discoloration of any items unless caused
                    by direct contact with seawater/Rainwater.
                  </div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    Mechanical, Electrical, Electronic derangement unless due to
                    an insured peril.
                  </div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    Loss or damage due to moth, vermin, wear, tear and gradual
                    deterioration.
                  </div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    Consequential loss or expense of whatsoever description.
                  </div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    oss or damage to furs, cash, bank notes, stamps, deeds,
                    tickets, travellers cheques, jewelry, watches and similar
                    valuable articles, foodstuffs, alcoholic beverages, coins
                    and other collections of a similar nature.
                  </div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    Loss or damage due to climatic or atmospheric conditions or
                    extremes of temperature.
                  </div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    Confiscation or detention by customs or other Authorities or
                    caused by delay.
                  </div>
                </li>
              </ul>

              <h5 className="fw-bold mb-4">Excess</h5>
              <ul className="fancylist">
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                  An Excess fee of AED 750/- for each and every claim for Loss/Damages.
                  </div>
                </li>
              </ul>

              <h3 className="fw-bold mb-4">Sanctioned Country List:</h3>
              <p>
              We will not trade with or provide any services to OFAC (Office of Foreign Assets Control) and sanctioned countries in accordance with the law of UAE.
              </p>
              {/* <ul className="fancylist">
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>Cuba</div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>Iran</div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>Sudan</div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>Syria</div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>Democratic Republic of Congo</div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>Russia</div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>North Korea</div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>Somalia</div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>Zimbabwe</div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>Ukraine</div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>Belarus</div>
                </li>
              </ul> */}

              {/* <h3 className="fw-bold mb-4">CUSTOM CLEARANCE</h3>
              <p>
                World2Door shall provide customs clearance services to the
                customer through our appointed customs brokers or vendors
                subject to the customs clearance regulations in the country of
                export and in the country of import.
              </p>
              <p>
                <em className="text-danger">
                  Note: The Duty and Taxes if any at the destination country to
                  be paid by the customer at the destination to our appointed
                  agent at actuals before delivery of goods.
                </em>
              </p>

              <h3 className="fw-bold mb-4">CDELIVERY CONDITIONS</h3>

              <h5 className="fw-bold mb-4">Port Delivery:</h5>
              <p>
                Port Delivery means the shipment will be delivered only up to
                the airport/seaport. The customs clearance and delivery at the
                destination is not included the scope of work
              </p>
              <ul className="fancylist">
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>Inclusions:</div>
                  <ul>
                    <li>
                      Collection of goods from the World/any place at origin.
                    </li>
                    <li>Customs clearance at origin</li>
                    <li>
                      Transportation of goods to the destination port through
                      one of the transport modes (Air/Sea/Land)
                    </li>
                  </ul>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>Exclusions:</div>
                  <ul>
                    <li>Destination airlines/Shipping lines charges.</li>
                    <li>Destination customs clearance</li>
                    <li>
                      Destination transportation from the port to the
                      destination
                    </li>
                    <li>Customs duty and taxes</li>
                    <li>
                      Shuttle services (In cases where there is no accessibility
                      for trucks to reach the residence)
                    </li>
                    <li>Handy man services</li>
                    <li>
                      Long walk (Where the distance from the offloading point to
                      the customer’s residence is very long)
                    </li>
                    <li>
                      Stair carry (In cases where the delivery is above 1st
                      floor and there is no lift/elevator or cargo does not fit
                      in the lift)
                    </li>
                  </ul>
                </li>
              </ul>

              <p className="text-danger">
                <em>
                  <b>Note:</b> All Charges in the exclusions but not limited and
                  which are applicable must be paid to the relevant service
                  providers at the destination by the customer.
                </em>
              </p>

              <h5 className="fw-bold mb-4">Door Delivery:</h5>
              <p>
                In this service, the cargo is delivered to the final address of
                the customer at the destination
              </p>
              <ul className="fancylist">
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>Inclusions:</div>
                  <ul>
                    <li>
                      Collection of goods from the World/Any place at origin.
                    </li>
                    <li>Customs clearance at origin</li>
                    <li>
                      Transportation of goods to the destination port through
                      one of the transport modes (Air/Sea/Land)
                    </li>
                    <li>Destination Airlines/Shipping lines charges</li>
                    <li>Destination customs clearance</li>
                    <li>
                      Destination transportation from the port to the final
                      destination
                    </li>
                  </ul>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>Exclusions:</div>
                  <ul>
                    <li>Customs Duty and Taxes</li>
                    <li>Demurrage & Storage Charges</li>
                    <li>
                      Shuttle services – (In cases where there is no
                      accessibility for trucks up to the residence)
                    </li>
                    <li>Handy man services</li>
                    <li>
                      Long walk (Where the distance from the offloading point to
                      the customer’s residence is very long)
                    </li>
                    <li>
                      Stair Carry (In cases where the delivery is Above 1st
                      Floor and there is No Lift or Cargo does not fit in the
                      Lift)
                    </li>
                    <li>Debris Removal/ Unpacking</li>
                    <li>
                      Over time Charges related to weekend Deliveries or outside
                      regular working hours
                    </li>
                  </ul>
                </li>
              </ul>
              <p className="text-danger">
                <em>
                  <b>Note:</b> All Charges in the Exclusions but not limited and
                  which are applicable must be paid to the relevant service
                  providers at the destination by the customer before delivery
                  of goods
                </em>
              </p>

              <h3 className="fw-bold mb-4">
                Land Transport to GCC & Middle East Countries
              </h3>

              <ul className="fancylist">
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    GCC Standard Inclusions:
                    <h5 className="fw-bold my-4">Origin Inclusions</h5>
                    <ul>
                      <li>Collection from origin location.</li>
                      <li>Loading into truck/trailer.</li>
                      <li>
                        Transportation from UAE to Final Destination Country.
                      </li>
                      <li>Borders customs clearance fees.</li>
                    </ul>
                    <h5 className="fw-bold my-4">Destination Inclusions:</h5>
                    <ul>
                      <li>Offloading at client’s residence.</li>
                      <li>
                        Unpacking of boxes on the flat surface (No arrangement
                        in drawer/cabinet etc…)
                      </li>
                      <li>Unwrapping of furniture items.</li>
                      <li>
                        Set up of basic furniture & removing of debris on the
                        same day of delivery.
                      </li>
                    </ul>
                  </div>
                </li>

                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    GCC Standard Exclusions:
                    <h5 className="fw-bold my-4">Exclusions at Borders:</h5>
                    <ul>
                      <li>Duty &Taxes</li>
                      <li>Loading into truck/trailer.</li>
                      <li>
                        Truck/Trailer halt charges, if any; for instance, border
                        congestion or any type of delay at border.
                      </li>
                      <li>
                        The customer gets only 2 days free detention after truck
                        reaches at border.
                      </li>
                    </ul>
                    <h5 className="fw-bold my-4">Exclusions at Destination:</h5>
                    <ul>
                      <li>
                        Storage in transit, truck detention, placement of items
                        into cupboards/shelves etc…
                      </li>
                      <li>
                        Reassembly of flatpack/new/IKEA/complicated furniture.
                      </li>
                      <li>
                        Handyman services like carpenter/plumber/painter/maid
                        services/cleaning/repairing services etc…
                      </li>
                      <li>
                        Wall fixing of units/curtains/TV/frames/paintings/lights
                        etc…
                      </li>
                      <li>
                        Stair carry above 1st floor level, difficult/long
                        carry/shuttle service, heavy lift carry, parking permits
                        and such associated charges, handling of any heavy items
                        like safe, pianos, billiard tables and the like,
                        delivery to more than one address or split delivery
                      </li>
                    </ul>
                  </div>
                </li>

                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    Guidelines for Restricted/Dutiable Goods For All GCC
                    Countries:
                    <h5 className="fw-bold my-4">
                      Duties could be applied as follows or will be charged At
                      Actuals:
                    </h5>
                    <ul>
                      <li>Electrical / electronic appliances - 5%</li>
                      <li>Audiotapes, videotapes, CDs / DVDs - 5%</li>
                      <li>Furniture - 15%</li>
                      <li>
                        Books and used clothing (Reasonable quantities are
                        exempt from duty payments)
                      </li>
                      <li>Other Items: 5% or at actuals.</li>
                    </ul>
                    <h5 className="fw-bold my-4">Restricted Items:</h5>
                    <p>
                      The following items are subject to censorship,
                      confiscation, or may result in penalties to the owner of
                      the goods:
                    </p>
                    <ul>
                      <li>
                        Pornographic films or obscene material in prints,
                        slides, records, books, magazines, pictures, photos,
                        other printed matters, personal computers, audio/video
                        CDs, diskettes, USBs, computer hard disks, printers,
                        mobile or cellular phones, audio/video VHS/VCR/cassette
                        tapes etc.
                      </li>
                      <li>
                        Any items that World obscene material, a penalty will be
                        charged to the consignee for CDs, VCR tapes and books
                        confiscated after the censorship. (Approx. US$ 150.00
                        each for CDs and VCR tapes; and US$ 20.00 each for books
                        or at actuals)
                      </li>
                    </ul>
                    <p className="text-danger">
                      <em>
                        <b>Note:</b> If items subject to censorship are
                        contained in the shipment, Customs Authority will remove
                        them from the container for censorship or review. To
                        reduce the time for clearance, these items must be
                        packed separately for easy access and cartons must be
                        marked clearly on the inventory. The delivery of
                        censored articles may incur additional charges; contact
                        World2Door for additional information.
                      </em>
                    </p>
                  </div>
                </li>

                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    PROHIBITED ITEMS:
                    <ul>
                      <li>
                        Games of chance (Gambling paraphernalia) including chess
                        sets, dice, backgammon and the likes.
                      </li>
                      <li>Animal skins in commercial quantities.</li>
                      <li>
                        Weapons and firearms (Real or ornamental, antiques,
                        etc.) including guns, ammunition, fireworks, spears,
                        etc. (If imported, the owner of the goods will be
                        subject to investigation that may result in fines and/or
                        prison)
                      </li>
                      <li>
                        Military uniforms or military equipment of any kind.
                      </li>
                      <li>
                        Alcoholic beverages or food products containing alcohol
                        such as vanilla, flavoring extract, cooking wines, etc.
                      </li>
                      <li>
                        Books, wine making kits, or other items used in the
                        preparation or manufacture of alcoholic drinks and
                        beverages.
                      </li>
                      <li>Narcotics and unidentified medicines.</li>
                      <li>
                        Radio transmitter or communication equipment / devices
                        (including receivers, VHF, Marine / police bands or
                        walkie-talkies).
                      </li>
                      <li>
                        Stuffed animals (products of taxidermy), statues,
                        figurines, carving or objects depicting human or animal
                        forms.
                      </li>
                      <li>
                        Pornographic materials and literature, including fashion
                        magazines with people wearing underwear or swimsuits.
                      </li>
                      <li>
                        Any foodstuffs containing pork and related pork meat
                        products, etc.
                      </li>
                      <li>
                        Any books pertaining to religions other than Islam
                      </li>
                    </ul>
                  </div>
                </li>

                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    Documents Required Before Shipping Date:
                    <h5 className="fw-bold my-4">Saudi Arabia:</h5>
                    <p>Shipper & Consignee Details:</p>
                    <ul>
                      <li>Contact Details - Email & Phone Number</li>
                      <li>Passport copy + KSA Visa copy</li>
                      <li>KSA ID copy (Front & Back Side)</li>
                      <li>
                        Court letter (Waqala/Authorization letter) – Original
                        (Waqala is an authorization letter in which customer
                        will authorize our clearance agent at border to clear
                        the shipment on his/her behalf)
                      </li>
                      <li>
                        Expats needs to provide UAE Visa page + Emirates ID Copy
                        along with above docs.
                      </li>
                    </ul>
                    <h5 className="fw-bold my-4">Bahrain:</h5>
                    <p>Shipper & Consignee Details:</p>
                    <ul>
                      <li>
                        Contact Details - Email & Phone Number of consignee.
                      </li>
                      <li>Passport copy + Bahrain Visa copy</li>
                      <li>Bahrain ID copy (Front & Back Side)</li>
                      <li>
                        Expats needs to provide UAE Visa page + Emirates ID Copy
                        along with above docs.
                      </li>
                    </ul>
                    <h5 className="fw-bold my-4">Oman:</h5>
                    <p>Shipper & Consignee Details:</p>
                    <ul>
                      <li>Contact Details - Email & Phone Number</li>
                      <li>Passport copy + Oman Visa copy</li>
                      <li>Oman ID copy (Front & Back Side)</li>
                      <li>
                        Import Code (customer need to visit nearby immigration
                        center to obtain the same) NOC letter (Will be provided
                        by World2Door Once Import Code from customer side is in
                        hand. A scanned copy of NOC is required from customer
                        side after printing NOC on company letterhead with
                        signatory signature along with the stamp.)
                      </li>
                      <li>
                        Expats needs to provide UAE Visa page + Emirates ID Copy
                        along with above docs
                      </li>
                    </ul>
                    <h5 className="fw-bold my-4">Jordan:</h5>
                    <p>Shipper & Consignee Details:</p>
                    <ul>
                      <li>Contact Details - Email & Phone Number</li>
                      <li>Passport copy + Jordan Visa copy</li>
                      <li>Jordan ID copy (Front & Back Side)</li>
                      <li>
                        Passport copy with visa page. Customer need to be
                        present in Amman customs office to sign customs
                        formalities
                      </li>
                      <li>
                        Client need to be Jordanian nationality to get an
                        Exemption from Customs Duties. if not he/she need to pay
                        duties as per the actual. Client need to be stayed more
                        than 2 years outside Jordan to get Exemption from
                        Customs Duties
                      </li>
                      <li>
                        EIn case of absence of shipper at destination at the
                        time of clearance, an authorization letter on court
                        paper from Jordan Embassy or Consulate is required and
                        normal custom duty will apply
                      </li>
                      <li>
                        Expats needs to provide UAE Visa page + Emirates ID Copy
                        along with above docs
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
              <h3 className="fw-bold mb-4">DISCLAIMER</h3>
              <ul className="fancylist">
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    World2Door shall not provide any services to the customers
                    having Nationalities declared “Restricted Nationalities” by
                    the U.A.E. Govt. from time to time.
                  </div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    World2Door shall not provide any services to any customer
                    intending services in the countries Declared “Hostile” by
                    the U.A.E. Govt. from time to time.
                  </div>
                </li>
                <li>
                  <span>
                    <img src="assets/images/checked.svg" alt="Not found" />
                  </span>
                  <div>
                    World2Door will not trade with or provide any services to
                    OFAC and sanctioned countries.
                  </div>
                </li>
              </ul>

              <h3 className="fw-bold mb-4">DISCLAIMER</h3>
              <p>
                The World2Door terms & conditions of services shall be governed
                by and subject to the laws of United Arab Emirates, without
                regard to the choice-of- law rules.
              </p>

              <h3 className="fw-bold mb-4">DISPUTE RESOLUTION</h3>
              <p>
                “World2Door” and the “Customer” agree that any claim or dispute
                arising from the or in connection with this contract, whether
                under the Federal Law, State Law, Local Law, International
                Conventions and Regulations, shall be brought exclusively before
                the Courts of UAE.
              </p>

              <h3 className="fw-bold mb-4">
                CODE OF ETHICS AND BUSINESS CONDUCT
              </h3>
              <p>
                World2Door is committed to conducting operations with the
                highest standards of business conduct and ethics and thereby
                expects all vendors and agents to act within the spirit of our
                code of ethics and business conduct in order to maintain an
                on-going business relationship.
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
